import {DomainContract} from '@flowtwin/communication';
import {AppSizingInput} from '@state/state-service/do-mapper/model/apps/app-sizing';

export const CONTRACT_COMPUTE_SIZING = (domainObject: AppSizingInput): DomainContract[] => {
  return [
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'selectedDevice[0].flowPressure.min',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.Pressure'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'selectedDevice[0].flowTemperature.min',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.Temperature'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'processFlowPressureMin',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.Pressure'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'processFlowTemperatureMin',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.Temperature'
    },
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'actualFlowMin', PATHINDATATRANSFEROBJECT: 'min.Flow.Actual'},
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'standardFlowMin', PATHINDATATRANSFEROBJECT: 'min.Flow.Standard'},
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'massFlowMin', PATHINDATATRANSFEROBJECT: 'min.Flow.Mass'},
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'energyFlowMin', PATHINDATATRANSFEROBJECT: 'min.Flow.Energy'},
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'selectedDevice[0].dimensions.nominalDiameter.min',
      PATHINDATATRANSFEROBJECT: 'min.Meter.NominalDiameter'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'selectedDevice[0].dimensions.schedule',
      PATHINDATATRANSFEROBJECT: 'min.Meter.Schedule'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'selectedDevice[0].dimensions.innerDiameter.min',
      PATHINDATATRANSFEROBJECT: 'min.Meter.InnerDiameter'
    },

    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'selectedDevice[0].flowPressure.max',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.Pressure'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'selectedDevice[0].flowTemperature.max',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.Temperature'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'processFlowPressureMax',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.Pressure'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'processFlowTemperatureMax',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.Temperature'
    },
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'actualFlowMax', PATHINDATATRANSFEROBJECT: 'max.Flow.Actual'},
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'standardFlowMax', PATHINDATATRANSFEROBJECT: 'max.Flow.Standard'},
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'massFlowMax', PATHINDATATRANSFEROBJECT: 'max.Flow.Mass'},
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'energyFlowMax', PATHINDATATRANSFEROBJECT: 'max.Flow.Energy'},
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'selectedDevice[0].dimensions.nominalDiameter.max',
      PATHINDATATRANSFEROBJECT: 'max.Meter.NominalDiameter'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'selectedDevice[0].dimensions.schedule',
      PATHINDATATRANSFEROBJECT: 'max.Meter.Schedule'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'selectedDevice[0].dimensions.innerDiameter.max',
      PATHINDATATRANSFEROBJECT: 'max.Meter.InnerDiameter'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'measuredFlowValue',
      PATHINDATATRANSFEROBJECT: 'measuredFlowValue'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'referenceCondition.key',
      PATHINDATATRANSFEROBJECT: 'referenceCondition.key'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'referenceCondition.pressure',
      PATHINDATATRANSFEROBJECT: 'referenceCondition.pressure'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'referenceCondition.temperatureA',
      PATHINDATATRANSFEROBJECT: 'referenceCondition.temperatureA'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'referenceCondition.temperatureB',
      PATHINDATATRANSFEROBJECT: 'referenceCondition.temperatureB'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'processConditionsMin',
      PATHINDATATRANSFEROBJECT: 'combinationIndexMin'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'processConditionsMax',
      PATHINDATATRANSFEROBJECT: 'combinationIndexMax'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'gasComposition',
      PATHINDATATRANSFEROBJECT: 'gasComposition'
    },
    {
      DOMAINOBJECT: domainObject,
      PATHINDOMAINOBJECT: 'calcMode',
      PATHINDATATRANSFEROBJECT: 'calcMode'
    }
  ];
};
