import {Device, FlowCondition, InstallationType, MeasuredFlowValue, NominalSize} from '../device/properties';
import {AbstractDomainObject} from '@flowtwin/communication';
import {UUID} from '../communication/type-alias';
import {FlowQuantity, Quantity} from '../unit/quantity';
import {GasData} from '../gas/gas-composition-doc';

// TODO @Arnold: Need to think about if we want to continue using classes or if we want to use interfaces. Simply moving to interfaces is not possible atm.
export class AppSizingInput extends AbstractDomainObject {
  fetchingInputOptions: boolean;
  productFamily: string | null;
  productType: string | null;
  schedule: string | null;
  installationType: InstallationType | null;
  nominalSize: NominalSize | null;

  flowCondition: FlowCondition | null;
  gasCompositionId: UUID | null;
  referenceCondition: string | null;

  selectedDevice: Device[] | null;

  processFlowRangeMin: FlowQuantity;
  processFlowRangeMax: FlowQuantity;
  processFlowPressureMin: Quantity<'Pressure'>;
  processFlowPressureMax: Quantity<'Pressure'>;
  processFlowTemperatureMin: Quantity<'Temperature'>;
  processFlowTemperatureMax: Quantity<'Temperature'>;
  measuredFlowValue: MeasuredFlowValue;
  processConditionsMin: number; // 0 ... 3
  processConditionsMax: number; // 4 ... 7
  nozzleDistance: number;
  pressureClass: string;
  innerDiameter: Quantity<'Diameter'>;
  fullBore: boolean;
  flowConditioner: string;
  flowRangeOption: string;
  calcMode: CalcMode;
}

export type CalcMode = 'sizing-fl100' | 'sizing-fl6xt' | 'ccus-fl6xt' | 'sizing-fl500' | 'sizing-fl6xt-oiml';

export class AppSizingOutput extends AbstractDomainObject {
  devices: Device[];
  gasdata: {
    min: GasData | null;
    max: GasData | null;
  };
  ccus: CcusResult;
  lvf: LvfResults;
  warnings: GasDataWarnings;
}

export interface GasDataWarnings {
  Fl500SoSOutOfBounds: boolean;
  Fl500H2TooHigh: boolean;
  Fl500H2NotSupported: boolean;
  Fl500CO2FractionExceeded: boolean;
  Fl500MinPressureForMethaneLow: boolean;
}

export class AppSizingMetadata {
  filter: boolean;
  devices: Device[];
  data: any;
  deviceOptions: any;
}

export class CcusResult {
  PhaseIndicator: PhaseIndicator | null;
}

export class LvfResults {
  GlobalLiquidVolumeFraction: Quantity<'AmountRatio'> | null;
  GlobalPhaseState: number | null;
}

export type PhaseIndicator = 'gas' | 'liquid' | 'supercritical' | 'not-defined' | 'gas-supercritical';
