import {DomainContract} from '@flowtwin/communication';

export const CONTRACT_CREATE_TAG = (payload): DomainContract[] => {
  return [
    {DOMAINOBJECT: payload, PATHINDOMAINOBJECT: 'tagName', PATHINDATATRANSFEROBJECT: 'name'},
    {DOMAINOBJECT: payload, PATHINDOMAINOBJECT: 'tagDescription', PATHINDATATRANSFEROBJECT: 'description'},
    {DOMAINOBJECT: payload, PATHINDOMAINOBJECT: 'projectId', PATHINDATATRANSFEROBJECT: 'projectId'}
  ];
};

export const CONTRACT_SAVE_TAG = (payload): DomainContract[] => {
  return [
    {DOMAINOBJECT: payload, PATHINDOMAINOBJECT: 'id', PATHINDATATRANSFEROBJECT: 'id'},
    {DOMAINOBJECT: payload, PATHINDOMAINOBJECT: 'name', PATHINDATATRANSFEROBJECT: 'name'},
    {DOMAINOBJECT: payload, PATHINDOMAINOBJECT: 'description', PATHINDATATRANSFEROBJECT: 'description'}
  ];
};
