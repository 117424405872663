import {Injectable} from '@angular/core';
import {StateService} from '@state/state-service/state.service';
import {Pages} from '@state/actions/actions-page-state';
import {stringIncludesArrayValues} from '@shared/utility/filter-string-for-array-values';
import {ComputeActions, ComputeReduxAction} from '@state/actions/actions-compute';

interface ClearAction {
  appsizing: ComputeActions;
  appuncertainty: ComputeActions;
  apptransducer: ComputeActions;
}

const APP_TO_ACTION_MAP: ClearAction = {
  appsizing: 'CLEAR_SIZING_RESULT',
  appuncertainty: 'CLEAR_UNCERTAINTY_RESULT',
  apptransducer: 'CLEAR_TRANSDUCER_RESULT'
};

const INPUT_PATHS = [Pages.appSizing, Pages.appUncertainty, Pages.appTransducer];

@Injectable({
  providedIn: 'root'
})
export class ClearResultService {
  constructor(private stateService: StateService) {}

  clearResultByInputPath(inputPath: string) {
    const deleteResultFrom = stringIncludesArrayValues(inputPath, INPUT_PATHS);

    if (deleteResultFrom.length > 0) {
      dispatchAction(APP_TO_ACTION_MAP[deleteResultFrom], this.stateService);
    }
  }

  clearResultByApp(app: Pages.appSizing | Pages.appUncertainty | Pages.appTransducer) {
    dispatchAction(APP_TO_ACTION_MAP[app.toLowerCase()], this.stateService);
  }
}

function dispatchAction(action, stateService: StateService) {
  const clearResultAction: ComputeReduxAction = new ComputeReduxAction(action, null);

  stateService.dispatch(clearResultAction);
}
