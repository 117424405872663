import {map} from 'rxjs/operators';
import {ReduxAction} from '@state/actions/actions-abstract';
import {PageStateReduxAction} from '@state/actions/actions-page-state';
import {Injectable} from '@angular/core';
import {StateService} from '@state/state-service/state.service';
import {DeviceEpicService} from '@state/epics/device-epic.service';
import {ComputeEpicService} from '@state/epics/compute-epic.service';
import {ProjectEpicService} from '@state/epics/project-epic.service';
import {ProjectReduxAction} from '@state/actions/actions-project';
import {VersionEpicService} from '@state/epics/version-epic.service';
import {MetadataEpicService} from '@state/epics/metadata-epic.service';
import {MetadataReduxAction} from '@state/actions/actions-metadata';
import {UnitSystemEpicService} from '@state/epics/unit-system-epic.service';
import {GasEpicService} from '@state/epics/gas-epic.service';
import {DeviceReduxAction} from '@state/actions/actions-device';
import {UserEpicService} from '@state/epics/user-epic.service';
import {UserReduxAction} from '@state/actions/actions-user';
import {TableReduxAction} from '@state/actions/actions-table';
import {UserReducerService} from '@state/reducers/user-reducer.service';
import {MetadataReducerService} from '@state/reducers/metadata-reducer.service';
import {VersionReduxAction} from '@state/actions/actions-version';
import {VersionsReducerService} from '@state/reducers/versions-reducer.service';
import {ProjectReducerService} from '@state/reducers/project-reducer.service';
import {UnitSystemReduxAction} from '@state/actions/actions-unit-system';
import {UnitSystemsReducerService} from '@state/reducers/unit-systems-reducer.service';
import {GasReduxAction} from '@state/actions/actions-gas';
import {GasReducerService} from '@state/reducers/gas-reducer.service';
import {ComputeReduxAction} from '@state/actions/actions-compute';
import {ComputeReducerService} from '@state/reducers/compute-reducer.service';
import {DeviceReducerService} from '@state/reducers/device-reducer.service';
import {TableViewReducerService} from '@state/reducers/table-view-reducer.service';
import {LocaleReduxAction} from '@state/actions/actions-locale';
import {LocaleReducerService} from '@state/reducers/locale-reducer.service';
import {DisplayTextReduxAction} from '@state/actions/actions-display-text';
import {DisplayTextReducerService} from '@state/reducers/display-text-reducer.service';
import {PageStateReducerService} from '@state/reducers/page-state-reducer.service';
import {InputReducerService} from '@state/reducers/input-reducer.service';
import {InputReduxAction} from '@state/actions/actions-input';
import {ModalReducerService} from '@state/reducers/modal-reducer.service';
import {ModalReduxAction} from '@state/actions/actions-modal';
import {DependencyReduxAction} from '@state/actions/actions-dependencies';
import {DependencyReducerService} from '@state/reducers/dependency-reducer';
import {UpgradeEpicService} from '@state/epics/upgrade-epic.service';
import {UpgradeReduxAction} from '@state/actions/actions-upgrade';
import {UpgradeReducerService} from '@state/reducers/upgrade-reducer.service';
import {AppModeReduxAction} from '@state/actions/actions-app-mode';
import {AppModeEpicService} from '@state/epics/app-mode.epic.service';
import {AppModeReducerService} from '@state/reducers/app-mode-reducer.service';
import {TagReduxAction} from '@state/actions/actions-tag';
import {TagEpicService} from '@state/epics/tag-epic.service';
import {TagReducerService} from '@state/reducers/tag-reducer.service';
import {BasicProjectReduxAction} from '@state/actions/actions-basic-project';
import {BasicProjectReducerService} from '@state/reducers/basic-project-reducer.service';

@Injectable({
  providedIn: 'root'
})
export class RootReducerService {
  constructor(
    private userEpic: UserEpicService,
    private metadataEpic: MetadataEpicService,
    private versionsEpic: VersionEpicService,
    private unitSystemEpic: UnitSystemEpicService,
    private gasEpic: GasEpicService,
    private projectEpic: ProjectEpicService,
    private deviceEpic: DeviceEpicService,
    private computeEpic: ComputeEpicService,
    private upgradeEpic: UpgradeEpicService,
    private appModeEpic: AppModeEpicService,
    private tagEpic: TagEpicService
  ) {}

  initRootReducer(stateService: StateService) {
    stateService.event$.pipe(map(type => type)).subscribe(action => {
      // assigning the correct sub reducer. Function is just a broker
      this.assignReducer(action, stateService);
    });
  }

  private assignReducer(action: ReduxAction, stateService: StateService) {
    switch (action.constructor) {
      case UserReduxAction:
        UserReducerService.assignHandler(action as UserReduxAction, stateService, this.userEpic);
        break;
      case MetadataReduxAction:
        MetadataReducerService.assignHandler(action as MetadataReduxAction, stateService, this.metadataEpic);
        break;
      case VersionReduxAction:
        VersionsReducerService.assignHandler(action as VersionReduxAction, stateService, this.versionsEpic);
        break;
      case ProjectReduxAction:
        ProjectReducerService.assignHandler(action as ProjectReduxAction, stateService, this.projectEpic);
        break;
      case UnitSystemReduxAction:
        UnitSystemsReducerService.assignHandler(action as UnitSystemReduxAction, stateService, this.unitSystemEpic);
        break;
      case GasReduxAction:
        GasReducerService.assignHandler(action as GasReduxAction, stateService, this.gasEpic);
        break;
      case ComputeReduxAction:
        ComputeReducerService.assignHandler(action as ComputeReduxAction, stateService, this.computeEpic);
        break;
      case DeviceReduxAction:
        DeviceReducerService.assignHandler(action as DeviceReduxAction, stateService, this.deviceEpic);
        break;
      case TableReduxAction:
        TableViewReducerService.assignHandler(action as TableReduxAction, stateService);
        break;
      case LocaleReduxAction:
        LocaleReducerService.assignHandler(action as LocaleReduxAction, stateService);
        break;
      case DisplayTextReduxAction:
        DisplayTextReducerService.assignHandler(action as DisplayTextReduxAction, stateService);
        break;
      case PageStateReduxAction:
        PageStateReducerService.assignHandler(action as PageStateReduxAction, stateService);
        break;
      case InputReduxAction:
        InputReducerService.assignHandler(action as InputReduxAction, stateService);
        break;
      case ModalReduxAction:
        ModalReducerService.assignHandler(action as ModalReduxAction, stateService);
        break;
      case DependencyReduxAction:
        DependencyReducerService.assignHandler(action as DependencyReduxAction, stateService);
        break;
      case UpgradeReduxAction:
        UpgradeReducerService.assignHandler(action as UpgradeReduxAction, stateService, this.upgradeEpic);
        break;
      case AppModeReduxAction:
        AppModeReducerService.assignHandler(action as AppModeReduxAction, stateService, this.appModeEpic);
        break;
      case TagReduxAction:
        TagReducerService.assignHandler(action as TagReduxAction, stateService, this.tagEpic);
        break;
      case BasicProjectReduxAction:
        BasicProjectReducerService.assignHandler(action as BasicProjectReduxAction, stateService, this.projectEpic, this.tagEpic);
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any Sub-Reducer.`);
    }
  }
}
