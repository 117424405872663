import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {StateService} from '@state/state-service/state.service';
import {catchError, map, skipWhile, timeout} from 'rxjs/operators';
import {UserLoggedInState} from '@state/state-service/do-mapper/model/communication/user';
import {of} from 'rxjs';
import {UserReduxAction} from '@state/actions/actions-user';
import {INIT_LOADING_TIMEOUT} from '../../constants';

export const userLoggedInGuard: CanActivateFn = () => {
  const stateService = inject(StateService);
  const router = inject(Router);

  return stateService.state$.pipe(
    map(s => s.user.state),
    skipWhile<UserLoggedInState>(state => state === 'loading'),
    timeout(INIT_LOADING_TIMEOUT),
    map(state => {
      if (state === 'logged-in') {
        return true;
      } else if (state === 'error') {
        router.navigate(['/login-error']);
        return false;
      } else if (state === 'no-flow-twin-user') {
        router.navigate(['/no-flow-twin-user']);
        return false;
      } else {
        console.error(`Unhandled user login state ${state}. This should not happen`);
        router.navigate(['/login-error']);
        return false;
      }
    }),
    catchError(_ => {
      console.error('User logged in guard timed out');
      const userLoginError = new UserReduxAction('USER_UPDATE_CURRENT_ERROR', {});
      stateService.dispatch(userLoginError);

      router.navigate(['/login-error']);
      return of(false);
    })
  );
};
