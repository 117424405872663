import {ArrayElementTypeAnnotation} from '../model/dto';
import {accessObjectByPath} from '../../util/access-object-by-string-path';
import {checkForLibraryKnownType} from '../../util/type-checker';
import {addKnownTypeToDto} from './add-known-paths-to-dto';
import _ from 'lodash';

export function transposeDomainObject(
  transposedDomainObject: any,
  element: any,
  path: string,
  elementType: ArrayElementTypeAnnotation,
  fullPaths: any[]
) {
  if (!accessObjectByPath(transposedDomainObject, path)) {
    // create empty object to attach further nested properties
    _.set(transposedDomainObject, path, {});
  }

  const lastPropOfPath = path.substring(path.lastIndexOf('.') + 1);
  const value = accessObjectByPath(element, lastPropOfPath);

  if (typeof value === 'object' && checkForLibraryKnownType(value, elementType)) {
    if (elementType === 'DTODATE') {
      element[lastPropOfPath]['format'] = 'YYYY-MM-DDTHH:mm:ss.sssZ';
    }

    addKnownTypeToDto(element, lastPropOfPath, transposedDomainObject, path, fullPaths);
  } else if (typeof value === 'object' && !(value instanceof Array)) {
    Object.keys(value).forEach(key => {
      transposeDomainObject(transposedDomainObject, value, `${path}.${key}`, elementType, fullPaths);
    });
  } else {
    addKnownTypeToDto(element, lastPropOfPath, transposedDomainObject, path, fullPaths);
  }
}
