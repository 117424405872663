import {AbstractControl, UntypedFormControl} from '@angular/forms';

function _getFormControl(keys: string[], form: AbstractControl): UntypedFormControl {
  const [head, ...rest] = keys;
  if (rest.length === 0) {
    const f = form.get(head);
    if (!f) {
      throw Error(head);
    }
    return f as UntypedFormControl;
  } else {
    const child = form.get(head);
    if (!child) {
      throw Error(head);
    }
    return _getFormControl(rest, child as UntypedFormControl);
  }
}

export const getFormControl = (form: AbstractControl) => (keys: string[] | string) => {
  try {
    return Array.isArray(keys) ? _getFormControl(keys, form) : _getFormControl(keys.split('.'), form);
  } catch (e) {
    throw Error('getFormControl: No form control found. Missing ' + e + ' in ' + keys);
  }
};
