import {Injectable} from '@angular/core';
import {StateService} from '@state/state-service/state.service';
import {
  ApiPayloadCreateOneSuccess,
  ApiPayloadDeleteOneSuccess,
  ApiPayloadGetAllSuccess,
  ApiPayloadSaveOneSuccess,
  ApiPayloadSetSelectedTag,
  TagReduxAction
} from '@state/actions/actions-tag';
import {TagEpicService} from '@state/epics/tag-epic.service';
import {set} from 'lodash';
import {ApiComputeGasSuccessPayload} from '@state/actions/actions-compute';

@Injectable({
  providedIn: null
})
export class TagReducerService {
  static assignHandler(action: TagReduxAction, stateService: StateService, epic: TagEpicService) {
    switch (action.type) {
      case 'TAGS_GET_ALL_SUCCESS':
        TagReducerService.updateTags(action.payload as ApiPayloadGetAllSuccess, stateService);
        break;
      case 'TAGS_GET_ALL_ERROR':
        // Handled by ErrorService
        break;
      case 'TAGS_GET_ONE_LOADING':
        epic.getOne(action, stateService);
        break;
      case 'TAGS_GET_ONE_SUCCESS':
        TagReducerService.setSelectedTag(action.payload as ApiPayloadSetSelectedTag, stateService);
        break;
      case 'TAGS_GET_ONE_ERROR':
        // Handled by ErrorService
        break;
      case 'TAG_SAVE_ONE_LOADING':
        epic.saveOne(action, stateService);
        break;
      case 'TAG_SAVE_ONE_SUCCESS':
        TagReducerService.updateTagInTags(action, stateService);
        break;
      case 'TAG_SAVE_ONE_ERROR':
        // Handled by ErrorService
        break;
      case 'TAG_CREATE_ONE_LOADING':
        epic.createOne(action, stateService);
        break;
      case 'TAG_CREATE_ONE_SUCCESS':
        TagReducerService.setSelectedTag(action.payload as ApiPayloadCreateOneSuccess, stateService);
        epic.goToTagPage(stateService.state.basicProject.data!.document!.id, (action.payload as ApiPayloadCreateOneSuccess).tag.id);
        break;
      case 'TAG_CREATE_ONE_ERROR':
        // Handled by ErrorService
        break;
      case 'TAG_DELETE_ONE_LOADING':
        epic.deleteOne(action, stateService);
        break;
      case 'TAG_DELETE_ONE_SUCCESS':
        TagReducerService.deleteTag(action.payload as ApiPayloadDeleteOneSuccess, stateService);
        break;
      case 'TAG_DELETE_ONE_ERROR':
        // Handled by ErrorService
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any Project Reducer-Function.`);
        break;
    }
  }

  static updateTags(action: ApiPayloadGetAllSuccess, stateService: StateService) {
    stateService.update(
      s => {
        s.basicProject.tags = action.tags;
      },
      {
        type: 'Tags State Updated',
        details: {results: action}
      }
    );
  }

  private static updateTagInTags(action, stateService: StateService) {
    const tag = (action.payload as ApiPayloadSaveOneSuccess).tag;

    stateService.update(
      s => {
        const matchingTag = s.basicProject.tags.find(t => t.id === tag.id);

        if (!matchingTag) {
          return;
        }

        const oldTags = s.basicProject.tags.filter(t => t.id !== tag.id);
        s.basicProject.tags = [...oldTags, tag];
      },
      {
        type: 'Tags Updated'
      }
    );
  }

  static deleteTag(action: ApiPayloadDeleteOneSuccess, stateService: StateService) {
    stateService.update(
      s => {
        s.basicProject.tags = s.basicProject.tags.filter(tag => tag.id !== action.tagId);
      },
      {
        type: 'Tag removed from tags array',
        details: {results: action}
      }
    );
  }

  private static setSelectedTag(payload: ApiPayloadSetSelectedTag | ApiPayloadCreateOneSuccess, stateService: StateService) {
    stateService.update(
      s => {
        s.project.tag = payload.tag;
        set(s, 'basicProject.data.document.tag', payload.tag.name);
      },
      {
        type: 'Set selected Tag',
        details: {results: payload}
      }
    );
  }
}
