import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '@state/state-service/state.service';
import {isQuantity, SICK_BASE_UNITS} from '@state/state-service/do-mapper/model/unit/unit-system-document';
import {displayValue} from '@state/state-service/do-mapper/model/unit/display-value';
import {Quantity} from '@state/state-service/do-mapper/model/unit/quantity';

@Pipe({
  name: 'unit',
  pure: false
})
export class UnitSystemLabelPipe implements PipeTransform {
  constructor(private stateService: StateService, private translateService: TranslateService) {}

  transform(quantity: Quantity<any>, fallback: boolean = false): string {
    if (!isQuantity(quantity) && fallback) {
      return '' + quantity;
    }

    if (!isQuantity(quantity)) {
      return '' + quantity;
    }

    if (!quantity) {
      return '0';
    }

    let displayUnitSystem = this.stateService.state.basicProject.data?.custom?.displayUnitSystem;

    if (!displayUnitSystem) {
      return `${quantity.value} ${this.translateService.instant(SICK_BASE_UNITS[quantity.unit].BaseUnit)}`;
    }

    const convert = displayValue(displayUnitSystem.unitSystem);
    const value = convert(quantity);

    const conversion = displayUnitSystem.unitSystem[quantity.unit].conversion;
    const unitLabel = this.translateService.instant(conversion);

    return value + ' ' + unitLabel;
  }
}
