import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {StateService} from '@state/state-service/state.service';
import {catchError, map, skipWhile, timeout} from 'rxjs/operators';
import {of} from 'rxjs';
import {INIT_LOADING_TIMEOUT} from '../../constants';

export const projectsGuard: CanActivateFn = () => {
  const stateService = inject(StateService);
  const router = inject(Router);

  return stateService.state$.pipe(
    skipWhile(s => s.pages.projects === 'init'),
    map(() => true),
    timeout(INIT_LOADING_TIMEOUT),
    catchError(err => {
      console.error('ProjectsGuard timed out', err);

      router.navigate(['/error']);
      return of(false);
    })
  );
};
