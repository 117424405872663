import {ReduxAction} from './actions-abstract';

export type LocaleActions = 'SWITCH_LOCALE';

export interface PayloadSwitchLocale {
  language: string;
}

export type LocalePayloads = PayloadSwitchLocale;

export class LocaleReduxAction extends ReduxAction {
  type: LocaleActions;
  payload: LocalePayloads;

  constructor(type: LocaleActions, payload: LocalePayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
