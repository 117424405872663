import {ArrayElementTypeAnnotation} from '../../dto-assembly-line/model/dto';
import {detectObjectIsLibraryKnown} from '../../util/type-checker';

export function validateContentWithGivenType(arrayElementType: ArrayElementTypeAnnotation, value: any) {
  if (
    value !== undefined &&
    value !== null &&
    arrayElementType !== (typeof value).toString().toUpperCase() &&
    arrayElementType !== detectObjectIsLibraryKnown(value, arrayElementType)
  ) {
    const wrongType =
      (typeof value).toString() === 'object'
        ? detectObjectIsLibraryKnown(value, arrayElementType)
        : (typeof value).toString().toUpperCase();

    throw new Error(
      `Wrong TYPE in dto payload. Expected/Defined ${arrayElementType}. Received ${wrongType ? wrongType : 'UNKNOWN OBJECT'}.`
    );
  }
}
