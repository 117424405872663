import {DomainContract} from '@flowtwin/communication';
import {AppTransducerInput} from '@state/state-service/do-mapper/model/apps/app-transducer';
import {BasicProject} from '@state/state-service/do-mapper/model/project/project-basic';
import {AppSizingInput, AppSizingOutput} from '@state/state-service/do-mapper/model/apps/app-sizing';
import {AppUncertaintyInput} from '@state/state-service/do-mapper/model/apps/app-uncertainty';

export const CONTRACT_CREATE_PROJECT = (project: BasicProject): DomainContract[] => {
  return [
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'name', PATHINDATATRANSFEROBJECT: 'name'},
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'description', PATHINDATATRANSFEROBJECT: 'description'},
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'reference', PATHINDATATRANSFEROBJECT: 'reference'},
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'unitSystemId', PATHINDATATRANSFEROBJECT: 'unitSystemId'},
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'feVersion', PATHINDATATRANSFEROBJECT: 'feVersion'}
  ];
};

export const CONTRACT_POST_PROJECT_BASIC = (project: BasicProject): DomainContract[] => {
  return [
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'document.projectName', PATHINDATATRANSFEROBJECT: 'name'},
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'document.description', PATHINDATATRANSFEROBJECT: 'description'},
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'document.reference', PATHINDATATRANSFEROBJECT: 'reference'},
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'custom.displayUnitSystem.document.id', PATHINDATATRANSFEROBJECT: 'unitSystemId'},
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'custom.displayUnitSystem.unitSystem', PATHINDATATRANSFEROBJECT: 'unitSystem'},
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'custom.gasCompositionDocs', PATHINDATATRANSFEROBJECT: 'gasCompositions'},
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'meta.lastSaved.frontendVersion', PATHINDATATRANSFEROBJECT: 'feVersion'},
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'meta.deleted', PATHINDATATRANSFEROBJECT: 'deleted'},
    {DOMAINOBJECT: project, PATHINDOMAINOBJECT: 'meta.userList', PATHINDATATRANSFEROBJECT: 'userList'}
  ];
};

export const CONTRACT_POST_APP_SIZING_INPUT = (input: AppSizingInput): DomainContract[] => {
  return [
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'flowCondition', PATHINDATATRANSFEROBJECT: 'flowCondition'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'gasCompositionId', PATHINDATATRANSFEROBJECT: 'gasCompositionId'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'installationType', PATHINDATATRANSFEROBJECT: 'installationType'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'measuredFlowValue', PATHINDATATRANSFEROBJECT: 'measuredFlowValue'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'nominalSize', PATHINDATATRANSFEROBJECT: 'nominalSize'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'processConditionsMin', PATHINDATATRANSFEROBJECT: 'processConditionCombination.min'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'processConditionsMax', PATHINDATATRANSFEROBJECT: 'processConditionCombination.max'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'processFlowRangeMin', PATHINDATATRANSFEROBJECT: 'processFlowRangeMin'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'processFlowRangeMax', PATHINDATATRANSFEROBJECT: 'processFlowRangeMax'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'processFlowPressureMin', PATHINDATATRANSFEROBJECT: 'processFlowPressureMin'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'processFlowPressureMax', PATHINDATATRANSFEROBJECT: 'processFlowPressureMax'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'processFlowTemperatureMin', PATHINDATATRANSFEROBJECT: 'processFlowTemperatureMin'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'processFlowTemperatureMax', PATHINDATATRANSFEROBJECT: 'processFlowTemperatureMax'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'productFamily', PATHINDATATRANSFEROBJECT: 'productFamily'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'productType', PATHINDATATRANSFEROBJECT: 'productType'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'referenceCondition', PATHINDATATRANSFEROBJECT: 'referenceCondition'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'nozzleDistance', PATHINDATATRANSFEROBJECT: 'nozzleDistance'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'fullBore', PATHINDATATRANSFEROBJECT: 'fullBore'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'schedule', PATHINDATATRANSFEROBJECT: 'schedule'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'innerDiameter', PATHINDATATRANSFEROBJECT: 'innerDiameter'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'pressureClass', PATHINDATATRANSFEROBJECT: 'pressureClass'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'flowConditioner', PATHINDATATRANSFEROBJECT: 'flowConditioner'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'flowRangeOption', PATHINDATATRANSFEROBJECT: 'flowRangeOption'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'selectedDevice', PATHINDATATRANSFEROBJECT: 'selectedDevice'}
  ];
};

export const CONTRACT_POST_APP_SIZING_OUTPUT = (output: AppSizingOutput): DomainContract[] => {
  return [
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.Pressure',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.Pressure'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.Temperature',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.Temperature'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.CO2Emission',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.CO2Emission'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.CO2EmissionFactor',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.CO2EmissionFactor'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.Compressibility',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.Compressibility'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.Density',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.Density'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.KFactor',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.KFactor'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.MolarMass',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.MolarMass'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.Repeatability',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.Repeatability'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.ReynoldsNumber',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.ReynoldsNumber'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.SpeedOfSound',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.SpeedOfSound'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.Velocity',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.Velocity'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.Viscosity',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.Viscosity'
    },

    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.LiquidVolumeFraction',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.LiquidVolumeFraction'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.ActualConditions.PhaseState',
      PATHINDATATRANSFEROBJECT: 'min.ActualConditions.PhaseState'
    },

    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.Pressure',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.Pressure'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.Temperature',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.Temperature'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.CO2Emission',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.CO2Emission'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.CO2EmissionFactor',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.CO2EmissionFactor'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.Compressibility',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.Compressibility'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.Density',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.Density'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.KFactor',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.KFactor'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.MolarMass',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.MolarMass'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.Repeatability',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.Repeatability'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.ReynoldsNumber',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.ReynoldsNumber'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.SpeedOfSound',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.SpeedOfSound'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.Velocity',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.Velocity'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.Viscosity',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.Viscosity'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.LiquidVolumeFraction',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.LiquidVolumeFraction'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.StandardConditions.PhaseState',
      PATHINDATATRANSFEROBJECT: 'min.StandardConditions.PhaseState'
    },

    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'min.Flow.Actual', PATHINDATATRANSFEROBJECT: 'min.Flow.Actual'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'min.Flow.Energy', PATHINDATATRANSFEROBJECT: 'min.Flow.Energy'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'min.Flow.Mass', PATHINDATATRANSFEROBJECT: 'min.Flow.Mass'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'min.Flow.Standard', PATHINDATATRANSFEROBJECT: 'min.Flow.Standard'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'min.HeatValue.Mass', PATHINDATATRANSFEROBJECT: 'min.HeatValue.Mass'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'min.HeatValue.Volume', PATHINDATATRANSFEROBJECT: 'min.HeatValue.Volume'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'min.Meter.NominalDiameter', PATHINDATATRANSFEROBJECT: 'min.Meter.NominalDiameter'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'min.Meter.Schedule', PATHINDATATRANSFEROBJECT: 'min.Meter.Schedule'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'min.Meter.InnerDiameter', PATHINDATATRANSFEROBJECT: 'min.Meter.InnerDiameter'},
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'min.Meter.MeterInnerDiameter',
      PATHINDATATRANSFEROBJECT: 'min.Meter.MeterInnerDiameter'
    },
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'min.Meter.WallThickness', PATHINDATATRANSFEROBJECT: 'min.Meter.WallThickness'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'min.MolarMass', PATHINDATATRANSFEROBJECT: 'min.MolarMass'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'min.Velocity.GasVelocity', PATHINDATATRANSFEROBJECT: 'min.Velocity.GasVelocity'}, // max
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.Pressure',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.Pressure'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.Temperature',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.Temperature'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.CO2Emission',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.CO2Emission'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.CO2EmissionFactor',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.CO2EmissionFactor'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.Compressibility',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.Compressibility'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.Density',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.Density'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.KFactor',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.KFactor'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.MolarMass',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.MolarMass'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.Repeatability',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.Repeatability'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.ReynoldsNumber',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.ReynoldsNumber'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.SpeedOfSound',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.SpeedOfSound'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.Velocity',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.Velocity'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.Viscosity',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.Viscosity'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.LiquidVolumeFraction',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.LiquidVolumeFraction'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.ActualConditions.PhaseState',
      PATHINDATATRANSFEROBJECT: 'max.ActualConditions.PhaseState'
    },

    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.Pressure',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.Pressure'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.Temperature',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.Temperature'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.CO2Emission',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.CO2Emission'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.CO2EmissionFactor',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.CO2EmissionFactor'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.Compressibility',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.Compressibility'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.Density',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.Density'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.KFactor',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.KFactor'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.MolarMass',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.MolarMass'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.Repeatability',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.Repeatability'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.ReynoldsNumber',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.ReynoldsNumber'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.SpeedOfSound',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.SpeedOfSound'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.Velocity',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.Velocity'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.Viscosity',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.Viscosity'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.LiquidVolumeFraction',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.LiquidVolumeFraction'
    },
    {
      DOMAINOBJECT: output.gasdata,
      PATHINDOMAINOBJECT: 'max.StandardConditions.PhaseState',
      PATHINDATATRANSFEROBJECT: 'max.StandardConditions.PhaseState'
    },

    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'max.Flow.Actual', PATHINDATATRANSFEROBJECT: 'max.Flow.Actual'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'max.Flow.Energy', PATHINDATATRANSFEROBJECT: 'max.Flow.Energy'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'max.Flow.Mass', PATHINDATATRANSFEROBJECT: 'max.Flow.Mass'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'max.Flow.Standard', PATHINDATATRANSFEROBJECT: 'max.Flow.Standard'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'max.HeatValue.Mass', PATHINDATATRANSFEROBJECT: 'max.HeatValue.Mass'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'max.HeatValue.Volume', PATHINDATATRANSFEROBJECT: 'max.HeatValue.Volume'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'max.Meter.NominalDiameter', PATHINDATATRANSFEROBJECT: 'max.Meter.NominalDiameter'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'max.Meter.Schedule', PATHINDATATRANSFEROBJECT: 'max.Meter.Schedule'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'max.Meter.InnerDiameter', PATHINDATATRANSFEROBJECT: 'max.Meter.InnerDiameter'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'max.Meter.WallThickness', PATHINDATATRANSFEROBJECT: 'max.Meter.WallThickness'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'max.MolarMass', PATHINDATATRANSFEROBJECT: 'max.MolarMass'},
    {DOMAINOBJECT: output.gasdata, PATHINDOMAINOBJECT: 'max.Velocity.GasVelocity', PATHINDATATRANSFEROBJECT: 'max.Velocity.GasVelocity'},
    {DOMAINOBJECT: output.ccus, PATHINDOMAINOBJECT: 'PhaseIndicator', PATHINDATATRANSFEROBJECT: 'ccus.PhaseNotification'},
    {
      DOMAINOBJECT: output.lvf,
      PATHINDOMAINOBJECT: 'GlobalLiquidVolumeFraction',
      PATHINDATATRANSFEROBJECT: 'lvf.GlobalLiquidVolumeFraction'
    },
    {DOMAINOBJECT: output.lvf, PATHINDOMAINOBJECT: 'GlobalPhaseState', PATHINDATATRANSFEROBJECT: 'lvf.GlobalPhaseState'},

    {DOMAINOBJECT: output.warnings, PATHINDOMAINOBJECT: 'Fl500SoSOutOfBounds', PATHINDATATRANSFEROBJECT: 'warnings.Fl500SoSOutOfBounds'},
    {DOMAINOBJECT: output.warnings, PATHINDOMAINOBJECT: 'Fl500H2TooHigh', PATHINDATATRANSFEROBJECT: 'warnings.Fl500H2TooHigh'},
    {DOMAINOBJECT: output.warnings, PATHINDOMAINOBJECT: 'Fl500H2NotSupported', PATHINDATATRANSFEROBJECT: 'warnings.Fl500H2NotSupported'},
    {
      DOMAINOBJECT: output.warnings,
      PATHINDOMAINOBJECT: 'Fl500CO2FractionExceeded',
      PATHINDATATRANSFEROBJECT: 'warnings.Fl500CO2FractionExceeded'
    },
    {
      DOMAINOBJECT: output.warnings,
      PATHINDOMAINOBJECT: 'Fl500MinPressureForMethaneLow',
      PATHINDATATRANSFEROBJECT: 'warnings.Fl500MinPressureForMethaneLow'
    }
  ];
};

export const CONTRACT_POST_APP_UNCERTAINTY_INPUT = (input: AppUncertaintyInput): DomainContract[] => {
  return [
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'processDataEntries', PATHINDATATRANSFEROBJECT: 'processDataEntries'},
    // {DOMAINOBJECT: gases, PATHINDOMAINOBJECT: 'gasCompositions', PATHINDATATRANSFEROBJECT: 'gasCompositions'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'remarks', PATHINDATATRANSFEROBJECT: 'explanation'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'userLimits.lowFlowUncertainty', PATHINDATATRANSFEROBJECT: 'userLimits.lowFlowUncertainty'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'userLimits.lowFlowLimit', PATHINDATATRANSFEROBJECT: 'userLimits.lowFlowLimit'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'userLimits.highFlowUncertainty', PATHINDATATRANSFEROBJECT: 'userLimits.highFlowUncertainty'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'velocities', PATHINDATATRANSFEROBJECT: 'velocities'},
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.deviceUncertaintySetups',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.deviceUncertaintySetups'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.unpairedProbesTiming',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.unpairedProbesTiming'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomPathAngle',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomPathAngle'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.customPathAngle',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.customPathAngle'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'biasNozzleDistance',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.biasNozzleDistance'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'nominalDiameter',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.nominalDiameter'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'pathCount',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.pathCount'
    },

    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.useMachUnit',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.useMachUnit'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.envelope',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.envelope'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.agcUpperLimit',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.agcUpperLimit'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.isentropicComponentKappa',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.isentropicComponentKappa'
    }
  ];
};

export const CONTRACT_POST_APP_TRANSDUCER_INPUT = (input: AppTransducerInput): DomainContract[] => {
  return [
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'pressure.min', PATHINDATATRANSFEROBJECT: 'pressure.min'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'pressure.max', PATHINDATATRANSFEROBJECT: 'pressure.max'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'pressure.minDesign', PATHINDATATRANSFEROBJECT: 'pressure.minDesign'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'pressure.maxDesign', PATHINDATATRANSFEROBJECT: 'pressure.maxDesign'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'temperature.min', PATHINDATATRANSFEROBJECT: 'temperature.min'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'temperature.max', PATHINDATATRANSFEROBJECT: 'temperature.max'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'temperature.minDesign', PATHINDATATRANSFEROBJECT: 'temperature.minDesign'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'temperature.maxDesign', PATHINDATATRANSFEROBJECT: 'temperature.maxDesign'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'remarks', PATHINDATATRANSFEROBJECT: 'remarks'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'gasCompositionId', PATHINDATATRANSFEROBJECT: 'gasCompositionId'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'nominalDiameter', PATHINDATATRANSFEROBJECT: 'nominalDiameter'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'injectH2', PATHINDATATRANSFEROBJECT: 'injectH2'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'xH2', PATHINDATATRANSFEROBJECT: 'xH2'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'userSelectedProbe', PATHINDATATRANSFEROBJECT: 'probeVec.userSelectedProbe'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'sensorType', PATHINDATATRANSFEROBJECT: 'probeVec.selectedProbeKey'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'explosionGroup', PATHINDATATRANSFEROBJECT: 'configuration.exGroupKey'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'sensorGasSelectionMethod', PATHINDATATRANSFEROBJECT: 'configuration.gasSelectionKey'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'hsElectronics', PATHINDATATRANSFEROBJECT: 'configuration.hsElectronics'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'extendedPNwCalculation', PATHINDATATRANSFEROBJECT: 'configuration.extendedPNwCalculation'}
  ];
};

export const CONTRACT_POST_SHARE_PROJECT = (userList: string[]): DomainContract[] => {
  const userListObject = {
    userList
  };
  return [{DOMAINOBJECT: userListObject, PATHINDOMAINOBJECT: 'userList', PATHINDATATRANSFEROBJECT: 'userList'}];
};
