import {animate, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

const ANIMATION_TIME = '0.4s ease-in-out';

@Component({
  selector: 'da-dialog-fullscreen',
  templateUrl: './dialog-fullscreen.component.html',
  styleUrls: ['./dialog-fullscreen.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [style({top: '-100vh'}), animate(ANIMATION_TIME, style({top: 0}))]),
      transition(':leave', [style({top: 0}), animate(ANIMATION_TIME, style({top: '-100vh'}))])
    ]),

    trigger('fadeInOut', [
      transition(':enter', [style({opacity: 0}), animate(ANIMATION_TIME, style({opacity: 1}))]),
      transition(':leave', [style({opacity: 1}), animate(ANIMATION_TIME, style({opacity: 0}))])
    ])
  ]
})
export class DialogFullscreenComponent implements OnInit {
  @Input() open = false;
  @Output() dismiss = new EventEmitter(); // if the user clicks the gray area

  close() {
    this.dismiss.emit('close');
  }

  toggle() {
    this.open = !this.open;
  }

  constructor() {}

  ngOnInit(): void {}
}
