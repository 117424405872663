import {clone} from '@shared/utility/clone';
import {State} from '@state/model/state.model';
import {Router} from '@angular/router';
import {ReduxAction} from '@state/actions/actions-abstract';
import {Injectable} from '@angular/core';
import {defaultState} from '@state/model/default-state';
import {filter, map, take} from 'rxjs/operators';
import {RootReducerService} from '@state/reducers/root-reducer.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {getDifferenceDeep} from '@shared/utility/get-deep-diff-object';
import {removeEmptyObjects} from '@shared/utility/remove-empty-objects';
import {ILogger, LoggerConfig, LoggerService, LogLevel} from '../../services/logger/logger.service';

const loggerConfig: LoggerConfig = {
  name: '-',
  symbol: '🏗️️',
  textColor: 'white',
  backgroundColor: '#9B5DE5'
};

interface NextOptions {
  type: string;
  details?: any;
}

@Injectable({
  providedIn: 'root'
})
export class StateService {
  readonly event$ = new Subject<ReduxAction>();
  readonly state$ = new BehaviorSubject<Readonly<State>>(defaultState);
  // For debugging reasons
  readonly next$ = new Subject<{state: State; options: NextOptions}>();
  private logger: ILogger;

  constructor(private rootReducer: RootReducerService, private router: Router, private loggerService: LoggerService) {
    // connectDevTools(this)
    this.rootReducer.initRootReducer(this);

    this.logger = this.initLogger();
  }

  private initLogger() {
    loggerConfig.name = this.constructor.name;
    return this.loggerService.initLogger(loggerConfig);
  }

  get state(): Readonly<State> {
    return deepFreeze(this.state$.value);
  }

  dispatch(action: ReduxAction) {
    this.logger.log('', 'dispatch ' + action.type, LogLevel.Info, [{payload: action.payload}]);

    this.event$.next(action);
  }

  next(state: State, options: NextOptions) {
    const oldState = clone(this.state$.value);
    this.state$.next(clone(state));
    this.next$.next({state, options});

    const newState = clone(this.state$.value);

    const diffState = removeEmptyObjects(getDifferenceDeep(oldState, newState));

    this.logger.log('', options.type == null ? '' : options.type, LogLevel.Info, [{Old: oldState}, {Diff: diffState}, {New: newState}]);
  }

  update(updateFn: (state: State) => void, options: NextOptions) {
    const state = clone(this.state$.value);
    updateFn(state);
    this.next(state, options);
  }

  updateWithNewValue(updateFn: (state: State) => any, options: NextOptions) {
    const state = clone(this.state$.value);
    const newState = {
      ...state,
      ...updateFn(state)
    };
    this.next(newState, options);
  }

  get loggedIn$() {
    return this.state$.pipe(
      map(s => s.user.state),
      filter(userState => userState === 'logged-in'),
      take(1)
    );
  }
}

const deepFreeze = (obj: any) => {
  Object.keys(obj).forEach(prop => {
    if (typeof obj[prop] === 'object' && !Object.isFrozen(obj[prop])) {
      deepFreeze(obj[prop]);
    }
  });
  return Object.freeze(obj);
};
