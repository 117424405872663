import {ArrayElementTypeAnnotation} from '../dto-assembly-line/model/dto';
import _ from 'lodash';

const KNOWN_TYPES = [
  {elementType: 'DTOQUANTITY', identifier: ['unit', 'value', 'description']},
  {elementType: 'DTOKEYVALUEITEM', identifier: ['key', 'value']},
  {elementType: 'DTOERROR', identifier: ['severity', 'globalMessage', 'details']},
  {elementType: 'DTODATE', identifier: ['value']}
];

export function checkForLibraryKnownType(value: any, elementType: ArrayElementTypeAnnotation) {
  const identifier = KNOWN_TYPES.find(x => x.elementType === elementType)?.identifier;

  if (identifier && value) {
    return _.isEqual(Object.keys(value).sort(), identifier.sort());
  }

  return false;
}

export function detectObjectIsLibraryKnown(value: any, elementType: ArrayElementTypeAnnotation): string {
  const type = KNOWN_TYPES.find(x => _.isEqual(x.identifier.sort(), Object.keys(value).sort()));

  if (type?.identifier && value) {
    return type.elementType;
  }
}
