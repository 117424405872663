import {ReduxAction} from './actions-abstract';
import {Metadata} from '../state-service/do-mapper/model/project/metadata';
import {GasData_DTO} from '@state/state-service/dto-mapper/model/project/flow-twin-project-dto';
import {AppTransducerInput, AppTransducerOutput} from '@state/state-service/do-mapper/model/apps/app-transducer';
import {GasCompositionDoc} from '@state/state-service/do-mapper/model/gas/gas-composition-doc';
import {AppUncertaintyInput, AppUncertaintyOutput} from '@state/state-service/do-mapper/model/apps/app-uncertainty';
import {AppSizingInput, PhaseIndicator} from '@state/state-service/do-mapper/model/apps/app-sizing';
import {User} from '@state/state-service/do-mapper/model/communication/user';
import {Quantity} from '@state/state-service/do-mapper/model/unit/quantity';

export type ComputeActions =
  | 'COMPUTE_GASES_LOAD'
  | 'COMPUTE_GASES_SUCCESS'
  | 'COMPUTE_GASES_ERROR'
  | 'COMPUTE_UNCERTAINTY_LOAD'
  | 'COMPUTE_UNCERTAINTY_SUCCESS'
  | 'COMPUTE_UNCERTAINTY_ERROR'
  | 'COMPUTE_TRANSDUCER_PERFORMANCE_LOAD'
  | 'COMPUTE_TRANSDUCER_PERFORMANCE_SUCCESS'
  | 'COMPUTE_TRANSDUCER_PERFORMANCE_ERROR'
  | 'CLEAR_SIZING_RESULT'
  | 'CLEAR_UNCERTAINTY_RESULT'
  | 'CLEAR_TRANSDUCER_RESULT';

export interface ApiComputeGasLoadPayload {
  appSizingInput: AppSizingInput;
}

export interface ApiComputeGasSuccessPayload {
  gasData: {
    min: GasData_DTO | null;
    max: GasData_DTO | null;
  };

  ccus: {
    PhaseIndicator: PhaseIndicator | null;
  };

  lvf: {
    GlobalLiquidVolumeFraction: Quantity<'AmountRatio'>;
    GlobalPhaseState: number;
  };

  warnings?: ApiGasDataWarnings;
}

export interface ApiGasDataWarnings {
  Fl500SoSOutOfBounds: boolean;
  Fl500H2TooHigh: boolean;
  Fl500H2NotSupported: boolean;
  Fl500CO2FractionExceeded: boolean;
  Fl500MinPressureForMethaneLow: boolean;
}

export interface ApiComputeUncertaintyLoadPayload {
  input: AppUncertaintyInput;
  gases: GasCompositionDoc[];
  metadata: Metadata;
}

export interface ApiComputeUncertaintySuccessPayload {
  results: AppUncertaintyOutput;
}

export interface ApiComputeTransducerPerformanceLoadPayload {
  input: AppTransducerInput;
  user: User;
  gases: GasCompositionDoc[];
  customGases: GasCompositionDoc[];
}

export interface ApiComputeTransducerPerformanceSuccessPayload {
  results: AppTransducerOutput;
}

export type ComputePayloads =
  | ApiComputeGasLoadPayload
  | ApiComputeGasSuccessPayload
  | ApiComputeUncertaintyLoadPayload
  | ApiComputeUncertaintySuccessPayload
  | ApiComputeTransducerPerformanceLoadPayload
  | ApiComputeTransducerPerformanceSuccessPayload;

export class ComputeReduxAction extends ReduxAction {
  type: ComputeActions;
  payload: ComputePayloads;

  constructor(type: ComputeActions, payload: ComputePayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
