import {DomainContract, DtoContract} from '../../contracts/contracts';
import _ from 'lodash';
import {getMissingPaths} from '../../util/get-missing-paths';
import {getNestedKeys} from '../../util/get-nested-keys';
import {cutOffKnownDatatypePathNestings} from '../../util/cut-off-known-datatype-path-nestings';

export interface EqualityResult {
  isEqual: boolean;
  diff: string[];
}

const forbiddenPaths = ['DTOQUANTITY', 'DTOKEYVALUEITEM', 'DTOERROR', 'DTODATE'];

export function assertArrayContentEquality(domainContracts: DomainContract[], dtoContracts: DtoContract[]): EqualityResult {
  let intersection = [];
  const diff = [];

  const desiredPaths = domainContracts.map(dc => dc.PATHINDOMAINOBJECT).sort();

  domainContracts[0].DOMAINOBJECT.forEach(domainObject => {
    let containsDesired = false;

    let domainObjectPaths = getNestedKeys(domainObject).sort();

    const dtoTypes = dtoContracts.map(dc => dc.ARRAY);

    dtoTypes.forEach(dtoType => {
      if (forbiddenPaths.includes(dtoType.toString())) {
        switch (dtoType) {
          case 'DTODATE':
            domainObjectPaths = cutOffKnownDatatypePathNestings(domainObjectPaths, 'value');
            domainObjectPaths = cutOffKnownDatatypePathNestings(domainObjectPaths, 'format');
            domainObjectPaths = _.uniq(domainObjectPaths);
            break;

          case 'DTOQUANTITY':
            domainObjectPaths = cutOffKnownDatatypePathNestings(domainObjectPaths, 'value');
            domainObjectPaths = cutOffKnownDatatypePathNestings(domainObjectPaths, 'unit');
            domainObjectPaths = cutOffKnownDatatypePathNestings(domainObjectPaths, 'description');

            domainObjectPaths = _.uniq(domainObjectPaths);
            break;

          case 'DTOKEYVALUEITEM':
            domainObjectPaths = cutOffKnownDatatypePathNestings(domainObjectPaths, 'value');
            domainObjectPaths = cutOffKnownDatatypePathNestings(domainObjectPaths, 'key');

            domainObjectPaths = _.uniq(domainObjectPaths);
            break;

          case 'DTOERROR':
            domainObjectPaths = cutOffKnownDatatypePathNestings(domainObjectPaths, 'severity');
            domainObjectPaths = cutOffKnownDatatypePathNestings(domainObjectPaths, 'globalMessage');
            domainObjectPaths = cutOffKnownDatatypePathNestings(domainObjectPaths, 'details');

            domainObjectPaths = _.uniq(domainObjectPaths);
            break;
        }
      }
    });

    intersection = _.intersection(domainObjectPaths, desiredPaths);

    containsDesired = _.isEqual(desiredPaths, intersection);

    if (!containsDesired) {
      const missingPaths = getMissingPaths(desiredPaths, domainObjectPaths);
      diff.push(missingPaths);
    }
  });

  return {
    isEqual: diff.length <= 0,
    diff
  } as EqualityResult;
}
