import {SharedDbDocument} from '../project/shared-db-document';
import {Quantity} from '../unit/quantity';
import {Flow, HeatValue, Meter, Velocity} from '../device/properties';
import {AbstractDomainObject} from '@flowtwin/communication';

export const createGasCompositionDoc: (props?: Partial<GasCompositionDoc>) => GasCompositionDoc = props => ({
  document: {
    id: props?.document?.id || '',
    name: props?.document?.name || '',
    description: props?.document?.description || '',
    userList: props?.document?.userList || [],
    deleted: props?.document?.deleted || false,
    creator: props?.document?.creator || ''
  },
  gasComposition: createGasComposition(props?.gasComposition),
  description: props?.document?.description || ''
});

export const createGasComposition: (props?: Partial<GasComposition>) => GasComposition = props => ({
  argon: {value: 0, unit: 'MolAmountRatio'},
  carbonDioxide: {value: 0, unit: 'MolAmountRatio'},
  carbonMonoxide: {value: 0, unit: 'MolAmountRatio'},
  ethane: {value: 0, unit: 'MolAmountRatio'},
  helium: {value: 0, unit: 'MolAmountRatio'},
  hydrogen: {value: 0, unit: 'MolAmountRatio'},
  hydrogenSulfide: {value: 0, unit: 'MolAmountRatio'},
  iButane: {value: 0, unit: 'MolAmountRatio'},
  iPentane: {value: 0, unit: 'MolAmountRatio'},
  methane: {value: 0, unit: 'MolAmountRatio'},
  nButane: {value: 0, unit: 'MolAmountRatio'},
  nDecane: {value: 0, unit: 'MolAmountRatio'},
  nHeptane: {value: 0, unit: 'MolAmountRatio'},
  nHexane: {value: 0, unit: 'MolAmountRatio'},
  nitrogen: {value: 0, unit: 'MolAmountRatio'},
  nNonane: {value: 0, unit: 'MolAmountRatio'},
  nOctane: {value: 0, unit: 'MolAmountRatio'},
  nPentane: {value: 0, unit: 'MolAmountRatio'},
  oxygen: {value: 0, unit: 'MolAmountRatio'},
  propane: {value: 0, unit: 'MolAmountRatio'},
  water: {value: 0, unit: 'MolAmountRatio'},
  ...props
});

export class GasCompositionDoc extends AbstractDomainObject {
  gasComposition: GasComposition;
  document: SharedDbDocument;
}

export interface GasComposition {
  argon: Quantity<'MolAmountRatio'>;
  carbonDioxide: Quantity<'MolAmountRatio'>;
  carbonMonoxide: Quantity<'MolAmountRatio'>;
  ethane: Quantity<'MolAmountRatio'>;
  helium: Quantity<'MolAmountRatio'>;
  hydrogen: Quantity<'MolAmountRatio'>;
  hydrogenSulfide: Quantity<'MolAmountRatio'>;
  iButane: Quantity<'MolAmountRatio'>;
  iPentane: Quantity<'MolAmountRatio'>;
  methane: Quantity<'MolAmountRatio'>;
  nButane: Quantity<'MolAmountRatio'>;
  nDecane: Quantity<'MolAmountRatio'>;
  nHeptane: Quantity<'MolAmountRatio'>;
  nHexane: Quantity<'MolAmountRatio'>;
  nitrogen: Quantity<'MolAmountRatio'>;
  nNonane: Quantity<'MolAmountRatio'>;
  nOctane: Quantity<'MolAmountRatio'>;
  nPentane: Quantity<'MolAmountRatio'>;
  oxygen: Quantity<'MolAmountRatio'>;
  propane: Quantity<'MolAmountRatio'>;
  water: Quantity<'MolAmountRatio'>;
}

export interface GasData {
  ActualConditions?: GasProperties;
  Flow?: Flow;
  HeatValue?: HeatValue;
  Meter?: Meter;
  StandardConditions?: GasProperties;
  Velocity?: Velocity;
  MolarMass?: Quantity<'MolarMass'>;
}

export interface GasProperties {
  Temperature: Quantity<'Temperature'>;
  Pressure: Quantity<'Pressure'>;
  Compressibility?: Quantity<'Numeral'>;
  MolarMass?: Quantity<'MolarMass'>;
  SpeedOfSound?: Quantity<'Speed'>;
  Velocity?: Quantity<'Speed'> | null;
  Repeatability?: Quantity<'Numeral'> | null;
  KFactor?: Quantity<'Numeral'> | null;
  Density?: Quantity<'Density'>;
  Viscosity?: Quantity<'Numeral'> | null;
  ReynoldsNumber?: Quantity<'Numeral'> | null;
  CO2EmissionFactor?: Quantity<'Numeral'> | null;
  CO2Emission?: Quantity<'Numeral'> | null;
  LiquidVolumeFraction?: Quantity<'AmountRatio'> | null;
  PhaseState?: number | null;
}
