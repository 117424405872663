import {accessObjectByPath} from '../../util/access-object-by-string-path';
import {Item} from '../model/dto';
import {undefinedChecker} from '../util/undefined-checker';

export function assembleDtoArrayComplex(assembledObject, elem, payload) {
  const domainObject = accessObjectByPath(elem.DOMAINOBJECT, elem.PATHINDOMAINOBJECT);
  domainObject.forEach((ae, i) => {
    assembledObject = {
      key: elem.KEY,
      type: 'ARRAY<' + elem.ARRAY[i] + '>',
      value: accessObjectByPath(elem.DOMAINOBJECT, elem.PATHINDOMAINOBJECT + '[' + i + ']')
    } as Item;

    undefinedChecker(payload, assembledObject);
  });
}
