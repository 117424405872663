import {ReduxAction} from './actions-abstract';
import {User} from '@state/state-service/do-mapper/model/communication/user';
import {GenericModalPayload} from '@state/actions/actions-modal';

export type UserActions =
  | 'USER_GET_ALL_LOAD'
  | 'USER_GET_ALL_SUCCESS'
  | 'USER_GET_ALL_ERROR'
  | 'USER_GET_CURRENT_LOAD'
  | 'USER_GET_CURRENT_SUCCESS'
  | 'USER_GET_CURRENT_ERROR'
  | 'USER_GET_CURRENT_NOT_FLOW_TWIN'
  | 'USER_UPDATE_CURRENT_LOAD'
  | 'USER_UPDATE_CURRENT_SUCCESS'
  | 'USER_UPDATE_CURRENT_ERROR'
  | 'USER_DELETE_LOAD'
  | 'USER_DELETE_SUCCESS'
  | 'USER_DELETE_ERROR'
  | 'USER_POST_LOAD'
  | 'USER_POST_SUCCESS'
  | 'USER_POST_ERROR';

export interface ApiPayloadGeneric {
  user?: User;
  users?: User[];
}

export interface ApiPayloadGetOneSuccess {
  user: User;
}

export interface ApiPayloadGetCurrentSuccess {
  user: User;
}

export type UserPayloads = GenericModalPayload | ApiPayloadGeneric | ApiPayloadGetOneSuccess | ApiPayloadGetCurrentSuccess;

export class UserReduxAction extends ReduxAction {
  type: UserActions;
  payload: UserPayloads;

  constructor(type: UserActions, payload: UserPayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
