import {createGasCompositionDoc} from './gas';
import {GasData} from './gas-composition-doc';

export declare const DEFAULT_GAS_COMPOSITION_UUID = 'DEFAULT_GAS_COMPOSITION_UUID';

const amarilloGas = createGasCompositionDoc('default-gas-amarillo', 'Amarillo', {
  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 0.4676},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 4.5279},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.1037},
  iPentane: {unit: 'MolAmountRatio', value: 0.0321},
  methane: {unit: 'MolAmountRatio', value: 90.6724},
  nButane: {unit: 'MolAmountRatio', value: 0.1563},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0.0393},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.0443},
  nitrogen: {unit: 'MolAmountRatio', value: 3.1284},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 0.828},
  water: {unit: 'MolAmountRatio', value: 0}
});

const ekofiskGas = createGasCompositionDoc('default-gas-ekofisk', 'Ekofisk', {
  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 1.4954},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 8.4919},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.3486},
  iPentane: {unit: 'MolAmountRatio', value: 0.0509},
  methane: {unit: 'MolAmountRatio', value: 85.9063},
  nButane: {unit: 'MolAmountRatio', value: 0.3506},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.048},
  nitrogen: {unit: 'MolAmountRatio', value: 1.0068},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 2.3015},
  water: {unit: 'MolAmountRatio', value: 0}
});

const gulfCoastGas = createGasCompositionDoc('default-gas-gulf-coast', 'GulfCoast', {
  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 0.5956},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 1.8186},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.0977},
  iPentane: {unit: 'MolAmountRatio', value: 0.0473},
  methane: {unit: 'MolAmountRatio', value: 96.5222},
  nButane: {unit: 'MolAmountRatio', value: 0.1007},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0.0664},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.0324},
  nitrogen: {unit: 'MolAmountRatio', value: 0.2595},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 0.4596},
  water: {unit: 'MolAmountRatio', value: 0}
});

const naturalGasLGas = createGasCompositionDoc('default-gas-natural-gas-l', 'NaturalGasL', {
  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 1.56},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 4.3},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.15},
  iPentane: {unit: 'MolAmountRatio', value: 0.04},
  methane: {unit: 'MolAmountRatio', value: 82.74},
  nButane: {unit: 'MolAmountRatio', value: 0.15},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.04},
  nitrogen: {unit: 'MolAmountRatio', value: 10.32},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 0.7},
  water: {unit: 'MolAmountRatio', value: 0}
});
const naturalGasHGas = createGasCompositionDoc('default-gas-natural-gas-h', 'NaturalGasH', {
  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 0.03},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 1.2},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.051},
  iPentane: {unit: 'MolAmountRatio', value: 0.014},
  methane: {unit: 'MolAmountRatio', value: 97.7},
  nButane: {unit: 'MolAmountRatio', value: 0.051},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.014},
  nitrogen: {unit: 'MolAmountRatio', value: 0.7},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 0.24},
  water: {unit: 'MolAmountRatio', value: 0}
});

const flareGasMediumMolarMassGas = createGasCompositionDoc('default-gas-flare-gas-medium-molar-mass', 'FlareGasMediumMolarMass', {
  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 1.4004},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 18.9489},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0.0009},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.2033},
  iPentane: {unit: 'MolAmountRatio', value: 0.0472},
  methane: {unit: 'MolAmountRatio', value: 76.3433},
  nButane: {unit: 'MolAmountRatio', value: 0.3974},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0.0186},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.0486},
  nitrogen: {unit: 'MolAmountRatio', value: 0.5048},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 2.0865},
  water: {unit: 'MolAmountRatio', value: 0}
});
const flareGasHighMolarMassGas = createGasCompositionDoc('default-gas-flare-gas-high-molar-mass', 'FlareGasHighMolarMass', {
  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 0},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 0},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 10},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0},
  iPentane: {unit: 'MolAmountRatio', value: 0},
  methane: {unit: 'MolAmountRatio', value: 0},
  nButane: {unit: 'MolAmountRatio', value: 90},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0},
  nitrogen: {unit: 'MolAmountRatio', value: 0},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 0},
  water: {unit: 'MolAmountRatio', value: 0}
});
const flareGasLowMolarMassGas = createGasCompositionDoc('default-gas-flare-gas-low-molar-mass', 'FlareGasLowMolarMass', {
  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 6.2332},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 0.316},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 66.6667},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.0044},
  iPentane: {unit: 'MolAmountRatio', value: 0.002},
  methane: {unit: 'MolAmountRatio', value: 26.0855},
  nButane: {unit: 'MolAmountRatio', value: 0.0058},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0.0017},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.0034},
  nitrogen: {unit: 'MolAmountRatio', value: 0.651},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 0.0302},
  water: {unit: 'MolAmountRatio', value: 0}
});

const ambientAirGas = createGasCompositionDoc('default-gas-ambient-air', 'AmbientAir', {
  argon: {unit: 'MolAmountRatio', value: 0.9254},
  carbonDioxide: {unit: 'MolAmountRatio', value: 0.0297},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 0},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0},
  iPentane: {unit: 'MolAmountRatio', value: 0},
  methane: {unit: 'MolAmountRatio', value: 0},
  nButane: {unit: 'MolAmountRatio', value: 0},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0},
  nitrogen: {unit: 'MolAmountRatio', value: 78.0997},
  oxygen: {unit: 'MolAmountRatio', value: 20.9453},
  propane: {unit: 'MolAmountRatio', value: 0},
  water: {unit: 'MolAmountRatio', value: 0}
});

const nitrogenGas = createGasCompositionDoc('default-gas-nitrogen', 'Nitrogen', {
  nitrogen: {unit: 'MolAmountRatio', value: 100}
});

const oxygenGas = createGasCompositionDoc('default-gas-oxygen', 'Oxygen', {
  oxygen: {unit: 'MolAmountRatio', value: 100}
});

export const DEFAULT_GASES_DO = [
  amarilloGas,
  ekofiskGas,
  gulfCoastGas,
  naturalGasLGas,
  naturalGasHGas,
  flareGasMediumMolarMassGas,
  flareGasHighMolarMassGas,
  flareGasLowMolarMassGas,
  ambientAirGas,
  nitrogenGas,
  oxygenGas
];

export const defaultGasData: (gasdata?: Partial<GasData>) => GasData = (gasdata = {}) => ({
  ActualConditions: {
    Pressure: {value: 0, unit: 'Pressure'},
    Temperature: {value: 0, unit: 'Temperature'},
    CO2Emission: {value: 0, unit: 'Numeral'},
    CO2EmissionFactor: {value: 0, unit: 'Numeral'},
    Compressibility: {value: 0, unit: 'Numeral'},
    Density: {value: 0, unit: 'Density'},
    KFactor: {value: 0, unit: 'Numeral'},
    MolarMass: {value: 0, unit: 'MolarMass'},
    Repeatability: {value: 0, unit: 'Numeral'},
    ReynoldsNumber: {value: 0, unit: 'Numeral'},
    SpeedOfSound: {value: 0, unit: 'Speed'},
    Velocity: {value: 0, unit: 'Speed'},
    Viscosity: {value: 0, unit: 'Numeral'},
    LiquidVolumeFraction: {value: 0, unit: 'AmountRatio'},
    PhaseState: 1
  },
  StandardConditions: {
    Pressure: {value: 0, unit: 'Pressure'},
    Temperature: {value: 0, unit: 'Temperature'},
    CO2Emission: {value: 0, unit: 'Numeral'},
    CO2EmissionFactor: {value: 0, unit: 'Numeral'},
    Compressibility: {value: 0, unit: 'Numeral'},
    Density: {value: 0, unit: 'Density'},
    KFactor: {value: 0, unit: 'Numeral'},
    MolarMass: {value: 0, unit: 'MolarMass'},
    Repeatability: {value: 0, unit: 'Numeral'},
    ReynoldsNumber: {value: 0, unit: 'Numeral'},
    SpeedOfSound: {value: 0, unit: 'Speed'},
    Velocity: {value: 0, unit: 'Speed'},
    Viscosity: {value: 0, unit: 'Numeral'},
    LiquidVolumeFraction: {value: 0, unit: 'AmountRatio'},
    PhaseState: 1
  },
  Flow: {
    Actual: {value: 0, unit: 'OperationalFlow'},
    Energy: {value: 0, unit: 'EnergyFlow'},
    Mass: {value: 0, unit: 'MassFlow'},
    Standard: {value: 0, unit: 'NormalizedFlow'}
  },
  HeatValue: {
    Mass: {value: 0, unit: 'HeatValue'},
    Volume: {value: 0, unit: 'HeatValue'}
  },
  Meter: {
    NominalDiameter: {value: 0, unit: 'Diameter'},
    InnerDiameter: {value: 0, unit: 'Diameter'},
    WallThickness: {value: 0, unit: 'Diameter'},
    MeterInnerDiameter: {value: 0, unit: 'Diameter'}
  },
  MolarMass: {value: 0, unit: 'MolarMass'},
  Velocity: {
    GasVelocity: {value: 0, unit: 'Speed'}
  },
  ...gasdata
});
