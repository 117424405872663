import {ReduxAction} from './actions-abstract';
import {LOADING_STATE} from '../model/state.model';

export enum Pages {
  projects = 'projects',
  project = 'project',
  gases = 'gases',
  customUnits = 'customUnits',
  users = 'users',
  mock = 'mock',
  crm = 'crm',
  appSizing = 'appSizing',
  appUncertainty = 'appUncertainty',
  appTransducer = 'appTransducer'
}

export type PageStateActions = 'UPDATE_PAGE_STATE';

export interface PayloadPageState {
  page: Pages;
  state: LOADING_STATE;
}

export type PageStatePayloads = PayloadPageState;

export class PageStateReduxAction extends ReduxAction {
  type: PageStateActions;
  payload: PageStatePayloads;

  constructor(type: PageStateActions, payload: PageStatePayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
