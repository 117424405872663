import {environment} from '@env/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from '@state/state-service/error.service';
import {StateService} from '@state/state-service/state.service';
import {OkResponse} from '@state/state-service/do-mapper/model/communication/commands';
import {CONTRACTS, Dto, DtoAssemblyDirector, DtoDefinition} from '../../../../../communication';
import {map} from 'rxjs/operators';
import {GenericModalPayload} from '@state/actions/actions-modal';
import {ReduxAction} from '@state/actions/actions-abstract';
import {
  ApiPayloadCreateOneLoading,
  ApiPayloadCreateOneSuccess,
  ApiPayloadDeleteOneLoading,
  ApiPayloadGetAllLoading,
  ApiPayloadGetOneLoading,
  ApiPayloadSaveOneLoading,
  ApiPayloadSaveOneSuccess,
  TagReduxAction
} from '@state/actions/actions-tag';
import {DoMapperTag} from '@state/state-service/do-mapper/mappers/tag';
import {CONTRACT_CREATE_TAG, CONTRACT_SAVE_TAG} from '@state/state-service/dto-mapper/domain-contracts/tag';
import {Router} from '@angular/router';
import {Tag} from '@state/state-service/do-mapper/model/tag/tag';
import {ApiErrorHandleConfig, handleApiError} from '@state/epics/utility/handle-api-error';

const projectsUrl = environment.url.aws + '/projects';
const tagSlug = 'tags';

@Injectable({
  providedIn: 'root'
})
export class TagEpicService {
  constructor(private http: HttpClient, private errorService: ErrorService, private router: Router) {}

  async getAll(action: TagReduxAction, stateService: StateService) {
    try {
      await stateService.loggedIn$.toPromise();

      const {projectId} = action.payload as ApiPayloadGetAllLoading;

      const tags = await this.http
        .get<OkResponse<Dto>>(`${projectsUrl}/${projectId}/${tagSlug}`)
        .pipe(
          map(res => {
            return Object.keys(res.data).length ? DoMapperTag.mapToTags(res.data) : [];
          })
        )
        .toPromise();

      const successAction: TagReduxAction = new TagReduxAction('TAGS_GET_ALL_SUCCESS', {tags});

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'TAGS_GET_ALL_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async getOne(action: TagReduxAction, stateService: StateService) {
    try {
      await stateService.loggedIn$.toPromise();

      const {projectId, tagId} = action.payload as ApiPayloadGetOneLoading;

      const tag = await this.http
        .get<OkResponse<Dto>>(`${projectsUrl}/${projectId}/${tagSlug}/${tagId}`)
        .pipe(
          map(res => {
            return DoMapperTag.mapToTag(res.data);
          })
        )
        .toPromise();

      const successAction: TagReduxAction = new TagReduxAction('TAGS_GET_ONE_SUCCESS', {tag});

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'TAGS_GET_ONE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async createOne(action: TagReduxAction, stateService: StateService) {
    try {
      await stateService.loggedIn$.toPromise();

      const {projectId, tagName, tagDescription} = action.payload as ApiPayloadCreateOneLoading;

      const tagDto: Dto = new DtoAssemblyDirector(
        'TAG_CREATE' as DtoDefinition,
        CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_CREATE_TAG.config,
        CONTRACT_CREATE_TAG({projectId, tagName, tagDescription})
      )
        .assembleDto()
        .collect();

      const createdTag = await this.http
        .post<OkResponse<any>>(`${projectsUrl}/${projectId}/${tagSlug}/create`, tagDto)
        .pipe(map(res => res.data.tag.payload))
        .toPromise();

      const createdTagDo: Tag = {
        created: createdTag.find(e => e.key === 'created').value,
        name: createdTag.find(e => e.key === 'name').value,
        description: createdTag.find(e => e.key === 'description').value,
        id: createdTag.find(e => e.key === 'id').value
      };

      const successAction: TagReduxAction = new TagReduxAction('TAG_CREATE_ONE_SUCCESS', <ApiPayloadCreateOneSuccess>{
        tag: createdTagDo
      });

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'TAG_CREATE_ONE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }
  async saveOne(action: TagReduxAction, stateService: StateService) {
    try {
      await stateService.loggedIn$.toPromise();

      const {projectId, data: tag} = action.payload as ApiPayloadSaveOneLoading;

      const tagDto: Dto = new DtoAssemblyDirector(
        'TAG_CREATE' as DtoDefinition,
        CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_TAG.config,
        CONTRACT_SAVE_TAG(tag)
      )
        .assembleDto()
        .collect();

      const patchedTag = await this.http
        .patch<OkResponse<any>>(`${projectsUrl}/${projectId}/${tagSlug}/${tag.id}/save`, tagDto)
        .pipe(map(res => res.data.tag.payload))
        .toPromise();

      const patchedTagDo: Tag = {
        created: patchedTag.find(e => e.key === 'created').value,
        name: patchedTag.find(e => e.key === 'name').value,
        description: patchedTag.find(e => e.key === 'description').value,
        id: patchedTag.find(e => e.key === 'id').value
      };

      const successAction: TagReduxAction = new TagReduxAction('TAG_SAVE_ONE_SUCCESS', <ApiPayloadSaveOneSuccess>{
        tag: patchedTagDo
      });

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'TAG_SAVE_ONE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async deleteOne(action: TagReduxAction, stateService: StateService) {
    try {
      await stateService.loggedIn$.toPromise();

      const {projectId, tagId} = action.payload as ApiPayloadDeleteOneLoading;

      const deleteTagUrl = `${projectsUrl}/${projectId}/${tagSlug}/${tagId}/delete`;

      await this.http.delete(deleteTagUrl).toPromise();

      const successAction: TagReduxAction = new TagReduxAction('TAG_DELETE_ONE_SUCCESS', {tagId});

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'TAG_DELETE_ONE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  goToTagPage(projectId: string, tagId: string) {
    this.router.navigate(['/projects', projectId, 'tags', tagId]);
  }
}
