import {ErrorHandler, Injectable} from '@angular/core';
import {StateService} from '@state/state-service/state.service';
import {State} from '@state/model/state.model';

@Injectable({
  providedIn: null
})
export class AppErrorHandler implements ErrorHandler {
  constructor(private stateService: StateService) {}

  handleError(error) {
    console.error(error);

    const state: State = this.stateService.state;
    if (state.dialogs.error.open === false) {
      state.dialogs.error.open = true;
      state.splash.open = false;
      state.dialogs.error.error = error;
      this.stateService.next(state, null);
    }
  }
}

export const AppErrorHandlerProvider = {
  provide: ErrorHandler,
  useClass: AppErrorHandler
};
