import {AppTransducerInput} from '@state/state-service/do-mapper/model/apps/app-transducer';
import {DoAssemblyDirector, Dto, DtoDoPropCollectionMap} from '@flowtwin/communication';

export class DoMapperTransducer {
  static mapToAppTransducerInput(domainContract: DtoDoPropCollectionMap[], typedDto: Dto): AppTransducerInput {
    return new DoAssemblyDirector(typedDto, domainContract)
      .createInstance<AppTransducerInput>(AppTransducerInput)
      .assembleDo<AppTransducerInput>()
      .collect();
  }
}
