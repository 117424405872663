import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '@core/material/material.module';

import {DialogComponent} from './dialog/dialog.component';
import {ExceptionDialogComponent} from './exception-dialog/exception-dialog.component';
import {ModalComponent} from './modal/modal.component';

import {TranslateModule} from '@ngx-translate/core';
import {DialogFullscreenComponent} from './dialog-fullscreen/dialog-fullscreen.component';
import {ShareComponent} from './share/share.component';
import {UIComponentsModule} from '@ui/ui-components/ui-components.module';
import {EditRecordComponent} from '@ui/dialog/edit-record/edit-record.component';
import {ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';

@NgModule({
  declarations: [
    DialogComponent,
    ModalComponent,
    ExceptionDialogComponent,
    DialogFullscreenComponent,
    ShareComponent,
    ShareComponent,
    EditRecordComponent
  ],
  imports: [CommonModule, MaterialModule, TranslateModule, UIComponentsModule, ReactiveFormsModule, FormlyModule],
  exports: [DialogComponent, DialogFullscreenComponent, ExceptionDialogComponent, ModalComponent, ShareComponent, EditRecordComponent]
})
export class DialogModule {}
