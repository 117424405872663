<div [id]="id">
  <div class="rangeLabel">
    <div
      (click)="precisionDialog.openDialog()"
      [matTooltip]="'form-range-slider.precisionEdit.tooltip' | translate"
      matTooltipPosition="above"
    >
      {{ label | translate }}
    </div>
    <mat-icon
      [matTooltip]="'form-range-slider.precisionEdit.tooltip' | translate"
      matTooltipPosition="above"
      (click)="precisionDialog.openDialog()"
      >create
    </mat-icon>
  </div>

  <da-range-slider
    [label]="label"
    [maxLabel]="maxLabel"
    [minLabel]="minLabel"
    [minIdx]="minIdx"
    [maxIdx]="maxIdx"
    [unit]="unit"
    [values]="values"
    (changed)="sliderChanged($event)"
  ></da-range-slider>
</div>
<da-dialog [open]="precisionDialog.open">
  <div header>
    <div mat-card-title>
      {{ label | translate }}
    </div>
  </div>
  <div body>
    <da-form-builder [form]="form" [fields$]="dialogFormFields$"></da-form-builder>
    <p class="error" *ngIf="this.form.errors">{{ this.form.errors.maxSmallerMin }}</p>
  </div>

  <div footer>
    <div class="row no-margin-bottom">
      <button mat-flat-button color="primary" class="col-2 no-margin-bottom" (click)="precisionDialog.cancel()">
        {{ 'dialog.generic.button.cancel' | translate }}
      </button>

      <button
        mat-flat-button
        color="primary"
        class="col-3 no-margin-bottom"
        type="submit"
        (click)="precisionDialog.ok()"
        [disabled]="precisionDialog.disabled(dialogFormFields$.value)"
      >
        {{ 'exception-dialog.ok' | translate }}
      </button>
    </div>
  </div>
</da-dialog>
