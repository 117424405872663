import {DtoContract} from '../../contracts';

export const REQ_POST_APP_SELECTION_SIZING_RESULT = {
  config: [
    {KEY: 'user.email', TYPE: 'STRING'},
    {KEY: 'user.locale', TYPE: 'STRING'},
    {KEY: 'user.permissions.admin', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.gbc04expert', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.gbc04service', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.gbc04sales', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.sicksales', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.externalpartner', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.externalservice', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.externalcustomer', TYPE: 'BOOLEAN'},

    {KEY: 'filter', TYPE: 'BOOLEAN'},
    {KEY: 'measuredFlowValue', TYPE: 'STRING'},

    {KEY: 'filters.productFamily', TYPE: 'STRING'},
    {KEY: 'filters.productType', TYPE: 'STRING'},

    {KEY: 'filters.nominalSize', TYPE: 'NUMBER'},
    {KEY: 'filters.fullBore', TYPE: 'BOOLEAN'},
    {KEY: 'filters.schedule', TYPE: 'STRING'},
    {KEY: 'filters.innerDiameter', TYPE: 'DTOQUANTITY'}, // pipe inner diameter, in case custom inner diameter is selected

    {KEY: 'filters.installationType', TYPE: 'STRING'},
    {KEY: 'filters.flowCondition', TYPE: 'STRING'},
    {KEY: 'filters.pressureClass', TYPE: 'STRING'},
    {KEY: 'filters.flowConditioner', TYPE: 'STRING'},
    {KEY: 'filters.flowRangeType', TYPE: 'STRING'},

    {KEY: 'application.flowPressure.max', TYPE: 'DTOQUANTITY'},
    {KEY: 'application.flowPressure.min', TYPE: 'DTOQUANTITY'},
    {KEY: 'application.flowTemperature.max', TYPE: 'DTOQUANTITY'},
    {KEY: 'application.flowTemperature.min', TYPE: 'DTOQUANTITY'},
    {KEY: 'application.flowRange.max', TYPE: 'DTOQUANTITY'},
    {KEY: 'application.flowRange.min', TYPE: 'DTOQUANTITY'},
    {KEY: 'application.combinationIndexMin', TYPE: 'NUMBER'},
    {KEY: 'application.combinationIndexMax', TYPE: 'NUMBER'},
    {KEY: 'application.gasComposition', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'}
  ] as DtoContract[]
};
