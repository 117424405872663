import {accessObjectByPath} from '../../util/access-object-by-string-path';
import {Item} from '../model/dto';
import {undefinedChecker} from '../util/undefined-checker';

export function assembleDtoArrayOfArrayComplex(assembledObject, elem, payload) {
  const domainObject = accessObjectByPath(elem.DOMAINOBJECT, elem.PATHINDOMAINOBJECT);
  domainObject.forEach((aoa, i) => {
    aoa.forEach((ae, j) => {
      assembledObject = {
        key: elem.KEY,
        type: 'ARRAY<' + elem.ARRAY[i] + '>',
        value: assembleValue(elem, i, j),
        aoaId: i
      } as Item;

      undefinedChecker(payload, assembledObject);

      function assembleValue(elem, i, j) {
        const value = accessObjectByPath(elem.DOMAINOBJECT, elem.PATHINDOMAINOBJECT + '[' + i + ']' + '[' + j + ']');

        if (elem.ARRAY[i] === 'DTODATE') {
          return {
            format: 'YYYY-MM-DDTHH:mm:ss.sssZ',
            value: new Date(value[Object.keys(value)[0]]).toISOString()
          };
        }

        return value;
      }
    });
  });
}
