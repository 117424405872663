import {DtoContract} from '../contracts';
import {DOCUMENT, DOCUMENT_ARRAY} from './document';

export const UNIT_SYSTEM_DOCUMENT: DtoContract[] = [...DOCUMENT];

export const UNIT_SYSTEM: DtoContract[] = [
    {KEY: 'unitSystem', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'}
];

export const UNIT_SYSTEM_DOCUMENT_ARRAY: DtoContract[] = [
    ...DOCUMENT_ARRAY,
];

export const UNIT_SYSTEM_ARRAY: DtoContract[] = [{KEY: 'unitSystem', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'DTOKEYVALUEITEM'}];
