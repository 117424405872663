import {Dto} from '../../dto-assembly-line/model/dto';
import {PropCollectionDomainObjectValidator} from '../validation/validate-prop-collection-map-with-domain-object';
import {assembleDomainObjectTransposedArray} from '../assembler/assemble-domain-object-array-transposed';
import {assembleDomainObjectArray} from '../assembler/assemble-domain-object-array';
import {assembleDomainObjectArrayOfArrays} from '../assembler/assemble-domain-object-array-of-arrays';
import {assembleDomainObjectPlain} from '../assembler/assemble-domain-object-plain';
import {DtoDoPropCollectionMap} from '../do-assembly-line';

export function DoAssemblyFactory<T>(domainObject: T, desiredProperties: DtoDoPropCollectionMap[], dto: Dto): T {
  new PropCollectionDomainObjectValidator<T>().checkconfig(domainObject, desiredProperties, dto);

  desiredProperties.forEach(dp => {
    const dtoItem = dto.payload.find(pl => pl.key === dp.dtoKey);

    if (dtoItem) {
      const isTransposedArray = dtoItem.type.includes('T_ARRAY') && dtoItem.aoaId === undefined;
      const isArray = dtoItem.type.includes('ARRAY') && dtoItem.aoaId === undefined;
      const isArrayOfArray = dtoItem.type.includes('ARRAY') && dtoItem.aoaId !== undefined;

      if (isTransposedArray) {
        assembleDomainObjectTransposedArray(dtoItem, dp, domainObject);
      } else if (isArray) {
        const dtoLinkedItems = dto.payload.filter(e => e.key === dp.dtoKey);
        assembleDomainObjectArray(dp, dtoLinkedItems, domainObject);
      } else if (isArrayOfArray) {
        const dtoLinkedItems = dto.payload.filter(e => e.key === dp.dtoKey);
        assembleDomainObjectArrayOfArrays(dp, dtoItem, dtoLinkedItems, domainObject);
      } else {
        assembleDomainObjectPlain(dp, dtoItem, domainObject);
      }
    } else {
      throw new Error(`The desired dtoKey of '${dp.dtoKey}' has no match in your dto.`);
    }
  });

  return domainObject as T;
}
