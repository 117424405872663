import {convertKeyValueArrayIntoObject, DoAssemblyDirector, Dto, DtoDoPropCollectionMap} from '@flowtwin/communication';
import {UnitSystem} from '../model/unit/unit-system-document';
import {GasCompositionDoc} from '../model/gas/gas-composition-doc';
import {BasicProject} from '@state/state-service/do-mapper/model/project/project-basic';
import {PROP_COLLECTION_MAP_PROJECTS_GET} from '@state/state-service/do-mapper/contracts/projects';
import {isDefined} from '@shared/utility/isDefined';
import {DEFAULT_GAS_COMPOSITION_UUID, DEFAULT_GASES_DO} from '@state/state-service/do-mapper/model/gas/default-gas-data';

export class DoMapperProject {
  static mapToBasicProjects(flowTwinProjects: Dto): BasicProject[] {
    return new DoAssemblyDirector(flowTwinProjects, PROP_COLLECTION_MAP_PROJECTS_GET)
      .createInstance<BasicProject>(BasicProject)
      .assembleTransposedDo<BasicProject>()
      .collect();
  }

  // new way to convert, with DomainContract and typedDto (compare npm flowtwin/communication)
  static mapToBasicProject(domainContract: DtoDoPropCollectionMap[], typedDto: Dto): BasicProject {
    const domainObject = new DoAssemblyDirector(typedDto, domainContract)
      .createInstance<BasicProject>(BasicProject)
      .assembleDo<BasicProject>()
      .collect();

    if (domainObject.custom.displayUnitSystem.unitSystem) {
      domainObject.custom.displayUnitSystem.unitSystem = convertKeyValueArrayIntoObject<UnitSystem>(
        domainObject.custom.displayUnitSystem.unitSystem as any
      );
    }

    domainObject.custom.gasCompositionDocs = domainObject.custom?.gasCompositionDocs?.filter(gc => isDefined(gc)) ?? [];

    if (domainObject.custom.gasCompositionDocs.length) {
      domainObject.custom.gasCompositionDocs = domainObject.custom?.gasCompositionDocs?.map(gas => {
        const gasCompDoc: GasCompositionDoc = {document: undefined, gasComposition: undefined};
        gasCompDoc.gasComposition = convertKeyValueArrayIntoObject(gas as any);
        gasCompDoc.document = {
          userList: [],
          id: gasCompDoc?.gasComposition['id'],
          description: gasCompDoc?.gasComposition['description'],
          name: gasCompDoc?.gasComposition['name'],
          deleted: false
        };
        delete gasCompDoc.gasComposition['id'];
        delete gasCompDoc.gasComposition['description'];
        delete gasCompDoc.gasComposition['name'];
        return gasCompDoc;
      }) as unknown as GasCompositionDoc[];
    } else {
      domainObject.custom.gasCompositionDocs = [DEFAULT_GASES_DO[0]];
    }

    return domainObject;
  }
}
