import {Injectable} from '@angular/core';
import {StateService} from '@state/state-service/state.service';
import {
  ApiPayloadDeleteByIdLoad,
  ApiPayloadGetAllLoad,
  ApiPayloadGetAllSuccess,
  ApiPayloadGetAppByIdSuccess,
  ApiPayloadSaveOneSuccess,
  ApiPayloadShareByIdLoad,
  PayloadUpdateProjectSaveState,
  ProjectReduxAction
} from '../actions/actions-project';
import {ProjectEpicService} from '../epics/project-epic.service';
import {PageStateReduxAction, PayloadPageState} from '@state/actions/actions-page-state';
import {clone} from '@shared/utility/clone';
import {uniqBy, union, mergeWith, isArray, set} from 'lodash';
import {AppAliases} from '@state/reducers/dependency-reducer';
import {AppUncertaintyInput} from '@state/state-service/do-mapper/model/apps/app-uncertainty';
import {AppSizingInput} from '@state/state-service/do-mapper/model/apps/app-sizing';
import {uuid} from '@shared/utility/uuid';
import {LOADING_STATE} from '@state/model/state.model';
import {FlowTwinProject} from '@state/state-service/do-mapper/model/project/project-flow-twin';
import {isDefined} from '@shared/utility/isDefined';
import {PROJECT_SPECIFIC_UNIT_SYSTEM_ID} from '@shared/models/consts';
import {PROCESS_DATA_ENTRIES_DEFAULT_COLORS} from '@state/state-service/do-mapper/model/artifacts/process-data-entry';

@Injectable({
  providedIn: null
})
export class ProjectReducerService {
  static assignHandler(action: ProjectReduxAction, stateService: StateService, epic: ProjectEpicService) {
    switch (action.type) {
      case 'PROJECT_CLEAR_CURRENT':
        ProjectReducerService.clearCurrenProject(action, stateService);
        break;
      case 'PROJECTS_GET_ALL_LOAD':
        ProjectReducerService.clearCurrentBasicProject(action, stateService);
        ProjectReducerService.clearCurrentProjects(action, stateService);
        if (!(action.payload as ApiPayloadGetAllLoad).silent) {
          ProjectReducerService.updateProjectsState(action, stateService);
        }
        epic.getAllForUser(action, stateService);
        break;
      case 'PROJECTS_GET_ALL_SUCCESS':
        ProjectReducerService.updateProjectsStates(action, stateService);
        break;
      case 'PROJECTS_GET_ALL_ERROR':
        // TODO: When discussed and decided what happens in error case, uncomment "state: error"
        ProjectReducerService.updateProjectsStates(
          {payload: {projects: [] /* state: 'error' */}, type: 'PROJECTS_GET_ALL_ERROR'},
          stateService
        );
        break;
      case 'PROJECTS_GET_ALL_ADMIN_LOAD':
        ProjectReducerService.clearCurrentProjects(action, stateService);
        if (!(action.payload as ApiPayloadGetAllLoad).silent) {
          ProjectReducerService.updateProjectsState(action, stateService);
        }
        epic.getAllForAdmin(action, stateService);
        break;
      case 'PROJECTS_GET_ALL_ADMIN_SUCCESS':
        ProjectReducerService.updateProjectsStates(action, stateService, true);
        break;
      case 'PROJECTS_GET_ALL_ADMIN_ERROR':
        ProjectReducerService.updateProjectsStates(
          {payload: {projects: [] /* state: 'error' */}, type: 'PROJECTS_GET_ALL_ERROR'},
          stateService
        );
        break;
      case 'GET_APP_DATA_BY_ID':
        ProjectReducerService.updateAppLoadingState(action, 'loading', stateService);
        epic.getAppById(action, stateService);
        break;
      case 'APP_GET_BY_ID_SUCCESS':
        ProjectReducerService.updateProjectAppInput(action, stateService);
        ProjectReducerService.updateAppLoadingState(action, 'ready', stateService);
        break;
      case 'APP_GET_BY_ID_ERROR':
        ProjectReducerService.updateAppLoadingState(action, 'error', stateService);
        // TODO @Arnold: Guess the fall through was not intended?
        break;
      case 'PROJECT_SAVE_LOAD':
        epic.update(action, stateService);
        break;
      case 'PROJECT_SAVE_SUCCESS':
        ProjectReducerService.updateProjectSaveState(action, stateService);
        ProjectReducerService.updateProjects(action, stateService);
        break;
      case 'PROJECT_SAVE_ERROR':
        break;
      case 'PROJECT_SHARE_LOAD':
        epic.shareById(action, stateService);
        break;
      case 'PROJECT_SHARE_SUCCESS':
        ProjectReducerService.updateProjectUserList(action, stateService);
        break;
      case 'PROJECT_SHARE_ERROR':
        break;
      case 'PROJECT_RESTORE_LOAD':
        epic.restore(action, stateService);
        break;
      case 'PROJECT_RESTORE_SUCCESS':
        ProjectReducerService.updateProjects(action, stateService);
        break;
      case 'PROJECT_RESTORE_ERROR':
        break;
      case 'PROJECT_CREATE_LOAD':
        ProjectReducerService.clearCurrentBasicProject(action, stateService);
        epic.create(action, stateService);
        break;
      case 'PROJECT_CREATE_SUCCESS':
        ProjectReducerService.updateProjectSaveState(action, stateService);
        ProjectReducerService.updateProjects(action, stateService);
        ProjectReducerService.updateProject(action, stateService);
        break;
      case 'PROJECT_CREATE_ERROR':
        break;
      case 'PROJECT_DELETE_BY_ID_LOAD':
        epic.deleteOneById(action, stateService);
        break;
      case 'PROJECT_DELETE_BY_ID_SUCCESS':
        ProjectReducerService.updateProjectDeletedState(action, stateService);
        break;
      case 'PROJECT_DELETE_BY_ID_ERROR':
        break;
      case 'UPDATE_PROJECT_SAVE_STATE':
        ProjectReducerService.updateProjectSaveState(action, stateService);
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any Project Reducer-Function.`);
        break;
    }
  }

  private static updateProjectsState(action: ProjectReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        switch (action.type) {
          case 'PROJECTS_GET_ALL_LOAD':
            s.projects.state = 'loading';
            break;
          case 'PROJECTS_GET_ALL_ADMIN_LOAD':
            s.projects.state = 'loading';
            break;
          default:
            break;
        }
      },
      {type: 'Application Data State ' + action.type}
    );
  }

  private static updateProjectSaveState(action: ProjectReduxAction, stateService: StateService) {
    const saveState = (action.payload as PayloadUpdateProjectSaveState).saveState;

    stateService.update(
      s => {
        s.project.saved = saveState;
      },
      {
        type: `Project Save State Updated to: ${saveState}.`,
        details: {results: action}
      }
    );
  }

  private static updateProjects(action: ProjectReduxAction, stateService: StateService) {
    const {project, saveState} = action.payload as ApiPayloadSaveOneSuccess;

    stateService.update(
      s => {
        if (project) {
          const oldProjects = clone(s.projects.data);
          const indexOfAmendedItem = oldProjects.findIndex(obj => obj.document?.id === project.document?.id);

          if (indexOfAmendedItem === -1) {
            s.projects.data.push(project);
          } else {
            s.projects.data = [...oldProjects.slice(0, indexOfAmendedItem), project, ...oldProjects.slice(indexOfAmendedItem + 1)];
          }
        }
      },
      {
        type: 'Project State Updated to: ' + saveState,
        details: {results: action}
      }
    );
  }

  private static updateProjectsStates(action: ProjectReduxAction, stateService: StateService, merge = false) {
    stateService.update(
      s => {
        if ('projects' in action.payload && !merge) {
          s.projects.data = action.payload.projects;
        } else {
          s.projects.data = s.projects.data.concat((action.payload as ApiPayloadGetAllSuccess).projects);
        }
        s.pages.projects = 'state' in action?.payload ? (action?.payload?.state as LOADING_STATE) : 'ready';

        if ('isAllFetched' in action.payload) {
          s.projects.state = action.payload?.isAllFetched ? 'ready' : 'loading';
        } else {
          s.projects.state = 'ready';
        }
      },
      {
        type: 'Page State Updated ' + action.type,
        details: {results: action}
      }
    );
  }

  private static updateProject(action: ProjectReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        const {project} = action.payload as ApiPayloadSaveOneSuccess;
        s.basicProject.data = project;

        if (project?.custom?.displayUnitSystem?.document?.id === PROJECT_SPECIFIC_UNIT_SYSTEM_ID) {
          set(s, 'basicProject.appProject.unitSystemSpecificTemp', project.custom.displayUnitSystem);
        }

        s.pages.project = 'ready';
      },
      {
        type: 'Project State Updated ' + action.type,
        details: {results: action}
      }
    );
  }

  private static updateAppLoadingState(action: ProjectReduxAction, appState: LOADING_STATE, stateService: StateService) {
    let appkey: AppAliases;
    switch ((action.payload as ApiPayloadGetAppByIdSuccess).appId) {
      case 'selection-sizing':
        appkey = AppAliases['selection-sizing'];
        break;
      case 'uncertainty-prediction':
        appkey = AppAliases['uncertainty-prediction'];
        break;
      case 'transducer-performance':
        appkey = AppAliases['transducer-performance'];
        break;
      default:
        return;
    }

    if (stateService.state$.value.project.data?.[appkey]?.state) {
      stateService.update(
        s => {
          s.project.data![appkey]!.state = appState;
        },
        {
          type: 'Project State Updated ' + action.type,
          details: {results: action}
        }
      );
    }
  }

  private static updateProjectAppInput(action: ProjectReduxAction, stateService: StateService) {
    let appkey: AppAliases;
    switch ((action.payload as ApiPayloadGetAppByIdSuccess).appId) {
      case 'selection-sizing':
        appkey = AppAliases['selection-sizing'];
        break;
      case 'uncertainty-prediction':
        appkey = AppAliases['uncertainty-prediction'];

        ((action.payload as ApiPayloadGetAppByIdSuccess).input as AppUncertaintyInput).processDataEntries.forEach((pde, index) => {
          if (!pde.id) {
            pde.id = uuid();
          }

          if (!pde?.color) {
            pde.color = PROCESS_DATA_ENTRIES_DEFAULT_COLORS[index];
          }
        });

        break;
      case 'transducer-performance':
        appkey = AppAliases['transducer-performance'];
        break;
      default:
        throw new Error(`Not yet implemented for app ${(action.payload as ApiPayloadGetAppByIdSuccess).appId}`);
    }
    stateService.update(
      s => {
        let input = s.project.data?.[appkey]?.input;
        if (isDefined(input)) {
          input = {
            ...(action.payload as ApiPayloadGetAppByIdSuccess).input,
            ...input
          };
        } else {
          input = {
            ...(action.payload as ApiPayloadGetAppByIdSuccess).input
          };
        }

        set(s, `project.data.${appkey}.input`, input);

        if (appkey === 'appSizing') {
          if (!s.basicProject.data?.custom?.gasCompositionDocs) {
            set(s, `basicProject.data.custom.gasCompositionDocs`, []);
          }

          const inputRelatedGasComp = s.gases.data.find(
            gc => gc.document.id === ((action.payload as ApiPayloadGetAppByIdSuccess).input as AppSizingInput).gasCompositionId
          );

          if (inputRelatedGasComp) {
            s.basicProject.data!.custom!.gasCompositionDocs = uniqBy(
              union(s.basicProject.data!.custom!.gasCompositionDocs, [inputRelatedGasComp]),
              ({document}) => document.id
            );
          }
        }

        if (isDefined((action.payload as ApiPayloadGetAppByIdSuccess).output)) {
          set(
            s,
            `project.data.${appkey}.output`,
            mergeWith(clone(s.project.data?.[appkey]?.output), (action.payload as ApiPayloadGetAppByIdSuccess).output, (a, b) =>
              isArray(b) ? b : undefined
            )
          );
        }

        s.pages.project = 'ready';
      },
      {
        type: 'Project State Updated ' + action.type,
        details: {results: action}
      }
    );
  }

  private static updatePageState(action: PageStateReduxAction, stateService: StateService) {
    const {page, state} = action.payload as PayloadPageState;

    stateService.update(
      s => {
        s.pages[page] = state;
      },
      {
        type: 'Page State Updated of: ' + page,
        details: {results: action}
      }
    );
  }

  private static clearCurrentProjects(action: ProjectReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        s.projects.data = [];
      },
      {
        type: `Purge List of Project`,
        details: {results: action}
      }
    );
  }

  private static clearCurrentBasicProject(action: ProjectReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        s.basicProject.data = {} as FlowTwinProject;
      },
      {
        type: 'Reset Project Data',
        details: {results: action}
      }
    );
  }

  private static clearCurrenProject(action: ProjectReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        s.project.data = {} as FlowTwinProject;
      },
      {
        type: 'Reset Project Data',
        details: {results: action}
      }
    );
  }

  private static updateProjectDeletedState(action: ProjectReduxAction, stateService: StateService) {
    const projectId = (action.payload as ApiPayloadDeleteByIdLoad).projectId;

    stateService.update(
      s => {
        const project = s.projects.data.find(p => p.document?.id === projectId);
        if (project) {
          set(project, 'meta.deleted', true);
        }
      },
      {
        type: `Update Project Delete State of: ${projectId}`,
        details: {results: action}
      }
    );
  }

  private static updateProjectUserList(action: ProjectReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        const project = s.projects.data.find(p => p.document?.id === (action.payload as ApiPayloadShareByIdLoad).projectId);
        if (project) {
          set(project, 'meta.userList', (action.payload as ApiPayloadShareByIdLoad).userList);
        }
      },
      {
        type: `Update UserList of Project`,
        details: {results: action}
      }
    );
  }
}
