import {GasComposition_DTO, GasCompositionDoc_DTO} from './gas-compositions-dto';

export const DEFAULT_GAS_COMPOSITION_UUID_DTO = 'DEFAULT_GAS_COMPOSITION_UUID';

// tslint:disable-next-line:variable-name
export const createGasComposition_DTO: (props?: Partial<GasComposition_DTO>) => GasComposition_DTO = props => ({
  id: DEFAULT_GAS_COMPOSITION_UUID_DTO,
  name: '',
  argon: {value: 0, unit: 'MolAmountRatio'},
  carbonDioxide: {value: 0, unit: 'MolAmountRatio'},
  carbonMonoxide: {value: 0, unit: 'MolAmountRatio'},
  ethane: {value: 0, unit: 'MolAmountRatio'},
  helium: {value: 0, unit: 'MolAmountRatio'},
  hydrogen: {value: 0, unit: 'MolAmountRatio'},
  hydrogenSulfide: {value: 0, unit: 'MolAmountRatio'},
  iButane: {value: 0, unit: 'MolAmountRatio'},
  iPentane: {value: 0, unit: 'MolAmountRatio'},
  methane: {value: 0, unit: 'MolAmountRatio'},
  nButane: {value: 0, unit: 'MolAmountRatio'},
  nDecane: {value: 0, unit: 'MolAmountRatio'},
  nHeptane: {value: 0, unit: 'MolAmountRatio'},
  nHexane: {value: 0, unit: 'MolAmountRatio'},
  nitrogen: {value: 0, unit: 'MolAmountRatio'},
  nNonane: {value: 0, unit: 'MolAmountRatio'},
  nOctane: {value: 0, unit: 'MolAmountRatio'},
  nPentane: {value: 0, unit: 'MolAmountRatio'},
  oxygen: {value: 0, unit: 'MolAmountRatio'},
  propane: {value: 0, unit: 'MolAmountRatio'},
  water: {value: 0, unit: 'MolAmountRatio'},
  ...props
});

// tslint:disable-next-line:variable-name
export const createGasCompositionDoc_DTO: (props?: Partial<GasCompositionDoc_DTO>) => GasCompositionDoc_DTO = props => ({
  id: '',
  name: '',
  description: '',
  userList: [],
  deleted: false,
  creator: '',
  //
  ...createGasComposition_DTO(props),
  ...props
});

// tslint:disable-next-line:variable-name
const amarilloGas_DTO = createGasCompositionDoc_DTO({
  id: 'default-gas-amarillo',
  name: 'Amarillo',
  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 0.4676},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 4.5279},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.1037},
  iPentane: {unit: 'MolAmountRatio', value: 0.0321},
  methane: {unit: 'MolAmountRatio', value: 90.6724},
  nButane: {unit: 'MolAmountRatio', value: 0.1563},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0.0393},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.0443},
  nitrogen: {unit: 'MolAmountRatio', value: 3.1284},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 0.828},
  water: {unit: 'MolAmountRatio', value: 0}
});

// tslint:disable-next-line:variable-name
const ekofiskGas_DTO = createGasCompositionDoc_DTO({
  id: 'default-gas-ekofisk',
  name: 'Ekofisk',
  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 1.4954},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 8.4919},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.3486},
  iPentane: {unit: 'MolAmountRatio', value: 0.0509},
  methane: {unit: 'MolAmountRatio', value: 85.9063},
  nButane: {unit: 'MolAmountRatio', value: 0.3506},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.048},
  nitrogen: {unit: 'MolAmountRatio', value: 1.0068},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 2.3015},
  water: {unit: 'MolAmountRatio', value: 0}
});

// tslint:disable-next-line:variable-name
const gulfCoastGas_DTO = createGasCompositionDoc_DTO({
  id: 'default-gas-gulf-coast',
  name: 'GulfCoast',
  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 0.5956},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 1.8186},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.0977},
  iPentane: {unit: 'MolAmountRatio', value: 0.0473},
  methane: {unit: 'MolAmountRatio', value: 96.5222},
  nButane: {unit: 'MolAmountRatio', value: 0.1007},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0.0664},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.0324},
  nitrogen: {unit: 'MolAmountRatio', value: 0.2595},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 0.4596},
  water: {unit: 'MolAmountRatio', value: 0}
});

// tslint:disable-next-line:variable-name
const naturalGasLGas_DTO = createGasCompositionDoc_DTO({
  id: 'default-gas-natural-gas-l',
  name: 'NaturalGasL',

  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 1.56},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 4.3},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.15},
  iPentane: {unit: 'MolAmountRatio', value: 0.04},
  methane: {unit: 'MolAmountRatio', value: 82.74},
  nButane: {unit: 'MolAmountRatio', value: 0.15},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.04},
  nitrogen: {unit: 'MolAmountRatio', value: 10.32},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 0.7},
  water: {unit: 'MolAmountRatio', value: 0}
});
// tslint:disable-next-line:variable-name
const naturalGasHGas_DTO = createGasCompositionDoc_DTO({
  id: 'default-gas-natural-gas-h',
  name: 'NaturalGasH',
  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 0.03},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 1.2},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.051},
  iPentane: {unit: 'MolAmountRatio', value: 0.014},
  methane: {unit: 'MolAmountRatio', value: 97.7},
  nButane: {unit: 'MolAmountRatio', value: 0.051},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.014},
  nitrogen: {unit: 'MolAmountRatio', value: 0.7},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 0.24},
  water: {unit: 'MolAmountRatio', value: 0}
});

// tslint:disable-next-line:variable-name
const flareGasMediumMolarMassGas_DTO = createGasCompositionDoc_DTO({
  id: 'default-gas-flare-gas-medium-molar-mass',
  name: 'FlareGasMediumMolarMass',

  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 1.4004},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 18.9489},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0.0009},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.2033},
  iPentane: {unit: 'MolAmountRatio', value: 0.0472},
  methane: {unit: 'MolAmountRatio', value: 76.3433},
  nButane: {unit: 'MolAmountRatio', value: 0.3974},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0.0186},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.0486},
  nitrogen: {unit: 'MolAmountRatio', value: 0.5048},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 2.0865},
  water: {unit: 'MolAmountRatio', value: 0}
});
// tslint:disable-next-line:variable-name
const flareGasHighMolarMassGas_DTO = createGasCompositionDoc_DTO({
  id: 'default-gas-flare-gas-high-molar-mass',
  name: 'FlareGasHighMolarMass',

  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 0},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 0},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 10},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0},
  iPentane: {unit: 'MolAmountRatio', value: 0},
  methane: {unit: 'MolAmountRatio', value: 0},
  nButane: {unit: 'MolAmountRatio', value: 90},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0},
  nitrogen: {unit: 'MolAmountRatio', value: 0},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 0},
  water: {unit: 'MolAmountRatio', value: 0}
});
// tslint:disable-next-line:variable-name
const flareGasLowMolarMassGas_DTO = createGasCompositionDoc_DTO({
  id: 'default-gas-flare-gas-low-molar-mass',
  name: 'FlareGasLowMolarMass',

  argon: {unit: 'MolAmountRatio', value: 0},
  carbonDioxide: {unit: 'MolAmountRatio', value: 6.2332},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 0.316},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 66.6667},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0.0044},
  iPentane: {unit: 'MolAmountRatio', value: 0.002},
  methane: {unit: 'MolAmountRatio', value: 26.0855},
  nButane: {unit: 'MolAmountRatio', value: 0.0058},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0.0017},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0.0034},
  nitrogen: {unit: 'MolAmountRatio', value: 0.651},
  oxygen: {unit: 'MolAmountRatio', value: 0},
  propane: {unit: 'MolAmountRatio', value: 0.0302},
  water: {unit: 'MolAmountRatio', value: 0}
});

// tslint:disable-next-line:variable-name
const ambientAirGas_DTO = createGasCompositionDoc_DTO({
  id: 'default-gas-ambient-air',
  name: 'AmbientAir',

  argon: {unit: 'MolAmountRatio', value: 0.9254},
  carbonDioxide: {unit: 'MolAmountRatio', value: 0.0297},
  carbonMonoxide: {unit: 'MolAmountRatio', value: 0},
  ethane: {unit: 'MolAmountRatio', value: 0},
  helium: {unit: 'MolAmountRatio', value: 0},
  hydrogen: {unit: 'MolAmountRatio', value: 0},
  hydrogenSulfide: {unit: 'MolAmountRatio', value: 0},
  iButane: {unit: 'MolAmountRatio', value: 0},
  iPentane: {unit: 'MolAmountRatio', value: 0},
  methane: {unit: 'MolAmountRatio', value: 0},
  nButane: {unit: 'MolAmountRatio', value: 0},
  nDecane: {unit: 'MolAmountRatio', value: 0},
  nHeptane: {unit: 'MolAmountRatio', value: 0},
  nHexane: {unit: 'MolAmountRatio', value: 0},
  nNonane: {unit: 'MolAmountRatio', value: 0},
  nOctane: {unit: 'MolAmountRatio', value: 0},
  nPentane: {unit: 'MolAmountRatio', value: 0},
  nitrogen: {unit: 'MolAmountRatio', value: 78.0997},
  oxygen: {unit: 'MolAmountRatio', value: 20.9453},
  propane: {unit: 'MolAmountRatio', value: 0},
  water: {unit: 'MolAmountRatio', value: 0}
});

// tslint:disable-next-line:variable-name
const nitrogenGas_DTO = createGasCompositionDoc_DTO({
  id: 'default-gas-nitrogen',
  name: 'Nitrogen',

  nitrogen: {unit: 'MolAmountRatio', value: 100}
});

// tslint:disable-next-line:variable-name
const oxygenGas_DTO = createGasCompositionDoc_DTO({
  id: 'default-gas-oxygen',
  name: 'Oxygen',
  oxygen: {unit: 'MolAmountRatio', value: 100}
});

export const DEFAULT_GASES_DTO = [
  amarilloGas_DTO,
  ekofiskGas_DTO,
  gulfCoastGas_DTO,
  naturalGasLGas_DTO,
  naturalGasHGas_DTO,
  flareGasMediumMolarMassGas_DTO,
  flareGasHighMolarMassGas_DTO,
  flareGasLowMolarMassGas_DTO,
  ambientAirGas_DTO,
  nitrogenGas_DTO,
  oxygenGas_DTO
];
