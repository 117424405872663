import {Contract, DomainContract, DtoContract, DtoDefinition} from '../../index';
import {getMissingMappings} from '../../util/get-missing-mappings';

export function getAssemblyContractNormalDto(
  dtoType: DtoDefinition,
  dtoContracts: DtoContract[],
  domainContracts: DomainContract[]
): Contract {
  const missingMappings = getMissingMappings(dtoContracts, domainContracts);

  if (missingMappings.length) {
    throw new Error(
      `Your DTO does not have keys called "${missingMappings
        .join(', ')
        .toString()}". Check your PATHINDATATRANSFEROBJECT-Values of your domainContract.`
    );
  }

  return dtoContracts.map((dtoContract: DtoContract) => {
    const mapping: DomainContract = domainContracts.find(domainContract => domainContract.PATHINDATATRANSFEROBJECT === dtoContract.KEY);

    if (mapping) {
      return {
        KEY: dtoContract.KEY,
        TYPE: dtoContract.TYPE,
        DOMAINOBJECT: mapping.DOMAINOBJECT,
        PATHINDOMAINOBJECT: mapping.PATHINDOMAINOBJECT,
        PATHINDATATRANSFEROBJECT: null,
        ARRAY: dtoContract?.ARRAY || null
      } as DtoContract & DomainContract;
    }
  });
}
