import {FieldType} from '@ngx-formly/core';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  getBaseUnitSystemValue,
  getDisplayedUnitSystemValue,
  UnitSystem
} from '@state/state-service/do-mapper/model/unit/unit-system-document';
import {StateService} from '@state/state-service/state.service';
import {Quantity} from '@state/state-service/do-mapper/model/unit/quantity';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {TranslationKey} from '@shared/models/translation-key';
import {ILogger, LoggerConfig, LoggerService, LogLevel} from '../../../services/logger/logger.service';

const loggerConfig: LoggerConfig = {
  name: '-',
  symbol: '📏',
  textColor: 'white',
  backgroundColor: '#FF4365'
};

@Component({
  selector: 'da-unit-input',
  templateUrl: './form-input-unit.html'
})
export class FormInputUnitComponent extends FieldType implements OnInit, OnDestroy {
  _value = '';
  _errorMessage: TranslationKey | string = '';
  _required = true;
  _suffix = '';
  _isDisabled = false;
  _isHidden: () => any;
  _label = '';
  _placeholder = '';
  _className = '';
  _invalid = false;

  private subscriptions: Subscription[] = [];
  private logger: ILogger;

  constructor(private stateService: StateService, private loggerService: LoggerService) {
    super();
    this.logger = this.initLogger();
  }

  private initLogger() {
    loggerConfig.name = this.constructor.name;
    return this.loggerService.initLogger(loggerConfig);
  }

  ngOnInit(): void {
    this.initControl();

    this.subscriptions.push(this.setSubscriptionForControl());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  initControl() {
    this._required = !!this.field.templateOptions.required;
    this._isDisabled = this.getDisabledState();
    this._isHidden = this.getHiddenState;
    this._label = this.field?.templateOptions?.label || '';
    this._placeholder = this.field?.templateOptions?.placeholder || '';
    this._className = this.field?.className || '';

    this.getInitialisedValue();
  }

  setSubscriptionForControl(): Subscription {
    return this.formControl.valueChanges.pipe(filter(x => x)).subscribe(() => {
      this.getInitialisedValue();

      if (this.field.templateOptions.action) {
        this.stateService.dispatch({
          type: this.field.templateOptions.action,
          payload: ''
        });
      }
    });
  }

  private getInitialisedValue() {
    try {
      const convert = getDisplayedUnitSystemValue(this.getUnitSystem());
      this._value = convert(this.formControl.value as Quantity<any>);
      this._suffix = this.getSuffix();
      this._invalid = this.getInvalidState();
    } catch (e) {
      // this.logError(e)
      return '';
    }
  }

  handleValidCheck(e) {
    this._value = e.target.value;
    this._invalid = this.getInvalidState();
  }

  updateForm(e) {
    const nextValue = Number(e.target.value);

    if (Number.isNaN(nextValue)) {
      this.formControl.setErrors({NAN: true});
      return;
    } else {
      this.formControl.setErrors({});
    }

    const nextQuantity = getBaseUnitSystemValue({
      value: nextValue,
      displayUnitSystem: this.getUnitSystem(),
      quant: this.getQuantity()
    });

    this.formControl.setValue(nextQuantity, {
      emitEvent: this.field.templateOptions?.emitOnChange() ?? true
    });

    if (!this.field.templateOptions.emitOnChange()) {
      this.initControl();
    }

    this._invalid = this.getInvalidState();
  }

  isOutOfLimits(): boolean {
    const absenceOfLimits = this.field.templateOptions.limits.every(e => !e && e !== 0);

    if (absenceOfLimits) {
      return this.field.formControl.invalid;
    }

    const lowerLimit = this.field.templateOptions.limits[0] != null;
    const upperLimit = this.field.templateOptions.limits[1] != null;

    const outOfLimits = [false, false];

    if (lowerLimit) {
      outOfLimits[0] = this._value <= this.field.templateOptions.limits[0];
    }

    if (upperLimit) {
      outOfLimits[1] = this._value >= this.field.templateOptions.limits[1];
    }

    const exceedsLimits = outOfLimits.some(l => l);
    if (exceedsLimits) {
      this.field.formControl.setErrors({'exceeding limit': true});
      if (outOfLimits[0]) {
        this._errorMessage = this.field.templateOptions?.errorMessages?.[0] || 'value.too.low';
      }
      if (outOfLimits[1]) {
        this._errorMessage = this.field.templateOptions?.errorMessages?.[1] || 'value.too.high';
      }
    }

    return exceedsLimits;
  }

  getDisabledState() {
    return this.field.formControl.disabled || this.field.templateOptions.disabled;
  }

  getHiddenState() {
    return this.field.templateOptions.isHidden();
  }

  getUnitSystem(): UnitSystem {
    return this.stateService.state.basicProject.data.custom.displayUnitSystem.unitSystem;
  }

  getSuffix() {
    try {
      const {unit} = (this?.formControl?.value as Quantity<any>) || {
        value: 0,
        unit: '???'
      };
      return this.getUnitSystem()?.[unit]?.conversion || null;
    } catch (e) {
      this.logError(e);
      return '?';
    }
  }

  getInvalidState() {
    if (!this.field.templateOptions.limits) {
      return this.field.formControl.invalid;
    } else {
      return this.isOutOfLimits();
    }
  }

  getQuantity() {
    return this.field.formControl.value as Quantity<any>;
  }

  private logError(e: any) {
    this.logger.log('', '' + this.field.key, LogLevel.Error, [{Error: e}]);
  }
}
