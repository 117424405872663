import {Component} from '@angular/core';
import {StateService} from '@state/state-service/state.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'da-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent {
  show$: Observable<boolean>;

  constructor(private stateService: StateService) {
    this.show$ = this.stateService.state$.pipe(map(s => s.splash.open));
  }
}
