import {Dto} from '../../dto-assembly-line/model/dto';
import {DtoDoPropCollectionMap} from '../do-assembly-line';
import {getMissingContractKeys} from '../../util/get-missing-contract-keys';

export class PropCollectionDomainObjectValidator<T> {
  checkconfig(domainObject: T, desiredProperties: DtoDoPropCollectionMap[], dto: Dto) {
    this.checkForDomainObject(domainObject);

    this.checkForDtoMapping(desiredProperties);

    this.checkForDto(dto);

    this.checkForExceedingMappingRequests(desiredProperties, dto);
  }

  private checkForDomainObject(domainObject: T) {
    if (!domainObject) {
      throw new Error('No DomainObject given.');
    }
  }

  private checkForDtoMapping(desiredProperties: DtoDoPropCollectionMap[]) {
    if (!desiredProperties || desiredProperties.length <= 0) {
      throw new Error('No Dto-Do Mapping given.');
    }
  }

  private checkForDto(dto: Dto) {
    if (!dto) {
      throw new Error('No DataTransferObject given.');
    }
  }

  private checkForExceedingMappingRequests(desiredProperties: DtoDoPropCollectionMap[], dto: Dto) {
    if (desiredProperties.length > dto.payload.length) {
      const missingKeys = getMissingContractKeys(desiredProperties, dto.payload);
      throw new Error(
        `Your DomainContract requests more mappings than the DTO provides.
        DomainContract Mappings ${desiredProperties.length} - ${dto.payload.length} DTO Keys.
        Your Domain Contract request these keys [${missingKeys.join(', ').toString()}] that are not in your DTO.`
      );
    }
  }
}
