import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {UnitSystemEpicService} from '../epics/unit-system-epic.service';
import {UnitSystemReduxAction} from '../actions/actions-unit-system';
import {Pages, PageStateReduxAction, PayloadPageState} from '../actions/actions-page-state';
import {clone} from '@shared/utility/clone';
import {uniqBy} from 'lodash';

@Injectable({
  providedIn: null
})
export class UnitSystemsReducerService {
  static assignHandler(action: UnitSystemReduxAction, stateService: StateService, epic: UnitSystemEpicService) {
    switch (action.type) {
      case 'UNIT_SYSTEMS_GET_ALL_LOADING':
        UnitSystemsReducerService.updateUnitSystemsState(action, stateService);
        epic.getAll(action, stateService);
        break;
      case 'UNIT_SYSTEMS_GET_ALL_SUCCESS':
        UnitSystemsReducerService.updateUnitSystems(action, stateService);
        UnitSystemsReducerService.updatePageState(
          {
            type: 'UPDATE_PAGE_STATE',
            payload: {page: Pages.customUnits, state: 'ready'}
          },
          stateService
        );
        break;
      case 'UNIT_SYSTEMS_GET_ALL_ERROR':
        break;
      case 'UNIT_SYSTEMS_SAVE_ONE_LOADING':
        epic.postOne(action, stateService);
        break;
      case 'UNIT_SYSTEMS_SAVE_ONE_SUCCESS':
        UnitSystemsReducerService.updateUnitSystems(action, stateService);
        break;
      case 'UNIT_SYSTEMS_SAVE_ONE_ERROR':
        break;
      case 'UNIT_SYSTEMS_DELETE_ONE_LOADING':
        epic.deleteOne(action, stateService);
        break;
      case 'UNIT_SYSTEMS_DELETE_ONE_SUCCESS':
        UnitSystemsReducerService.updateUnitSystems(action, stateService);
        break;
      case 'UNIT_SYSTEMS_DELETE_ONE_ERROR':
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any Unit-System Reducer-Function.`);
        break;
    }
  }

  private static updateUnitSystemsState(action: UnitSystemReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        switch (action.type) {
          case 'UNIT_SYSTEMS_GET_ALL_LOADING':
            s.unitSystems.state = 'loading';
            break;
          default:
            break;
        }
      },
      {type: 'Unit Systems Data State ' + action.type}
    );
  }

  private static updateUnitSystems(action: UnitSystemReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        const unitSystemsWithoutOld = s.unitSystems.data.filter(
          us => us.document.id !== ('unitSystem' in action.payload ? action.payload?.unitSystem?.document?.id || null : null)
        );

        if (action.type === 'UNIT_SYSTEMS_DELETE_ONE_SUCCESS') {
          s.unitSystems.data = clone(unitSystemsWithoutOld);
        } else {
          const concatUnitSystems = unitSystemsWithoutOld
            .concat(
              'unitSystems' in action.payload ? action.payload.unitSystems : 'unitSystem' in action.payload ? action.payload.unitSystem : []
            )
            .filter(us => us);

          s.unitSystems.data = clone(uniqBy(concatUnitSystems, ({document}) => document.id));
        }

        s.unitSystems.state = 'ready';
      },
      {
        type: 'Update Unit Systems'
      }
    );
  }

  private static updatePageState(action: PageStateReduxAction, stateService: StateService) {
    const {page, state} = action.payload as PayloadPageState;

    stateService.update(
      s => {
        s.pages[page] = state;
      },
      {
        type: 'Page State Updated of: ' + page,
        details: {results: action}
      }
    );
  }
}
