import {DtoDoPropCollectionMap} from '@flowtwin/communication';

export const PROP_COLLECTION_MAP_VERSION: DtoDoPropCollectionMap[] = [
  {dtoKey: 'octave.base', doKey: 'octave.base'},
  {dtoKey: 'octave.packages.common', doKey: 'octave.common'},
  {dtoKey: 'octave.packages.unit', doKey: 'octave.unit'},
  {dtoKey: 'octave.packages.matlab', doKey: 'octave.matlab'},
  {dtoKey: 'octave.packages.communication', doKey: 'octave.communication'},
  {dtoKey: 'aws.base', doKey: 'aws.base'},
  {dtoKey: 'aws.packages.unit', doKey: 'aws.unit'},
  {dtoKey: 'aws.packages.communication', doKey: 'aws.communication'}
];
