import {GasCompositionUUID} from '../communication/type-alias';
import {Quantity} from '../unit/quantity';
import {KeyValueItem} from '../project/metadata';
import {AbstractDomainObject, DtoKeyValueItem, DtoQuantity} from '@flowtwin/communication';
import {GasCompositionDoc} from '@state/state-service/do-mapper/model/gas/gas-composition-doc';
import {ProcessConditionBoundaries} from '@state/state-service/do-mapper/model/artifacts/process-conditions-boundaries';
import {CalcMode} from '@state/state-service/do-mapper/model/apps/app-sizing';

export class AppTransducerInput extends AbstractDomainObject {
  pressure: ProcessConditionBoundaries<'Pressure'>;
  temperature: ProcessConditionBoundaries<'Temperature'>;
  gasCompositionId: GasCompositionUUID;
  injectH2: boolean;
  xH2: Quantity<'AmountRatio'>;
  nominalDiameter: Quantity<'Diameter'>;
  remarks: string;
  sensorGasSelectionMethod: KeyValueItem;
  hsElectronics: boolean;
  explosionGroup: KeyValueItem;
  sensorType: KeyValueItem;
  userSelectedProbe: boolean;
  extendedPNwCalculation?: boolean;
  calcMode?: CalcMode;
}

export class AppTransducerOutput extends AbstractDomainObject {
  agc: {
    innerPath: {
      pMin: DtoQuantity;
      pMax: DtoQuantity;
    };
    outerPath: {
      pMin: DtoQuantity;
      pMax: DtoQuantity;
    };
  };
  agcState: {
    innerPath: {
      pMin: number;
      pMax: number;
    };
    outerPath: {
      pMin: number;
      pMax: number;
    };
  };
  agcAssembly: {
    innerPath: DtoQuantity;
    outerPath: DtoQuantity;
  };
  various: {
    pathLengthInner: DtoQuantity;
    pathLengthOuter: DtoQuantity;
    pressureAssembly: DtoQuantity;
    limitSearchMaxNW: DtoQuantity;
    limitSearchMinPressure: DtoQuantity;
    hsState: number;
    reflectionState: number;
    probe: DtoKeyValueItem;
    gasType: DtoKeyValueItem;
  };
  summaries: {
    applicationState: boolean;
    applicationRecommendation: {
      summary: boolean;
      pressure: boolean;
      nominalDiameter: boolean;
      h2State: boolean;
      sosState: boolean;
    };
    agcSimulation: boolean;
    signalSimulation: boolean;
    designState: {summary: boolean; minDesign: boolean};
  };
}

export interface AppTransducerMetadata {
  test: string;
  gasCompositions: GasCompositionDoc[];
}
