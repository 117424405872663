import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {DisplayTextReduxAction, PayloadTitle} from '../actions/actions-display-text';

@Injectable({
  providedIn: 'root'
})
export class DisplayTextReducerService {
  static assignHandler(action: DisplayTextReduxAction, stateService: StateService) {
    switch (action.type) {
      case 'SET_TITLE':
        DisplayTextReducerService.updateTitle(action, stateService);
        break;
    }
  }

  private static updateTitle(action: DisplayTextReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        s.toolbar.title = (action.payload as PayloadTitle).title;
      },
      {
        type: `Update Page Title - ${action.type}`
      }
    );
  }
}
