import {ReduxAction} from './actions-abstract';

export type DisplayTextActions = 'SET_TITLE';

export interface PayloadTitle {
  title: string;
}

export type DisplayTextPayloads = PayloadTitle;

export class DisplayTextReduxAction extends ReduxAction {
  type: DisplayTextActions;
  payload: DisplayTextPayloads;

  constructor(type: DisplayTextActions, payload: DisplayTextPayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
