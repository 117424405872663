import {ArrayElementTypeAnnotation, DtoDefinition, TransposedTypeAnnotation} from '../model/dto';
import {Contract, DomainContract, DtoContract} from '../../contracts/contracts';
import {transposeDomainObject} from './transpose-domain-object';
import _ from 'lodash';
import {getMissingMappings} from '../../util/get-missing-mappings';

export function getAssemblyContractTransposedDto(
  dtoType: DtoDefinition,
  dtoContracts: DtoContract[],
  domainContracts: DomainContract[],
  transposedDomainObject: Record<string, unknown>,
  fullPaths: any[]
): Contract {
  domainContracts[0].DOMAINOBJECT.forEach(arrayElement => {
    Object.keys(arrayElement).forEach((key, o) =>
      transposeDomainObject(transposedDomainObject, arrayElement, key, dtoContracts[o].ARRAY as ArrayElementTypeAnnotation, fullPaths)
    );
  });

  fullPaths = _.uniq(fullPaths);

  const missingMappings = getMissingMappings(dtoContracts, domainContracts);

  if (missingMappings.length) {
    throw new Error(
      `Your DTO does not have keys called "${missingMappings
        .join(', ')
        .toString()}". Check your PATHINDATATRANSFEROBJECT-Values of your domainContract.`
    );
  }

  return dtoContracts.map((dtoContract: DtoContract) => {
    const domainObjectMapping = domainContracts.find(domainContract => domainContract.PATHINDATATRANSFEROBJECT === dtoContract.KEY);

    if (domainObjectMapping && fullPaths.find(fp => fp === domainObjectMapping.PATHINDOMAINOBJECT)) {
      return {
        KEY: dtoContract.KEY,
        TYPE: ('T_ARRAY<' + dtoContract.ARRAY + '>') as TransposedTypeAnnotation,
        DOMAINOBJECT: transposedDomainObject,
        PATHINDOMAINOBJECT: domainObjectMapping.PATHINDOMAINOBJECT,
        PATHINDATATRANSFEROBJECT: null,
        ARRAY: dtoContract?.ARRAY || null
      } as DtoContract & DomainContract;
    }
  });
}
