import {convertKeyValueArrayIntoObject, DoAssemblyDirector, Dto, DtoDoPropCollectionMap} from '@flowtwin/communication';
import {AppSizingInput, AppSizingMetadata, AppSizingOutput} from '@state/state-service/do-mapper/model/apps/app-sizing';
import {isDefined} from '@shared/utility/isDefined';

export class DoMapperSizing {
  static mapToLoadAppSizingInput(domainContract: DtoDoPropCollectionMap[], typedDto: Dto): AppSizingInput {
    const domainObject = new DoAssemblyDirector(typedDto, domainContract)
      .createInstance<AppSizingInput>(AppSizingInput)
      .assembleDo<AppSizingInput>()
      .collect();

    domainObject.selectedDevice = domainObject.selectedDevice.filter(sd => isDefined(sd));

    if (domainObject.selectedDevice.length) {
      domainObject.selectedDevice = convertKeyValueArrayIntoObject(domainObject.selectedDevice as any);
      domainObject.selectedDevice = [domainObject.selectedDevice[Object.keys(domainObject.selectedDevice)[0]]];
    } else {
      domainObject.selectedDevice = null;
    }

    return domainObject;
  }

  static mapToAppSizingComputeGas(domainContract: DtoDoPropCollectionMap[], typedDto: Dto): AppSizingOutput {
    return new DoAssemblyDirector(typedDto, domainContract)
      .createInstance<AppSizingOutput>(AppSizingOutput)
      .assembleDo<AppSizingOutput>()
      .collect();
  }

  static mapToAppSizingDeviceSearch(domainContract: DtoDoPropCollectionMap[], typedDto: Dto): AppSizingMetadata {
    const domainObject: AppSizingMetadata = new DoAssemblyDirector(typedDto, domainContract)
      .createInstance<AppSizingMetadata>(AppSizingMetadata)
      .assembleDo<AppSizingMetadata>()
      .collect();

    domainObject.devices = domainObject.devices.filter(d => isDefined(d));

    if (domainObject.devices.length) {
      // TODO @Arnold: The types are a mess here if this works as it should :)
      domainObject.devices = convertKeyValueArrayIntoObject(domainObject.devices as any);
      domainObject.devices = [domainObject.devices[Object.keys(domainObject.devices)[0]]];
    } else {
      domainObject.devices = null;
    }

    return domainObject;
  }

  static mapToAppSizingOutput(contract: DtoDoPropCollectionMap[], dto: Dto): AppSizingOutput {
    return new DoAssemblyDirector(dto, contract).createInstance<AppSizingOutput>(AppSizingOutput).assembleDo<AppSizingOutput>().collect();
  }
}
