import {Item, TypeAnnotation} from '../../dto-assembly-line/model/dto';
import {getLibraryKnownType} from '../validation/get-library-known-type';
import {DtoDoPropCollectionMap} from '../do-assembly-line';
import _ from 'lodash';

export function assembleDomainObjectArrayOfArrays(dp: DtoDoPropCollectionMap, dtoItem: Item, dtoItems: Item[], domainObject: any) {
  const domainObjectPath = dp.doKey;
  let resultArray = [];
  let intermediateArray = [];
  let currentAoAId = dtoItem.aoaId;
  let j = 0;

  dtoItems.forEach(item => {
    const rawType = item.type.match('(?<=<).+?(?=>)')[0] as TypeAnnotation;

    if (currentAoAId != item.aoaId) {
      resultArray.push(intermediateArray);
      currentAoAId = item.aoaId;
      intermediateArray = [];
    }

    intermediateArray.push(getLibraryKnownType(rawType, item.value));

    if (j === dtoItems.length - 1) {
      resultArray.push(intermediateArray);
    }
    j++;
  });

  if (resultArray.every(x => x === undefined)) {
    resultArray = undefined;
  }

  _.set(domainObject, domainObjectPath, resultArray);
}
