import {NgModule} from '@angular/core';
import {DrawBarChartService} from './draw-barchart.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [DrawBarChartService],
  exports: []
})
export class UtilityModule {}
