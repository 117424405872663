import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {
  ApiComputeGasSuccessPayload,
  ApiComputeTransducerPerformanceSuccessPayload,
  ApiComputeUncertaintySuccessPayload,
  ComputeReduxAction
} from '../actions/actions-compute';
import {ComputeEpicService} from '../epics/compute-epic.service';
import {isLoadingState} from '@state/model/state.model';
import {set} from 'lodash';

@Injectable({
  providedIn: null
})
export class ComputeReducerService {
  static assignHandler(action: ComputeReduxAction, stateService: StateService, epic: ComputeEpicService) {
    switch (action.type) {
      case 'CLEAR_SIZING_RESULT':
        ComputeReducerService.clearSizingResults(stateService);
        break;
      case 'CLEAR_UNCERTAINTY_RESULT':
        ComputeReducerService.clearUncertaintyResults(stateService);
        break;
      case 'CLEAR_TRANSDUCER_RESULT':
        ComputeReducerService.clearTransducerPerformanceResults(stateService);
        break;
      case 'COMPUTE_GASES_LOAD':
        ComputeReducerService.clearSizingResults(stateService);
        epic.postComputeSizing(action, stateService);
        break;
      case 'COMPUTE_GASES_SUCCESS':
        ComputeReducerService.updateSizingResults(action, stateService);
        break;
      case 'COMPUTE_GASES_ERROR':
        ComputeReducerService.updateSizingResults(action, stateService);
        break;
      case 'COMPUTE_UNCERTAINTY_LOAD':
        ComputeReducerService.clearUncertaintyResults(stateService);
        epic.postComputeUncertainty(action, stateService);
        break;
      case 'COMPUTE_UNCERTAINTY_SUCCESS':
        ComputeReducerService.updateUncertaintyResults(action, stateService);
        break;
      case 'COMPUTE_UNCERTAINTY_ERROR':
        ComputeReducerService.updatePageStateOfUncertainty(action, stateService);
        break;
      case 'COMPUTE_TRANSDUCER_PERFORMANCE_LOAD':
        ComputeReducerService.clearTransducerPerformanceResults(stateService);
        epic.postComputeTransducer(action, stateService);
        break;
      case 'COMPUTE_TRANSDUCER_PERFORMANCE_SUCCESS':
        ComputeReducerService.updateTransducerPerformanceResults(action, stateService);
        break;
      case 'COMPUTE_TRANSDUCER_PERFORMANCE_ERROR':
        ComputeReducerService.updateTransducerPerformanceResults(action, stateService);
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any Compute Reducer-Function.`);
        break;
    }
  }

  private static updateSizingResults(action: ComputeReduxAction, stateService: StateService) {
    if (action.type.match(/[a-zA-Z]*ERROR[a-zA-Z]*/)?.[0] === 'ERROR') {
      stateService.update(
        s => {
          s.pages.appSizing = 'error';
        },
        {
          type: 'Page State set to "error"',
          details: {results: action}
        }
      );
    } else {
      stateService.update(
        s => {
          set(s, 'project.data.appSizing.output.gasdata', (action.payload as ApiComputeGasSuccessPayload).gasData);
          set(s, 'project.data.appSizing.output.ccus', (action.payload as ApiComputeGasSuccessPayload).ccus);
          set(s, 'project.data.appSizing.output.lvf', (action.payload as ApiComputeGasSuccessPayload).lvf);
          set(s, 'project.data.appSizing.output.warnings', (action.payload as ApiComputeGasSuccessPayload).warnings);
        },
        {
          type: 'DeviceService.sizing - update gas data',
          details: {
            prop: (action.payload as ApiComputeGasSuccessPayload)?.gasData
          }
        }
      );
    }
  }

  private static clearUncertaintyResults(stateService: StateService) {
    stateService.update(
      s => {
        set(s, 'project.data.appUncertainty.output', null);
      },
      {type: 'Clear Uncertainty Results '}
    );
  }

  private static updateUncertaintyResults(action: ComputeReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        set(s, 'project.data.appUncertainty.output', (action.payload as ApiComputeUncertaintySuccessPayload).results);
        s.pages.appUncertainty = 'ready';
      },
      {
        type: 'ComputeService: computed uncertainty.',
        details: {
          results: (action.payload as ApiComputeUncertaintySuccessPayload).results
        }
      }
    );
  }

  private static updatePageStateOfUncertainty(action: ComputeReduxAction, stateService: StateService) {
    if (action.type.match(/[a-zA-Z]*ERROR[a-zA-Z]*/)?.[0] === 'ERROR') {
      stateService.update(
        s => {
          s.pages.appUncertainty = 'error';
        },
        {
          type: 'Page State Reset to "error"',
          details: {results: action}
        }
      );
    } else {
      stateService.update(
        s => {
          if (isLoadingState(action.payload)) {
            s.pages.appUncertainty = action.payload;
          }
        },
        {
          type: 'Page State Updated' + action.type,
          details: {results: action}
        }
      );
    }
  }

  private static clearTransducerPerformanceResults(stateService: StateService) {
    stateService.update(
      s => {
        set(s, 'project.data.appTransducer.output', null);
      },
      {type: 'Clear Uncertainty Results '}
    );
  }

  private static updateTransducerPerformanceResults(action: ComputeReduxAction, stateService: StateService) {
    if (action.type.match(/[a-zA-Z]*ERROR[a-zA-Z]*/)?.[0] === 'ERROR') {
      stateService.update(
        s => {
          s.pages.appUncertainty = 'error';
        },
        {
          type: 'Page State Reset to "error"',
          details: {results: action}
        }
      );
    } else {
      stateService.update(
        s => {
          set(s, 'project.data.appTransducer.output', (action.payload as ApiComputeTransducerPerformanceSuccessPayload).results);
          s.pages.appTransducer = 'ready';
        },
        {
          type: 'ComputeService: computed transducer performance.',
          details: {
            results: (action.payload as ApiComputeUncertaintySuccessPayload).results
          }
        }
      );
    }
  }

  private static clearSizingResults(stateService: StateService) {
    stateService.update(
      s => {
        set(s, 'project.data.appSizing.output', null);
      },
      {type: 'Clear Sizing Results '}
    );
  }
}
