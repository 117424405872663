export class AbstractDomainObject {}

export class DomainProxyObject<T extends AbstractDomainObject> {
  private _dataObject: T;

  constructor(dataObject?: T, cls?: typeof AbstractDomainObject) {
    if (dataObject) {
      this.dataObject = dataObject;
    } else {
      // create a new instance of the generic <T> here
      this.dataObject = new cls() as T;
    }
  }

  set dataObject(dataObject: T) {
    this._dataObject = dataObject;
  }

  get dataObject(): T {
    return this._dataObject;
  }
}
