import {Injectable} from '@angular/core';
import {debounceTime, delay, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '@state/state-service/state.service';
import {DisplayTextReduxAction} from '@state/actions/actions-display-text';
import {isDefined} from '@shared/utility/isDefined';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private static setDocumentTitle(title: string | null) {
    window.document.title = `FLOWtwin™${title ? ' | ' + title : ''}`;
  }

  constructor(private stateService: StateService, private translateService: TranslateService) {}

  public setPageTitle(title: string) {
    const setTitleKey: DisplayTextReduxAction = new DisplayTextReduxAction('SET_TITLE', {
      title: title
    });
    this.stateService.dispatch(setTitleKey);

    TitleService.setDocumentTitle(title);
  }

  public setPageTitleKey(titleKey: string) {
    const title = this.translateTitle(titleKey);

    this.setPageTitle(title);
  }

  connect() {
    this.stateService.state$
      .pipe(
        map(s => s.user.document.locale),
        distinctUntilChanged(),
        filter(isDefined),
        // language for the translation service is set after this event is fired
        // --> title would be translated with the old language, if we don't wait for a bit.
        // May be removed when timing issue is solved.
        debounceTime(100)
      )
      .subscribe(_ => {
        // HTML document title translation must be triggered programmatically when language changes
        const title = this.stateService.state$.value.toolbar.title;
        if (title) {
          TitleService.setDocumentTitle(title);
        }
      });
  }

  private translateTitle(title: string) {
    return this.translateService.instant(title);
  }
}
