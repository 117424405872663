import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {VersionEpicService} from '../epics/version-epic.service';
import {AppVersions} from '../state-service/do-mapper/model/communication/app-versions';
import {VersionReduxAction} from '../actions/actions-version';

@Injectable({
  providedIn: null
})
export class VersionsReducerService {
  static assignHandler(action: VersionReduxAction, stateService: StateService, epic: VersionEpicService) {
    switch (action.type) {
      case 'VERSIONS_GET_ALL_LOAD':
        epic.getVersions(action, stateService);
        break;
      case 'VERSIONS_GET_ALL_SUCCESS':
        VersionsReducerService.updateAppVersions(action, stateService);
        break;
      case 'VERSIONS_GET_ALL_ERROR':
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any Version Reducer-Function.`);
        break;
    }
  }

  // TODO: After mapping in epic -> use typed payload "AppVersions"
  private static updateAppVersions(action: VersionReduxAction, stateService: StateService) {
    const octaveVersion = action.payload.payload.octave;
    const awsVersion = action.payload.payload.aws;

    stateService.update(
      state => {
        state.versions = {
          ...state.versions,
          aws: {
            base: awsVersion?.base || '0',
            unit: awsVersion?.unit || '0',
            communication: awsVersion?.communication || '0'
          },
          octave: {
            base: octaveVersion?.base || '0',
            common: octaveVersion?.common || '0',
            matlab: octaveVersion?.matlab || '0',
            unit: octaveVersion?.unit || '0',
            communication: octaveVersion?.communication || '0'
          }
        } as AppVersions;
      },
      {
        type: 'Update App Versions'
      }
    );
  }
}
