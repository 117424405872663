import {ReduxAction} from './actions-abstract';
import {User} from '@state/state-service/do-mapper/model/communication/user';
import {BasicProject} from '@state/state-service/do-mapper/model/project/project-basic';
import {SortDirection} from '@angular/material/sort';

export enum Table {
  projects = 'projects',
  units = 'unitSystems',
  gases = 'gases',
  users = 'users'
}

export type TableActions =
  | 'TABLE_SORT'
  | 'TABLE_SHOW_DELETED'
  | 'TABLE_SHOW_OUTDATED'
  | 'TABLE_SHOW_ALL_ADMIN'
  | 'TABLE_SEARCH_TERM'
  | 'TABLE_FAVORITE';

export interface PayloadTableSort {
  table: Table;
  sort: SortDirection;
  field: string;
}

export interface PayloadTableShowDeleted {
  table: Table;
  showDeleted: boolean;
}

export interface PayloadTableShowOutdated {
  table: Table;
  showOutdated: boolean;
}

export interface PayloadTableShowAllAdmin {
  table: Table;
  showAllAdmin: boolean;
}

export interface PayloadTableSearchTerm {
  table: Table;
  searchTerm: string;
}

export interface PayloadProjectTableFavorite {
  project: BasicProject;
  user: User;
}

export type TablePayloads =
  | PayloadTableSort
  | PayloadTableShowDeleted
  | PayloadTableShowOutdated
  | PayloadTableSearchTerm
  | PayloadTableShowAllAdmin
  | PayloadProjectTableFavorite;

export class TableReduxAction extends ReduxAction {
  type: TableActions;
  payload: TablePayloads;

  constructor(type: TableActions, payload: TablePayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
