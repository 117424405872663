import {Injectable} from '@angular/core';
import {TranslateQuantityService} from '@logic/translate-quantity/translate-quanitity.service';
import {Quantity} from '@state/state-service/do-mapper/model/unit/quantity';
import * as d3 from 'd3';
import {Device} from '@state/state-service/do-mapper/model/device/properties';

@Injectable({providedIn: null})
export class DrawBarChartService {
  constructor(private unitSystemsService: TranslateQuantityService) {}

  //
  // Charts
  //
  drawBarChart(
    providedBoundaries: {min: Quantity<any>; max: Quantity<any>},
    requiredBoundaries: {min: Quantity<any>; max: Quantity<any>}
  ): d3.Selection<SVGSVGElement, undefined, null, undefined> {
    const convert = (quantity: Quantity<any>) => {
      if (!quantity) {
        return 0;
      }
      const {value} = this.unitSystemsService.translateQuantity(quantity);
      if (!value || isNaN(value)) {
        return 0;
      }
      return value;
    };

    const unitLabel = this.unitSystemsService.translateQuantity(requiredBoundaries.min).unit;

    const PADDING_LEFT = 30;
    const PADDING_RIGHT = 40;

    const COLOR_ACTUAL_DEVICE = '#007cc1';
    const COLOR_SETPOINT_OK = '#99c24d';
    const COLOR_SETPOINT_NOT_OK = '#f90';
    const COLOR_AXIS = '#595959';

    const BAR_HEIGHT = 15;

    const HEIGHT = BAR_HEIGHT * 3 + 10;
    const WIDTH = 800;

    const deviceMin = convert(providedBoundaries.min);
    const deviceMax = convert(providedBoundaries.max);
    const setPointMin = convert(requiredBoundaries.min);
    const setPointMax = convert(requiredBoundaries.max);

    const min = Math.min(deviceMin, setPointMin, deviceMax, setPointMax);
    const max = Math.max(deviceMin, setPointMin, deviceMax, setPointMax);

    // TODO: We create a detached SVG canvas, needs testing, secondary is to provide a container as parent in signature
    const svg = d3.create('svg').attr('viewBox', `0 0 ${PADDING_LEFT + WIDTH + PADDING_RIGHT} ${HEIGHT}`);
    const scale = d3.scaleLinear().range([0, WIDTH]).domain([min, max]).nice();

    svg
      .append('g')
      .attr('transform', `translate(${PADDING_LEFT}, ${BAR_HEIGHT * 2 + 2})`)
      .call(d3.axisBottom(scale).tickFormat(d => d + ' ' + unitLabel));

    // need to customize the axis with attributes to have the styling not only in FE based on css but in pdf as well later on
    svg.select('.domain').attr('stroke', COLOR_AXIS).attr('stroke-width', '1');
    svg.selectAll('.tick text').attr('fill', COLOR_AXIS);
    svg.selectAll('.tick line').attr('stroke', COLOR_AXIS);

    const COLOR_SETPOINT = deviceMin <= setPointMin && deviceMax >= setPointMax ? COLOR_SETPOINT_OK : COLOR_SETPOINT_NOT_OK;

    const MIN_BAR_DISTANCE = 5;
    const BAR_DISTANCE_SETPOINT = scale(setPointMax) - scale(setPointMin);
    // if the distance is negative, we need to display at least a minimum to indicate where the setpoint is
    let DISPLAYED_BAR_DISTANCE = BAR_DISTANCE_SETPOINT > 0 ? BAR_DISTANCE_SETPOINT : MIN_BAR_DISTANCE;

    // required boundaries box
    svg
      .append('g')
      .append('rect')
      .attr('x', PADDING_LEFT + scale(setPointMin))
      .attr('y', 0)
      .attr('height', BAR_HEIGHT)
      .attr('width', DISPLAYED_BAR_DISTANCE)
      .style('fill', COLOR_SETPOINT);

    const BAR_DISTANCE_DEVICE = scale(deviceMax) - scale(deviceMin);
    // if the distance is negative, we need to display at least a minimum to indicate where the setpoint is
    DISPLAYED_BAR_DISTANCE = BAR_DISTANCE_DEVICE > 0 ? BAR_DISTANCE_DEVICE : MIN_BAR_DISTANCE;

    // provided boundaries box
    svg
      .append('g')
      .append('rect')
      .attr('x', PADDING_LEFT + scale(deviceMin))
      .attr('y', BAR_HEIGHT)
      .attr('height', BAR_HEIGHT)
      .attr('width', DISPLAYED_BAR_DISTANCE)
      .style('fill', COLOR_ACTUAL_DEVICE);

    // this.barChart.emit(d3.select(this.chart.nativeElement).html())

    return svg; // QUESTION: or svg.html() ?
  }

  convertChartIntoString(nativeElement: HTMLElement, renderedSVG): string {
    d3.select(nativeElement).node().append(renderedSVG.node());

    return d3.select(nativeElement).html();
  }

  getSizingFlowBarChart(chartElement, selectedDevice: Device, gasdata: {min; max}): string {
    const renderedFlowBarChart = this.drawBarChart(
      {
        min: selectedDevice.flowRange.min,
        max: selectedDevice.flowRange.max
      },
      {
        min: gasdata.min.Flow.Actual,
        max: gasdata.max.Flow.Actual
      }
    );

    return this.convertChartIntoString(chartElement.nativeElement, renderedFlowBarChart);
  }

  getSizingVogBarChart(chartElement, selectedDevice: Device, gasdata: {min; max}): string {
    const renderedVogBarChart = this.drawBarChart(
      {
        min: selectedDevice.vog.min,
        max: selectedDevice.vog.max
      },
      {
        min: gasdata.min.ActualConditions.Velocity,
        max: gasdata.max.ActualConditions.Velocity
      }
    );

    return this.convertChartIntoString(chartElement.nativeElement, renderedVogBarChart);
  }

  getSizingTempBarChart(chartElement, selectedDevice: Device, input): string {
    const renderedTempBarChart = this.drawBarChart(
      {
        min: selectedDevice.flowTemperature.min,
        max: selectedDevice.flowTemperature.max
      },
      {
        min: input.processFlowTemperatureMin,
        max: input.processFlowTemperatureMax
      }
    );

    return this.convertChartIntoString(chartElement.nativeElement, renderedTempBarChart);
  }

  getSizingPressureBarChart(chartElement, selectedDevice: Device, input): string {
    const renderedPressureBarChart = this.drawBarChart(
      {
        min: selectedDevice.flowPressure.min,
        max: selectedDevice.flowPressure.max
      },
      {
        min: input.processFlowPressureMin,
        max: input.processFlowPressureMax
      }
    );

    return this.convertChartIntoString(chartElement.nativeElement, renderedPressureBarChart);
  }
}
