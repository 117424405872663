import {accessObjectByPath} from '../../util/access-object-by-string-path';
import {Item} from '../model/dto';
import {undefinedChecker} from '../util/undefined-checker';

export function assembleDtoArrayOfArrayEntity(assembledObject, elem, payload) {
  const domainObjectProp = accessObjectByPath(elem.DOMAINOBJECT, elem.PATHINDOMAINOBJECT);

  domainObjectProp.forEach((e, i) => {
    if (!e?.length || e.length === 0) {
      assembledObject = {
        key: elem.KEY,
        type: 'ARRAY<' + elem.ARRAY + '>',
        value: null
      } as Item;

      undefinedChecker(payload, assembledObject);
    } else {
      e?.forEach((ae, j) => {
        assembledObject = {
          key: elem.KEY,
          type: 'ARRAY<' + elem.ARRAY + '>',
          value: accessObjectByPath(elem.DOMAINOBJECT, elem.PATHINDOMAINOBJECT + '[' + i + ']' + '[' + j + ']'),
          aoaId: i
        } as Item;

        undefinedChecker(payload, assembledObject);
      });
    }
  });
}
