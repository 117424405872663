import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'da-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  @Input() open = false;
  @Output() dismiss = new EventEmitter(); // if the user clicks the gray area

  close() {
    this.dismiss.emit('close');
  }
}
