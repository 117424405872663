import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {GasEpicService} from '../epics/gas-epic.service';
import {ApiPayloadGeneric, GasReduxAction} from '../actions/actions-gas';
import {Pages, PageStateReduxAction, PayloadPageState} from '../actions/actions-page-state';
import {clone} from '@shared/utility/clone';
import {uniqBy} from 'lodash';
import {isDefined} from '@shared/utility/isDefined';

@Injectable({
  providedIn: null
})
export class GasReducerService {
  static assignHandler(action: GasReduxAction, stateService: StateService, epic: GasEpicService) {
    switch (action.type) {
      case 'GASES_GET_ALL_LOADING':
        GasReducerService.updateGasState(action, stateService);
        epic.getAll(action, stateService);
        break;
      case 'GASES_GET_ALL_SUCCESS':
        GasReducerService.updateGases(action, stateService);
        GasReducerService.updatePageState(
          {
            type: 'UPDATE_PAGE_STATE',
            payload: {page: Pages.gases, state: 'ready'}
          },
          stateService
        );
        break;
      case 'GASES_GET_ALL_ERROR':
        break;
      case 'GASES_SAVE_ONE_LOADING':
        epic.postOne(action, stateService);
        break;
      case 'GASES_SAVE_ONE_SUCCESS':
        GasReducerService.updateGases(action, stateService);
        break;
      case 'GASES_SAVE_ONE_ERROR':
        break;
      case 'GASES_DELETE_ONE_LOADING':
        epic.deleteOne(action, stateService);
        break;
      case 'GASES_DELETE_ONE_SUCCESS':
        GasReducerService.updateGases(action, stateService);
        break;
      case 'GASES_DELETE_ONE_ERROR':
        break;
      case 'GAS_DELETE_ONE_FROM_PROJECT':
        GasReducerService.deleteGasFromProject(action, stateService);
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any Gas Reducer-Function.`);
        break;
    }
  }

  private static updateGasState(action: GasReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        switch (action.type) {
          case 'GASES_GET_ALL_LOADING':
            s.gases.state = 'loading';
            break;
          default:
            break;
        }
      },
      {type: 'Gases Data State ' + action.type}
    );
  }

  private static updateGases(action: GasReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        const gasesWithoutOld = s.gases.data.filter(
          gas => gas.document.id !== ('gas' in action.payload ? action.payload?.gas?.document?.id || null : null)
        );

        if (action.type === 'GASES_DELETE_ONE_SUCCESS') {
          s.gases.data = clone(gasesWithoutOld);
        } else {
          const concatGases = gasesWithoutOld
            .concat('gases' in action.payload ? action.payload.gases : 'gas' in action.payload ? action.payload.gas : null)
            .filter(isDefined);

          s.gases.data = clone(uniqBy(concatGases, ({document}) => document.id));
        }

        s.gases.state = 'ready';
      },
      {
        type: 'Update Gases'
      }
    );
  }

  private static updatePageState(action: PageStateReduxAction, stateService: StateService) {
    const {page, state} = action.payload as PayloadPageState;

    stateService.update(
      s => {
        s.pages[page] = state;
      },
      {
        type: 'Page State Updated of: ' + page,
        details: {results: action}
      }
    );
  }

  private static deleteGasFromProject(action: GasReduxAction, stateService: StateService) {
    const gasToDelete = (action.payload as ApiPayloadGeneric).gas;

    stateService.update(
      s => {
        s.basicProject.data.custom.gasCompositionDocs = s.basicProject.data.custom.gasCompositionDocs.filter(
          gc => gc.document.id !== gasToDelete.document.id
        );
      },
      {
        type: 'Delete Gas From Project'
      }
    );
  }
}
