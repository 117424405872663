import {Injectable} from '@angular/core';
import {ApiPayloadGeneric, ApiPayloadGetOneSuccess, UserReduxAction} from '@state/actions/actions-user';
import {StateService} from '@state/state-service/state.service';
import {UserEpicService} from '@state/epics/user-epic.service';
import {Pages, PageStateReduxAction, PayloadPageState} from '@state/actions/actions-page-state';
import {defaultState} from '@state/model/default-state';
import {User} from '@state/state-service/do-mapper/model/communication/user';

@Injectable({
  providedIn: null
})
export class UserReducerService {
  static assignHandler(action: UserReduxAction, stateService: StateService, epic: UserEpicService) {
    switch (action.type) {
      case 'USER_GET_ALL_LOAD':
        UserReducerService.updateUsersState(action, stateService);
        epic.getUserAll(action as any, stateService).then();
        break;
      case 'USER_GET_ALL_SUCCESS':
        UserReducerService.updateUsersList(action, stateService);
        UserReducerService.updateUsersState(action, stateService);
        UserReducerService.updatePageState(
          {
            type: 'UPDATE_PAGE_STATE',
            payload: {page: Pages.users, state: 'ready'}
          },
          stateService
        );
        break;
      case 'USER_GET_ALL_ERROR':
        break;
      case 'USER_GET_CURRENT_LOAD':
        UserReducerService.updateSplash(true, stateService);
        epic.getCurrentUser(action, stateService);
        break;
      case 'USER_GET_CURRENT_SUCCESS':
        UserReducerService.updateUserStateToLoggedIn(action, stateService);
        UserReducerService.updateSplash(false, stateService);
        break;
      case 'USER_GET_CURRENT_ERROR':
      case 'USER_GET_CURRENT_NOT_FLOW_TWIN':
        UserReducerService.updateUserStateToNoFlowTwinUser(action, stateService);
        UserReducerService.updateSplash(false, stateService);
        break;
      case 'USER_UPDATE_CURRENT_LOAD':
        epic.updateUser(action, stateService);
        break;
      case 'USER_UPDATE_CURRENT_SUCCESS':
        this.updateUserInUsersList(action, stateService);
        break;
      case 'USER_UPDATE_CURRENT_ERROR':
        this.updateUserStateToError(action, stateService);
        break;
      case 'USER_POST_LOAD':
        epic.postUser(action, stateService);
        break;
      case 'USER_POST_SUCCESS':
        this.updateUserInUsersList(action, stateService);
        break;
      case 'USER_POST_ERROR':
        break;
      case 'USER_DELETE_LOAD':
        epic.deleteUser(action, stateService);
        break;
      case 'USER_DELETE_SUCCESS':
        this.removeUserFromUsersList(action, stateService);
        break;
      case 'USER_DELETE_ERROR':
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any User Reducer-Function.`);
        break;
    }
  }

  private static updateUsersState(action: UserReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        switch (action.type) {
          case 'USER_GET_ALL_LOAD':
            s.users.state = 'loading';
            break;
          case 'USER_GET_ALL_SUCCESS':
            s.users.state = 'ready';
            break;
          default:
            break;
        }
      },
      {type: 'User Data State ' + action.type}
    );
  }

  private static updateUserStateToError(action: UserReduxAction, stateService: StateService) {
    stateService.update(
      state => {
        state.user.state = 'error';
        state.user.document = defaultState.user.document;
      },
      {
        type: 'Update User State to be Erroneous'
      }
    );
  }
  private static updateUserStateToNoFlowTwinUser(action: UserReduxAction, stateService: StateService) {
    stateService.update(
      state => {
        state.user.state = 'no-flow-twin-user';
        state.user.document = defaultState.user.document;
      },
      {
        type: 'Update User State to be no FlowTwin User'
      }
    );
  }

  private static updateUserStateToLoggedIn(action: UserReduxAction, stateService: StateService) {
    stateService.update(
      state => {
        state.user = (action.payload as ApiPayloadGetOneSuccess).user;
        state.user.state = 'logged-in';
      },
      {
        type: 'Update User State to be Logged-In'
      }
    );
  }

  private static removeUserFromUsersList(action: UserReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        s.users.data = s.users.data.filter(user => user.document.id !== (action.payload as ApiPayloadGeneric).user.document.id);
      },
      {type: 'Remove User from UserList'}
    );
  }

  private static updateUserInUsersList(action: UserReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        const allOtherUsers = s.users.data.filter(s => s.document.id !== (action.payload as ApiPayloadGeneric).user.document.id);

        s.users.data = [...allOtherUsers, ...[(action.payload as ApiPayloadGeneric).user]];
      },
      {type: 'Update User in UserList'}
    );
  }

  private static updateUsersList(action: UserReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        s.users.data = (action.payload as ApiPayloadGeneric).users.sort((a: User, b: User) => a.document.id.localeCompare(b.document.id));
      },
      {type: 'Update User in UserList'}
    );
  }

  private static updatePageState(action: PageStateReduxAction, stateService: StateService) {
    const {page, state} = action.payload as PayloadPageState;

    stateService.update(
      s => {
        s.pages[page] = state;
      },
      {
        type: 'Page State Updated of: ' + page,
        details: {results: action}
      }
    );
  }

  // Former name in Root-Reducer: update_splash
  private static updateSplash(splashState: boolean, stateService: StateService) {
    stateService.update(
      s => {
        s.splash.open = splashState;
      },
      {
        type: 'AuthService.additionalCheck - open splash'
      }
    );
  }
}
