import {DtoContract} from '../../contracts';

export const RES_POST_APP_SELECTION_SIZING_RESULT = {
  config: [
    // devices
    {KEY: 'devices', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.segments', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.measuredFlowValues', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.installationTypes', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.pathLayouts', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.referenceConditions', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.sensorTypes', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.sensorGasSelectionMethod', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.schedules', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.explosionGroups', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.flowConditions', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.flowConditioners', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.flowRangeOptions', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.nominalSizes', TYPE: 'ARRAY<ENTITY>', ARRAY: 'NUMBER'},
    {KEY: 'metadata.nozzleDistances', TYPE: 'ARRAY<ENTITY>', ARRAY: 'NUMBER'},
    {KEY: 'metadata.pressureClasses', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.productFamilies', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.productTypes', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {
      KEY: 'metadata.uncertaintySetup.deviceUncertaintySetups',
      TYPE: 'ARRAY<ENTITY>',
      ARRAY: 'DTOKEYVALUEITEM'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.biasNozzleDistance',
      TYPE: 'NUMBER'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.useCustomPathAngle',
      TYPE: 'BOOLEAN'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated',
      TYPE: 'BOOLEAN'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty',
      TYPE: 'BOOLEAN'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.customPathAngle',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming',
      TYPE: 'BOOLEAN'
    },
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.pathCount', TYPE: 'STRING'},
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.nominalDiameter',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.unpairedProbesTiming',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation',
      TYPE: 'BOOLEAN'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation',
      TYPE: 'BOOLEAN'
    },
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.postprocessing.envelope', TYPE: 'BOOLEAN'},
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.vogTheta', TYPE: 'DTOQUANTITY'},
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.agcUpperLimit',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.isentropicComponentKappa',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uThickness',
      TYPE: 'DTOQUANTITY'
    },
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uU', TYPE: 'DTOQUANTITY'},
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4A_uU', TYPE: 'DTOQUANTITY'},
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.valueM', TYPE: 'DTOQUANTITY'},
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uuAlpha', TYPE: 'DTOQUANTITY'},
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4A_uThickness',
      TYPE: 'DTOQUANTITY'
    }
  ] as DtoContract[]
};
