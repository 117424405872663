import {Pipe, PipeTransform} from '@angular/core';
import {StateService} from '@state/state-service/state.service';
import {displayValue} from '@state/state-service/do-mapper/model/unit/display-value';
import {Quantity} from '@state/state-service/do-mapper/model/unit/quantity';

@Pipe({
  name: 'unitConvert',
  pure: false
})
export class UnitConvertPipe implements PipeTransform {
  constructor(private stateService: StateService) {}

  transform(quantity: Quantity<any> | null | undefined, ...args: unknown[]): string {
    if (!quantity) {
      return '-';
    }

    const displayUnitSystem = this.stateService.state.basicProject.data.custom.displayUnitSystem;
    const convert = displayValue(displayUnitSystem.unitSystem);
    return convert(quantity);
  }
}
