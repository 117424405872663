import {Component, OnDestroy, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {Observable, of, Subscription} from 'rxjs';

export type DropdownOption = {
  value: string;
  label: string;
};

@Component({
  templateUrl: './form-dropdown.component.html',
  styleUrls: ['./form-dropdown.component.scss']
})
export class FormDropdownComponent extends FieldType implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  get label() {
    return this.field.templateOptions.label || '';
  }

  get dropDownOptions() {
    const options = this.field.templateOptions.options || [];
    return options instanceof Observable ? options : of(options);
  }

  get translateOptions() {
    return this.field.templateOptions.translateOptions || true;
  }

  get showNone() {
    return this.field.templateOptions.showNone as string | null | undefined;
  }

  get onChangeCallback() {
    const noop = () => null;
    return this.field.templateOptions.onChangeCallback || noop;
  }

  get hide() {
    const cb = this.field.templateOptions.hide;
    if (!cb) {
      return false;
    }

    return cb();
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subs.push(
      this.formControl.valueChanges.subscribe(() => {
        this.onChangeCallback();
      })
    );
  }
}
