import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {LocaleReduxAction, PayloadSwitchLocale} from '../actions/actions-locale';

@Injectable({
  providedIn: 'root'
})
export class LocaleReducerService {
  static assignHandler(action: LocaleReduxAction, stateService: StateService) {
    switch (action.type) {
      case 'SWITCH_LOCALE':
        LocaleReducerService.switchLanguage(action, stateService);
        break;
    }
  }

  private static switchLanguage(action: LocaleReduxAction, stateService: StateService) {
    const {language} = action.payload as PayloadSwitchLocale;

    stateService.update(
      s => {
        s.user.document.locale = language;
      },
      {
        type: 'LanguageSelectComponent.lang',
        details: stateService.state.user.document.locale + ' -> ' + language
      }
    );
  }
}
