import {DomainContract} from '@flowtwin/communication';
import {AppUncertaintyInput} from '@state/state-service/do-mapper/model/apps/app-uncertainty';

export const POST_COMPUTE_UNCERTAINTY = (input: AppUncertaintyInput): DomainContract[] => {
  return [
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[0].name',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMin.name'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[0].active',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMin.active'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[0].temperature',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMin.temperature'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[0].pressure',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMin.pressure'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[0].gasCompositionId',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMin.gasCompositionId'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[0].molWeight',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMin.molWeight'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[0].kappa',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMin.kappa'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[0].usedGasMethod',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMin.usedGasMethod'
    },

    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[1].name',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMax.name'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[1].active',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMax.active'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[1].temperature',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMax.temperature'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[1].pressure',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMax.pressure'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[1].gasCompositionId',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMax.gasCompositionId'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[1].molWeight',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMax.molWeight'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[1].kappa',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMax.kappa'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[1].usedGasMethod',
      PATHINDATATRANSFEROBJECT: 'processDataEntryMax.usedGasMethod'
    },

    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[2].name',
      PATHINDATATRANSFEROBJECT: 'processDataEntry1.name'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[2].active',
      PATHINDATATRANSFEROBJECT: 'processDataEntry1.active'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[2].temperature',
      PATHINDATATRANSFEROBJECT: 'processDataEntry1.temperature'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[2].pressure',
      PATHINDATATRANSFEROBJECT: 'processDataEntry1.pressure'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[2].gasCompositionId',
      PATHINDATATRANSFEROBJECT: 'processDataEntry1.gasCompositionId'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[2].molWeight',
      PATHINDATATRANSFEROBJECT: 'processDataEntry1.molWeight'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[2].kappa',
      PATHINDATATRANSFEROBJECT: 'processDataEntry1.kappa'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[2].usedGasMethod',
      PATHINDATATRANSFEROBJECT: 'processDataEntry1.usedGasMethod'
    },

    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[3].name',
      PATHINDATATRANSFEROBJECT: 'processDataEntry2.name'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[3].active',
      PATHINDATATRANSFEROBJECT: 'processDataEntry2.active'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[3].temperature',
      PATHINDATATRANSFEROBJECT: 'processDataEntry2.temperature'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[3].pressure',
      PATHINDATATRANSFEROBJECT: 'processDataEntry2.pressure'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[3].gasCompositionId',
      PATHINDATATRANSFEROBJECT: 'processDataEntry2.gasCompositionId'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[3].molWeight',
      PATHINDATATRANSFEROBJECT: 'processDataEntry2.molWeight'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[3].kappa',
      PATHINDATATRANSFEROBJECT: 'processDataEntry2.kappa'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[3].usedGasMethod',
      PATHINDATATRANSFEROBJECT: 'processDataEntry2.usedGasMethod'
    },

    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[4].name',
      PATHINDATATRANSFEROBJECT: 'processDataEntry3.name'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[4].active',
      PATHINDATATRANSFEROBJECT: 'processDataEntry3.active'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[4].temperature',
      PATHINDATATRANSFEROBJECT: 'processDataEntry3.temperature'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[4].pressure',
      PATHINDATATRANSFEROBJECT: 'processDataEntry3.pressure'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[4].gasCompositionId',
      PATHINDATATRANSFEROBJECT: 'processDataEntry3.gasCompositionId'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[4].molWeight',
      PATHINDATATRANSFEROBJECT: 'processDataEntry3.molWeight'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[4].kappa',
      PATHINDATATRANSFEROBJECT: 'processDataEntry3.kappa'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[4].usedGasMethod',
      PATHINDATATRANSFEROBJECT: 'processDataEntry3.usedGasMethod'
    },

    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[5].name',
      PATHINDATATRANSFEROBJECT: 'processDataEntry4.name'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[5].active',
      PATHINDATATRANSFEROBJECT: 'processDataEntry4.active'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[5].temperature',
      PATHINDATATRANSFEROBJECT: 'processDataEntry4.temperature'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[5].pressure',
      PATHINDATATRANSFEROBJECT: 'processDataEntry4.pressure'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[5].gasCompositionId',
      PATHINDATATRANSFEROBJECT: 'processDataEntry4.gasCompositionId'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[5].molWeight',
      PATHINDATATRANSFEROBJECT: 'processDataEntry4.molWeight'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[5].kappa',
      PATHINDATATRANSFEROBJECT: 'processDataEntry4.kappa'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'processDataEntries[5].usedGasMethod',
      PATHINDATATRANSFEROBJECT: 'processDataEntry4.usedGasMethod'
    },

    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'gasCompositions', PATHINDATATRANSFEROBJECT: 'gasCompositions'},

    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'remarks', PATHINDATATRANSFEROBJECT: 'explanation'},

    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'userLimits.lowFlowUncertainty',
      PATHINDATATRANSFEROBJECT: 'userLimits.lowFlowUncertainty'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'userLimits.lowFlowLimit',
      PATHINDATATRANSFEROBJECT: 'userLimits.lowFlowLimit'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'userLimits.highFlowUncertainty',
      PATHINDATATRANSFEROBJECT: 'userLimits.highFlowUncertainty'
    },

    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'velocities', PATHINDATATRANSFEROBJECT: 'velocities'},

    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'deviceUncertaintySetups',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.deviceUncertaintySetups'
    },

    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.vogTheta',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.vogTheta'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.valueM',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.valueM'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.agcUpperLimit',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.agcUpperLimit'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.isentropicComponentKappa',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.isentropicComponentKappa'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.m4A_uU',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.m4A_uU'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.m4A_uThickness',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.m4A_uThickness'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.m4B_uU',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.m4B_uU'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.m4B_uThickness',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.m4B_uThickness'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.m4B_uuAlpha',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.parameters.m4B_uuAlpha'
    },

    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.unpairedProbesTiming',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.unpairedProbesTiming'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomPathAngle',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomPathAngle'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.customPathAngle',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.customPathAngle'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'biasNozzleDistance',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.biasNozzleDistance'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'nominalDiameter',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.nominalDiameter'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'pathCount',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.configuration.pathCount'
    },

    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.useMachUnit',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.useMachUnit'
    },
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation'
    },
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.envelope',
      PATHINDATATRANSFEROBJECT: 'parameterSetup.extendedUncertaintySetup.postprocessing.envelope'
    },

    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'selectedDevice', PATHINDATATRANSFEROBJECT: 'selectedDevice'}
  ];
};
