import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MockInterceptorProvider} from './mock-interceptor/mock.interceptor';
import {ComputeInterceptorProvider} from './compute-interceptor/compute.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [MockInterceptorProvider, ComputeInterceptorProvider]
})
export class MockModule {}
