import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppErrorHandler} from './error/error-handler';

import {InputComponent} from './input/input.component';
import {RangeSliderComponent} from './range-slider/range-slider.component';
import {MaterialModule} from '@core/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

import {LanguageSelectComponent} from './language-select/language-select.component';
import {SplashComponent} from './splash/splash.component';
import {MinMaxBarChartComponent} from './min-max-bar-chart/min-max-bar-chart.component';
import {UserChipInputComponent} from './user-chip-input/user-chip-input.component';
import {InputTableComponent} from '@ui/ui-components/table-draggable/input-table/input-table.component';
import {SharedModule} from '@shared/shared.module';
import {UtilityModule} from '../utility/utility.module';
import {TableSearchInputComponent} from './table-search-input/table-search-input.component';
import {MessageBarComponent} from './message-bar/message-bar.component';
import {LiquidDetectionResultComponent} from './liquid-detection/liquid-detection.component';

@NgModule({
  declarations: [
    InputComponent,
    RangeSliderComponent,
    LanguageSelectComponent,
    SplashComponent,
    MinMaxBarChartComponent,
    UserChipInputComponent,
    InputTableComponent,
    TableSearchInputComponent,
    MessageBarComponent,
    LiquidDetectionResultComponent
  ],
  imports: [CommonModule, MaterialModule, TranslateModule, FormsModule, ReactiveFormsModule, SharedModule, UtilityModule],
  providers: [AppErrorHandler],
  exports: [
    InputComponent,
    RangeSliderComponent,
    LanguageSelectComponent,
    SplashComponent,
    MinMaxBarChartComponent,
    UserChipInputComponent,
    InputTableComponent,
    TableSearchInputComponent,
    MessageBarComponent,
    LiquidDetectionResultComponent
  ]
})
export class UIComponentsModule {}
