import {Dto} from '@flowtwin/communication';
import {SubDtoMapperProject} from '../../state-service/dto-mapper/sub-mappers/project';
import {RootDtoMapper} from '../../state-service/dto-mapper/root-dto.mapper';
import {EndpointReferenceContext} from './endpoint-reference-context';

export function prepareToCall(ctx: EndpointReferenceContext, data: any): Dto {
  let dto = null;

  switch (ctx.key) {
    case 'document':
      dto = SubDtoMapperProject.mapToFlowTwinProjectFromBasic(data);
      break;
    case 'appProject':
      break;
    case 'appSizing':
      dto = RootDtoMapper.appSizingMappingBroker(data, ctx.partOfApp);
      break;
    case 'appUncertainty':
      dto = RootDtoMapper.appUncertaintyMappingBroker(data, ctx.partOfApp);
      break;
    case 'appTransducer':
      dto = RootDtoMapper.appTransducerMappingBroker(data, ctx.partOfApp);
      break;
  }

  return dto;
}
