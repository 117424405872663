export function getMissingPaths(array1, array2): {desired: string; missing: string}[] {
  if (array1.length === array2.length) {
    return array1
      .map((x, i) => {
        if (!array2.includes(x)) {
          return {desired: array1[i], present: 'n/a'};
        }
      })
      .filter(x => x);
  } else {
    return array1
      .map((x, i) => {
        if (!array2.includes(x)) {
          return {desired: array1[i], present: 'n/a'};
        }
      })
      .filter(x => x);
  }
}
