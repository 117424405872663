import {DtoContract} from '../../contracts';

export const RES_POST_APP_TRANSDUCER_PERFORMANCE_RESULT = {
  config: [
    {KEY: 'agc.pMin.innerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'agc.pMin.outerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'agc.pMax.innerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'agc.pMax.outerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'pathLength.innerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'pathLength.outerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'agcAssembly.innerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'agcAssembly.outerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'pressureAssembly', TYPE: 'DTOQUANTITY'},
    {KEY: 'agcState.pMin.innerPath', TYPE: 'NUMBER'},
    {KEY: 'agcState.pMin.outerPath', TYPE: 'NUMBER'},
    {KEY: 'agcState.pMax.innerPath', TYPE: 'NUMBER'},
    {KEY: 'agcState.pMax.outerPath', TYPE: 'NUMBER'},
    {KEY: 'limitSearch.maxNW', TYPE: 'DTOQUANTITY'},
    {KEY: 'limitSearch.minPressure', TYPE: 'DTOQUANTITY'},
    {KEY: 'hsState', TYPE: 'BOOLEAN'},
    {KEY: 'reflectionState', TYPE: 'BOOLEAN'},
    {KEY: 'designState', TYPE: 'NUMBER'},
    {KEY: 'probe', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'gasType', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'summaryState.applicationState', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.applicationRecommendation.Summary', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.applicationRecommendation.Pressure', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.applicationRecommendation.NominalDiameter', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.applicationRecommendation.H2State', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.applicationRecommendation.SosState', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.agcSimulation', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.signalSimulation', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.designState.summary', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.designState.minDesign', TYPE: 'BOOLEAN'}
  ] as DtoContract[]
};
