import {DtoContract} from '../../contracts';

export const RES_GET_APP_SYSTEM_UNCERTAINTY_OUTPUT = {
  config: [
    {KEY: 'flowrates', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},
    {KEY: 'velocities', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},

    {KEY: 'warning.deviceOutOfSpec', TYPE: 'BOOLEAN'},
    {KEY: 'warning.implausibleResults', TYPE: 'BOOLEAN'},
    {KEY: 'warning.signalStrengthWeak', TYPE: 'BOOLEAN'},

    {KEY: 'uncertainties.processConditionMin.name', TYPE: 'STRING'},
    {KEY: 'uncertainties.processConditionMin.active', TYPE: 'BOOLEAN'},
    {KEY: 'uncertainties.processConditionMin.agc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processConditionMin.flowrateMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processConditionMin.flowrateMaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processConditionMin.uncertaintyQmax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processConditionMin.uncertaintyQmaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processConditionMin.velocityMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processConditionMin.uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},

    {KEY: 'uncertainties.processConditionMax.name', TYPE: 'STRING'},
    {KEY: 'uncertainties.processConditionMax.active', TYPE: 'BOOLEAN'},
    {KEY: 'uncertainties.processConditionMax.agc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processConditionMax.flowrateMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processConditionMax.flowrateMaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processConditionMax.uncertaintyQmax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processConditionMax.uncertaintyQmaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processConditionMax.velocityMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processConditionMax.uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},

    {KEY: 'uncertainties.processCondition1.name', TYPE: 'STRING'},
    {KEY: 'uncertainties.processCondition1.active', TYPE: 'BOOLEAN'},
    {KEY: 'uncertainties.processCondition1.agc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition1.flowrateMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition1.flowrateMaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition1.uncertaintyQmax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition1.uncertaintyQmaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition1.velocityMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition1.uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},

    {KEY: 'uncertainties.processCondition2.name', TYPE: 'STRING'},
    {KEY: 'uncertainties.processCondition2.active', TYPE: 'BOOLEAN'},
    {KEY: 'uncertainties.processCondition2.agc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition2.flowrateMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition2.flowrateMaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition2.uncertaintyQmax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition2.uncertaintyQmaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition2.velocityMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition2.uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},

    {KEY: 'uncertainties.processCondition3.name', TYPE: 'STRING'},
    {KEY: 'uncertainties.processCondition3.active', TYPE: 'BOOLEAN'},
    {KEY: 'uncertainties.processCondition3.agc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition3.flowrateMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition3.flowrateMaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition3.uncertaintyQmax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition3.uncertaintyQmaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition3.velocityMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition3.uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},

    {KEY: 'uncertainties.processCondition4.name', TYPE: 'STRING'},
    {KEY: 'uncertainties.processCondition4.active', TYPE: 'BOOLEAN'},
    {KEY: 'uncertainties.processCondition4.agc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition4.flowrateMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition4.flowrateMaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition4.uncertaintyQmax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition4.uncertaintyQmaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition4.velocityMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertainties.processCondition4.uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},

    {KEY: 'uncertaintiesRaw.processConditionMin.name', TYPE: 'STRING'},
    {KEY: 'uncertaintiesRaw.processConditionMin.active', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintiesRaw.processConditionMin.agc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processConditionMin.flowrateMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processConditionMin.flowrateMaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processConditionMin.uncertaintyQmax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processConditionMin.uncertaintyQmaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processConditionMin.velocityMax', TYPE: 'DTOQUANTITY'},
    {
      KEY: 'uncertaintiesRaw.processConditionMin.uncertainties',
      TYPE: 'ARRAY<ENTITY>',
      ARRAY: 'DTOQUANTITY'
    },

    {KEY: 'uncertaintiesRaw.processConditionMax.name', TYPE: 'STRING'},
    {KEY: 'uncertaintiesRaw.processConditionMax.active', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintiesRaw.processConditionMax.agc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processConditionMax.flowrateMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processConditionMax.flowrateMaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processConditionMax.uncertaintyQmax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processConditionMax.uncertaintyQmaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processConditionMax.velocityMax', TYPE: 'DTOQUANTITY'},
    {
      KEY: 'uncertaintiesRaw.processConditionMax.uncertainties',
      TYPE: 'ARRAY<ENTITY>',
      ARRAY: 'DTOQUANTITY'
    },

    {KEY: 'uncertaintiesRaw.processCondition1.name', TYPE: 'STRING'},
    {KEY: 'uncertaintiesRaw.processCondition1.active', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintiesRaw.processCondition1.agc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition1.flowrateMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition1.flowrateMaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition1.uncertaintyQmax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition1.uncertaintyQmaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition1.velocityMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition1.uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},

    {KEY: 'uncertaintiesRaw.processCondition2.name', TYPE: 'STRING'},
    {KEY: 'uncertaintiesRaw.processCondition2.active', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintiesRaw.processCondition2.agc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition2.flowrateMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition2.flowrateMaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition2.uncertaintyQmax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition2.uncertaintyQmaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition2.velocityMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition2.uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},

    {KEY: 'uncertaintiesRaw.processCondition3.name', TYPE: 'STRING'},
    {KEY: 'uncertaintiesRaw.processCondition3.active', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintiesRaw.processCondition3.agc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition3.flowrateMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition3.flowrateMaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition3.uncertaintyQmax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition3.uncertaintyQmaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition3.velocityMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition3.uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},

    {KEY: 'uncertaintiesRaw.processCondition4.name', TYPE: 'STRING'},
    {KEY: 'uncertaintiesRaw.processCondition4.active', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintiesRaw.processCondition4.agc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition4.flowrateMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition4.flowrateMaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition4.uncertaintyQmax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition4.uncertaintyQmaxAsc', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition4.velocityMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintiesRaw.processCondition4.uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'}
  ] as DtoContract[]
};
export const RES_GET_APP_SYSTEM_UNCERTAINTY_INPUT = {
  config: [
    {
      KEY: 'processDataEntries',
      TYPE: 'ARRAY<ARRAY<ENTITY>>',
      ARRAY: 'DTOKEYVALUEITEM'
    },
    // these gasCompositions matches to compositions used in processDataEntries "gasCompositionDocId"
    // the gasCompositionDocId referenced in processDataEntires is merged into gasCompositions as DTOKEYVALUEITEM
    {KEY: 'explanation', TYPE: 'STRING'},
    {KEY: 'userLimits.lowFlowUncertainty', TYPE: 'DTOQUANTITY'},
    {KEY: 'userLimits.lowFlowLimit', TYPE: 'DTOQUANTITY'},
    {KEY: 'userLimits.highFlowUncertainty', TYPE: 'DTOQUANTITY'},
    {KEY: 'velocities', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},
    {KEY: 'parameterSetup.deviceUncertaintySetups', TYPE: 'ARRAY<ARRAY<ENTITY>>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming', TYPE: 'BOOLEAN'},
    {
      KEY: 'parameterSetup.extendedUncertaintySetup.configuration.unpairedProbesTiming',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty',
      TYPE: 'BOOLEAN'
    },
    {
      KEY: 'parameterSetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty',
      TYPE: 'DTOQUANTITY'
    },
    {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomPathAngle', TYPE: 'BOOLEAN'},
    {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.customPathAngle', TYPE: 'DTOQUANTITY'},
    {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.biasNozzleDistance', TYPE: 'NUMBER'},
    {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated', TYPE: 'BOOLEAN'},
    {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.nominalDiameter', TYPE: 'DTOQUANTITY'},
    {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.pathCount', TYPE: 'STRING'},
    {KEY: 'parameterSetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation', TYPE: 'BOOLEAN'},
    {KEY: 'parameterSetup.extendedUncertaintySetup.postprocessing.useMachUnit', TYPE: 'BOOLEAN'},
    {
      KEY: 'parameterSetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation',
      TYPE: 'BOOLEAN'
    },
    {KEY: 'parameterSetup.extendedUncertaintySetup.postprocessing.envelope', TYPE: 'BOOLEAN'},
    {KEY: 'parameterSetup.extendedUncertaintySetup.parameters.agcUpperLimit', TYPE: 'DTOQUANTITY'},
    {
      KEY: 'parameterSetup.extendedUncertaintySetup.parameters.isentropicComponentKappa',
      TYPE: 'DTOQUANTITY'
    }
  ] as DtoContract[]
};
export const RES_POST_APP_UNCERTAINTY_PREDICTION_RESULT = {
  config: [
    {KEY: 'flowrates', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},
    {KEY: 'velocities', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},

    {KEY: 'warning.deviceOutOfSpec', TYPE: 'BOOLEAN'},
    {KEY: 'warning.implausibleResults', TYPE: 'BOOLEAN'},
    {KEY: 'warning.signalStrengthWeak', TYPE: 'BOOLEAN'},
    {KEY: 'userLimits.machLowFlowLimit', TYPE: 'DTOQUANTITY'},
    ...buildContractForProcessPoint('processConditionMin', false),
    ...buildContractForProcessPoint('processConditionMax', false),
    ...buildContractForProcessPoint('processCondition1', false),
    ...buildContractForProcessPoint('processCondition2', false),
    ...buildContractForProcessPoint('processCondition3', false),
    ...buildContractForProcessPoint('processCondition4', false),

    ...buildContractForProcessPoint('processConditionMin', true),
    ...buildContractForProcessPoint('processConditionMax', true),
    ...buildContractForProcessPoint('processCondition1', true),
    ...buildContractForProcessPoint('processCondition2', true),
    ...buildContractForProcessPoint('processCondition3', true),
    ...buildContractForProcessPoint('processCondition4', true)
  ] as DtoContract[]
};

function buildContractForProcessPoint(conditionName: string, raw = false): Array<DtoContract> {
  return [
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.name`, TYPE: 'STRING'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.active`, TYPE: 'BOOLEAN'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.sos`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.agc`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.flowrateMax`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.flowrateMaxAsc`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.uncertaintyQmax`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.uncertaintyQmaxAsc`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.velocityMax`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.velocityMaxAsc`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.machMax`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.machMaxAsc`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.uncertainties`, TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.mach`, TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.liquidVolumeFraction`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.phaseState`, TYPE: 'NUMBER'}
  ];
}
