import {DomainContract} from '@flowtwin/communication';
import {User} from '../../do-mapper/model/communication/user';
import {AppSizingInput, AppSizingMetadata} from '@state/state-service/do-mapper/model/apps/app-sizing';

export const CONTRACT_POST_DEVICE_SEARCH = (
  appSelectionSizingInput: AppSizingInput,
  appSelectionSizingMetadata: AppSizingMetadata
): DomainContract[] => {
  return [
    {DOMAINOBJECT: appSelectionSizingMetadata, PATHINDOMAINOBJECT: 'filter', PATHINDATATRANSFEROBJECT: 'filter'},
    {DOMAINOBJECT: appSelectionSizingInput, PATHINDOMAINOBJECT: 'measuredFlowValue', PATHINDATATRANSFEROBJECT: 'measuredFlowValue'},
    {DOMAINOBJECT: appSelectionSizingInput, PATHINDOMAINOBJECT: 'productFamily', PATHINDATATRANSFEROBJECT: 'filters.productFamily'},
    {DOMAINOBJECT: appSelectionSizingInput, PATHINDOMAINOBJECT: 'productType', PATHINDATATRANSFEROBJECT: 'filters.productType'},
    {DOMAINOBJECT: appSelectionSizingInput, PATHINDOMAINOBJECT: 'nominalSize', PATHINDATATRANSFEROBJECT: 'filters.nominalSize'},
    {DOMAINOBJECT: appSelectionSizingInput, PATHINDOMAINOBJECT: 'innerDiameter', PATHINDATATRANSFEROBJECT: 'filters.innerDiameter'},
    {DOMAINOBJECT: appSelectionSizingInput, PATHINDOMAINOBJECT: 'schedule', PATHINDATATRANSFEROBJECT: 'filters.schedule'},
    {DOMAINOBJECT: appSelectionSizingInput, PATHINDOMAINOBJECT: 'installationType', PATHINDATATRANSFEROBJECT: 'filters.installationType'},
    {DOMAINOBJECT: appSelectionSizingInput, PATHINDOMAINOBJECT: 'flowCondition', PATHINDATATRANSFEROBJECT: 'filters.flowCondition'},
    {DOMAINOBJECT: appSelectionSizingInput, PATHINDOMAINOBJECT: 'pressureClass', PATHINDATATRANSFEROBJECT: 'filters.pressureClass'},
    {DOMAINOBJECT: appSelectionSizingInput, PATHINDOMAINOBJECT: 'flowConditioner', PATHINDATATRANSFEROBJECT: 'filters.flowConditioner'},
    {DOMAINOBJECT: appSelectionSizingInput, PATHINDOMAINOBJECT: 'flowRangeOption', PATHINDATATRANSFEROBJECT: 'filters.flowRangeType'},
    {DOMAINOBJECT: appSelectionSizingInput, PATHINDOMAINOBJECT: 'fullBore', PATHINDATATRANSFEROBJECT: 'filters.fullBore'},
    {
      DOMAINOBJECT: appSelectionSizingInput,
      PATHINDOMAINOBJECT: 'processFlowPressureMax',
      PATHINDATATRANSFEROBJECT: 'application.flowPressure.max'
    },
    {
      DOMAINOBJECT: appSelectionSizingInput,
      PATHINDOMAINOBJECT: 'processFlowPressureMin',
      PATHINDATATRANSFEROBJECT: 'application.flowPressure.min'
    },
    {
      DOMAINOBJECT: appSelectionSizingInput,
      PATHINDOMAINOBJECT: 'processFlowTemperatureMax',
      PATHINDATATRANSFEROBJECT: 'application.flowTemperature.max'
    },
    {
      DOMAINOBJECT: appSelectionSizingInput,
      PATHINDOMAINOBJECT: 'processFlowTemperatureMin',
      PATHINDATATRANSFEROBJECT: 'application.flowTemperature.min'
    },
    {
      DOMAINOBJECT: appSelectionSizingInput,
      PATHINDOMAINOBJECT: 'processFlowRangeMax',
      PATHINDATATRANSFEROBJECT: 'application.flowRange.max'
    },
    {
      DOMAINOBJECT: appSelectionSizingInput,
      PATHINDOMAINOBJECT: 'processFlowRangeMin',
      PATHINDATATRANSFEROBJECT: 'application.flowRange.min'
    },
    {
      DOMAINOBJECT: appSelectionSizingInput,
      PATHINDOMAINOBJECT: 'processConditionsMin',
      PATHINDATATRANSFEROBJECT: 'application.combinationIndexMin'
    },
    {
      DOMAINOBJECT: appSelectionSizingInput,
      PATHINDOMAINOBJECT: 'processConditionsMax',
      PATHINDATATRANSFEROBJECT: 'application.combinationIndexMax'
    },
    {
      DOMAINOBJECT: appSelectionSizingInput,
      PATHINDOMAINOBJECT: 'gasComposition',
      PATHINDATATRANSFEROBJECT: 'application.gasComposition'
    }
  ];
};
