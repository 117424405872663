import {DtoDoPropCollectionMap} from '@flowtwin/communication';

export const PROP_COLLECTION_MAP_FLOW_TWIN_ERROR: DtoDoPropCollectionMap[] = [
  {
    dtoKey: 'status',
    doKey: 'status'
  },
  {
    dtoKey: 'errorMessage',
    doKey: 'errorMessage'
  },
  {
    dtoKey: 'statusCode',
    doKey: 'statusCode'
  },
  {
    dtoKey: 'currentRoute',
    doKey: 'currentRoute'
  },
  {
    dtoKey: 'errorData',
    doKey: 'errorData'
  },
  {
    dtoKey: 'errorCode',
    doKey: 'errorCode'
  }
];
