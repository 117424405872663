import {DtoContract} from '../contracts';

export const USER_DOCUMENT: DtoContract[] = [
  {KEY: 'id', TYPE: 'STRING'},
  {KEY: 'name', TYPE: 'STRING'},
  {KEY: 'email', TYPE: 'STRING'},
  {KEY: 'familyName', TYPE: 'STRING'},
  {KEY: 'givenName', TYPE: 'STRING'},
  {KEY: 'locale', TYPE: 'STRING'},
  {KEY: 'staredProjects', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'}
];

export const USER_PERMISSIONS: DtoContract[] = [
  {KEY: 'permissions.admin', TYPE: 'BOOLEAN'},
  {KEY: 'permissions.supervisor', TYPE: 'BOOLEAN'},
  {KEY: 'permissions.gbc04expert', TYPE: 'BOOLEAN'},
  {KEY: 'permissions.gbc04service', TYPE: 'BOOLEAN'},
  {KEY: 'permissions.gbc04sales', TYPE: 'BOOLEAN'},
  {KEY: 'permissions.sicksales', TYPE: 'BOOLEAN'},
  {KEY: 'permissions.externalpartner', TYPE: 'BOOLEAN'},
  {KEY: 'permissions.externalservice', TYPE: 'BOOLEAN'},
  {KEY: 'permissions.externalcustomer', TYPE: 'BOOLEAN'}
];

export const EXTERNAL_USER_PERMISSIONS: DtoContract[] = [
  {KEY: 'externalPermissions.apps', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
  {KEY: 'externalPermissions.productFamilies', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'}
];
