import {Dto, DtoDefinition} from '../dto-assembly-line/model/dto';
import {DoAssemblyFactory} from './factory/do.factory';
import {createTypedInstance} from './util/create-typed-instance';

export interface DtoDoPropCollectionMap {
  dtoKey: string;
  doKey: string;
}

export class DoAssemblyDirector {
  private assembledDomainObject: any;
  private readonly dataTransferObject: Dto;
  private readonly desiredProperties: DtoDoPropCollectionMap[];
  private readonly expectedDtoKey: DtoDefinition;

  constructor(dto: Dto, propList: DtoDoPropCollectionMap[], expectedDtoKey?: DtoDefinition) {
    this.dataTransferObject = dto;
    this.desiredProperties = propList;
    this.expectedDtoKey = expectedDtoKey;
  }

  createInstance<T>(c: new () => T): DoAssemblyDirector {
    if (this.expectedDtoKey && this.expectedDtoKey !== this.dataTransferObject['type']) {
      throw new Error('expected DTO key ' + this.expectedDtoKey + ' does not match received DTO key ' + this.dataTransferObject['type']);
    }
    this.assembledDomainObject = createTypedInstance(c) as T;
    return this;
  }

  assembleDo<T>(): DoAssemblyDirector {
    this.assembledDomainObject = DoAssemblyFactory<T>(this.assembledDomainObject, this.desiredProperties, this.dataTransferObject) as T;

    return this;
  }

  assembleTransposedDo<T>(): DoAssemblyDirector {
    this.assembledDomainObject = DoAssemblyFactory<T>(this.assembledDomainObject, this.desiredProperties, this.dataTransferObject) as T;

    this.assembledDomainObject = Object.keys(this.assembledDomainObject).map(key => this.assembledDomainObject[key]);

    return this;
  }

  collect() {
    return this.assembledDomainObject;
  }
}
