import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as d3 from 'd3';
import {drag} from 'd3-drag';

const THUMB_WIDTH = 125;
const THUMB_HEIGHT = 30;
const THUMB_CORNER_RADIUS = THUMB_HEIGHT / 2;
const LABEL_FONT_SIZE = 12;
const RANGE_THICKNESS = 5;
const COLOR_BLUE = '#007cc1';
const COLOR_ORANGE = '#f90';

export interface RangeSliderEvent {
  minIdx: number;
  maxIdx: number;

  minChanged: boolean;
  maxChanged: boolean;
}

@Component({
  selector: 'da-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements AfterViewInit, OnChanges {
  constructor() {}

  @Input() label = '';
  @Input() values: string[] | number[] = [0, 1];

  @Input() minIdx = 0;
  @Input() minLabel = '';

  @Input() maxIdx = 0;
  @Input() maxLabel = '';

  @Input() unit = '';

  @Output() changed = new EventEmitter<RangeSliderEvent>();

  @ViewChild('container') container: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.draw();
  }

  min = 0; // a value [0...1] indicates the position of the min slider thumb
  max = 0; // a value [0...1]  indicates the position of the max slider thumb

  minDragged = false;
  maxDragged = false;

  ngAfterViewInit(): void {
    this.init();
    this.draw();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
    this.draw();
  }

  init() {
    this.minDragged = false;
    this.maxDragged = false;
    this.min = this.minIdx / this.values.length;
    this.max = this.maxIdx / this.values.length;
  }

  getMinThumb(svg: any) {
    const thumbMin = svg.append('g');

    this.setMinThumbRoundedCorner(thumbMin);
    this.setMinThumbCoverUpRightSide(thumbMin);
    this.setMinThumbCircle(thumbMin);

    return thumbMin;
  }

  setMinThumbRoundedCorner(thumbMin) {
    thumbMin
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', THUMB_WIDTH)
      .attr('height', THUMB_HEIGHT)
      .attr('fill', COLOR_BLUE)
      .attr('rx', THUMB_CORNER_RADIUS)
      .style('cursor', 'pointer');
  }

  setMinThumbCoverUpRightSide(thumbMin) {
    thumbMin
      .append('rect')
      .attr('x', THUMB_WIDTH - THUMB_CORNER_RADIUS)
      .attr('y', 0)
      .attr('width', THUMB_CORNER_RADIUS)
      .attr('height', THUMB_HEIGHT)
      .attr('fill', COLOR_BLUE)
      .style('cursor', 'pointer');
  }

  setMinThumbCircle(thumbMin) {
    thumbMin
      .append('circle')
      .attr('cx', THUMB_WIDTH)
      .attr('cy', THUMB_HEIGHT / 2)
      .attr('r', RANGE_THICKNESS + 3)
      .attr('fill', COLOR_ORANGE)
      .style('stroke', 'white')
      .style('stroke-width', RANGE_THICKNESS);
  }

  getMinThumbLabel(thumbMin) {
    return thumbMin
      .append('text')
      .text(this.minLabel)
      .attr('x', THUMB_WIDTH / 2)
      .attr('y', THUMB_HEIGHT / 2 + LABEL_FONT_SIZE / 3)
      .style('fill', '#fff')
      .style('text-anchor', 'middle')
      .style('font-size', LABEL_FONT_SIZE)
      .style('cursor', 'pointer');
  }

  getMaxThumb(svg: any) {
    const thumbMax = svg.append('g');

    this.setMaxThumbRoundedCorner(thumbMax);
    this.setMaxThumbCoverUpRightSide(thumbMax);
    this.setMaxThumbCircle(thumbMax);

    return thumbMax;
  }

  setMaxThumbRoundedCorner(thumbMax) {
    thumbMax
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', THUMB_WIDTH)
      .attr('height', THUMB_HEIGHT)
      .attr('fill', COLOR_BLUE)
      .attr('rx', THUMB_CORNER_RADIUS)
      .style('cursor', 'pointer');
  }

  setMaxThumbCoverUpRightSide(thumbMax) {
    thumbMax
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', THUMB_CORNER_RADIUS)
      .attr('height', THUMB_HEIGHT)
      .attr('fill', COLOR_BLUE)
      .style('cursor', 'pointer');
  }

  setMaxThumbCircle(thumbMax) {
    thumbMax
      .append('circle')
      .attr('cx', 0)
      .attr('cy', THUMB_HEIGHT / 2)
      .attr('r', RANGE_THICKNESS + 3)
      .attr('fill', COLOR_ORANGE)
      .style('stroke', 'white')
      .style('stroke-width', RANGE_THICKNESS);
  }

  getMaxThumbLabel(thumbMax) {
    return thumbMax
      .append('text')
      .text(this.maxLabel)
      .attr('x', THUMB_WIDTH / 2)
      .attr('y', THUMB_HEIGHT / 2 + LABEL_FONT_SIZE / 3)
      .style('fill', '#fff')
      .style('font-size', LABEL_FONT_SIZE)
      .style('text-anchor', 'middle')
      .style('cursor', 'pointer');
  }

  getClearedContainer() {
    if (!this.container) {
      return null;
    }

    const container = d3.select(this.container.nativeElement);

    container.selectAll('svg').remove();

    return container;
  }

  getSvg(container, max, thumbHeight) {
    if (!this.container) {
      return null;
    }

    return container.append('svg').attr('viewBox', `0 0 ${max} ${thumbHeight}`);
  }

  setBackgroundRange(svg, max) {
    const range = svg.append('g');

    range
      .append('rect')
      .attr('x', THUMB_WIDTH)
      .attr('y', THUMB_HEIGHT / 2)
      .attr('width', Math.max(0, max - THUMB_WIDTH * 2))
      .attr('height', 1)
      .attr('fill', '#AAA');
  }

  drawMinThumb(thumbMin, max, updateFunc) {
    drag()
      .on('drag', e => {
        this.minDragged = true;
        this.setMinDx(this.getMinDx() + e.dx);

        if (this.getMinDx() < 0) {
          this.setMinDx(0);
        }

        if (this.getMinDx() > max - THUMB_WIDTH * 2) {
          this.setMinDx(max - THUMB_WIDTH * 2);
        }

        if (this.getMinDx() + THUMB_WIDTH > this.getMaxDx()) {
          this.setMaxDx(this.getMinDx() + THUMB_WIDTH);
          this.maxDragged = true;
        }
        updateFunc();
      })
      .on('end', () => {
        this.createRangeSliderEvent();
      })(thumbMin);
  }

  drawMaxThumb(thumbMax, max, updateFunc) {
    drag()
      .on('drag', e => {
        this.maxDragged = true;
        this.setMaxDx(this.getMaxDx() + e.dx);

        if (this.getMaxDx() > max - THUMB_WIDTH) {
          this.setMaxDx(max - THUMB_WIDTH);
        }

        if (this.getMaxDx() < THUMB_WIDTH) {
          this.setMaxDx(THUMB_WIDTH);
        }

        if (this.getMaxDx() < this.getMinDx() + THUMB_WIDTH) {
          this.setMinDx(this.getMaxDx() - THUMB_WIDTH);
          this.minDragged = true;
        }
        updateFunc();
      })
      .on('end', () => {
        this.createRangeSliderEvent();
      })(thumbMax);
  }

  draw() {
    if (!this.container) {
      return;
    }

    const _MAX = this.getWidth();
    const svg = this.getSvg(this.getClearedContainer(), _MAX, THUMB_HEIGHT);

    this.setBackgroundRange(svg, _MAX);
    const updateBetween = this.updateBetween(svg);

    const thumbMin = this.getMinThumb(svg);
    const minLabel = this.getMinThumbLabel(thumbMin);
    const updateMinThumb = this.updateMinThumb(thumbMin);

    const thumbMax = this.getMaxThumb(svg);
    const maxLabel = this.getMaxThumbLabel(thumbMax);
    const updateMaxThumb = this.updateMaxThumb(thumbMax);

    const updateLabels = this.updateLabels(minLabel, maxLabel);

    const update = () => {
      updateBetween();
      updateMinThumb();
      updateMaxThumb();
      updateLabels();
    };

    this.drawMinThumb(thumbMin, _MAX, update);
    this.drawMaxThumb(thumbMax, _MAX, update);

    update();
  }

  private updateBetween(svg: any) {
    const between = svg
      .append('g')
      .append('rect')
      .attr('x', 0)
      .attr('y', THUMB_HEIGHT / 2 - RANGE_THICKNESS / 2)
      .attr('width', 0)
      .attr('height', RANGE_THICKNESS)
      .attr('fill', COLOR_ORANGE);

    return () => {
      between.attr('x', this.getMinDx() + THUMB_WIDTH);
      // Math.round prevents very small negative numbers to cause some errors
      between.attr('width', Math.max(0, Math.round(this.getMaxDx() - this.getMinDx() - THUMB_WIDTH)));
    };
  }

  updateMinThumb(thumbMin) {
    return () => {
      const dx = this.getMinDx();
      thumbMin.attr('transform', `translate(${dx}, 0)`);
    };
  }

  updateMaxThumb(thumbMax) {
    return () => {
      const dx = this.getMaxDx();
      thumbMax.attr('transform', `translate(${dx}, 0)`);
    };
  }

  private updateLabels(min, max) {
    return () => {
      min.text(this.getMinLabelFromValues());
      max.text(this.getMaxLabelFromValues());
    };
  }

  getMinDx() {
    const minDx = this.min * this.getTrackLen();
    return minDx < 0 ? 0 : minDx;
  }

  setMinDx(n: number) {
    if (n < 0) {
      this.min = 0;
    } else {
      this.min = n / this.getTrackLen();
    }
  }

  getMaxDx() {
    const maxDx = this.max * this.getTrackLen() + THUMB_WIDTH;
    return maxDx < 0 ? 0 : maxDx;
  }

  setMaxDx(n: number) {
    this.max = (n - THUMB_WIDTH) / this.getTrackLen();
  }

  getTrackLen() {
    return this.getWidth() - THUMB_WIDTH - THUMB_WIDTH;
  }

  getMinLabelFromValues() {
    if (this.minDragged || this.minLabel === '') {
      const idxMin = Math.round((this.values.length - 1) * this.min);
      return ('' + this.values[idxMin] + ' ' + this.unit).trim();
    }
    return ('' + this.minLabel + ' ' + this.unit).trim();
  }

  getMaxLabelFromValues() {
    if (this.maxDragged || this.maxLabel === '') {
      const idxMax = Math.round((this.values.length - 1) * this.max);
      return ('' + this.values[idxMax] + ' ' + this.unit).trim();
    }
    return ('' + this.maxLabel + ' ' + this.unit).trim();
  }

  getWidth() {
    if (!this.container) {
      return 0;
    }

    const container = d3.select(this.container.nativeElement);
    return (container.node() as any).getBoundingClientRect().width;
  }

  emit() {
    this.createRangeSliderEvent();
  }

  createRangeSliderEvent() {
    const newEvent: RangeSliderEvent = {
      minIdx: Math.round((this.values.length - 1) * this.min),
      maxIdx: Math.round((this.values.length - 1) * this.max),

      minChanged: this.minDragged,
      maxChanged: this.maxDragged
    };

    this.changed.emit(newEvent);
  }
}
