import {Injectable} from '@angular/core';
import {LOADING_STATE} from '../model/state.model';
import {Pages, PageStateReduxAction} from '../actions/actions-page-state';
import {StateService} from '../state-service/state.service';

@Injectable({
  providedIn: null
})
export class PageStateService {
  constructor(private stateService: StateService) {}

  setPageState(page: Pages, state: LOADING_STATE) {
    const setPageStateToInit: PageStateReduxAction = new PageStateReduxAction('UPDATE_PAGE_STATE', {
      page,
      state
    });

    this.stateService.dispatch(setPageStateToInit);
  }
}
