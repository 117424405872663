import {Dto, DtoDefinition} from '../model/dto';
import {Contract, DomainContract, DtoContract} from '../../contracts/contracts';
import {getAssemblyContractTransposedDto} from '../util/get-assembly-contract-transposed-dto';
import {getAssemblyContractNormalDto} from '../util/get-assembly-contract-normal-dto';
import {dtoAssembler} from '../assembler/dto.assembler';

export function dtoAssemblyFactory(
  dtoType: DtoDefinition,
  dtoContracts: DtoContract[],
  domainContracts: DomainContract[],
  transpose = false
): Dto {
  let assemblyContract: Contract;

  if (transpose) {
    const transposedDomainObject = {};
    const fullPaths = [];

    assemblyContract = getAssemblyContractTransposedDto(dtoType, dtoContracts, domainContracts, transposedDomainObject, fullPaths);
  } else {
    assemblyContract = getAssemblyContractNormalDto(dtoType, dtoContracts, domainContracts);
  }

  return dtoAssembler(dtoType, assemblyContract);
}
