import {CONTRACTS, Dto, DtoAssemblyDirector} from '@flowtwin/communication';
import {AppModePayloads} from '@state/actions/actions-app-mode';
import {CONTRACT_POST_APP_MODE} from '../domain-contracts/app-mode';

export class SubDtoMapperAppMode {
  static mapToAppMode(appMode: AppModePayloads): Dto | null {
    if (!appMode) {
      return null;
    }

    return new DtoAssemblyDirector(
      'APP_SELECTION_SIZING_SAVE_INPUT',
      CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_FT_SETTINGS.config,
      CONTRACT_POST_APP_MODE(appMode)
    )
      .assembleDto()
      .collect();
  }
}
