<form [formGroup]="form">
  <formly-form
    daConnectFormToState
    connectForm="{{ inputPath }}"
    [formGroup]="form"
    [fieldsToCreate]="fields$"
    class="col-1-to-4"
    [model]="project"
    [fields]="fields$ | async"
    [form]="form"
  ></formly-form>
</form>
