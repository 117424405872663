import {SharedDbDocument} from '../project/shared-db-document';
import {Decimal} from 'decimal.js';
import {Quantity} from './quantity';
import {isDefined} from '@shared/utility/isDefined';

// tslint:disable-next-line:class-name
export class UnitSystemDocument {
  unitSystem?: UnitSystem;
  document?: SharedDbDocument;
}

export const IMPERIAL_UNIT_SYSTEM: UnitSystem = {
  Numeral: {
    conversion: 'UNIT_NUMERAL',
    decimalPlaces: 3
  },
  Speed: {
    conversion: 'UNIT_FPS',
    decimalPlaces: 3
  },
  Pressure: {
    conversion: 'UNIT_PSI_ABS',
    decimalPlaces: 3
  },
  Volume: {
    conversion: 'UNIT_FOOT3',
    decimalPlaces: 3
  },
  Length: {
    conversion: 'UNIT_FOOT',
    decimalPlaces: 3
  },
  Diameter: {
    conversion: 'UNIT_INCH',
    decimalPlaces: 3
  },
  Temperature: {
    conversion: 'UNIT_FAHRENHEIT',
    decimalPlaces: 3
  },
  Density: {
    conversion: 'UNIT_POUND_PER_F3',
    decimalPlaces: 3
  },
  OperationalFlow: {
    conversion: 'UNIT_ACFH',
    decimalPlaces: 3
  },
  NormalizedFlow: {
    conversion: 'UNIT_SCFH',
    decimalPlaces: 3
  },
  Mass: {
    conversion: 'UNIT_LBS',
    decimalPlaces: 3
  },
  MassFlow: {
    conversion: 'UNIT_LB_PER_HOUR',
    decimalPlaces: 3
  },
  AmountRatio: {
    conversion: 'UNIT_PERCENT',
    decimalPlaces: 3
  },
  MolAmountRatio: {
    conversion: 'UNIT_MOL_PERCENT',
    decimalPlaces: 3
  },
  MolarMass: {
    conversion: 'UNIT_LB_MOL_LBMOL',
    decimalPlaces: 3
  },
  HeatValue: {
    conversion: 'UNIT_BTU_PER_CF',
    decimalPlaces: 3
  },
  Angle: {
    conversion: 'UNIT_DEGREE',
    decimalPlaces: 3
  },
  EnergyFlow: {
    conversion: 'UNIT_KWH_PER_HOUR',
    decimalPlaces: 3
  },
  EnergyCounter: {
    conversion: 'UNIT_KWH',
    decimalPlaces: 3
  },
  SoundLevel: {
    conversion: 'UNIT_DECIBEL',
    decimalPlaces: 2
  },
  SignalTiming: {
    conversion: 'UNIT_NANOSECONDS',
    decimalPlaces: 4
  }
};

export const METRIC_UNIT_SYSTEM: UnitSystem = {
  Numeral: {
    conversion: 'UNIT_NUMERAL',
    decimalPlaces: 3
  },
  Speed: {
    conversion: 'UNIT_MPS',
    decimalPlaces: 3
  },
  Pressure: {
    conversion: 'UNIT_BAR_ABS',
    decimalPlaces: 3
  },
  Volume: {
    conversion: 'UNIT_M3',
    decimalPlaces: 3
  },
  Length: {
    conversion: 'UNIT_METER',
    decimalPlaces: 3
  },
  Diameter: {
    conversion: 'UNIT_INCH',
    decimalPlaces: 3
  },
  Temperature: {
    conversion: 'UNIT_CELSIUS',
    decimalPlaces: 3
  },
  Density: {
    conversion: 'UNIT_KG_PER_M3',
    decimalPlaces: 3
  },
  OperationalFlow: {
    conversion: 'UNIT_M3PH',
    decimalPlaces: 3
  },
  NormalizedFlow: {
    conversion: 'UNIT_SM3PH',
    decimalPlaces: 3
  },
  Mass: {
    conversion: 'UNIT_KG',
    decimalPlaces: 3
  },
  MassFlow: {
    conversion: 'UNIT_KG_PER_HOUR',
    decimalPlaces: 3
  },
  AmountRatio: {
    conversion: 'UNIT_PERCENT',
    decimalPlaces: 2
  },
  MolAmountRatio: {
    conversion: 'UNIT_MOL_PERCENT',
    decimalPlaces: 3
  },
  MolarMass: {
    conversion: 'UNIT_KG_PER_KMOL',
    decimalPlaces: 3
  },
  HeatValue: {
    conversion: 'UNIT_MJ_PER_M3',
    decimalPlaces: 3
  },
  Angle: {
    conversion: 'UNIT_DEGREE',
    decimalPlaces: 3
  },
  EnergyFlow: {
    conversion: 'UNIT_POWER_MJH',
    decimalPlaces: 3
  },
  EnergyCounter: {
    conversion: 'UNIT_MJ',
    decimalPlaces: 3
  },
  SoundLevel: {
    conversion: 'UNIT_DECIBEL',
    decimalPlaces: 2
  },
  SignalTiming: {
    conversion: 'UNIT_NANOSECONDS',
    decimalPlaces: 4
  }
};

export const getBaseUnitSystemValue = <U extends UnitSystemKey>(props: {
  value: string | number;
  quant: Quantity<U>;
  displayUnitSystem: UnitSystem;
}) => {
  const {value, quant, displayUnitSystem} = props;
  return quantity({unit: quant.unit, value, unitSystem: displayUnitSystem});
};

export const getDisplayedUnitSystemValue =
  (unitSystem: UnitSystem) =>
  (quanti: Quantity<any>, decimalPlaces = -1): string => {
    const unitKey = quanti?.unit as UnitSystemKey; // e.g. Temperature
    const dPlaces = decimalPlaces === -1 ? unitSystem?.[unitKey]?.decimalPlaces : decimalPlaces;
    const format = (value: number | null) => (isDefined(value) ? value.toFixed(dPlaces) : '-');
    return format(getTargetUnitSystemValue(unitSystem)(quanti));
  };

export const getTargetUnitSystemValue =
  (unitSystem: UnitSystem) =>
  (quantity2: Quantity<any>): number | null => {
    const unitKey = quantity2?.unit as UnitSystemKey; // e.g. Temperature
    const targeUnitTag = unitSystem?.[unitKey]?.conversion; // e.g. 'UNIT_KELVIN'
    const sickBaseUnitTag = SICK_BASE_UNITS?.[unitKey]?.BaseUnit;

    if (sickBaseUnitTag === targeUnitTag) {
      return quantity2?.value ?? null;
    }

    const conversion = SICK_BASE_UNITS[unitKey].Conversions.find(c => c.UnitTag === targeUnitTag);

    if (!conversion) {
      throw Error(`Not able to compute display value for quantity:${quantity2} using UnitSystem: ${unitSystem}`);
    }

    return (quantity2.value ?? 0) * Number(conversion.Factor) + Number(conversion.Offset);
  };

export const IMPERIAL_UNIT_SYSTEM_DOC: UnitSystemDocument = {
  unitSystem: IMPERIAL_UNIT_SYSTEM,
  document: {
    deleted: false,
    name: 'unit-system.name.imperial',
    description: 'unit-system.description.imperial',
    userList: [],
    creator: '',
    id: 'imperial'
  }
};

export const METRIC_UNIT_SYSTEM_DOC: UnitSystemDocument = {
  unitSystem: METRIC_UNIT_SYSTEM,
  document: {
    deleted: false,
    name: 'unit-system.name.metric',
    description: 'unit-system.description.metric',
    userList: [],
    creator: '',
    id: 'metric'
  }
};

export const SICK_BASE_UNITS: {
  [k in UnitSystemKey]: {
    BaseUnit: UnitTag;
    Conversions: {
      UnitTag: UnitTag;
      Power: number;
      Offset: number;
      Factor: string;
    }[];
  };
} = {
  // Speed
  Speed: {
    BaseUnit: 'UNIT_MPS',
    Conversions: [
      {
        UnitTag: 'UNIT_FPS',
        Power: 1.0,
        Offset: 0,
        Factor: '3.2808399'
      }
    ]
  },

  // Pressure
  Pressure: {
    BaseUnit: 'UNIT_BAR',
    Conversions: [
      {
        UnitTag: 'UNIT_ATMOSPHERE',
        Power: 1.0,
        Offset: 0,
        Factor: '0.9869233'
      },
      // {
      //   UnitTag: 'UNIT_KG_PER_CM2',
      //   Power: 1.0,
      //   Offset: 0,
      //   Factor: '1.0197162',
      // },
      {
        UnitTag: 'UNIT_MEGAPASCAL',
        Power: 1.0,
        Offset: 0,
        Factor: '0.1'
      },
      {
        UnitTag: 'UNIT_KILOPASCAL',
        Power: 1.0,
        Offset: 0,
        Factor: '100'
      },
      {
        UnitTag: 'UNIT_KILOPASCAL_ABS',
        Power: 1.0,
        Offset: 101.325,
        Factor: '100'
      },
      {
        UnitTag: 'UNIT_PSI',
        Power: 1.0,
        Offset: 0,
        Factor: '14.503774'
      },
      {
        UnitTag: 'UNIT_PSI_ABS',
        Power: 1.0,
        Offset: 14.5,
        Factor: '14.503774'
      },
      {
        UnitTag: 'UNIT_PSI_G',
        Power: 1.0,
        Offset: 0,
        Factor: '14.503774'
      },
      {
        UnitTag: 'UNIT_BAR_ABS',
        Power: 1.0,
        Offset: 1.01325,
        Factor: '1'
      }
    ]
  },

  // Volume
  Volume: {
    BaseUnit: 'UNIT_M3',
    Conversions: [
      {
        UnitTag: 'UNIT_FOOT3',
        Power: 1.0,
        Offset: 0,
        Factor: '35.314667'
      },
      {
        UnitTag: 'UNIT_ACF',
        Power: 1.0,
        Offset: 0,
        Factor: '35.314667'
      },
      {
        UnitTag: 'UNIT_SCF',
        Power: 1.0,
        Offset: 0,
        Factor: '35.314667'
      },
      {
        UnitTag: 'UNIT_MMACF',
        Power: 1.0,
        Offset: 0,
        Factor: '3.531073446327683615819209039548e-5'
      },
      {
        UnitTag: 'UNIT_MMSCF',
        Power: 1.0,
        Offset: 0,
        Factor: '3.531073446327683615819209039548e-5'
      }
    ]
  },

  // Length
  Length: {
    BaseUnit: 'UNIT_METER',
    Conversions: [
      {
        UnitTag: 'UNIT_MILLIMETER',
        Power: 1.0,
        Offset: 0,
        Factor: '1000'
      },
      {
        UnitTag: 'UNIT_FOOT',
        Power: 1.0,
        Offset: 0,
        Factor: '3.2808399'
      },
      {
        UnitTag: 'UNIT_INCH',
        Power: 1.0,
        Offset: 0,
        Factor: '39.37007874015748'
      }
    ]
  },
  // Diameter
  Diameter: {
    BaseUnit: 'UNIT_INCH',
    Conversions: [
      {
        UnitTag: 'UNIT_MILLIMETER',
        Power: 1.0,
        Offset: 0,
        Factor: '25.4'
      },
      {
        UnitTag: 'UNIT_METER',
        Power: 1.0,
        Offset: 0,
        Factor: '0.0254'
      }
    ]
  },

  // Temperature
  Temperature: {
    BaseUnit: 'UNIT_CELSIUS',
    Conversions: [
      {
        UnitTag: 'UNIT_KELVIN',
        Power: 1.0,
        Factor: '1.0',
        Offset: 273.15
      },
      {
        UnitTag: 'UNIT_FAHRENHEIT',
        Power: 1.0,
        Factor: '1.8',
        Offset: 32
      },
      {
        UnitTag: 'UNIT_RANKINE',
        Power: 1.0,
        Factor: '1.8',
        Offset: 491.67
      }
    ]
  },

  // Density
  Density: {
    BaseUnit: 'UNIT_KG_PER_M3',
    Conversions: [
      {
        UnitTag: 'UNIT_G_PER_M3',
        Power: 1.0,
        Offset: 0,
        Factor: '1000'
      },
      {
        UnitTag: 'UNIT_G_PER_CM3',
        Power: 1.0,
        Offset: 0,
        Factor: '0.001'
      },
      {
        UnitTag: 'UNIT_POUND_PER_F3',
        Power: 1.0,
        Offset: 0,
        Factor: '6.2427961e-002'
      },
      {
        UnitTag: 'UNIT_POUND_PER_IN3',
        Power: 1.0,
        Offset: 0,
        Factor: '3.612729e-005'
      }
    ]
  },

  // OperationalFlow
  OperationalFlow: {
    BaseUnit: 'UNIT_M3PH',
    Conversions: [
      // {
      //   UnitTag: 'UNIT_LITER_PER_HOUR',
      //   Power: 1.0,
      //   Offset: 0,
      //   Factor: '1000',
      // },
      // {
      //   UnitTag: 'UNIT_LITER_PER_MINUTE',
      //   Power: 1.0,
      //   Offset: 0,
      //   Factor: '16.666667',
      // },
      {
        UnitTag: 'UNIT_M3PD',
        Power: 1.0,
        Offset: 0,
        Factor: '24'
      },
      {
        UnitTag: 'UNIT_ACFH',
        Power: 1.0,
        Offset: 0,
        Factor: '35.314666721'
      },
      // {
      //   UnitTag: 'UNIT_ACFM',
      //   Power: 1.0,
      //   Offset: 0,
      //   Factor: '0.5885778',
      // },
      {
        UnitTag: 'UNIT_ACFD',
        Power: 1.0,
        Offset: 0,
        Factor: '847.55200132'
      },
      {
        UnitTag: 'UNIT_MMACFD',
        Power: 1.0,
        Offset: 0,
        Factor: '8.4745762711864406779661016949153e-4'
      },
      {
        UnitTag: 'UNIT_MMACFH',
        Power: 1.0,
        Offset: 0,
        Factor: '3.531073446327683615819209039548e-5'
      }
    ]
  },

  // NormalizedFlow
  NormalizedFlow: {
    BaseUnit: 'UNIT_SM3PH',
    Conversions: [
      // {
      //   UnitTag: 'UNIT_LITER_PER_HOUR',
      //   Power: 1.0,
      //   Offset: 0,
      //   Factor: '1000',
      // },
      // {
      //   UnitTag: 'UNIT_LITER_PER_MINUTE',
      //   Power: 1.0,
      //   Offset: 0,
      //   Factor: '16.666667',
      // },
      {
        UnitTag: 'UNIT_SCMD',
        Power: 1.0,
        Offset: 0,
        Factor: '24'
      },
      {
        UnitTag: 'UNIT_SCFH',
        Power: 1.0,
        Offset: 0,
        Factor: '35.314666721'
      },
      // {
      //   UnitTag: 'UNIT_SCFM',
      //   Power: 1.0,
      //   Offset: 0,
      //   Factor: '0.5885778',
      // },
      {
        UnitTag: 'UNIT_SCFD',
        Power: 1.0,
        Offset: 0,
        Factor: '847.55200132'
      },
      {
        UnitTag: 'UNIT_MMSCFD',
        Power: 1.0,
        Offset: 0,
        Factor: '8.4745762711864406779661016949153e-4'
      },
      {
        UnitTag: 'UNIT_MMSCFH',
        Power: 1.0,
        Offset: 0,
        Factor: '3.531073446327683615819209039548e-5'
      }
    ]
  },

  // Mass
  Mass: {
    BaseUnit: 'UNIT_KG',
    Conversions: [
      {
        UnitTag: 'UNIT_TON',
        Power: 1.0,
        Offset: 0,
        Factor: '0.001'
      },
      {
        UnitTag: 'UNIT_LONG_TON',
        Power: 1.0,
        Offset: 0,
        Factor: '0.0009842'
      },
      {
        UnitTag: 'UNIT_SHORT_TON',
        Power: 1.0,
        Offset: 0,
        Factor: '0.0011023'
      },
      {
        UnitTag: 'UNIT_LBS',
        Power: 1.0,
        Offset: 0,
        Factor: '2.2046226218485'
      }
    ]
  },

  // MassFlow
  MassFlow: {
    BaseUnit: 'UNIT_KG_PER_HOUR',
    Conversions: [
      {
        UnitTag: 'UNIT_TON_PER_HOUR',
        Power: 1.0,
        Offset: 0,
        Factor: '0.001'
      },
      {
        UnitTag: 'UNIT_LONG_TON_PER_HOUR',
        Power: 1.0,
        Offset: 0,
        Factor: '0.0009842'
      },
      {
        UnitTag: 'UNIT_SHORT_TON_PER_HOUR',
        Power: 1.0,
        Offset: 0,
        Factor: '0.0011023'
      },
      {
        UnitTag: 'UNIT_LB_PER_HOUR',
        Power: 1.0,
        Offset: 0,
        Factor: '2.2046226218485'
      }
    ]
  },

  // AmountRatio
  AmountRatio: {
    BaseUnit: 'UNIT_FRACTION',
    Conversions: [
      {
        UnitTag: 'UNIT_PERCENT',
        Power: 1.0,
        Factor: '100',
        Offset: 0.0
      }
    ]
  },

  // MolAmountRatio
  MolAmountRatio: {
    BaseUnit: 'UNIT_MOL_PERCENT',
    Conversions: [
      // {
      //   UnitTag: 'UNIT_KILO_MOL_PERCENT',
      //   Power: 1.0,
      //   Offset: 0,
      //   Factor: '1000',
      // },
      // {
      //   UnitTag: 'UNIT_MILLI_MOL_PERCENT',
      //   Power: 1.0,
      //   Offset: 0,
      //   Factor: '0.001',
      // },
      {
        UnitTag: 'UNIT_MOL_PER_MOL',
        Power: 1.0,
        Offset: 0,
        Factor: '0.01'
      }
    ]
  },

  // MolarMass
  MolarMass: {
    BaseUnit: 'UNIT_KG_PER_KMOL',
    Conversions: [
      {
        UnitTag: 'UNIT_G_PER_MOL',
        Power: 1.0,
        Offset: 0,
        Factor: '1'
      },
      {
        UnitTag: 'UNIT_LB_MOL_LBMOL',
        Power: 1.0,
        Offset: 0,
        Factor: '1'
      }
    ]
  },

  // HeatValue
  HeatValue: {
    BaseUnit: 'UNIT_MJ_PER_M3',
    Conversions: [
      {
        UnitTag: 'UNIT_KWH_PER_M3',
        Power: 1.0,
        Offset: 0,
        Factor: '0.277777778'
      },
      {
        UnitTag: 'UNIT_BTU_PER_CF',
        Power: 1.0,
        Offset: 0,
        Factor: '26.839192'
      }
      // {
      //   UnitTag: 'UNIT_THERM_PER_CF',
      //   Power: 1.0,
      //   Offset: 0,
      //   Factor: '0.000268456003',
      // },
    ]
  },
  // Angle
  Angle: {
    BaseUnit: 'UNIT_DEGREE',
    Conversions: [
      {
        UnitTag: 'UNIT_RAD',
        Power: 1.0,
        Offset: 0,
        Factor: '0.017453293'
      }
    ]
  },

  // EnergyFlow
  EnergyFlow: {
    BaseUnit: 'UNIT_POWER_MJH',
    Conversions: [
      {
        UnitTag: 'UNIT_POWER_MJD',
        Power: 1.0,
        Offset: 0,
        Factor: '24'
      },
      {
        UnitTag: 'UNIT_POWER_TJH',
        Power: 1.0,
        Offset: 0,
        Factor: '1e-6'
      },
      {
        UnitTag: 'UNIT_POWER_TJD',
        Power: 1.0,
        Offset: 0,
        Factor: '2.4e-5'
      },
      {
        UnitTag: 'UNIT_POWER_BTUH',
        Power: 1.0,
        Offset: 0,
        Factor: '947.817120313'
      },
      {
        UnitTag: 'UNIT_POWER_BTUD',
        Power: 1.0,
        Offset: 0,
        Factor: '22747.61088752'
      },
      {
        UnitTag: 'UNIT_KWH_PER_HOUR',
        Power: 1.0,
        Offset: 0,
        Factor: '0.27777777777778'
      }
    ]
  },

  //
  // EnergyCounter
  //
  EnergyCounter: {
    BaseUnit: 'UNIT_MJ',
    Conversions: [
      {
        UnitTag: 'UNIT_BTU',
        Power: 1.0,
        Offset: 0,
        Factor: '947.817120313'
      },
      {
        UnitTag: 'UNIT_KWH',
        Power: 1.0,
        Offset: 0,
        Factor: '0.27777777777778'
      }
    ]
  },
  // SoundLevel
  SoundLevel: {
    BaseUnit: 'UNIT_DECIBEL',
    Conversions: []
  },

  SignalTiming: {
    BaseUnit: 'UNIT_SECONDS',
    Conversions: [
      {
        UnitTag: 'UNIT_MILLISECONDS',
        Power: 1.0,
        Offset: 0,
        Factor: '1000'
      },
      {
        UnitTag: 'UNIT_MICROSECONDS',
        Power: 1.0,
        Offset: 0,
        Factor: '1000000'
      },
      {
        UnitTag: 'UNIT_NANOSECONDS',
        Power: 1.0,
        Offset: 0,
        Factor: '1000000000'
      }
    ]
  },

  //
  // Numeral
  //
  // A simple number without any unit and no Conversions
  //
  //
  Numeral: {
    BaseUnit: 'UNIT_NUMERAL',
    Conversions: []
  }
};

export type UnitTag =
  | 'UNIT_ACF'
  | 'UNIT_ACFD'
  | 'UNIT_ACFH'
  // | 'UNIT_ACFM' --> DEL
  | 'UNIT_ATMOSPHERE'
  | 'UNIT_BAR_ABS'
  | 'UNIT_BAR'
  | 'UNIT_BTU_PER_CF'
  | 'UNIT_BTU'
  | 'UNIT_CELSIUS'
  | 'UNIT_DECIBEL'
  | 'UNIT_DEGREE'
  | 'UNIT_FAHRENHEIT'
  | 'UNIT_FOOT'
  // | 'UNIT_FOOT3_X10' --> DEL
  // | 'UNIT_FOOT3_X100' --> DEL
  // | 'UNIT_FOOT3_X1000' --> DEL
  | 'UNIT_FOOT3'
  | 'UNIT_FPS'
  | 'UNIT_FRACTION'
  // | 'UNIT_FTWC_68F' --> DEL
  | 'UNIT_G_PER_CM3'
  | 'UNIT_G_PER_M3'
  | 'UNIT_G_PER_MOL'
  // | 'UNIT_GALLON_US' --> DEL
  | 'UNIT_INCH'
  // | 'UNIT_INHG_0C' --> DEL
  // | 'UNIT_INWC_4C' --> DEL
  // | 'UNIT_INWC_60F' --> DEL
  // | 'UNIT_INWC_68F' --> DEL
  | 'UNIT_KELVIN'
  // | 'UNIT_KG_PER_CM2' --> DEL
  | 'UNIT_KG_PER_HOUR'
  | 'UNIT_KG_PER_KMOL'
  | 'UNIT_KG_PER_M3'
  | 'UNIT_KG'
  // | 'UNIT_KILO_MOL_PERCENT' --> DEL
  | 'UNIT_KILOPASCAL'
  | 'UNIT_KILOPASCAL_ABS'
  | 'UNIT_KWH_PER_HOUR'
  | 'UNIT_KWH_PER_M3'
  | 'UNIT_KWH'
  | 'UNIT_LB_MOL_LBMOL'
  | 'UNIT_LB_PER_HOUR'
  | 'UNIT_LBS'
  // | 'UNIT_LITER_PER_HOUR' --> DEL
  // | 'UNIT_LITER_PER_MINUTE' --> DEL
  // | 'UNIT_LITER' --> DEL
  | 'UNIT_LONG_TON_PER_HOUR'
  | 'UNIT_LONG_TON'
  // | 'UNIT_M3_X10' --> DEL
  // | 'UNIT_M3_X100' --> DEL
  // | 'UNIT_M3_X1000' --> DEL
  | 'UNIT_M3'
  | 'UNIT_M3PD'
  | 'UNIT_M3PH'
  | 'UNIT_MEGAPASCAL'
  | 'UNIT_METER'
  // | 'UNIT_MILLI_MOL_PERCENT' --> DEL
  // | 'UNIT_MILLIBAR' --> DEL
  | 'UNIT_MILLIMETER'
  | 'UNIT_NANOSECONDS'
  | 'UNIT_MICROSECONDS'
  | 'UNIT_MILLISECONDS'
  | 'UNIT_SECONDS'
  | 'UNIT_MJ_PER_M3'
  | 'UNIT_MJ'
  | 'UNIT_MMACF'
  | 'UNIT_MMACFD'
  | 'UNIT_MMACFH'
  // | 'UNIT_MMHG_0C' --> DEL
  | 'UNIT_MMSCF'
  | 'UNIT_MMSCFD'
  | 'UNIT_MMSCFH'
  // | 'UNIT_MMWC_4C' --> DEL
  // | 'UNIT_MMWC_68F' --> DEL
  | 'UNIT_MOL_PER_MOL'
  | 'UNIT_MOL_PERCENT'
  | 'UNIT_MPS'
  | 'UNIT_NUMERAL'
  // | 'UNIT_PASCAL' --> DEL
  | 'UNIT_PERCENT'
  | 'UNIT_POUND_PER_F3'
  | 'UNIT_POUND_PER_IN3'
  | 'UNIT_POWER_BTUD'
  | 'UNIT_POWER_BTUH'
  | 'UNIT_POWER_MJD'
  | 'UNIT_POWER_MJH'
  | 'UNIT_POWER_TJD'
  | 'UNIT_POWER_TJH'
  | 'UNIT_PSI'
  | 'UNIT_PSI_ABS'
  | 'UNIT_PSI_G'
  | 'UNIT_RAD'
  | 'UNIT_RANKINE'
  | 'UNIT_SCF'
  | 'UNIT_SCFD'
  | 'UNIT_SCFH'
  // | 'UNIT_SCFM' --> DEL
  | 'UNIT_SCMD'
  | 'UNIT_SHORT_TON_PER_HOUR'
  | 'UNIT_SHORT_TON'
  | 'UNIT_SM3PH'
  // | 'UNIT_THERM_PER_CF' --> DEL
  | 'UNIT_TON_PER_HOUR'
  | 'UNIT_TON';

// isQuantity type guard
//
// Usage:

// const obj = { unit: 'Temperature', value: 0 }
// if (isQuantity(obj)) {
//  --> obj is now of type Quantity
// }
export function isQuantity<U extends UnitSystemKey>(obj: any): obj is Quantity<U> {
  return obj != null && obj.unit != null && obj.value !== undefined;
}

// tslint:disable-next-line:variable-name
export const quantityFn: QuantityFn = props => {
  const {unitSystem, unit, value} = props;

  const {BaseUnit: baseUnit, Conversions: conversions} = SICK_BASE_UNITS[unit];
  const displayUnit = unitSystem[unit].conversion;

  if (value === null || value === undefined) {
    return {
      unit,
      value,
      description: `Display: ${value} ${translations[displayUnit]} // Base: ${value} ${translations[baseUnit]}`
    } as Quantity<any>;
  }

  if (baseUnit === displayUnit) {
    const res: Quantity<any> = {
      unit,
      value: new Decimal(value).toNumber()
    };
    res.description = `Display: ${value} ${translations[displayUnit]} // Base: ${value} ${translations[baseUnit]}`;
    return res;
  }

  const conversion = conversions.find(c => c.UnitTag === displayUnit);

  if (!conversion) {
    throw Error(`Not able to convert the base unit value: ${baseUnit} -> ${displayUnit} using unit-system: ${unitSystem}`);
  }

  const {Factor, Offset} = conversion;

  const displayValue = Number(value);

  // const baseValue = (displayValue - Number(Offset)) / Number(Factor)

  // base value precision approach - init
  const displayValueDec = new Decimal(value);
  const offsetDec = new Decimal(Offset);
  const factorDec = new Decimal(Factor);

  // base value precision approach - calculation
  const baseValueDec = displayValueDec.minus(offsetDec).dividedBy(factorDec);

  const ret = {
    unit,
    value: baseValueDec.toNumber()
  };

  if (props.explain) {
    // @ts-ignore
    ret['description'] = `${unit}: ${displayValue} ${displayUnit} -> ${baseValueDec} ${baseUnit}`;
  }

  return ret;
};

type QuantityFn = <U extends UnitSystemKey>(props: {
  unit: U;
  value: string | number | null;
  unitSystem: UnitSystem;
  explain?: boolean; // for easy unit tests debugging
}) => Quantity<U>;

export const quantity: QuantityFn = props => {
  const {unitSystem, unit, value} = props;

  const {BaseUnit: baseUnit, Conversions: conversions} = SICK_BASE_UNITS[unit];
  const displayUnit = unitSystem[unit].conversion;

  if (value === null || value === undefined) {
    return {
      unit,
      value,
      description: `Display: ${value} ${translations[displayUnit]} // Base: ${value} ${translations[baseUnit]}`
    } as Quantity<any>;
  }

  if (baseUnit === displayUnit) {
    const res: Quantity<any> = {
      unit,
      value: new Decimal(value).toDP(unitSystem[unit].decimalPlaces).toNumber()
    };
    res.description = `Display: ${value} ${translations[displayUnit]} // Base: ${value} ${translations[baseUnit]}`;
    return res;
  }

  const conversion = conversions.find(c => c.UnitTag === displayUnit);

  if (!conversion) {
    throw Error(`Not able to convert the base unit value: ${baseUnit} -> ${displayUnit} using unit-system: ${unitSystem}`);
  }

  const {Factor, Offset} = conversion;

  const displayValue2 = Number(value);

  // const baseValue = (displayValue - Number(Offset)) / Number(Factor)

  // base value precision approach - init
  const displayValueDec = new Decimal(value);
  const offsetDec = new Decimal(Offset);
  const factorDec = new Decimal(Factor);

  // base value precision approach - calculation
  const baseValueDec = displayValueDec.minus(offsetDec).dividedBy(factorDec);

  const ret = {
    unit,
    value: baseValueDec.toNumber()
  };

  if (props.explain) {
    // @ts-ignore
    ret['description'] = `${unit}: ${displayValue2} ${displayUnit} -> ${baseValueDec} ${baseUnit}`;
  }

  return ret;
};

// tslint:disable-next-line:variable-name
export const translations: {[k in UnitTag]: string} = {
  UNIT_FRACTION: 'UNIT_FRACTION',
  UNIT_ACF: 'aft³',
  UNIT_ACFD: 'aft³/d',
  UNIT_ACFH: 'aft³/h',
  UNIT_ATMOSPHERE: 'atm',
  UNIT_BAR_ABS: 'bar(a)',
  UNIT_BAR: 'bar(g)',
  UNIT_BTU_PER_CF: 'BTU/ft³',
  UNIT_BTU: 'BTU',
  UNIT_CELSIUS: '°C',
  UNIT_DECIBEL: 'dB',
  UNIT_DEGREE: '°',
  UNIT_FAHRENHEIT: '°F',
  UNIT_FOOT: 'ft',
  UNIT_FOOT3: 'ft³',
  UNIT_FPS: 'ft/s',
  UNIT_G_PER_CM3: 'g/cm³',
  UNIT_G_PER_M3: 'g/m³',
  UNIT_G_PER_MOL: 'g/mol',
  UNIT_INCH: 'inch',
  UNIT_KELVIN: 'K',
  UNIT_KG_PER_HOUR: 'kg/h',
  UNIT_KG_PER_KMOL: 'kg/kMol',
  UNIT_KG_PER_M3: 'kg/m³',
  UNIT_KG: 'kg',
  UNIT_KILOPASCAL: 'kPa(g)',
  UNIT_KILOPASCAL_ABS: 'kPa(a)',
  UNIT_KWH_PER_HOUR: 'kWh/h',
  UNIT_KWH_PER_M3: 'kWh/m³',
  UNIT_KWH: 'kWh',
  UNIT_LB_MOL_LBMOL: 'lb/lbmol',
  UNIT_LB_PER_HOUR: 'lb/h',
  UNIT_LBS: 'lbs',
  UNIT_LONG_TON_PER_HOUR: 'ton/h',
  UNIT_LONG_TON: 'ton',
  UNIT_M3: 'm³',
  UNIT_M3PD: 'm³/d',
  UNIT_M3PH: 'm³/h',
  UNIT_MEGAPASCAL: 'MPa',
  UNIT_METER: 'm',
  UNIT_MILLIMETER: 'mm',
  UNIT_MJ_PER_M3: 'MJ/m³',
  UNIT_MJ: 'MJ',
  UNIT_MMACF: 'MMaft³',
  UNIT_MMACFD: 'MMaft³/d',
  UNIT_MMACFH: 'MMaft³/h',
  UNIT_MMSCF: 'MMsft³',
  UNIT_MMSCFD: 'MMsft³/d',
  UNIT_MMSCFH: 'MMsft³/h',
  UNIT_MOL_PER_MOL: 'mol/mol',
  UNIT_MOL_PERCENT: 'mol %',
  UNIT_MPS: 'm/s',
  UNIT_NUMERAL: '',
  UNIT_PERCENT: '%',
  UNIT_POUND_PER_F3: 'lb/ft³',
  UNIT_POUND_PER_IN3: 'lb/inch',
  UNIT_POWER_BTUD: 'UNIT_POWER_BTUD',
  UNIT_POWER_BTUH: 'UNIT_POWER_BTUH',
  UNIT_POWER_MJD: 'UNIT_POWER_MJD',
  UNIT_POWER_MJH: 'UNIT_POWER_MJH',
  UNIT_POWER_TJD: 'UNIT_POWER_TJD',
  UNIT_POWER_TJH: 'UNIT_POWER_TJH',
  UNIT_PSI: 'psi',
  UNIT_PSI_ABS: 'psi(a)',
  UNIT_PSI_G: 'psi(g)',
  UNIT_RAD: 'rad',
  UNIT_RANKINE: '°R',
  UNIT_SCF: 'sft³',
  UNIT_SCFD: 'sft³/d',
  UNIT_SCFH: 'sft³/h',
  UNIT_SCMD: 'sm³/d',
  UNIT_SHORT_TON_PER_HOUR: 'UNIT_SHORT_TON_PER_HOUR',
  UNIT_SHORT_TON: 't',
  UNIT_SM3PH: 'sm³/h',
  UNIT_TON_PER_HOUR: 't/h',
  UNIT_TON: 't',
  UNIT_MICROSECONDS: 'µs',
  UNIT_MILLISECONDS: 'ms',
  UNIT_NANOSECONDS: 'ns',
  UNIT_SECONDS: 's'
};

// tslint:disable-next-line:class-name
interface Conversion {
  UnitTag: UnitTag;
  Power: number;
  Offset: number;
  Factor: string;
}

export function convert(props: {value: number | string | null; from: UnitTag; to: UnitTag}): number | null {
  const {value, from, to} = props;

  if (value == null) {
    return null;
  }

  for (const key of UNIT_SYSTEM_KEYS) {
    const unitDef = SICK_BASE_UNITS[key];
    for (const conversion of unitDef.Conversions) {
      if (unitDef.BaseUnit === from && conversion.UnitTag === to) {
        return compute(Number(value), conversion);
      }
      if (unitDef.BaseUnit === to && conversion.UnitTag === from) {
        return computeReverse(Number(value), conversion);
      }
    }
  }
  throw Error(`Don't know how to convert ${from} => ${to}`);
}

function compute(value: number, conversion: Conversion) {
  const power = new Decimal(conversion.Power);
  const offset = new Decimal(conversion.Offset);
  const factor = new Decimal(conversion.Factor);
  const valueDec = new Decimal(value);

  return valueDec.pow(power).times(factor).plus(offset).toNumber();
}

function computeReverse(value: number, conversion: Conversion) {
  const power = new Decimal(conversion.Power);
  const offset = new Decimal(conversion.Offset);
  const factor = new Decimal(conversion.Factor);
  const valueDec = new Decimal(value);

  return valueDec.pow(new Decimal(1).dividedBy(power)).dividedBy(factor).minus(offset).toNumber();
}

export const UNIT_SYSTEM_KEYS: UnitSystemKey[] = [
  'AmountRatio',
  'Angle',
  'Density',
  'Diameter',
  'EnergyCounter',
  'EnergyFlow',
  'HeatValue',
  'Length',
  'Mass',
  'MassFlow',
  'MolarMass',
  'MolAmountRatio',
  'NormalizedFlow',
  'Numeral',
  'OperationalFlow',
  'Pressure',
  'Speed',
  'Temperature',
  'Volume',
  'SoundLevel',
  'SignalTiming'
];

export type UnitSystemKey = keyof UnitSystem;

// tslint:disable-next-line:class-name
export interface UnitSystem {
  Numeral: {
    conversion: 'UNIT_NUMERAL';
    decimalPlaces: number;
  };
  Speed: {
    conversion: 'UNIT_MPS' | 'UNIT_FPS';
    decimalPlaces: number;
  };
  Pressure: {
    conversion:
      | 'UNIT_BAR'
      | 'UNIT_ATMOSPHERE'
      // | 'UNIT_KG_PER_CM2'
      | 'UNIT_MEGAPASCAL'
      | 'UNIT_KILOPASCAL'
      | 'UNIT_KILOPASCAL_ABS'
      // | 'UNIT_PASCAL'
      | 'UNIT_PSI'
      | 'UNIT_PSI_ABS'
      | 'UNIT_PSI_G'
      | 'UNIT_BAR_ABS';
    // | 'UNIT_MILLIBAR'
    // | 'UNIT_INWC_68F'
    // | 'UNIT_INHG_0C'
    // | 'UNIT_FTWC_68F'
    // | 'UNIT_MMWC_68F'
    // | 'UNIT_MMHG_0C'
    // | 'UNIT_INWC_60F'
    // | 'UNIT_INWC_4C'
    // | 'UNIT_MMWC_4C'
    decimalPlaces: number;
  };
  Volume: {
    conversion:
      | 'UNIT_M3'
      // | 'UNIT_LITER'
      | 'UNIT_FOOT3'
      | 'UNIT_ACF'
      | 'UNIT_SCF'
      | 'UNIT_MMACF'
      | 'UNIT_MMSCF';
    // | 'UNIT_GALLON_US'
    // | 'UNIT_M3_X10'
    // | 'UNIT_M3_X100'
    // | 'UNIT_M3_X1000'
    // | 'UNIT_FOOT3_X10'
    // | 'UNIT_FOOT3_X100'
    // | 'UNIT_FOOT3_X1000'
    decimalPlaces: number;
  };
  Length: {
    conversion: 'UNIT_METER' | 'UNIT_MILLIMETER' | 'UNIT_FOOT' | 'UNIT_INCH';
    decimalPlaces: number;
  };
  Diameter: {
    conversion: 'UNIT_METER' | 'UNIT_MILLIMETER' | 'UNIT_FOOT' | 'UNIT_INCH';
    decimalPlaces: number;
  };
  Temperature: {
    conversion: 'UNIT_CELSIUS' | 'UNIT_KELVIN' | 'UNIT_FAHRENHEIT' | 'UNIT_RANKINE';
    decimalPlaces: number;
  };
  Density: {
    conversion: 'UNIT_KG_PER_M3' | 'UNIT_G_PER_M3' | 'UNIT_G_PER_CM3' | 'UNIT_POUND_PER_F3' | 'UNIT_POUND_PER_IN3';
    decimalPlaces: number;
  };
  OperationalFlow: {
    conversion:
      | 'UNIT_M3PH'
      // | 'UNIT_LITER_PER_HOUR'
      // | 'UNIT_LITER_PER_MINUTE'
      | 'UNIT_M3PD'
      | 'UNIT_ACFH'
      // | 'UNIT_ACFM'
      | 'UNIT_ACFD'
      | 'UNIT_MMACFD'
      | 'UNIT_MMACFH';
    decimalPlaces: number;
  };
  NormalizedFlow: {
    conversion:
      | 'UNIT_SM3PH'
      // | 'UNIT_LITER_PER_HOUR'
      // | 'UNIT_LITER_PER_MINUTE'
      | 'UNIT_SCMD'
      | 'UNIT_SCFH'
      // | 'UNIT_SCFM'
      | 'UNIT_SCFD'
      | 'UNIT_MMSCFD'
      | 'UNIT_MMSCFH';
    decimalPlaces: number;
  };
  Mass: {
    conversion: 'UNIT_KG' | 'UNIT_TON' | 'UNIT_LONG_TON' | 'UNIT_SHORT_TON' | 'UNIT_LBS';
    decimalPlaces: number;
  };
  MassFlow: {
    conversion: 'UNIT_KG_PER_HOUR' | 'UNIT_TON_PER_HOUR' | 'UNIT_LONG_TON_PER_HOUR' | 'UNIT_SHORT_TON_PER_HOUR' | 'UNIT_LB_PER_HOUR';
    decimalPlaces: number;
  };
  AmountRatio: {
    conversion: 'UNIT_PERCENT' | 'UNIT_FRACTION';
    decimalPlaces: number;
  };
  MolAmountRatio: {
    conversion: 'UNIT_MOL_PERCENT' | 'UNIT_MOL_PER_MOL';
    decimalPlaces: number;
  };

  MolarMass: {
    conversion: 'UNIT_KG_PER_KMOL' | 'UNIT_G_PER_MOL' | 'UNIT_LB_MOL_LBMOL';
    decimalPlaces: number;
  };
  HeatValue: {
    conversion: 'UNIT_MJ_PER_M3' | 'UNIT_KWH_PER_M3' | 'UNIT_BTU_PER_CF';
    // | 'UNIT_THERM_PER_CF'
    decimalPlaces: number;
  };
  Angle: {
    conversion: 'UNIT_DEGREE' | 'UNIT_RAD';
    decimalPlaces: number;
  };
  EnergyFlow: {
    conversion:
      | 'UNIT_POWER_MJH'
      | 'UNIT_POWER_MJD'
      | 'UNIT_POWER_TJH'
      | 'UNIT_POWER_TJD'
      | 'UNIT_POWER_BTUH'
      | 'UNIT_POWER_BTUD'
      | 'UNIT_KWH_PER_HOUR';
    decimalPlaces: number;
  };
  EnergyCounter: {
    conversion: 'UNIT_MJ' | 'UNIT_BTU' | 'UNIT_KWH';
    decimalPlaces: number;
  };
  SoundLevel: {
    conversion: 'UNIT_DECIBEL';
    decimalPlaces: number;
  };
  SignalTiming: {
    conversion: 'UNIT_NANOSECONDS' | 'UNIT_MICROSECONDS' | 'UNIT_MILLISECONDS' | 'UNIT_SECONDS';
    decimalPlaces: number;
  };
}
