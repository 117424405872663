import {HttpClient} from '@angular/common/http';
import {OkResponse} from '../../state-service/do-mapper/model/communication/commands';
import {prepareToCall} from './prepare-call-to-save-api';
import {callSavingEndpoint} from './pending-call-to-save-api';
import {Dto} from '@flowtwin/communication';

export interface EndpointReferenceContext {
  key: string;
  urlSuffix: 'document' | 'appProject' | 'selection-sizing' | 'uncertainty-prediction' | 'transducer-performance';
  dtoAssemblyHandler: (ctx: EndpointReferenceContext, data: any) => Dto;
  endpointCallingHandler: (
    http: HttpClient,
    projectId: string,
    tagId: string,
    ctx: EndpointReferenceContext,
    data: Dto,
    direction: string,
    url: any
  ) => Promise<OkResponse<any>>;
  partOfApp: 'input' | 'output' | 'metadata';
}

export const SAVING_ENDPOINT_REFERENCE_CONTEXT: EndpointReferenceContext[] = [
  {
    key: 'document',
    urlSuffix: 'document',
    dtoAssemblyHandler: prepareToCall,
    endpointCallingHandler: callSavingEndpoint,
    partOfApp: null
  },
  {
    key: 'appProject',
    urlSuffix: 'appProject',
    dtoAssemblyHandler: prepareToCall,
    endpointCallingHandler: callSavingEndpoint,
    partOfApp: 'input'
  },
  {
    key: 'appProject',
    urlSuffix: 'appProject',
    dtoAssemblyHandler: prepareToCall,
    endpointCallingHandler: callSavingEndpoint,
    partOfApp: 'output'
  },
  {
    key: 'appSizing',
    urlSuffix: 'selection-sizing',
    dtoAssemblyHandler: prepareToCall,
    endpointCallingHandler: callSavingEndpoint,
    partOfApp: 'input'
  },
  {
    key: 'appSizing',
    urlSuffix: 'selection-sizing',
    dtoAssemblyHandler: prepareToCall,
    endpointCallingHandler: callSavingEndpoint,
    partOfApp: 'output'
  },
  {
    key: 'appTransducer',
    urlSuffix: 'transducer-performance',
    dtoAssemblyHandler: prepareToCall,
    endpointCallingHandler: callSavingEndpoint,
    partOfApp: 'input'
  },
  {
    key: 'appTransducer',
    urlSuffix: 'transducer-performance',
    dtoAssemblyHandler: prepareToCall,
    endpointCallingHandler: callSavingEndpoint,
    partOfApp: 'output'
  },
  {
    key: 'appUncertainty',
    urlSuffix: 'uncertainty-prediction',
    dtoAssemblyHandler: prepareToCall,
    endpointCallingHandler: callSavingEndpoint,
    partOfApp: 'input'
  },
  {
    key: 'appUncertainty',
    urlSuffix: 'uncertainty-prediction',
    dtoAssemblyHandler: prepareToCall,
    endpointCallingHandler: callSavingEndpoint,
    partOfApp: 'output'
  }
];
