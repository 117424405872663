import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Route, Router} from '@angular/router';

import {AuthService} from '../authentification/auth.service';
import {from, Observable} from 'rxjs';
import {filter, map, skipWhile, tap} from 'rxjs/operators';
import {StateService} from '@state/state-service/state.service';
import {environment} from '@env/environment';

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private state: StateService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.shallPass(route).pipe(
      tap((allowed: boolean) => {
        if (!allowed) {
          this.redirectTo('no-access');
        }
      }),
      map(allowed => {
        return allowed;
      })
    );
  }

  canLoad(route: Route) {
    return this.shallPass(route);
  }

  shallPass(route: Route | ActivatedRouteSnapshot) {
    return from(this.state.state$).pipe(
      skipWhile(val => val.user.state !== 'logged-in'),
      map(_ => {
        if (
          this.userHasRole(this.state.state.user.document.permissions, route.data.expectedRoles) ||
          environment.name === 'dev' ||
          environment.name === 'local'
        ) {
          return true;
        } else {
          return false;
        }
      })
    );
  }
  userHasRole(permissions: any, expectedRoles: []): boolean {
    if (!expectedRoles) {
      return true;
    }

    for (const role of expectedRoles) {
      if (permissions.hasOwnProperty(role) && permissions[role]) {
        return permissions[role];
      }
    }

    return false;
  }

  private redirectTo(destination: string) {
    this.router.navigate([destination]);
  }
}
