import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FlowTwinProject} from '@state/state-service/do-mapper/model/project/project-flow-twin';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'da-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent {
  @Input() form = new UntypedFormGroup({});
  @Input() project?: Partial<FlowTwinProject>;
  @Input() fields$: BehaviorSubject<FormlyFieldConfig[]>;
  @Input() inputPath = '';
}
