import {map} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {StateService} from '../state-service/state.service';
import {MetadataReduxAction} from '../actions/actions-metadata';
import {ErrorService} from '@state/state-service/error.service';
import {GenericModalPayload} from '@state/actions/actions-modal';
import {ILogger, LoggerConfig, LoggerService, LogLevel} from '../../services/logger/logger.service';
import {DoAssemblyDirector, Dto} from '@flowtwin/communication';
import {OkResponse_DTO} from '@state/state-service/dto-mapper/model/communication/response-dto';
import {
  DeviceUncertaintySetup,
  PathLayout,
  ReferenceCondition,
  UncertaintySetup
} from '@state/state-service/do-mapper/model/device/properties';
import {PROP_COLLECTION_MAP_METADATA} from '@state/state-service/do-mapper/contracts/metadata';
import {Metadata} from '@state/state-service/do-mapper/model/project/metadata';
import {ApiErrorHandleConfig, handleApiError} from '@state/epics/utility/handle-api-error';

const loggerConfig: LoggerConfig = {
  name: '-',
  symbol: '📡',
  textColor: 'black',
  backgroundColor: '#F45D01'
};

const url = environment.url.aws + '/metadata';

@Injectable({
  providedIn: 'root'
})
export class MetadataEpicService {
  private logger: ILogger;

  constructor(private http: HttpClient, private errorService: ErrorService, private loggerService: LoggerService) {
    this.logger = this.initLogger();
  }

  private initLogger() {
    loggerConfig.name = this.constructor.name;
    return this.loggerService.initLogger(loggerConfig);
  }

  private mapDtoToMetadataResults(input: Dto): Metadata {
    const fetchResult = new DoAssemblyDirector(input, PROP_COLLECTION_MAP_METADATA)
      .createInstance<Metadata>(Metadata)
      .assembleDo<Metadata>()
      .collect() as Metadata;

    return {
      ...fetchResult,
      pathLayouts: fetchResult.pathLayouts?.map(x => (x as any).value as PathLayout) || null,
      referenceConditions: fetchResult.referenceConditions?.map(x => (x as any).value as ReferenceCondition) || null,
      sensorTypes: fetchResult.sensorTypes?.map(x => (x as any).value) || null,
      sensorGasSelectionMethods: fetchResult.sensorGasSelectionMethods?.map(x => (x as any).value) || null,
      uncertaintySetup: {
        ...fetchResult.uncertaintySetup,
        deviceUncertaintySetups:
          fetchResult.uncertaintySetup?.deviceUncertaintySetups.map(x => (x as any).value as DeviceUncertaintySetup) || []
      } as UncertaintySetup
    };
  }

  public async getMetadata(action: MetadataReduxAction, stateService: StateService) {
    try {
      await stateService.loggedIn$.toPromise();

      const start = Date.now();
      const metadata = await this.http
        .get<OkResponse_DTO<Dto>>(url)
        // TODO @Arnold: result does not exist in Dto according to the type definition
        .pipe(map(res => this.mapDtoToMetadataResults(res.data['result'])))
        .toPromise();

      const duration = Date.now() - start;

      this.logger.log('', 'Get All', LogLevel.Info, [
        {OldMetadata: stateService.state.project.metadata},
        {NewMetadata: metadata},
        {Duration: `Duration: ${duration}ms`}
      ]);

      const successAction: MetadataReduxAction = new MetadataReduxAction('METADATA_GET_ALL_SUCCESS', {metadata});

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'METADATA_GET_ALL_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }
}
