import {DtoContract} from '../../contracts';

export const RES_GET_VERSIONS = {
    config: [
        {KEY: 'octave.base', TYPE: 'STRING'},
        {KEY: 'octave.packages.common', TYPE: 'STRING'},
        {KEY: 'octave.packages.unit', TYPE: 'STRING'},
        {KEY: 'octave.packages.matlab', TYPE: 'STRING'},
        {KEY: 'octave.packages.communication', TYPE: 'STRING'},
        {KEY: 'aws.base', TYPE: 'STRING'},
        {KEY: 'aws.packages.unit', TYPE: 'STRING'},
        {KEY: 'aws.packages.communication', TYPE: 'STRING'}
    ] as DtoContract[]
};
