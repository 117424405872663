import {UUID} from './type-alias';
import {AbstractDomainObject} from '@flowtwin/communication';
import {ProductFamily} from '@state/state-service/do-mapper/model/device/properties';
import {Application} from '@state/actions/actions-input';

export type UserLoggedInState = 'loading' | 'logged-in' | 'no-flow-twin-user' | 'error';

export interface ExternalPermissions {
  apps: Application[];
  productFamilies: ProductFamily[];
}

export interface User {
  state: UserLoggedInState;
  document: {
    id: UUID;
    deleted: boolean;
    emailVerified: boolean;
    locale: string;
    givenName: string;
    familyName: string;
    email: string;
    staredProjects: UUID[];
    permissions: Permissions;
    externalPermissions: ExternalPermissions;
  };
}

// tslint:disable-next-line:class-name
export class User_Class extends AbstractDomainObject {
  state: UserLoggedInState;
  document: {
    id: UUID;
    deleted: boolean;
    emailVerified: boolean;
    locale: string;
    givenName: string;
    familyName: string;
    email: string;
    staredProjects: UUID[];
    permissions: Permissions;
    externalPermissions: ExternalPermissions;
  };
}

export interface Permissions {
  admin: boolean;
  supervisor: boolean;
  gbc04expert: boolean;
  gbc04service: boolean;
  gbc04sales: boolean;
  sicksales: boolean;
  externalpartner: boolean;
  externalservice: boolean;
  externalcustomer: boolean;
}
