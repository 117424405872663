import {accessObjectByPath} from '../../util/access-object-by-string-path';
import {Item} from '../model/dto';
import {undefinedChecker} from '../util/undefined-checker';

export function assembleDtoDefault(assembledObject, elem, payload) {
  assembledObject = {
    key: elem.KEY,
    type: elem.TYPE,
    value: accessObjectByPath(elem.DOMAINOBJECT, elem.PATHINDOMAINOBJECT)
  } as Item;

  undefinedChecker(payload, assembledObject);
}
