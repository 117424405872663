import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {
  CalcModePayload,
  GasVelocitiesPayload,
  InputActionPayloadGeneric,
  InputRangeSliderPayload,
  InputReduxAction,
  ParameterSetupPayload,
  ProcessConditionPayload,
  ProjectSpecificGasCompositionsPayload,
  ResetInputPayload,
  DeviceUncertaintySetupsPayload,
  UpdateProjectInputPayload
} from '../actions/actions-input';
import {mergeTwoObjectsBySinglePath} from '@shared/utility/access-object-by-path';
import {PROJECT_SPECIFIC_UNIT_SYSTEM_ID} from '@shared/models/consts';
import {createDefaultFlowTwinProject} from '@state/state-service/do-mapper/model/project/default-project';
import {getDifferenceDeep} from '@shared/utility/get-deep-diff-object';
import {removeEmptyObjects} from '@shared/utility/remove-empty-objects';
import {set, union, uniqBy} from 'lodash';
import {clone} from '@shared/utility/clone';
import {isDefined} from '@shared/utility/isDefined';
import _ from 'lodash';

@Injectable({
  providedIn: null
})
export class InputReducerService {
  static assignHandler(action: InputReduxAction, stateService: StateService) {
    switch (action.type) {
      case 'INPUT_CHANGED':
        InputReducerService.setProjectsSaveStateToUnsaved(action, stateService);
        break;
      case 'UPDATE_PROJECT_INPUT':
        InputReducerService.updateProjectInput(action, stateService);
        break;
      case 'RESET_INPUT':
        InputReducerService.resetInputOfApplication(action, stateService);
        break;
      case 'GAS_COMPOSITION_ID':
        InputReducerService.syncGasCompositionProductAndSizingAndTransducer(action, stateService);
        break;
      case 'MEASURED_FLOW_VALUE':
        InputReducerService.resetMeasuredFlowRange(action, stateService);
        break;
      case 'NOMINAL_SIZE':
        InputReducerService.syncNominalDiametersGlobally(action, stateService);
        break;
      case 'INSTALLATION_TYPE':
        this.setSizingInputs(action, stateService);
        break;
      case 'INPUT_PRECISION':
        InputReducerService.UpdateSliderByPrecisionInput(action, stateService);
        break;
      case 'PROCESS_FLOW_PRESSURE_MAX':
        InputReducerService.setSizingInputs(action, stateService);
        break;
      case 'PROCESS_FLOW_PRESSURE_MIN':
        InputReducerService.setSizingInputs(action, stateService);
        break;
      case 'PROCESS_FLOW_RANGE_MAX':
        InputReducerService.setSizingInputs(action, stateService);
        break;
      case 'PROCESS_FLOW_RANGE_MIN':
        InputReducerService.setSizingInputs(action, stateService);
        break;
      case 'PROCESS_FLOW_TEMPERATURE_MAX':
        InputReducerService.setSizingInputs(action, stateService);
        break;
      case 'PROCESS_FLOW_TEMPERATURE_MIN':
        InputReducerService.setSizingInputs(action, stateService);
        break;
      case 'PROJECT_SPECIFIC_CUSTOM_UNIT_SYSTEM':
        InputReducerService.updateProjectSpecificUnitSystem(action, stateService);
        break;
      case 'PROCESS_CONDITION':
        InputReducerService.updateProcessCondition(action, stateService);
        break;
      case 'UNIT_SYSTEM_ID':
        InputReducerService.setCustomUnitSystemForProject(action, stateService);
        break;
      case 'GAS_VELOCITIES':
        InputReducerService.updateGasVelocities(action, stateService);
        InputReducerService.setProjectsSaveStateToUnsaved(action, stateService);
        break;
      case 'PARAMETER_SETUP':
        InputReducerService.updateParameterSetup(action, stateService);
        InputReducerService.setProjectsSaveStateToUnsaved(action, stateService);
        break;
      case 'METER_ENTRY':
        InputReducerService.updateMeterEntry(action, stateService);
        break;
      case 'SENSOR_TYPE':
        InputReducerService.updateSensorType(action, stateService);
        break;
      case 'EXPLOSION_GROUP':
        InputReducerService.updateExplosionGroup(action, stateService);
        break;
      case 'SENSOR_GAS_SELECTION_METHOD':
        InputReducerService.updateSensorGasSelectionMethod(action, stateService);
        break;
      case 'PROJECT_SPECIFIC_GAS_COMPOSITION':
        InputReducerService.updateProjectCustomGases(action, stateService);
        break;
      case 'CALC_MODE':
        InputReducerService.setCalcMode(action, stateService);
        break;
      case 'SET_DEVICE_UNCERTAINTY_SETUPS':
        InputReducerService.setDeviceUncertaintySetups(action, stateService);
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any Input Reducer-Function.`);
        break;
    }
  }

  private static setProjectsSaveStateToUnsaved(action: InputReduxAction, stateService: StateService) {
    stateService.updateWithNewValue(
      state => {
        state.project.saved = 'unsaved';
        set(state, 'project.data.appUncertainty.output', null);
      },
      {
        type: `Input: INPUT - ${action.type}`
      }
    );
  }

  private static setSizingInputs(action: InputReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        // TODO @Arnold: We need some kind of validation and types here
        set(s, `project.data.appSizing.input.${action.payload['input'][0]}`, action.payload['input'][1]);
      },
      {
        type: `Input Reducer: SYNC_INPUT - ${action.type}`
      }
    );
  }

  private static UpdateSliderByPrecisionInput(action: InputReduxAction, stateService: StateService) {
    // TODO @Arnold: Imo typecasting should happen in the assignHandler(...), so we have the correct types inside the functions
    const {control, value} = action.payload as InputRangeSliderPayload;

    stateService.update(
      s => {
        // TODO @Arnold: Would it be possible to type control so we can use it here as a key? If not we should think of a validation logic here
        set(s, `project.data.appSizing.input.${control}`, value);
      },
      {
        type: `Input Reducer: SYNC_INPUT - ${action.type}`
      }
    );
  }

  private static resetInputOfApplication(action: InputReduxAction, stateService: StateService) {
    const {application} = action.payload as ResetInputPayload;

    switch (application) {
      case 'sizing':
        stateService.update(
          state => {
            if (isDefined(state.project.data?.appSizing) && Object.keys(state.project.data!.appSizing).length > 0) {
              const appSelectionSizing = state.project.data!.appSizing.input!;

              appSelectionSizing.productFamily = null;
              appSelectionSizing.productType = null;
              appSelectionSizing.installationType = null;
              appSelectionSizing.schedule = null;
              appSelectionSizing.flowCondition = null;
              appSelectionSizing.nominalSize = null;
              appSelectionSizing.pressureClass = null;
              appSelectionSizing.fullBore = false;
              appSelectionSizing.innerDiameter = {
                unit: 'Diameter',
                value: 0
              };
              appSelectionSizing.selectedDevice = null;
              appSelectionSizing.flowConditioner = null;
              appSelectionSizing.flowRangeOption = null;
              appSelectionSizing.selectedDevice = null;
              appSelectionSizing.schedule = null;
            }

            if (isDefined(state.project.data?.appUncertainty) && Object.keys(state.project.data!.appUncertainty).length > 0) {
              const appUncertaintyPrediction = state.project.data!.appUncertainty.input;
              if (appUncertaintyPrediction?.parameterSetup?.extendedUncertaintySetup?.configuration?.biasNozzleDistance) {
                appUncertaintyPrediction.parameterSetup.extendedUncertaintySetup.configuration.biasNozzleDistance = null;
              }
            }
          },
          {
            type: `Input Reducer: INPUT - ${action.type}`
          }
        );
        break;
    }
  }

  private static updateProjectInput(action: InputReduxAction, stateService: StateService) {
    const {path, newInputValues} = action.payload as UpdateProjectInputPayload;

    stateService.updateWithNewValue(
      state => {
        state.project.saved = 'unsaved';

        if (
          path.includes('basicProject.data.custom.displayUnitSystem.document') &&
          newInputValues?.id !== PROJECT_SPECIFIC_UNIT_SYSTEM_ID
        ) {
          const selectedUnitSystem = state.unitSystems.data.find(us => us.document.id === newInputValues?.id);
          set(state, 'basicProject.data.custom.displayUnitSystem', selectedUnitSystem);
        } else {
          return mergeTwoObjectsBySinglePath(path, state, newInputValues);
        }
      },
      {type: `Update Input of: ${path}`}
    );
  }

  private static syncNominalDiametersGlobally(action: InputReduxAction, stateService: StateService) {
    stateService.update(
      state => {
        set(state, `project.data.appSizing.input.nominalSize`, action.payload['input'][1]);
        set(state, `project.data.appTransducer.input.nominalDiameter`, {
          value: action.payload['input'][1],
          unit: 'Diameter'
        });
      },
      {type: `Input Reducer: INPUT - ${action.type}`}
    );
  }

  private static resetMeasuredFlowRange(action: InputReduxAction, stateService: StateService) {
    stateService.update(
      state => {
        if (!(action.payload as InputActionPayloadGeneric).oldValue) {
          return;
        }

        set(state, `project.data.appSizing.input.${action.payload['input'][0]}`, action.payload['input'][1]);
        set(state, `project.data.appSizing.input.processFlowRangeMin`, {
          value: 0,
          unit: action.payload['input'][1]
        });
        set(state, `project.data.appSizing.input.processFlowRangeMax`, {
          value: 0,
          unit: action.payload['input'][1]
        });
      },
      {
        type: `Input Reducer: SYNC_INPUT - ${action.type}`
      }
    );
  }

  private static updateProjectSpecificUnitSystem(action: InputReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        if ('unitSystem' in action.payload) {
          set(s, `basicProject.data.custom.displayUnitSystem`, action.payload.unitSystem);
          set(s, `project.data.appProject.unitSystemSpecificTemp`, action.payload.unitSystem);
        }
      },
      {
        type: 'Update Project Specific Unit System'
      }
    );
  }

  private static syncGasCompositionProductAndSizingAndTransducer(action: InputReduxAction, stateService: StateService) {
    const payload = (action.payload as InputActionPayloadGeneric).input;

    const value = payload[1];

    if (value) {
      stateService.update(
        s => {
          set(
            s,
            'basicProject.data.custom.gasCompositionDocs',
            uniqBy(
              union(s.basicProject.data?.custom?.gasCompositionDocs, [s.gases.data.find(g => g.document.id === value)]).filter(isDefined),
              ({document}) => document.id
            )
          );
        },
        {
          type: `Input Reducer: SYNC_INPUT - ${action.type}`
        }
      );
    }
  }

  private static updateProcessCondition(action: InputReduxAction, stateService: StateService) {
    const {processDataEntries} = action.payload as ProcessConditionPayload;

    stateService.update(
      s => {
        const pdeCopy = {
          new: clone(processDataEntries),
          old: s.project.data?.appUncertainty?.input?.processDataEntries ?? []
        };

        // TODO @Arnold: Please check the error when removing the @ts-ignore
        /* necessary because their ID is generated in getDefaultProcessConditions() (FE).
        Would always detect a change although nothing has really changed. */
        // @ts-ignore
        pdeCopy.new.forEach(pde => delete pde.id);
        // @ts-ignore
        pdeCopy.old.forEach(pde => delete pde.id);

        const diff = removeEmptyObjects(getDifferenceDeep(pdeCopy.old, pdeCopy.new));

        const usedGasComps = [...pdeCopy.new.map(pde => pde.gasCompositionId), s.project.data.appSizing.input.gasCompositionId];

        s.basicProject.data.custom.gasCompositionDocs = _.uniqWith(
          [
            ...s.basicProject.data.custom.gasCompositionDocs,
            ...s.gases.data.filter(g => usedGasComps.includes(g.document.id)),
            ...s.basicProject.data.custom.gasCompositionDocs.filter(g => !usedGasComps.includes(g.document.id))
          ],
          (a, b) => a.document.id === b.document.id
        );

        if (Object.keys(diff).length > 0) {
          set(s, 'project.data.appUncertainty.input.processDataEntries', processDataEntries);
          s.project.saved = 'unsaved';
        }
      },
      {type: 'Update Process Conditions'}
    );
  }

  private static setCustomUnitSystemForProject(action: InputReduxAction, stateService: StateService) {
    const {input} = action.payload as InputActionPayloadGeneric;
    const unitSystemId = input[1];

    stateService.update(
      state => {
        let unitSystem = state.unitSystems.data.find(u => u.document.id === unitSystemId) ?? null;

        if (!unitSystem) {
          unitSystem =
            state.basicProject.data?.custom?.displayUnitSystem?.document.id === unitSystemId
              ? state.basicProject.data?.custom?.displayUnitSystem ?? null
              : null;

          if (!unitSystem) {
            unitSystem = state.project.data.appProject?.unitSystemSpecificTemp ?? null;

            if (!unitSystem) {
              // never used specific? Create default unit system
              unitSystem = {
                document: {
                  id: PROJECT_SPECIFIC_UNIT_SYSTEM_ID,
                  name: PROJECT_SPECIFIC_UNIT_SYSTEM_ID,
                  deleted: false,
                  creator: state.user.document.email,
                  userList: [],
                  description: PROJECT_SPECIFIC_UNIT_SYSTEM_ID
                },
                unitSystem: createDefaultFlowTwinProject().custom!.displayUnitSystem.unitSystem
              };
            }
          }
        }

        set(state, 'basicProject.data.custom.displayUnitSystem', unitSystem);
      },
      {type: `RootReducer: INPUT - ${action.type}`}
    );
  }

  private static updateGasVelocities(action: InputReduxAction, stateService: StateService) {
    const {gasVelocities} = action.payload as GasVelocitiesPayload;

    stateService.update(
      state => {
        set(state, 'project.data.appUncertainty.input.velocities', gasVelocities);
      },
      {type: 'Update Gas Velocities'}
    );
  }

  private static updateParameterSetup(action: InputReduxAction, stateService: StateService) {
    const {parameterSetup} = action.payload as ParameterSetupPayload;

    stateService.update(
      s => {
        set(s, 'project.data.appUncertainty.input.parameterSetup', {
          ...s.project.data?.appUncertainty?.input?.parameterSetup,
          ...parameterSetup
        });
      },
      {type: 'Update Device Parameter Setup'}
    );
  }

  private static updateMeterEntry(action: InputReduxAction, stateService: StateService) {
    const {input} = action.payload as InputActionPayloadGeneric;

    stateService.update(
      state => {
        let diffTemp = null;
        let diffPress = null;

        const pressure = state.project.data?.appTransducer?.input?.pressure;
        const temperature = state.project.data?.appTransducer?.input?.temperature;

        if (!temperature || !pressure) {
          return;
        }

        switch (input.condition) {
          case 'meter.config.design.min':
            diffTemp = getDifferenceDeep(input.temperature.value, temperature.minDesign);
            diffPress = getDifferenceDeep(input.pressure.value, pressure.minDesign);

            pressure.minDesign = input.pressure.value;
            temperature.minDesign = input.temperature.value;
            break;
          case 'meter.config.design.max':
            diffTemp = getDifferenceDeep(input.temperature.value, temperature.maxDesign);
            diffPress = getDifferenceDeep(input.pressure.value, pressure.maxDesign);

            pressure.maxDesign = input.pressure.value;
            temperature.maxDesign = input.temperature.value;
            break;
          case 'meter.config.operation.min':
            diffTemp = getDifferenceDeep(input.temperature.value, temperature.min);
            diffPress = getDifferenceDeep(input.pressure.value, pressure.min);

            pressure.min = input.pressure.value;
            temperature.min = input.temperature.value;
            break;
          case 'meter.config.operation.max':
            diffTemp = getDifferenceDeep(input.temperature.value, temperature.max);
            diffPress = getDifferenceDeep(input.pressure.value, pressure.max);

            pressure.max = input.pressure.value;
            temperature.max = input.temperature.value;
            break;
        }

        diffPress = removeEmptyObjects(diffPress);
        diffTemp = removeEmptyObjects(diffTemp);

        if (Object.keys(diffPress).length > 0 || Object.keys(diffTemp).length > 0) {
          state.project.saved = 'unsaved';
        }
      },
      {type: 'Update Meter Entry'}
    );
  }

  private static updateExplosionGroup(action: InputReduxAction, stateService: StateService) {
    const payload = (action.payload as InputActionPayloadGeneric).input;
    const {metadata} = stateService.state.project;

    stateService.update(
      s => {
        set(
          s,
          `project.data.appTransducer.input.${payload[0]}`,
          metadata.explosionGroups?.find(eg => eg.value === payload[1])
        );
      },
      {
        type: `Input Reducer: SYNC_INPUT - ${action.type}`
      }
    );
  }

  private static updateSensorType(action: InputReduxAction, stateService: StateService) {
    const payload = (action.payload as InputActionPayloadGeneric).input;
    const {metadata} = stateService.state.project;

    stateService.update(
      s => {
        const isUserSelectedProbe = action.payload['input'][1].toLowerCase() !== ('auto' || 'automatic');
        set(s, 'project.data.appTransducer.input.userSelectedProbe', isUserSelectedProbe);
        set(
          s,
          `project.data.appTransducer.input.${payload[0]}`,
          metadata.sensorTypes?.find(eg => eg.value === payload[1])
        );
      },
      {
        type: `Input Reducer: SYNC_INPUT - ${action.type}`
      }
    );
  }

  private static updateSensorGasSelectionMethod(action: InputReduxAction, stateService: StateService) {
    const payload = (action.payload as InputActionPayloadGeneric).input;
    const {metadata} = stateService.state.project;

    stateService.update(
      s => {
        set(
          s,
          `project.data.appTransducer.input.${payload[0]}`,
          metadata.sensorGasSelectionMethods?.find(eg => eg.value === payload[1])
        );
      },
      {
        type: `Input Reducer: SYNC_INPUT - ${action.type}`
      }
    );
  }

  private static updateProjectCustomGases(action: InputReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        const index = s.basicProject.data?.custom?.gasCompositionDocs.findIndex(
          g => g.document.id === (action.payload as ProjectSpecificGasCompositionsPayload).gas.document.id
        );

        if (isDefined(index) && index > -1) {
          set(s, `basicProject.data.custom.gasCompositionDocs[${index}]`, (action.payload as ProjectSpecificGasCompositionsPayload).gas);
          return;
        } else {
          set(s, 'basicProject.data.custom.gasCompositionDocs', [
            ...(s.basicProject.data?.custom?.gasCompositionDocs ?? []),
            (action.payload as ProjectSpecificGasCompositionsPayload).gas
          ]);
        }
      },
      {
        type: `Input Reducer: Update Custom Gas - ${action.type}`
      }
    );
  }

  private static setCalcMode(action: InputReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        set(s, 'project.data.appSizing.input.calcMode', (action.payload as CalcModePayload).calcMode);
      },
      {
        type: `Input Reducer: Update Calculation Mode to ${(action.payload as CalcModePayload).calcMode}.`
      }
    );
  }

  private static setDeviceUncertaintySetups(action: InputReduxAction, stateService: StateService) {
    const deviceUncertaintySetups = (action.payload as DeviceUncertaintySetupsPayload).deviceUncertainties;

    stateService.update(
      s => {
        s.project.data.appUncertainty.input.parameterSetup.deviceUncertaintySetups = deviceUncertaintySetups;
      },
      {
        type: `Input Reducer: Update Device Uncertainty Setups.`
      }
    );
  }
}
