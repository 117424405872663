import {DtoContract} from '../../contracts';

export const REQ_POST_APP_TRANSDUCER_PERFORMANCE_INPUT = {
  config: [
    {KEY: 'pressure.min', TYPE: 'DTOQUANTITY'},
    {KEY: 'pressure.max', TYPE: 'DTOQUANTITY'},
    {KEY: 'pressure.minDesign', TYPE: 'DTOQUANTITY'},
    {KEY: 'pressure.maxDesign', TYPE: 'DTOQUANTITY'},
    {KEY: 'temperature.min', TYPE: 'DTOQUANTITY'},
    {KEY: 'temperature.max', TYPE: 'DTOQUANTITY'},
    {KEY: 'temperature.minDesign', TYPE: 'DTOQUANTITY'},
    {KEY: 'temperature.maxDesign', TYPE: 'DTOQUANTITY'},
    {KEY: 'remarks', TYPE: 'STRING'},
    {KEY: 'gasCompositionId', TYPE: 'STRING'},
    {KEY: 'nominalDiameter', TYPE: 'DTOQUANTITY'},
    {KEY: 'probeVec.userSelectedProbe', TYPE: 'BOOLEAN'},
    {KEY: 'probeVec.selectedProbeKey', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'injectH2', TYPE: 'BOOLEAN'},
    {KEY: 'xH2', TYPE: 'DTOQUANTITY'},
    {KEY: 'configuration.exGroupKey', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'configuration.gasSelectionKey', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'configuration.hsElectronics', TYPE: 'BOOLEAN'},
    {KEY: 'configuration.extendedPNwCalculation', TYPE: 'BOOLEAN'}
  ] as DtoContract[]
};
export const REQ_POST_APP_TRANSDUCER_PERFORMANCE_RESULT = {
  config: [
    {KEY: 'user.email', TYPE: 'STRING'},
    {KEY: 'user.locale', TYPE: 'STRING'},

    {KEY: 'user.permissions.admin', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.gbc04expert', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.gbc04service', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.gbc04sales', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.sicksales', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.externalpartner', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.externalservice', TYPE: 'BOOLEAN'},
    {KEY: 'user.permissions.externalcustomer', TYPE: 'BOOLEAN'},

    {KEY: 'pressure.min', TYPE: 'DTOQUANTITY'},
    {KEY: 'pressure.max', TYPE: 'DTOQUANTITY'},
    {KEY: 'pressure.maxDesign', TYPE: 'DTOQUANTITY'},

    {KEY: 'temperature.min', TYPE: 'DTOQUANTITY'},
    {KEY: 'temperature.max', TYPE: 'DTOQUANTITY'},
    {KEY: 'temperature.minDesign', TYPE: 'DTOQUANTITY'},
    {KEY: 'temperature.maxDesign', TYPE: 'DTOQUANTITY'},

    {KEY: 'gasComposition', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'nominalDiameter', TYPE: 'DTOQUANTITY'},
    {KEY: 'probeVec.userSelectedProbe', TYPE: 'BOOLEAN'},
    {KEY: 'probeVec.selectedProbeKey', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'injectH2', TYPE: 'BOOLEAN'},
    {KEY: 'xH2', TYPE: 'DTOQUANTITY'},
    {KEY: 'configuration.exGroupKey', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'configuration.gasSelectionKey', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'configuration.hsElectronics', TYPE: 'BOOLEAN'},
    {KEY: 'configuration.userGroup', TYPE: 'STRING'},
    {KEY: 'configuration.extendedPNwCalculation', TYPE: 'BOOLEAN'},
    {KEY: 'calcMode', TYPE: 'STRING'}
  ] as DtoContract[]
};
