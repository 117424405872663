import {Device} from '@state/state-service/do-mapper/model/device/properties';
import {Metadata} from '@state/state-service/do-mapper/model/project/metadata';
import {ReduxAction} from '@state/actions/actions-abstract';
import {FlowTwinProject} from '@state/state-service/do-mapper/model/project/project-flow-twin';
import {GasCompositionDoc} from '@state/state-service/do-mapper/model/gas/gas-composition-doc';
import {AppSizingMetadata} from '@state/state-service/do-mapper/model/apps/app-sizing';

export type DeviceActions = 'DEVICE_SEARCH_LOAD' | 'DEVICE_SEARCH_SUCCESS' | 'DEVICE_SEARCH_ERROR';

export interface ApiPayloadSearchLoad {
  project: FlowTwinProject;
  metadata: AppSizingMetadata;
}

export interface ApiPayloadSearchSuccess {
  devices: Device[];
  metadata: Metadata;
}

export type DevicePayloads = ApiPayloadSearchLoad | ApiPayloadSearchSuccess;

export class DeviceReduxAction extends ReduxAction {
  type: DeviceActions;
  payload: DevicePayloads;

  constructor(type: DeviceActions, payload: DevicePayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
