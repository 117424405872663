import {Injectable} from '@angular/core';
import {StateService} from './state.service';
import {Dialogs, DialogState, GenericModalPayload, ModalReduxAction} from '@state/actions/actions-modal';
import {ReduxAction} from '@state/actions/actions-abstract';

@Injectable({
  providedIn: null
})
export class ErrorService {
  constructor() {}

  performUnifiedErrorHandling<T extends ReduxAction>(
    config: Pick<GenericModalPayload, 'modal'>,
    originErrorAction: T,
    stateService: StateService
  ) {
    this.dispatchError<T>(originErrorAction, stateService);

    this.dispatchError<ModalReduxAction>(this.generateModalAction(config), stateService);
    console.error(config.modal.config.error);
  }

  private dispatchError<T extends ReduxAction>(originErrorAction: T, stateService: StateService): void {
    stateService.dispatch(originErrorAction);
  }

  private generateModalAction(config: Pick<GenericModalPayload, 'modal'>): ModalReduxAction {
    return new ModalReduxAction('MODAL_ERROR', {
      modal: {
        ...{
          config: {
            ...config.modal?.config,
            ...{
              open: DialogState.open
            }
          },
          dialog: Dialogs.error
        }
      }
    });
  }
}
