import {DtoContract} from '../../contracts';

export const RES_GET_TAGS = {
  config: [
    {KEY: 'id', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'name', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'description', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'created', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'}
  ] as DtoContract[]
};

export const RES_POST_CREATE_TAG = {
  config: [
    {KEY: 'id', TYPE: 'STRING'},
    {KEY: 'name', TYPE: 'STRING'},
    {KEY: 'description', TYPE: 'STRING'},
    {KEY: 'created', TYPE: 'STRING'}
  ] as DtoContract[]
};

export const RES_GET_TAG = {
  config: [
    {KEY: 'id', TYPE: 'STRING'},
    {KEY: 'name', TYPE: 'STRING'},
    {KEY: 'description', TYPE: 'STRING'},
    {KEY: 'created', TYPE: 'STRING'}
  ] as DtoContract[]
};
