import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {PageStateReduxAction, PayloadPageState} from '../actions/actions-page-state';

@Injectable({
  providedIn: 'root'
})
export class PageStateReducerService {
  static assignHandler(action: PageStateReduxAction, stateService: StateService) {
    switch (action.type) {
      case 'UPDATE_PAGE_STATE':
        PageStateReducerService.updatePageState(action, stateService);
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any Compute Reducer-Function.`);
        break;
    }
  }

  private static updatePageState(action: PageStateReduxAction, stateService: StateService) {
    const {page, state} = action.payload as PayloadPageState;

    stateService.update(
      s => {
        s.pages[page] = state;
      },
      {
        type: 'Page State Updated of: ' + page,
        details: {results: action}
      }
    );
  }
}
