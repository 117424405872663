<mat-form-field
  appearance="outline"
  [ngStyle]="{
    display: _isHidden() ? 'none' : 'block',
    color: _invalid ? 'red' : 'rgba(50, 60, 67, 0.87)'
  }"
>
  <mat-label>{{ _label }}</mat-label>
  <input
    matInput
    type="text"
    [id]="id"
    [name]="to?.name || this.field.id"
    [formlyAttributes]="this.field"
    [placeholder]="to?.placeholder || _placeholder"
    [(ngModel)]="to?.model || this._value"
    [tabIndex]="to?.tabindex"
    [required]="to?.required || _required"
    [disabled]="this._isDisabled"
    (change)="updateForm($event)"
    (blur)="updateForm($event)"
    (keyup)="handleValidCheck($event)"
  />
  <div matSuffix class="suffix">{{ _suffix | translate }}</div>
  <mat-hint class="mat-error" *ngIf="_invalid"> {{ this._errorMessage | translate }} </mat-hint>
</mat-form-field>
