import {DoAssemblyDirector, Dto} from '@flowtwin/communication';
import {AppVersions} from '../model/communication/app-versions';
import {PROP_COLLECTION_MAP_VERSION} from '@state/state-service/do-mapper/contracts/version';

export class DoMapperVersions {
  static mapToVersions(versions: Dto): AppVersions {
    return new DoAssemblyDirector(versions, PROP_COLLECTION_MAP_VERSION)
      .createInstance<AppVersions>(AppVersions)
      .assembleDo<AppVersions>()
      .collect();
  }
}
