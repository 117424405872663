import {convertKeyValueArrayIntoObject, DoAssemblyDirector, Dto, DtoDoPropCollectionMap} from '@flowtwin/communication';
import {GasCompositionDoc} from '../model/gas/gas-composition-doc';
import {PROP_COLLECTION_MAP_APP_UNCERTAINTY_OUTPUT} from '@state/state-service/do-mapper/contracts/app-uncertainty';
import {AppUncertaintyInput, AppUncertaintyOutput} from '@state/state-service/do-mapper/model/apps/app-uncertainty';

export class DoMapperUncertainty {
  static mapToAppUncertaintyInput(domainContract: DtoDoPropCollectionMap[], typedDto: Dto): AppUncertaintyInput {
    // TODO @Arnold: General observation of the DoAssemblyDirector usage in all the mappers: The type of the converted object is any.
    //  It seems that it is sometimes an object of the type specified in the generic functions, sometimes an array of that object.
    //  If we can make that functionality typesafe, it should clear up a lot of compilation errors in this and the other mapper files.
    const doConverted = new DoAssemblyDirector(typedDto, domainContract)
      .createInstance<AppUncertaintyInput>(AppUncertaintyInput)
      .assembleDo<AppUncertaintyInput>()
      .collect();

    doConverted.processDataEntries = doConverted.processDataEntries?.map(element => convertKeyValueArrayIntoObject(element)) as any;

    doConverted.parameterSetup.deviceUncertaintySetups = doConverted.parameterSetup.deviceUncertaintySetups?.map(element =>
      convertKeyValueArrayIntoObject(element)
    ) as any;

    const gasCompositions: GasCompositionDoc[] = [];
    doConverted.gasCompositions?.forEach(element => {
      element = convertKeyValueArrayIntoObject(element);
      const gasCompositionDoc: GasCompositionDoc = {
        document: {id: '', name: '', deleted: false, userList: []},
        gasComposition: undefined
      };
      gasCompositionDoc.document.id = element.id;
      delete element.id;
      delete element?.gasCompositionDocId;
      gasCompositionDoc.gasComposition = element;
      gasCompositions.push(gasCompositionDoc);
    });
    delete doConverted?.gasCompositions;
    return doConverted;
  }

  static mapToAppUncertaintyOutput(uncertaintyOutput: Dto): AppUncertaintyOutput {
    return new DoAssemblyDirector(uncertaintyOutput, PROP_COLLECTION_MAP_APP_UNCERTAINTY_OUTPUT)
      .createInstance<AppUncertaintyOutput>(AppUncertaintyOutput)
      .assembleDo<AppUncertaintyOutput>()
      .collect();
  }
}
