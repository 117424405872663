import {StateService} from '../state-service/state.service';
import {DependencyPayload, DependencyReduxAction} from '../actions/actions-dependencies';
import {State} from '@state/model/state.model';
import {clone} from '@shared/utility/clone';
import {mergeWith, isArray} from 'lodash';
import {getDifferenceDeep} from '@shared/utility/get-deep-diff-object';
import {removeEmptyObjects} from '@shared/utility/remove-empty-objects';
import {FlowTwinProject} from '@state/state-service/do-mapper/model/project/project-flow-twin';

export enum AppAliases {
  'selection-sizing' = 'appSizing',
  'uncertainty-prediction' = 'appUncertainty',
  'transducer-performance' = 'appTransducer'
}

export class DependencyReducerService {
  static assignHandler(action: DependencyReduxAction, stateService: StateService) {
    switch (action.type) {
      case 'GET_DEPENDENCY_FOR_PRODUCT':
        break;
      case 'GET_DEPENDENCY_FOR_SIZING':
        break;
      case 'GET_DEPENDENCY_FOR_UNCERTAINTY':
        DependencyReducerService.updateInputForUncertainty(action, stateService);
        break;
      case 'GET_DEPENDENCY_FOR_TRANSDUCER':
        DependencyReducerService.updateInputForTransducer(action, stateService);
        break;
    }
  }

  private static updateInputForUncertainty(action: DependencyReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        DependencyReducerService.mergeDependenciesWithExistingState(
          action.payload.app,
          s,
          (action.payload as DependencyPayload).dependencies
        );
      },
      {
        type: `Dependency Reducer - ${action.type}`
      }
    );
  }

  private static updateInputForTransducer(action: DependencyReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        DependencyReducerService.mergeDependenciesWithExistingState(
          action.payload.app,
          s,
          (action.payload as DependencyPayload).dependencies
        );
      },
      {
        type: `Dependency Reducer - ${action.type}`
      }
    );
  }

  private static mergeDependenciesWithExistingState(app: keyof FlowTwinProject, s: State, dependencies: Array<{key: string; value: any}>) {
    const dependency = dependencies.reduce((obj, item) => Object.assign(obj, {[item.key]: item.value}), {});

    if (app === 'appProject' || app === 'document' || app === 'meta' || app === 'custom') {
      return;
    }

    const input = mergeWith(clone(s.project.data[app].input), dependency, (a, b) => (isArray(b) ? b : undefined));

    const diff = removeEmptyObjects(getDifferenceDeep(s.project.data[app].input, input));

    if (Object.keys(diff).length > 0) {
      s.project.data[app].input = input;
    }
  }
}
