import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {UpgradeReduxAction} from '../actions/actions-upgrade';
import {UpgradeEpicService} from '../epics/upgrade-epic.service';

@Injectable({
  providedIn: null
})
export class UpgradeReducerService {
  static assignHandler(action: UpgradeReduxAction, stateService: StateService, epic: UpgradeEpicService) {
    switch (action.type) {
      case 'UPGRADE_PROJECT_LOAD':
        UpgradeReducerService.setStateOfUpgradeProcess(action, stateService);
        epic.updateProject(action, stateService);
        break;
      case 'UPGRADE_PROJECT_SUCCESS':
        UpgradeReducerService.setStateOfUpgradeProcess(action, stateService);
        break;
      case 'UPGRADE_PROJECT_ERROR':
        UpgradeReducerService.setStateOfUpgradeProcess(action, stateService);
        break;
    }
  }

  private static setStateOfUpgradeProcess(action: UpgradeReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        // TODO @Arnold: This todo is from some time ago, please check if it is still valid
        // TODO: Replace Old with new Project, when everything works
        s.projects.upgrade.state = action.payload.state;
      },
      {type: `Upgrade-State was set to ${action.payload.state}.`}
    );
  }
}
