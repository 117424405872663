import {DomainContract} from '@flowtwin/communication';
import {User} from '../../do-mapper/model/communication/user';

export const CONTRACT_UPDATE_USER = (user: User): DomainContract[] => {
  return [
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.id',
      PATHINDATATRANSFEROBJECT: 'id'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.email',
      PATHINDATATRANSFEROBJECT: 'email'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.familyName',
      PATHINDATATRANSFEROBJECT: 'familyName'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.givenName',
      PATHINDATATRANSFEROBJECT: 'givenName'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.name',
      PATHINDATATRANSFEROBJECT: 'name'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.admin',
      PATHINDATATRANSFEROBJECT: 'permissions.admin'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.supervisor',
      PATHINDATATRANSFEROBJECT: 'permissions.supervisor'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.gbc04expert',
      PATHINDATATRANSFEROBJECT: 'permissions.gbc04expert'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.gbc04service',
      PATHINDATATRANSFEROBJECT: 'permissions.gbc04service'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.gbc04sales',
      PATHINDATATRANSFEROBJECT: 'permissions.gbc04sales'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.sicksales',
      PATHINDATATRANSFEROBJECT: 'permissions.sicksales'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.externalpartner',
      PATHINDATATRANSFEROBJECT: 'permissions.externalpartner'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.externalservice',
      PATHINDATATRANSFEROBJECT: 'permissions.externalservice'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.externalcustomer',
      PATHINDATATRANSFEROBJECT: 'permissions.externalcustomer'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.locale',
      PATHINDATATRANSFEROBJECT: 'locale'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.staredProjects',
      PATHINDATATRANSFEROBJECT: 'staredProjects'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'externalPermissions.apps',
      PATHINDATATRANSFEROBJECT: 'externalPermissions.apps'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'externalPermissions.productFamilies',
      PATHINDATATRANSFEROBJECT: 'externalPermissions.productFamilies'
    }
  ];
};

export const CONTRACT_POST_USER = (user: User): DomainContract[] => {
  return [
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.id',
      PATHINDATATRANSFEROBJECT: 'id'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.name',
      PATHINDATATRANSFEROBJECT: 'name'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.email',
      PATHINDATATRANSFEROBJECT: 'email'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.familyName',
      PATHINDATATRANSFEROBJECT: 'familyName'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.givenName',
      PATHINDATATRANSFEROBJECT: 'givenName'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.admin',
      PATHINDATATRANSFEROBJECT: 'permissions.admin'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.supervisor',
      PATHINDATATRANSFEROBJECT: 'permissions.supervisor'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.gbc04expert',
      PATHINDATATRANSFEROBJECT: 'permissions.gbc04expert'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.gbc04service',
      PATHINDATATRANSFEROBJECT: 'permissions.gbc04service'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.gbc04sales',
      PATHINDATATRANSFEROBJECT: 'permissions.gbc04sales'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.sicksales',
      PATHINDATATRANSFEROBJECT: 'permissions.sicksales'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.externalpartner',
      PATHINDATATRANSFEROBJECT: 'permissions.externalpartner'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.externalservice',
      PATHINDATATRANSFEROBJECT: 'permissions.externalservice'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.permissions.externalcustomer',
      PATHINDATATRANSFEROBJECT: 'permissions.externalcustomer'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.locale',
      PATHINDATATRANSFEROBJECT: 'locale'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.staredProjects',
      PATHINDATATRANSFEROBJECT: 'staredProjects'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.externalPermissions.apps',
      PATHINDATATRANSFEROBJECT: 'externalPermissions.apps'
    },
    {
      DOMAINOBJECT: user,
      PATHINDOMAINOBJECT: 'document.externalPermissions.productFamilies',
      PATHINDATATRANSFEROBJECT: 'externalPermissions.productFamilies'
    }
  ];
};
