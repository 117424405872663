export function isPrimitive(val): boolean {
  if (val === null) {
    return true;
  }

  if (typeof val == 'object' || typeof val == 'function') {
    return false;
  } else {
    return true;
  }
}
