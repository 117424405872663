<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header>
    <div class="panel-header" [ngClass]="{invalid: !valid}">
      <div class="number counter" *ngIf="to.label"></div>
      {{ to.label | translate }}
    </div>
  </mat-expansion-panel-header>

  <ng-container #fieldComponent></ng-container>
</mat-expansion-panel>
