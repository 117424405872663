import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor() {}

  // Doesn't throw an error if a field is missing
  updateFormSloppy(form: UntypedFormGroup, newValue: any) {
    form.patchValue(newValue);
  }

  // throws an error if a field is missing
  updateFormStrictly(form: UntypedFormGroup, newValue: any) {
    form.setValue(newValue);
  }
}
