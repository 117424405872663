import {Injectable} from '@angular/core';
import {ComputeCommandReq_DTO, ComputeCommandRes_DTO} from '../state-service/dto-mapper/model/communication/compute-commands-dto';
import {uuid} from '@shared/utility/uuid';
import {OkResponse} from '../state-service/do-mapper/model/communication/commands';
import {map} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {StateService} from '@state/state-service/state.service';
import {OkResponse_DTO} from '@state/state-service/dto-mapper/model/communication/response-dto';
import {ErrorService} from '@state/state-service/error.service';
import {
  ApiComputeGasLoadPayload,
  ApiComputeTransducerPerformanceLoadPayload,
  ApiComputeTransducerPerformanceSuccessPayload,
  ApiComputeUncertaintyLoadPayload,
  ComputeReduxAction
} from '@state/actions/actions-compute';
import {GenericModalPayload} from '@state/actions/actions-modal';
import {RootDtoMapper} from '@state/state-service/dto-mapper/root-dto.mapper';
import {Dto} from '@flowtwin/communication';
import {DoMapperProjectResults} from '@state/state-service/do-mapper/mappers/project-results';
import {ILogger, LoggerConfig, LoggerService, LogLevel} from '../../services/logger/logger.service';
import {DoMapperSizing} from '@state/state-service/do-mapper/mappers/app-sizing';
import {Metadata} from '@state/state-service/do-mapper/model/project/metadata';
import {DoMapperUncertainty} from '@state/state-service/do-mapper/mappers/app-uncertainty';
import {SubDtoMapperAppSizing} from '@state/state-service/dto-mapper/sub-mappers/app-sizing';
import {PROP_COLLECTION_MAP_APP_TRANSDUCER_PERFORMANCE_OUTPUT} from '@state/state-service/do-mapper/contracts/app-transducer';
import {PROP_COLLECTION_MAP_COMPUTE_SIZING} from '@state/state-service/do-mapper/contracts/app-sizing';
import {SubDtoMapperAppTransducer} from '@state/state-service/dto-mapper/sub-mappers/app-transducer';
import {concatTwoArraysUnique} from '@shared/utility/concat-two-arrays-unique';
import {ApiErrorHandleConfig, handleApiError} from '@state/epics/utility/handle-api-error';
import {AppModePayload} from '@state/actions/actions-app-mode';

const loggerConfig: LoggerConfig = {
  name: '-',
  symbol: '🧮',
  textColor: 'black',
  backgroundColor: '#00F5D4'
};

const URL_COMPUTE_GAS_PROPERTIES = environment.url.aws + '/compute-gasproperties';
const URL_COMPUTE_UNCERTAINTY = environment.url.aws + '/compute-uncertainty';
const URL_COMPUTE_TRANSDUCER = environment.url.aws + '/compute-transducerperformance';

@Injectable({
  providedIn: 'root'
})
export class ComputeEpicService {
  private logger: ILogger;

  constructor(private http: HttpClient, private errorService: ErrorService, private loggerService: LoggerService) {
    this.logger = this.initLogger();
  }

  private initLogger() {
    loggerConfig.name = this.constructor.name;
    return this.loggerService.initLogger(loggerConfig);
  }

  async postComputeUncertainty(action: ComputeReduxAction, stateService: StateService) {
    try {
      const dto = RootDtoMapper.mapToComputeUncertainty(
        (action.payload as ApiComputeUncertaintyLoadPayload).input,
        (action.payload as ApiComputeUncertaintyLoadPayload).gases,
        (action.payload as ApiComputeUncertaintyLoadPayload).metadata
      );

      const locale = stateService.state.user.document.locale;

      await stateService.loggedIn$.toPromise();

      const req: ComputeCommandReq_DTO = {
        requestId: uuid(),
        locale,
        command: 'compute-uncertainty',
        dto
      };

      const result = await this.http
        .post<OkResponse<ComputeCommandRes_DTO>>(URL_COMPUTE_UNCERTAINTY, req)
        .pipe(map(res => res.data))
        .toPromise();

      this.logger.log('', 'compute-uncertainty', LogLevel.Info, [{Request: req}, {Response: result}, {Errors: result.errors}]);

      if (Array.isArray(result.errors) && result.errors.length > 0) {
        return;
      }

      const resultDo = DoMapperUncertainty.mapToAppUncertaintyOutput(result as any);

      const successAction: ComputeReduxAction = new ComputeReduxAction('COMPUTE_UNCERTAINTY_SUCCESS', {results: resultDo});

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'COMPUTE_UNCERTAINTY_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async postComputeSizing(action: ComputeReduxAction, stateService: StateService) {
    try {
      const reqDto: Dto = SubDtoMapperAppSizing.mapToComputeSizing(
        (action.payload as ApiComputeGasLoadPayload).appSizingInput,
        stateService.state.project.metadata as Metadata,
        stateService.state.basicProject.data?.custom?.gasCompositionDocs ?? []
      );

      const res = await this.http.post<OkResponse_DTO<ComputeCommandReq_DTO>>(URL_COMPUTE_GAS_PROPERTIES, reqDto).toPromise();

      this.logger.log('', 'compute-gas-props', LogLevel.Info, [{Request: reqDto}, {Response: res}]);

      const computeGasResp = DoMapperSizing.mapToAppSizingComputeGas(PROP_COLLECTION_MAP_COMPUTE_SIZING, res.data as any);

      const successAction: ComputeReduxAction = new ComputeReduxAction('COMPUTE_GASES_SUCCESS', {
        gasData: computeGasResp.gasdata,
        ccus: computeGasResp.ccus,
        lvf: computeGasResp.lvf,
        warnings: computeGasResp.warnings
      });
      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'COMPUTE_GASES_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async postComputeTransducer(action: ComputeReduxAction, stateService: StateService) {
    try {
      const projectDTO = SubDtoMapperAppTransducer.mapToComputeTransducer(
        (action.payload as ApiComputeTransducerPerformanceLoadPayload).input,
        (action.payload as ApiComputeTransducerPerformanceLoadPayload).user,
        concatTwoArraysUnique(
          (action.payload as ApiComputeTransducerPerformanceLoadPayload).gases,
          (action.payload as ApiComputeTransducerPerformanceLoadPayload).customGases,
          'document.id'
        )
      );

      const req: ComputeCommandReq_DTO = {
        requestId: uuid(),
        locale: '',
        command: 'compute-transducer-performance',
        dto: projectDTO
      };

      const res = await this.http.post<OkResponse_DTO<ComputeCommandReq_DTO>>(URL_COMPUTE_TRANSDUCER, req).toPromise();

      this.logger.log('', 'compute-transducer-performance', LogLevel.Info, [{Request: req}, {Response: res}]);

      const domainObject = DoMapperProjectResults.mapToAppTransducerPerformanceComputedResults(
        PROP_COLLECTION_MAP_APP_TRANSDUCER_PERFORMANCE_OUTPUT,
        // TODO @Arnold: result does not exist on res.data according to the type definition
        res.data['result']
      );

      const successAction: ComputeReduxAction = new ComputeReduxAction('COMPUTE_TRANSDUCER_PERFORMANCE_SUCCESS', {
        results: domainObject
      } as ApiComputeTransducerPerformanceSuccessPayload);
      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'COMPUTE_TRANSDUCER_PERFORMANCE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }
}
