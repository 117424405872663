import {DtoDoPropCollectionMap} from '@flowtwin/communication';

export const PROP_COLLECTION_MAP_GASES_GET: DtoDoPropCollectionMap[] = [
  {dtoKey: 'document.id', doKey: 'document.id'},
  {dtoKey: 'document.name', doKey: 'document.name'},
  {dtoKey: 'document.description', doKey: 'document.description'},
  {dtoKey: 'document.creator', doKey: 'document.creator'},
  {dtoKey: 'document.deleted', doKey: 'document.deleted'},
  {dtoKey: 'document.userList', doKey: 'document.userList'},
  {dtoKey: 'gasComposition', doKey: 'gasComposition'}
];
