import {ArrayElementTypeAnnotation, TypeAnnotation} from '../../dto-assembly-line/model/dto';
import {DtoQuantity} from '../../dto-assembly-line/model/dto-quantity';
import {DtoKeyValueItem} from '../../dto-assembly-line/model/dto-key-value-item';
import {DtoError} from '../../dto-assembly-line/model/dto-error';
import {DtoDate} from '../../dto-assembly-line/model/dto-date';

export function getLibraryKnownType(type: TypeAnnotation | ArrayElementTypeAnnotation, item: any, callback: any = null) {
  if (isUndefined(item)) return undefined;
  if (isNull(item)) return null;

  switch (type) {
    case 'STRING':
      return item.toString();
    case 'NUMBER':
      return Number(item);
    case 'BOOLEAN':
      return Boolean(item);
    case 'NULL':
      return null;
    case 'DTOQUANTITY':
      return new DtoQuantity(item['unit'], item['value'], item['description']);
    case 'DTOKEYVALUEITEM':
      return new DtoKeyValueItem(item['key'], item['value']);
    case 'DTOERROR':
      return new DtoError(item['severity'], item['globalMessage'], item['details']);
    case 'DTODATE':
      return new DtoDate(item['format'], item['value']);
    default:
      return callback ? callback() : item;
  }
}

function isUndefined(item: any) {
  return item === undefined || item === 'undefined';
}

function isNull(item: any) {
  return item === null || item === 'null';
}
