import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RoleGuardService} from '@core/guards/user-role-guard.service';
import {LoginErrorGuardService} from '@core/guards/login-error-guard.service';
import {projectsGuard} from '@core/guards/projects.guard';
import {userLoggedInGuard} from '@core/guards/user-logged-in.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'projects',
    pathMatch: 'full',
    data: {
      hideInEnv: ['prod', 'uat', 'local', 'dev']
    }
  },
  {
    path: 'service',
    loadChildren: () => import('@pages/app-modes/app-modes.module').then(m => m.AppModesModule),
    data: {
      hideInEnv: ['prod', 'uat', 'local', 'dev']
    }
  },
  {
    path: 'projects',
    loadChildren: () => import('@pages/projects/projects-page.module').then(m => m.ProjectsPageModule),
    data: {
      sideNavSlot: 1
    },
    canActivate: [userLoggedInGuard, projectsGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('@pages/users/users-page.module').then(m => m.UsersPageModule),
    data: {
      expectedRoles: ['admin'],
      sideNavSlot: 4
    },
    canActivate: [RoleGuardService]
  },
  {
    path: 'gases',
    loadChildren: () => import('@pages/gases/gases-page.module').then(m => m.GasesPageModule),
    data: {
      sideNavSlot: 3
    },
    canActivate: [userLoggedInGuard]
  },
  {
    path: 'no-flow-twin-user',
    loadChildren: () => import('@pages/no-flow-twin-user-page/no-flow-twin-user-page.module').then(m => m.NoFlowTwinUserPageModule),
    data: {
      hideInEnv: ['prod', 'uat', 'local', 'dev']
    }
  },
  {
    path: 'no-access',
    loadChildren: () => import('@pages/no-access/no-access-page.module').then(m => m.NoAccessPageModule),
    data: {
      hideInEnv: ['prod', 'uat', 'local', 'dev']
    }
  },
  {
    path: 'mock',
    loadChildren: () => import('@pages/mock/mock-page.module').then(m => m.MockPageModule),
    data: {
      expectedRoles: ['admin'],
      sideNavSlot: 5,
      hideInEnv: ['prod', 'uat']
    },
    canActivate: [RoleGuardService]
  },
  {
    path: 'custom-units',
    loadChildren: () => import('@pages/custom-units/custom-units-page.module').then(m => m.CustomUnitsPageModule),
    data: {
      sideNavSlot: 2
    },
    canActivate: [userLoggedInGuard]
  },
  {
    path: 'login-error',
    loadComponent: () => import('@pages/errors/login-error/login-error.component').then(c => c.LoginErrorComponent),
    canActivate: [LoginErrorGuardService],
    data: {
      hideInEnv: ['prod', 'uat', 'local', 'dev']
    }
  },
  {
    path: 'error',
    loadComponent: () => import('@pages/errors/generic-error/generic-error.component').then(c => c.GenericErrorComponent),
    data: {
      hideInEnv: ['prod', 'uat', 'local', 'dev']
    }
  },
  {
    path: '**', // Wildcard route for a 404 page
    loadChildren: () => import('./pages/not-found/not-found-page.module').then(m => m.NotFoundPageModule),
    data: {
      hideInEnv: ['prod', 'uat', 'local', 'dev']
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
