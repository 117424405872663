import {ReduxAction} from './actions-abstract';
import {UnitSystemDocument} from '@state/state-service/do-mapper/model/unit/unit-system-document';
import {GasCompositionDoc} from '@state/state-service/do-mapper/model/gas/gas-composition-doc';
import {DeviceUncertaintySetup, UncertaintySetup} from '@state/state-service/do-mapper/model/device/properties';
import {ProcessDataEntry} from '@state/state-service/do-mapper/model/artifacts/process-data-entry';
import {CalcMode} from '@state/state-service/do-mapper/model/apps/app-sizing';

export enum Application {
  Sizing = 'sizing',
  Uncertainty = 'uncertainty',
  ApplicationEvaluation = 'app-eval'
}

export type InputActions =
  | 'INPUT_CHANGED'
  | 'PROCESS_FLOW_RANGE_MIN'
  | 'PROCESS_FLOW_RANGE_MAX'
  | 'PROCESS_FLOW_PRESSURE_MIN'
  | 'PROCESS_FLOW_PRESSURE_MAX'
  | 'PROCESS_FLOW_TEMPERATURE_MIN'
  | 'PROCESS_FLOW_TEMPERATURE_MAX'
  | 'MEASURED_FLOW_VALUE'
  | 'INSTALLATION_TYPE'
  | 'NOMINAL_SIZE'
  | 'GAS_COMPOSITION_ID'
  | 'INPUT_PRECISION'
  | 'RESET_INPUT'
  | 'UPDATE_PROJECT_INPUT'
  | 'PROJECT_SPECIFIC_CUSTOM_UNIT_SYSTEM'
  | 'PROJECT_SPECIFIC_GAS_COMPOSITION'
  | 'PROCESS_CONDITION'
  | 'UNIT_SYSTEM_ID'
  | 'GAS_VELOCITIES'
  | 'PARAMETER_SETUP'
  | 'SENSOR_TYPE'
  | 'METER_ENTRY'
  | 'EXPLOSION_GROUP'
  | 'SENSOR_GAS_SELECTION_METHOD'
  | 'PRODUCT_FAMILY'
  | 'SET_DEVICE_UNCERTAINTY_SETUPS'
  | 'CALC_MODE';

export interface InputActionPayloadGeneric {
  input: any;
  oldValue?: any;
}

export interface InputRangeSliderPayload {
  control: string;
  value: {
    unit: string;
    value: number;
    description: string;
  };
}

export interface UpdateProjectInputPayload {
  path: string;
  newInputValues: any;
}

export interface ResetInputPayload {
  application: Application;
}

export interface ProjectSpecificUnitSystemPayload {
  unitSystem: UnitSystemDocument;
}

export interface ProjectSpecificGasCompositionsPayload {
  gas: GasCompositionDoc;
}

export interface ProcessConditionPayload {
  processDataEntries: ProcessDataEntry[];
}

export interface GasVelocitiesPayload {
  gasVelocities: any;
}

export interface ParameterSetupPayload {
  parameterSetup: UncertaintySetup;
}

export interface CalcModePayload {
  calcMode: CalcMode;
}

export interface DeviceUncertaintySetupsPayload {
  deviceUncertainties: DeviceUncertaintySetup[];
}

export interface InputChanged {}

export type InputActionPayloads =
  | InputActionPayloadGeneric
  | InputRangeSliderPayload
  | ResetInputPayload
  | UpdateProjectInputPayload
  | ProjectSpecificUnitSystemPayload
  | ProjectSpecificGasCompositionsPayload
  | ProcessConditionPayload
  | GasVelocitiesPayload
  | CalcModePayload
  | ParameterSetupPayload
  | DeviceUncertaintySetupsPayload
  | InputChanged;

export class InputReduxAction extends ReduxAction {
  type: InputActions;
  payload: InputActionPayloads;

  constructor(type: InputActions, payload: InputActionPayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
