import {DtoContract} from '../../contracts';

export const REQ_POST_CREATE_TAG = {
  config: [
    {KEY: 'name', TYPE: 'STRING'},
    {KEY: 'description', TYPE: 'STRING'},
    {KEY: 'projectId', TYPE: 'STRING'}
  ] as DtoContract[]
};

export const REQ_POST_TAG = {
  config: [
    {KEY: 'id', TYPE: 'STRING'},
    {KEY: 'name', TYPE: 'STRING'},
    {KEY: 'description', TYPE: 'STRING'}
  ] as DtoContract[]
};
