import {Injectable, OnDestroy} from '@angular/core';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {StateService} from '@state/state-service/state.service';
import {AppSizingInput, CalcMode} from '@state/state-service/do-mapper/model/apps/app-sizing';
import {UUID} from '@state/state-service/do-mapper/model/communication/type-alias';
import {InputReduxAction} from '@state/actions/actions-input';
import {isDefined} from '@shared/utility/isDefined';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CalcModeService implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(private stateService: StateService, private router: Router) {}

  initService() {
    const appSizingInputsChanged$ = this.stateService.state$.pipe(
      map(s => s.project.data?.appSizing?.input),
      distinctUntilChanged((a, b) => a?.productFamily === b?.productFamily && a?.gasCompositionId === b?.gasCompositionId),
      filter(appSizing => isDefined(appSizing) && appSizing.hasOwnProperty('productFamily') && appSizing.hasOwnProperty('gasCompositionId'))
    );

    const setCalcMode = appSizingInputsChanged$.subscribe(inputs => {
      const currentCalcMode = this.setCalcModeForSizing(this.getDependingInputs(inputs));
      this.setCalcMode(currentCalcMode);
    });

    this.subscriptions.add(setCalcMode);
  }

  private getDependingInputs(appSizing: AppSizingInput): {
    productFamily: string | null;
    gasCompositionId: UUID | null;
    flowRangeOption: string | null;
  } {
    return {
      productFamily: appSizing?.productFamily,
      gasCompositionId: appSizing?.gasCompositionId,
      flowRangeOption: appSizing?.flowRangeOption
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getCalcMode(): CalcMode | undefined {
    return this.stateService.state.project.data?.appSizing?.input?.calcMode;
  }

  private setCalcMode(calcMode: CalcMode): void {
    const setCalcModeAction: InputReduxAction = new InputReduxAction('CALC_MODE', {calcMode});

    this.stateService.dispatch(setCalcModeAction);
  }

  setCalcModeForSizing(inputs: {
    productFamily: string | null;
    gasCompositionId: UUID | null;
    flowRangeOption: string | null;
  }): CalcMode | null {
    const gas = this.stateService.state.gases.data.find(gasComp => gasComp.document.id === inputs.gasCompositionId);
    const isFL6XT = inputs.productFamily === 'FL600XT';
    const isFL100 = inputs.productFamily === 'FL100FlareXT';
    const isFL500 = inputs.productFamily === 'FLOWSIC500';
    const isOIML = inputs.flowRangeOption === 'OIML 0.5';
    const isCo2Only = gas?.gasComposition?.carbonDioxide?.value === 100;

    if (isFL100) {
      return 'sizing-fl100';
    } else if (isFL6XT && isOIML) {
      return 'sizing-fl6xt-oiml';
    } else if (isFL6XT && !isCo2Only) {
      return 'sizing-fl6xt';
    } else if (isFL6XT && isCo2Only) {
      return 'ccus-fl6xt';
    } else if (isFL500) {
      return 'sizing-fl500';
    } else {
      return null;
    }
  }
}
