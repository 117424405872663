import {Injectable} from '@angular/core';
import {OkResponse} from '@state/state-service/do-mapper/model/communication/commands';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {ApiPayloadGeneric, UserReduxAction} from '@state/actions/actions-user';
import {StateService} from '@state/state-service/state.service';
import {map} from 'rxjs/operators';
import {User_DTO} from '@state/state-service/dto-mapper/model/communication/user-dto';
import {ErrorService} from '@state/state-service/error.service';
import {GenericModalPayload} from '@state/actions/actions-modal';
import {CONTRACTS, Dto, DtoAssemblyDirector, DtoDefinition} from '@flowtwin/communication';
import {clone} from '@shared/utility/clone';
import {DoMapperUser} from '@state/state-service/do-mapper/mappers/user';
import {CONTRACT_POST_USER, CONTRACT_UPDATE_USER} from '@state/state-service/dto-mapper/domain-contracts/user';
import {ILogger, LoggerConfig, LoggerService, LogLevel} from '../../services/logger/logger.service';
import {Router} from '@angular/router';
import {ApiErrorHandleConfig, handleApiError} from '@state/epics/utility/handle-api-error';

const url = environment.url.aws;

const loggerConfig: LoggerConfig = {
  name: '-',
  symbol: '👤',
  textColor: 'black',
  backgroundColor: '#babfff'
};

@Injectable({
  providedIn: 'root'
})
export class UserEpicService {
  private logger: ILogger;

  constructor(private http: HttpClient, private errorService: ErrorService, private loggerService: LoggerService, private router: Router) {
    this.logger = this.initLogger();
  }

  private initLogger() {
    loggerConfig.name = this.constructor.name;
    return this.loggerService.initLogger(loggerConfig);
  }

  async getUserAll(action: UserReduxAction, stateService: StateService) {
    try {
      const result = await this.http
        .get<OkResponse<Dto>>(url + '/users')
        .pipe(
          map(res => {
            if (res.data.payload.length === 0) {
              return [];
            }
            return DoMapperUser.mapToUsers(res.data);
          })
        )
        .toPromise();

      const successAction: UserReduxAction = new UserReduxAction('USER_GET_ALL_SUCCESS', {
        users: result
      });

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorConfig: Pick<GenericModalPayload, 'modal'> = {
        modal: {
          config: {
            message: error.message || JSON.stringify({error}, null, 2),
            error,
            cause: 'Could not load users.'
          }
        }
      };

      const originErrorAction = new UserReduxAction('USER_GET_ALL_ERROR', null);

      this.errorService.performUnifiedErrorHandling(errorConfig, originErrorAction, stateService);
    }
  }

  async getCurrentUser(action: UserReduxAction, stateService: StateService) {
    try {
      const result = await this.http
        .get<OkResponse<any>>(url + '/me')
        .pipe(
          map(meResponse => {
            return DoMapperUser.mapToUser(meResponse.data);
          })
        )
        .toPromise();

      const successAction: UserReduxAction = new UserReduxAction('USER_GET_CURRENT_SUCCESS', {user: result});

      stateService.dispatch(successAction);
    } catch (error: any) {
      // TODO @Arnold: We need to overthink the type/usage of UserReduxAction. We can either make the payload nullable or we need to create a new action or UserPayloads type.
      let errorUser = new UserReduxAction('USER_GET_CURRENT_ERROR', null);
      if (error.status === 404) {
        this.logger.log('Current user is not a flow twin user', null, LogLevel.Warn, error);
        // Clear session storage to remove any sick id references so the user can log in with a different account
        sessionStorage.clear();
        this.router.navigate(['/no-flow-twin-user']);
        errorUser = new UserReduxAction('USER_GET_CURRENT_NOT_FLOW_TWIN', null);
      } else {
        this.logger.log('Could not get current user', null, LogLevel.Error, error);
      }
      stateService.dispatch(errorUser);
    }
  }

  async updateUser(action: UserReduxAction, stateService: StateService) {
    try {
      const user = {
        ...(action.payload as ApiPayloadGeneric).user
      };

      const userDto = new DtoAssemblyDirector(
        'ME_POST' as DtoDefinition,
        CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_ME.config,
        CONTRACT_UPDATE_USER(user)
      )
        .assembleDto()
        .collect();

      await this.http.post<OkResponse<any>>(url + '/me', userDto).toPromise();

      const successAction: UserReduxAction = new UserReduxAction('USER_UPDATE_CURRENT_SUCCESS', {
        user: clone((action.payload as ApiPayloadGeneric).user)
      });
      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'USER_UPDATE_CURRENT_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async postUser(action: UserReduxAction, stateService: StateService) {
    try {
      // TODO: domain object does not have a name property ????
      (action.payload as any).user.document.name = '';
      const user = {
        ...(action.payload as ApiPayloadGeneric).user
      };

      const dto = new DtoAssemblyDirector(
        'USERS_POST' as DtoDefinition,
        CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_USERS.config,
        CONTRACT_POST_USER(user)
      )
        .assembleDto()
        .collect();

      await this.http.post<OkResponse<User_DTO>>(url + '/users', dto).toPromise();

      const successAction: UserReduxAction = new UserReduxAction('USER_POST_SUCCESS', {user: (action.payload as ApiPayloadGeneric).user});

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'USER_POST_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async deleteUser(action: UserReduxAction, stateService: StateService) {
    try {
      const userId = (action.payload as ApiPayloadGeneric).user?.document.id;

      await this.http.delete<OkResponse<User_DTO>>(url + '/users/' + userId).toPromise();

      const successAction: UserReduxAction = new UserReduxAction('USER_DELETE_SUCCESS', {
        user: (action.payload as ApiPayloadGeneric).user
      });

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'USER_DELETE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }
}
