import {DtoContract} from '../../contracts';
import {EXTERNAL_USER_PERMISSIONS, USER_DOCUMENT, USER_PERMISSIONS} from '../../base-definitions/user';

export const REQ_GET_ME = {
  config: [{}] as DtoContract[]
};
export const REQ_POST_ME = {
  config: [...USER_DOCUMENT, ...USER_PERMISSIONS, ...EXTERNAL_USER_PERMISSIONS] as DtoContract[]
};

export const REQ_GET_USERS = {
  config: [{}] as DtoContract[]
};
export const REQ_POST_USERS = {
  config: [...USER_DOCUMENT, ...USER_PERMISSIONS, ...EXTERNAL_USER_PERMISSIONS] as DtoContract[]
};
