import {NgModule} from '@angular/core';
import {CookieService} from '@shared/cookies/cookies.service';
import {ReleaseNotesComponent} from '@shared/release-notes/release-notes/release-notes.component';
import {DialogModule} from '@ui/dialog/dialog.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MaterialModule} from '@core/material/material.module';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [ReleaseNotesComponent],
  imports: [DialogModule, TranslateModule, MatButtonModule, MaterialModule, CommonModule, ReactiveFormsModule],
  exports: [ReleaseNotesComponent],
  providers: [CookieService]
})
export class ReleaseNotesModule {}
