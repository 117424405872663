import {FlowTwinProject} from '../../state-service/do-mapper/model/project/project-flow-twin';
import {SAVE_STATE} from '../../model/state.model';
import {ApiPayloadSaveOneSuccess, ProjectReduxAction} from '../../actions/actions-project';
import {StateService} from '../../state-service/state.service';

export function successHandler(project: FlowTwinProject, stateService: StateService) {
  const resultState: SAVE_STATE = 'saved';

  const successAction: ProjectReduxAction = new ProjectReduxAction('PROJECT_SAVE_SUCCESS', {
    project,
    saveState: resultState
  } as ApiPayloadSaveOneSuccess);

  stateService.dispatch(successAction);
}
