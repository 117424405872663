import {AuthConfig} from 'angular-oauth2-oidc';

// tslint:disable-next-line: variable-name

const url = window.location.origin;

export const oidcConfig: AuthConfig = {
  issuer: 'https://id.uat.sick.com/auth/realms/sickservices',
  clientId: 'FlowsicDesignApplication',
  redirectUri: url + '/projects',
  // silentRefreshRedirectUri: url + '/silent-refresh.html',
  // scope: 'openid profile email offline_access',
  // requireHttps: false,
  responseType: 'code',
  showDebugInformation: true
};
