import {DtoDoPropCollectionMap} from '@flowtwin/communication';

export const PROP_COLLECTION_MAP_APP_UNCERTAINTY_INPUT: DtoDoPropCollectionMap[] = [
  {dtoKey: 'processDataEntries', doKey: 'processDataEntries'},
  {dtoKey: 'explanation', doKey: 'remarks'},
  {dtoKey: 'userLimits.lowFlowUncertainty', doKey: 'userLimits.lowFlowUncertainty'},
  {dtoKey: 'userLimits.lowFlowLimit', doKey: 'userLimits.lowFlowLimit'},
  {dtoKey: 'userLimits.highFlowUncertainty', doKey: 'userLimits.highFlowUncertainty'},
  {dtoKey: 'velocities', doKey: 'velocities'},
  {dtoKey: 'parameterSetup.deviceUncertaintySetups', doKey: 'parameterSetup.deviceUncertaintySetups'},
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming',
    doKey: 'parameterSetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.configuration.unpairedProbesTiming',
    doKey: 'parameterSetup.extendedUncertaintySetup.configuration.unpairedProbesTiming'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty',
    doKey: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty',
    doKey: 'parameterSetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomPathAngle',
    doKey: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomPathAngle'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.configuration.customPathAngle',
    doKey: 'parameterSetup.extendedUncertaintySetup.configuration.customPathAngle'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.configuration.biasNozzleDistance',
    doKey: 'parameterSetup.extendedUncertaintySetup.configuration.biasNozzleDistance'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated',
    doKey: 'parameterSetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.configuration.nominalDiameter',
    doKey: 'parameterSetup.extendedUncertaintySetup.configuration.nominalDiameter'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.configuration.pathCount',
    doKey: 'parameterSetup.extendedUncertaintySetup.configuration.pathCount'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation',
    doKey: 'parameterSetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.postprocessing.useMachUnit',
    doKey: 'parameterSetup.extendedUncertaintySetup.postprocessing.useMachUnit'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation',
    doKey: 'parameterSetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.postprocessing.envelope',
    doKey: 'parameterSetup.extendedUncertaintySetup.postprocessing.envelope'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.parameters.agcUpperLimit',
    doKey: 'parameterSetup.extendedUncertaintySetup.parameters.agcUpperLimit'
  },
  {
    dtoKey: 'parameterSetup.extendedUncertaintySetup.parameters.isentropicComponentKappa',
    doKey: 'parameterSetup.extendedUncertaintySetup.parameters.isentropicComponentKappa'
  }
];

export const PROP_COLLECTION_MAP_APP_UNCERTAINTY_OUTPUT: DtoDoPropCollectionMap[] = [...domainContractUncertainty()];

function domainContractUncertainty(): Array<DtoDoPropCollectionMap> {
  return [
    {dtoKey: 'flowrates', doKey: 'flowrates'},
    {dtoKey: 'velocities', doKey: 'velocities'},
    {dtoKey: 'warning.deviceOutOfSpec', doKey: 'warning.deviceOutOfSpec'},
    {dtoKey: 'warning.implausibleResults', doKey: 'warning.implausibleResults'},
    {dtoKey: 'warning.signalStrengthWeak', doKey: 'warning.signalStrengthWeak'},
    {dtoKey: 'userLimits.machLowFlowLimit', doKey: 'userLimits.machLowFlowLimit'},
    ...buildDomainContractForProcessPoint('processConditionMin', false),
    ...buildDomainContractForProcessPoint('processConditionMax', false),
    ...buildDomainContractForProcessPoint('processCondition1', false),
    ...buildDomainContractForProcessPoint('processCondition2', false),
    ...buildDomainContractForProcessPoint('processCondition3', false),
    ...buildDomainContractForProcessPoint('processCondition4', false),

    ...buildDomainContractForProcessPoint('processConditionMin', true),
    ...buildDomainContractForProcessPoint('processConditionMax', true),
    ...buildDomainContractForProcessPoint('processCondition1', true),
    ...buildDomainContractForProcessPoint('processCondition2', true),
    ...buildDomainContractForProcessPoint('processCondition3', true),
    ...buildDomainContractForProcessPoint('processCondition4', true)
  ] as Array<DtoDoPropCollectionMap>;
}

function buildDomainContractForProcessPoint(conditionName: string, raw = false): Array<DtoDoPropCollectionMap> {
  return [
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.name`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.name`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.active`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.active`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.sos`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.sos`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.agc`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.agc`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.flowrateMax`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.flowrateMax`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.flowrateMaxAsc`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.flowrateMaxAsc`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.uncertaintyQmax`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.uncertaintyQmax`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.uncertaintyQmaxAsc`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.uncertaintyQmaxAsc`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.velocityMax`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.velocityMax`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.velocityMaxAsc`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.velocityMaxAsc`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.machMaxAsc`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.machMaxAsc`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.machMax`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.machMax`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.uncertainties`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.uncertainties`
    },
    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.mach`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.mach`
    },

    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.phaseState`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.phaseState`
    },

    {
      dtoKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.liquidVolumeFraction`,
      doKey: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.liquidVolumeFraction`
    }
  ];
}
