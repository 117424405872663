import {Item, TypeAnnotation} from '../../dto-assembly-line/model/dto';
import {getLibraryKnownType} from '../validation/get-library-known-type';
import {DtoDoPropCollectionMap} from '../do-assembly-line';
import _ from 'lodash';

export function assembleDomainObjectArray(dp: DtoDoPropCollectionMap, dtoItems: Item[], domainObject: any) {
  const domainObjectPath = dp.doKey;
  let resultArray = [];
  dtoItems.forEach(item => {
    const rawType = item.type.match('(?<=<).+?(?=>)')[0] as TypeAnnotation;

    resultArray.push(getLibraryKnownType(rawType, item.value));
  });

  resultArray = resultArray.filter(item => item !== null);

  if (resultArray.length && resultArray.every(x => x === undefined)) {
    resultArray = undefined;
  }

  _.set(domainObject, domainObjectPath, resultArray);
}
