import {defaultState} from '@state/model/default-state';
import {
  DropdownValue,
  PROCESS_CONDITIONS_DEFAULTS_MAX,
  PROCESS_CONDITIONS_DEFAULTS_MIN,
  PRODUCT_FAMILIES,
  ProductFamily
} from '../model/device/properties';
import {DoMapperGasCompositions} from './gas-compositions';
import {isDefined} from '@shared/utility/isDefined';

export class DoMapperMetadata {
  static mapToMetaData(metadata): any {
    return {
      state: 'init',
      flowConditions: metadata?.flowConditions || defaultState.project.metadata.flowConditions,
      installationTypes: metadata?.installationTypes || defaultState.project.metadata.installationTypes,
      measuredFlowValues: metadata?.measuredFlowValues || defaultState.project.metadata.measuredFlowValues,
      nominalSizes: metadata?.nominalSizes || defaultState.project.metadata.nominalSizes,
      nozzleDistances: metadata?.nozzleDistances || defaultState.project.metadata.nozzleDistances,
      pathLayouts: metadata?.pathLayouts || defaultState.project.metadata.pathLayouts,
      productFamilies: DoMapperMetadata.mapToProductFamilies(metadata?.productFamilies as ProductFamily[]),
      referenceConditions: metadata?.referenceConditions || defaultState.project.metadata.referenceConditions,
      segments: metadata?.segments || defaultState.project.metadata.segments,
      uncertainties: metadata?.uncertainties || defaultState.project.metadata.uncertainties,
      uncertaintySetup: metadata?.uncertaintySetup || defaultState.project.metadata.uncertaintySetup,
      processConditionsMin: PROCESS_CONDITIONS_DEFAULTS_MIN,
      processConditionsMax: PROCESS_CONDITIONS_DEFAULTS_MAX,
      pressureClasses: metadata?.pressureClasses || defaultState.project.metadata.pressureClasses,
      productTypes: metadata?.productTypes || defaultState.project.metadata.productTypes,
      innerDiameter: null,
      fullBore: [true, false],
      flowConditioners: metadata?.flowConditioners || [],
      flowRangeOptions: metadata?.flowRangeOptions || [],
      gasTypes: metadata?.gasTypes?.map(gasType => DoMapperGasCompositions.mapToGasCompositionDoc(gasType)),
      sensorTypes: metadata?.sensorTypes || [],
      sensorGasSelectionMethods: metadata?.sensorGasSelectionMethod || [],
      explosionGroups: metadata?.explosionGroups || [],
      default: metadata?.default || [],
      schedules: metadata?.schedules.map(s => s.value) || []
    };
  }

  static mapToProductFamilies(param: ProductFamily[]): DropdownValue<ProductFamily>[] {
    if (!param) {
      return [];
    }

    return param
      .map(fam => {
        return PRODUCT_FAMILIES.find(family => family.value === fam);
      })
      .filter(isDefined);
  }
}
