import {DtoContract} from '../../contracts';

export const RES_POST_APP_UNCERTAINTY_PREDICTION_RESULT = {
  config: [
    {KEY: 'flowrates', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},
    {KEY: 'velocities', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},

    {KEY: 'warning.deviceOutOfSpec', TYPE: 'BOOLEAN'},
    {KEY: 'warning.implausibleResults', TYPE: 'BOOLEAN'},
    {KEY: 'warning.signalStrengthWeak', TYPE: 'BOOLEAN'},
    {KEY: 'userLimits.machLowFlowLimit', TYPE: 'DTOQUANTITY'},

    ...buildContractForProcessPoint('processConditionMin', false),
    ...buildContractForProcessPoint('processConditionMax', false),
    ...buildContractForProcessPoint('processCondition1', false),
    ...buildContractForProcessPoint('processCondition2', false),
    ...buildContractForProcessPoint('processCondition3', false),
    ...buildContractForProcessPoint('processCondition4', false),

    ...buildContractForProcessPoint('processConditionMin', true),
    ...buildContractForProcessPoint('processConditionMax', true),
    ...buildContractForProcessPoint('processCondition1', true),
    ...buildContractForProcessPoint('processCondition2', true),
    ...buildContractForProcessPoint('processCondition3', true),
    ...buildContractForProcessPoint('processCondition4', true)
  ] as DtoContract[]
};

function buildContractForProcessPoint(conditionName: string, raw = false): Array<DtoContract> {
  return [
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.name`, TYPE: 'STRING'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.active`, TYPE: 'BOOLEAN'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.sos`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.agc`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.flowrateMax`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.flowrateMaxAsc`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.uncertaintyQmax`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.uncertaintyQmaxAsc`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.velocityMax`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.velocityMaxAsc`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.machMax`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.machMaxAsc`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.uncertainties`, TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.mach`, TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.liquidVolumeFraction`, TYPE: 'DTOQUANTITY'},
    {KEY: `uncertainties${raw ? 'Raw' : ''}.${conditionName}.phaseState`, TYPE: 'NUMBER'}
  ];
}
