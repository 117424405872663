import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {VersionReduxAction} from '../actions/actions-version';
import {environment} from '@env/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {OkResponse} from '../state-service/do-mapper/model/communication/commands';
import {ErrorService} from '@state/state-service/error.service';
import {GenericModalPayload} from '@state/actions/actions-modal';
import {DoMapperVersions} from '@state/state-service/do-mapper/mappers/versions';
import {ApiErrorHandleConfig, handleApiError} from '@state/epics/utility/handle-api-error';

const url = environment.url.aws;

@Injectable({
  providedIn: 'root'
})
export class VersionEpicService {
  constructor(private http: HttpClient, private errorService: ErrorService) {}

  async getVersions(action: VersionReduxAction, stateService: StateService) {
    try {
      await stateService.loggedIn$.toPromise();

      const result = await this.http
        .get<OkResponse<any>>(url + '/versions') //       .pipe(map(res => res.data.result))
        .toPromise();

      const successAction: VersionReduxAction = new VersionReduxAction('VERSIONS_GET_ALL_SUCCESS', {
        payload: DoMapperVersions.mapToVersions(result.data)
      });

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'VERSIONS_GET_ALL_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }
}
