import {Injectable} from '@angular/core';
import {AppModeReduxAction} from '@state/actions/actions-app-mode';
import {StateService} from '@state/state-service/state.service';

@Injectable({providedIn: 'root'})
export class AppModeService {
  constructor(private stateService: StateService) {}

  fetchServiceModeState() {
    const getAppModeAction = new AppModeReduxAction('GET_APP_MODE_LOAD', {
      application: {appMode: 'OPERATIONAL'}
    });

    this.stateService.dispatch(getAppModeAction);
  }
}
