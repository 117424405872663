import {UnitSystemKey} from '@state/state-service/do-mapper/model/unit/unit-system-document';
import {FlowQuantity, Quantity} from '@state/state-service/do-mapper/model/unit/quantity';
import {GasData} from '@state/state-service/do-mapper/model/gas/gas-composition-doc';

export interface DropdownValue<T> {
  value: T;
  label: string;
}

export type Segment = 'Upstream' | 'Downstream' | 'EnvironmentalAndMining' | 'GasIndustry' | 'Lng' | 'Midstream';

export type Uncertainty = 'Standard' | 'Improved' | 'Custody' | 'CustodyMPE1_0' | 'CustodyMPE0_5' | 'Process';

export type InstallationType = 'Instrument' | 'FlowCalibrated' | 'IntegratedMeter';

export type MeasuredFlowValue = Extract<UnitSystemKey, 'OperationalFlow' | 'NormalizedFlow' | 'MassFlow'>;

export type FlowCondition =
  | 'Undisturbed'
  | 'Disturbed'
  | '10D_5D_InletWithFlowConditioner'
  | 'ReducedPiping_Or_DisturbedFlow'
  | 'Standard'
  | 'FlowConditioner';

export enum ProductFamily {
  FL100FlareXT = 'FL100FlareXT',
  FL600XT = 'FL600XT',
  FLOWSIC500 = 'FLOWSIC500',
  FLOWSIC550 = 'FLOWSIC550',
  FLOWSIC900 = 'FLOWSIC900',
  FLOWSIC600DRUS = 'FLOWSIC600_DRU_S'
}
export const PRODUCT_FAMILIES: DropdownValue<ProductFamily>[] = [
  {
    value: ProductFamily.FL100FlareXT,
    label: 'FLOWSIC100 Flare-XT'
  },
  {
    value: ProductFamily.FLOWSIC500,
    label: 'FLOWSIC500'
  },
  {
    value: ProductFamily.FLOWSIC550,
    label: 'FLOWSIC550'
  },
  {
    value: ProductFamily.FL600XT,
    label: 'FLOWSIC600-XT'
  },
  {
    value: ProductFamily.FLOWSIC900,
    label: 'FLOWSIC900'
  },
  {
    value: ProductFamily.FLOWSIC600DRUS,
    label: 'FLOWSIC600 DRU-S'
  }
];

// tslint:disable-next-line:class-name
export interface ReferenceCondition {
  key: string;
  pressure: number;
  temperatureA: number;
  temperatureB: number;
}

// Nominal Sizes:
export type NominalSize =
  | 3
  | 4
  | 6
  | 8
  | 10
  | 12
  | 14
  | 16
  | 18
  | 20
  | 22
  | 24
  | 26
  | 28
  | 30
  | 32
  | 34
  | 36
  | 38
  | 40
  | 42
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  | 68
  | 72;

export type NozzleDistance = 6 | 9 | 10;

export const PROCESS_CONDITIONS_DEFAULTS_MIN: number[] = [0, 1, 2, 3];
export const PROCESS_CONDITIONS_DEFAULTS_MAX: number[] = [4, 5, 6, 7];

export interface UncertaintySetup {
  deviceUncertaintySetups: DeviceUncertaintySetup[];
  /** These settings are not device-specific. */
  extendedUncertaintySetup: ExtendedUncertaintySetup;
}

export interface DeviceUncertaintySetup {
  deviceName: string;
  /** Shifted zero point for M0 countermeasure. */
  zeroPointUncertaintyM0: Quantity<'SignalTiming'>;
  /** Shifted zero point for M1 countermeasure. */
  zeroPointUncertaintyM1: Quantity<'SignalTiming'>;
  /** Defines the construction angle, in which the path is built-in. */
  angleOfMeasurementPath: Quantity<'Angle'>;
  /** Theoretical uncertainty coefficient based on CFD simulation. */
  uncertaintyCfdCoefficient: Quantity<'AmountRatio'>;
}

export interface ExtendedUncertaintySetup {
  /** Specific parameters, which are encapsulated into parameter_mat */
  parameters: {
    vogTheta: Quantity<'Speed'>;
    valueM: Quantity<'Numeral'>;
    agcUpperLimit: Quantity<'SoundLevel'>;
    isentropicComponentKappa: Quantity<'Numeral'>;
    m4A_uU: Quantity<'Length'>;
    m4A_uThickness: Quantity<'Length'>;
    m4B_uU: Quantity<'Length'>;
    m4B_uThickness: Quantity<'Length'>;
    m4B_uuAlpha: Quantity<'Angle'>;
  };
  /** These configuration properties are part of the config_mat. */
  configuration: {
    useUnpairedProbesTiming: boolean;
    /** This value is required in nanoseconds! */
    unpairedProbesTiming: Quantity<'SignalTiming'>;
    useCustomZeroPointUncertainty: boolean;
    /** Value in percent */
    customZeroPointUncertainty: Quantity<'AmountRatio'>;
    useCustomPathAngle: boolean;
    customPathAngle: Quantity<'Angle'>;
    /**
     * GBC04ADSW-390: We define nozzle distance for F1F-R only as index number, since
     * re-indexing in backend as float number (e.g. 6.4") can cause faulty interpretation
     */
    biasNozzleDistance: number | null;
    /**
     * This property is related to GBC04ADSW-316.
     * It determines if a install type separates
     * into "Meter, SICK spool" or "Meter, SICK spool calibrated"
     */
    deviceIsFlowCalibrated: boolean;
    /** selected path nominal diameter */
    nominalDiameter: Quantity<'Diameter'>;
    /** selected path count */
    pathCount: string;
  };
  /** Postprocessing settings are specific calculation attachments to improve result display */
  postprocessing: {
    activeSoundCorrelation: boolean;
    envelope: boolean;
    attachUncorrectedDeviation: boolean; // M0
    useMachUnit: boolean;
  };
}

export interface PathLayout {
  key: string;
  productFamily: string;
  productType: string;
}

export interface Device {
  productFamily: string | ProductFamily; // 'FL100Flare'
  productType: string; //
  segment: Segment;

  ambientTemperature: {
    max: Quantity<'Temperature'>;
    min: Quantity<'Temperature'>;
  };
  flowConditions: FlowCondition; // TODO: flowConditions => flowCondition (plural - singular)
  flowPressure: {
    max: Quantity<'Pressure'>;
    min: Quantity<'Pressure'>;
    option?: string;
  };
  flowRange: {
    max: FlowQuantity;
    min: FlowQuantity;
    option?: string;
  };
  flowTemperature: {
    max: Quantity<'Temperature'>;
    min: Quantity<'Temperature'>;
  };
  installationType: InstallationType;
  dimensions: {
    nominalDiameter: {
      max: Quantity<'Diameter'>;
      min: Quantity<'Diameter'>;
    };
    innerDiameter?: {
      max: Quantity<'Diameter'>;
      min: Quantity<'Diameter'>;
    };
    schedule: string | null;
  };
  vog: {
    min: Quantity<'Speed'>;
    max: Quantity<'Speed'>;
  };
  supported: boolean;
  uncertainty: Uncertainty;
  projectGasData: {
    min: GasData | null;
    max: GasData | null;
  };
}

export interface Velocity {
  GasVelocity: Quantity<'Speed'>;
}

export interface Flow {
  Actual?: Quantity<'OperationalFlow'> | null;
  Standard?: Quantity<'NormalizedFlow'> | null;
  Mass?: Quantity<'MassFlow'> | null;
  Energy?: Quantity<'EnergyFlow'> | null;
}

export interface HeatValue {
  Mass?: Quantity<'HeatValue'> | null;
  Volume?: Quantity<'HeatValue'> | null;
}

export interface Meter {
  NominalDiameter?: Quantity<'Diameter'> | null;
  WallThickness?: Quantity<'Diameter'> | null;
  InnerDiameter?: Quantity<'Diameter'> | null;
  MeterInnerDiameter?: Quantity<'Diameter'> | null;
}
