import {User} from '../do-mapper/model/communication/user';
import {Metadata} from '@state/state-service/do-mapper/model/project/metadata';
import {convertObjectIntoArrayOfKeyValues} from '@flowtwin/communication';
import {GasCompositionDoc} from '@state/state-service/do-mapper/model/gas/gas-composition-doc';
import {AppTransducerInput, AppTransducerMetadata, AppTransducerOutput} from '../do-mapper/model/apps/app-transducer';
import {SubDtoMapperUncertainty} from '@state/state-service/dto-mapper/sub-mappers/app-uncertainty';
import {SubDtoMapperAppSizing} from '@state/state-service/dto-mapper/sub-mappers/app-sizing';
import {SubDtoMapperAppTransducer} from '@state/state-service/dto-mapper/sub-mappers/app-transducer';
import {AppSizingInput, AppSizingMetadata, AppSizingOutput} from '@state/state-service/do-mapper/model/apps/app-sizing';
import {AppUncertaintyInput, AppUncertaintyMetadata, AppUncertaintyOutput} from '@state/state-service/do-mapper/model/apps/app-uncertainty';
import {merge} from 'lodash';

export class RootDtoMapper {
  // deviceSearch
  public static mapToCallDeviceSearch(sizingInput: AppSizingInput, gasCompositions: GasCompositionDoc[]) {
    const sizingMeta: AppSizingMetadata = {filter: false, devices: [], data: null, deviceOptions: null};
    const gasCompositionDoc = gasCompositions.find(x => x.document.id === sizingInput?.gasCompositionId);

    return SubDtoMapperAppSizing.mapToCallDeviceSearch(sizingInput, sizingMeta, gasCompositionDoc);
  }

  static appSizingMappingBroker(
    domainObject: AppSizingInput | AppSizingOutput | AppSizingMetadata,
    partOfApp: 'input' | 'output' | 'metadata'
  ) {
    let dto = null;

    switch (partOfApp) {
      case 'input':
        dto = SubDtoMapperAppSizing.mapToSaveAppSizingInput(domainObject[partOfApp]);
        break;
      case 'output':
        dto = SubDtoMapperAppSizing.mapToSaveAppSizingOutput(domainObject[partOfApp]);
        break;
      case 'metadata':
        // TODO: Implement Once Needed
        break;
    }

    return dto;
  }

  static appUncertaintyMappingBroker(
    domainObject: AppUncertaintyInput | AppUncertaintyOutput | AppUncertaintyMetadata,
    partOfApp: 'input' | 'output' | 'metadata'
  ) {
    let dto = null;

    switch (partOfApp) {
      case 'input':
        dto = SubDtoMapperUncertainty.mapToSaveAppUncertaintyInput(domainObject[partOfApp]);
        break;
      case 'output':
        dto = SubDtoMapperUncertainty.mapToSaveAppUncertaintyOutput(domainObject[partOfApp]);
        break;
      case 'metadata':
        // TODO: Implement Once Needed
        break;
    }

    return dto;
  }

  static appTransducerMappingBroker(
    domainObject: AppTransducerInput | AppTransducerOutput | AppTransducerMetadata,
    partOfApp: 'input' | 'output' | 'metadata'
  ) {
    let dto = null;

    switch (partOfApp) {
      case 'input':
        dto = SubDtoMapperAppTransducer.mapToSaveAppTransducerInput(domainObject[partOfApp]);
        break;
      case 'output':
        dto = SubDtoMapperAppTransducer.mapToSaveAppTransducerOutput(domainObject[partOfApp]);
        break;
      case 'metadata':
        // TODO: Implement Once Needed
        break;
    }

    return dto;
  }

  static mapToComputeUncertainty(uncertaintyInput: AppUncertaintyInput, gasCompositions: GasCompositionDoc[], metadata: Metadata) {
    const flattenedGasCompositions = gasCompositions.map(gc => {
      return {
        ...gc.gasComposition,
        ...{
          id: gc.document.id
        }
      };
    });

    const aoaGasesKeyValue = flattenedGasCompositions.map(gc => convertObjectIntoArrayOfKeyValues(gc));
    const aoaDusKeyValue = metadata.uncertaintySetup.deviceUncertaintySetups.map(dus => convertObjectIntoArrayOfKeyValues(dus));

    const mergedDomainObject = {
      ...uncertaintyInput,
      gasCompositions: aoaGasesKeyValue,
      deviceUncertaintySetups: aoaDusKeyValue,
      selectedDevice: convertObjectIntoArrayOfKeyValues(uncertaintyInput.selectedDevice[0]) as any,
      ...{
        parameterSetup: {
          deviceUncertaintySetups: uncertaintyInput.parameterSetup.deviceUncertaintySetups,
          extendedUncertaintySetup: merge(
            {},
            metadata.uncertaintySetup.extendedUncertaintySetup,
            uncertaintyInput.parameterSetup.extendedUncertaintySetup
          )
        }
      }
    };

    return SubDtoMapperUncertainty.mapToComputeUncertainty(mergedDomainObject);
  }
}
