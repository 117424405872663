// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {AuthConfig} from 'angular-oauth2-oidc';
import {oidcConfig} from './oidc-config';
import {AppVersions} from '@state/state-service/do-mapper/model/communication/app-versions';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

export interface Environment {
  name: 'local' | 'dev' | 'uat' | 'prod';
  sickIdUrl: string;
  production: boolean;
  oidc: AuthConfig;
  versions: AppVersions;
  url: {
    aws: string; // Amazon lambda backend
    assets: string;
    flowTwin: string;
  };
  initializeSentry: boolean;
}

// const baseUrl = `https://ddb.flowsic-applicationdesign.dev-cloud.sick.com/`

export const defaultEnvironment: Environment = {
  name: 'local',
  sickIdUrl: 'https://id.uat.sick.com/',
  production: false,
  oidc: oidcConfig,
  versions: {
    frontend: {
      base: require('../../package.json').version
    },
    aws: {
      base: '0.0.0',
      unit: '0.0.0',
      communication: '0.0.0'
    },
    octave: {
      base: '0.0.0',
      common: '0.0.0',
      unit: '0.0.0',
      matlab: '0.0.0',
      communication: '0.0.0'
    }
  } as AppVersions,
  url: {
    aws: 'https://52ustdc9ke.execute-api.eu-central-1.amazonaws.com/dev/app',
    assets: `https://assets.flowsic-applicationdesign.dev-cloud.sick.com/`,
    flowTwin: 'https://flowsic-applicationdesign.dev-cloud.sick.com/'

    // aws: 'http://localhost:3000/dev/app',
    // octave: 'http://localhost:8080/',
    // zimmeha temp
    // aws: 'http://localhost:3000/dev/app',
    // octave: 'https://api.flowsic-applicationdesign.dev-cloud.sick.com',
  },
  initializeSentry: false
};
