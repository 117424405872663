import {DtoDoPropCollectionMap} from '@flowtwin/communication';

export const PROP_COLLECTION_MAP_METADATA: DtoDoPropCollectionMap[] = [
  {dtoKey: 'segments', doKey: 'segments'},
  {dtoKey: 'measuredFlowValues', doKey: 'measuredFlowValues'},
  {dtoKey: 'uncertainties', doKey: 'uncertainties'},
  {dtoKey: 'installationTypes', doKey: 'installationTypes'},
  {dtoKey: 'pathLayouts', doKey: 'pathLayouts'},
  {dtoKey: 'referenceConditions', doKey: 'referenceConditions'},
  {dtoKey: 'sensorTypes', doKey: 'sensorTypes'},
  {dtoKey: 'sensorGasSelectionMethods', doKey: 'sensorGasSelectionMethods'},
  {dtoKey: 'explosionGroups', doKey: 'explosionGroups'},
  {dtoKey: 'flowConditions', doKey: 'flowConditions'},
  {dtoKey: 'flowConditioners', doKey: 'flowConditioners'},
  {dtoKey: 'flowRangeOptions', doKey: 'flowRangeOptions'},
  {dtoKey: 'nominalSizes', doKey: 'nominalSizes'},
  {dtoKey: 'nozzleDistances', doKey: 'nozzleDistances'},
  {dtoKey: 'pressureClasses', doKey: 'pressureClasses'},
  {dtoKey: 'productFamilies', doKey: 'productFamilies'},
  {dtoKey: 'productTypes', doKey: 'productTypes'},
  {dtoKey: 'schedules', doKey: 'schedules'},
  {dtoKey: 'uncertaintySetup.deviceUncertaintySetups', doKey: 'uncertaintySetup.deviceUncertaintySetups'},
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.biasNozzleDistance',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.biasNozzleDistance'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.useCustomPathAngle',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.useCustomPathAngle'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.customPathAngle',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.customPathAngle'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.pathCount',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.pathCount'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.nominalDiameter',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.nominalDiameter'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.unpairedProbesTiming',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.configuration.unpairedProbesTiming'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.postprocessing.useMachUnit',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.postprocessing.useMachUnit'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.postprocessing.envelope',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.postprocessing.envelope'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.vogTheta',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.vogTheta'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.agcUpperLimit',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.agcUpperLimit'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.isentropicComponentKappa',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.isentropicComponentKappa'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uThickness',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uThickness'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uU',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uU'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4A_uU',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4A_uU'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.valueM',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.valueM'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uuAlpha',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uuAlpha'
  },
  {
    dtoKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4A_uThickness',
    doKey: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4A_uThickness'
  }
];
