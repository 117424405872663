export function cutOffKnownDatatypePathNestings(paths: string[], forbiddenEnding: string): string[] {
    const temp = paths.find(path => path.endsWith(forbiddenEnding));
    if (!temp) {
        return paths;
    } else {
        const n = temp.lastIndexOf('.');
        paths[paths.indexOf(temp)] = temp.substring(0, n);
        return paths;
    }
}
