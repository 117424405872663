import {ReduxAction} from './actions-abstract';
import {Metadata} from '../state-service/do-mapper/model/project/metadata';

export type MetadataActions = 'METADATA_GET_ALL_LOAD' | 'METADATA_GET_ALL_SUCCESS' | 'METADATA_GET_ALL_ERROR';

export interface ApiPayloadGeneric {}

export interface ApiPayloadGetOneSuccess {
  metadata: Metadata;
}

export type MetadataPayloads = ApiPayloadGetOneSuccess | ApiPayloadGeneric;

export class MetadataReduxAction extends ReduxAction {
  type: MetadataActions;

  constructor(type: MetadataActions, payload: MetadataPayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
