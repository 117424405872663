import {FormService} from '../form/form.service';
import {StateService} from '@state/state-service/state.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';
import {debounceTime, filter, map, pairwise, startWith, take, tap, withLatestFrom} from 'rxjs/operators';
import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {accessObjectByPath} from '@shared/utility/access-object-by-path';
import {clone} from '@shared/utility/clone';
import {getDifferenceDeep} from '@shared/utility/get-deep-diff-object';
import {removeEmptyObjects} from '@shared/utility/remove-empty-objects';
import {InputActions, InputReduxAction} from '@state/actions/actions-input';
import {ClearResultService} from '../../services/clear-result/clear-result.service';
import {isDefined} from '@shared/utility/isDefined';
import {FormlyFieldConfig} from '@ngx-formly/core';

@Directive({
  selector: '[daConnectFormToState]'
})
export class ConnectFormToStateDirective implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('connectForm') pathInState = '';
  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input('fieldsToCreate') fields$!: BehaviorSubject<FormlyFieldConfig[]>;
  @Input() debounce = 25;

  private subscriptions: Subscription[] = [];
  private initializedFields: Set<string> = new Set();

  constructor(private stateService: StateService, private formService: FormService, private clearResultService: ClearResultService) {}

  ngOnInit() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());

    this.waitForInitializedFieldConfigs(this.fields$);
  }

  private hasBeenInitialized(key: string): boolean {
    return this.initializedFields.has(key);
  }

  private markAsInitialized(key: string): void {
    this.initializedFields.add(key);
  }

  private clearInitialized() {
    this.initializedFields.clear();
  }

  private removeAsInitialized(key: string): void {
    this.initializedFields.delete(key);
  }

  private waitForInitializedFieldConfigs(fields: BehaviorSubject<FormlyFieldConfig[]>): void {
    fields
      .pipe(
        filter(isDefined),
        filter(fields => fields.length > 0),
        take(1)
      )
      .subscribe(() => {
        this.waitForInitializedStateValues();
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
    this.clearInitialized();
  }

  private waitForInitializedStateValues() {
    const projectInput$ = this.getProjectInputSubscriptionFromState();

    const stateValues: Subscription = projectInput$.subscribe(res => {
      let hasBeenInitialized = this.hasBeenInitialized(JSON.stringify(res[1]));

      // special condition for product form to be reinitialized with state values once "Reset Filters" has been pressed
      if (hasBeenInitialized && res[1][0].fieldGroup.find(fg => fg.key === 'productFamily')) {
        this.removeAsInitialized(JSON.stringify(res[1]));
        hasBeenInitialized = false;
      }

      if (!hasBeenInitialized) {
        this.mirrorStateToForm(res[0]);
        setTimeout(() => {
          this.markAsInitialized(JSON.stringify(res[1]));
        }, 1000);
      }

      this.initMirroringFormToStateSub();
    });

    this.subscriptions.push(stateValues);
  }

  private getProjectInputSubscriptionFromState() {
    return this.stateService.state$.pipe(
      debounceTime(this.debounce),
      map(state => accessObjectByPath(state, this.pathInState)),
      filter(isDefined),
      withLatestFrom(this.fields$)
    );
  }

  private mirrorStateToForm(existingInputs: any) {
    if (this.pathInState.length > 0 && existingInputs !== undefined) {
      const associatedInputs = this.getFlattenedAssociatedInputs(existingInputs, Object.keys(this.formGroup.controls));

      this.formService.updateFormSloppy(this.formGroup, associatedInputs);
    } else {
      console.warn('Cannot create a link between input PATH & PROPERTY in State. Questionable path of: ', this.pathInState, '.');
    }
  }
  private initMirroringFormToStateSub() {
    const inputHandling = this.getInputHandlingSubscription();

    const inputValues: Subscription = inputHandling.subscribe(this.updateStateWithNewInputValues.bind(this));

    this.removeClosedSubs();

    this.subscriptions.push(inputValues);
  }

  private getInputHandlingSubscription() {
    return this.formGroup.valueChanges.pipe(
      debounceTime(this.debounce),
      startWith(<string | number>this.formGroup.value),
      pairwise(),
      map(res => {
        return {
          old: res[0],
          new: res[1]
        };
      }),
      take(1)
    );
  }

  private updateStateWithNewInputValues(inputs: any) {
    const differences = removeEmptyObjects(clone(getDifferenceDeep(inputs.old, inputs.new)));

    if (Object.keys(differences).length > 0) {
      const changedInputs = {};

      Object.keys(differences).forEach(key => {
        changedInputs[key] = inputs.new[key];
      });

      this.updateProjectInputInState(changedInputs, this.pathInState);

      const wasInitSet = Object.keys(inputs.old).every(key => inputs.old[key] === null);

      if (!wasInitSet) {
        this.triggerAction(changedInputs, inputs.old);

        this.clearResults();
      }
    }
  }

  private updateProjectInputInState = (newInputValues: any, inputPath: string) => {
    const updateProjectInput: InputReduxAction = new InputReduxAction('UPDATE_PROJECT_INPUT', {
      path: inputPath,
      newInputValues
    });

    this.stateService.dispatch(updateProjectInput);

    if (this.pathInState.includes('input')) {
      const inputChanged: InputReduxAction = new InputReduxAction('INPUT_CHANGED', {});
      this.stateService.dispatch(inputChanged);
    }
  };

  private triggerAction = (updatedProperties: any, oldproperties: any) => {
    updatedProperties = Object.keys(updatedProperties).map(key => [key, updatedProperties[key]]);

    updatedProperties.forEach((up: string, index: number) => {
      let actionType = up[0].replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      actionType = actionType.toUpperCase();

      const newValue = updatedProperties[index];
      const oldValue = oldproperties[updatedProperties[index][0]];

      const inputAction: InputReduxAction = new InputReduxAction(actionType as InputActions, {
        input: newValue,
        oldValue
      });

      this.stateService.dispatch(inputAction);
    });
  };

  private getFlattenedAssociatedInputs(existingInputs: any, properties: string[]) {
    const intersection = {};
    properties.forEach(s => {
      intersection[s] = existingInputs[s];
      if (intersection[s]?.hasOwnProperty('key')) {
        intersection[s] = intersection[s].value;
      }
    });

    return intersection;
  }

  private clearResults() {
    this.clearResultService.clearResultByInputPath(this.pathInState);
  }

  private removeClosedSubs() {
    this.subscriptions = this.subscriptions.filter(s => {
      if (s.closed) {
        s.unsubscribe();
        return false;
      }
      return true;
    });
  }
}
