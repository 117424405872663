import {CONTRACTS_AWS_BE_CONFIG} from './aws-be/contracts-aws-be';
import {CONTRACTS_FE_AWS_CONFIG} from './fe-aws/contracts-fe-aws';
import {ArrayElementTypeAnnotation, TransposedTypeAnnotation, TypeAnnotation} from '../dto-assembly-line/model/dto';

export type DtoContract = {
  KEY: string;
  TYPE: TypeAnnotation | TransposedTypeAnnotation;
  ARRAY?: ArrayElementTypeAnnotation | ArrayElementTypeAnnotation[];
};

export type DomainContract = {
  DOMAINOBJECT: any;
  PATHINDATATRANSFEROBJECT: string;
  PATHINDOMAINOBJECT: string;
};

export type Contract = (DtoContract & DomainContract)[];

export const CONTRACTS = Object.freeze({
  REQ_FE_AWS_DTO_CONTRACTS: CONTRACTS_FE_AWS_CONFIG.REQ_FE_AWS_ENDPOINTS,
  RES_FE_AWS_DTO_CONTRACTS: CONTRACTS_FE_AWS_CONFIG.RES_FE_AWS_ENDPOINTS,
  REQ_AWS_BE_DTO_CONTRACTS: CONTRACTS_AWS_BE_CONFIG.REQ_AWS_BE_ENDPOINTS,
  RES_AWS_BE_DTO_CONTRACTS: CONTRACTS_AWS_BE_CONFIG.RES_AWS_BE_ENDPOINTS
});
