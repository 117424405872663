import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GasPipe} from './pipes/gas/gas.pipe';
import {LocalizedDatePipe} from './pipes/localized-date/localized-date.pipe';
import {UnitConvertPipe} from './pipes/unit-convert/unit-convert.pipe';
import {UnitSystemLabelPipe} from './pipes/unit-system-label/unit-system-label.pipe';
import {UnitTranslatePipe} from './pipes/unit-translate/unit-translate.pipe';
import {FirstLetterUpperCasePipe} from '@shared/pipes/text/first-letter-upper-case.pipe';
import {VisualizeResultPipe} from '@shared/pipes/visualize-result/visualize-result.pipe';
import {MatExpansionPanelHeaderComponent} from './components/mat-expansion-panel-header/mat-expansion-panel-header.component';
import {ProcessConditionsPipe} from '@shared/pipes/process-conditions/process-conditions.pipe';
import {CookieService} from '@shared/cookies/cookies.service';
import {FilterDefaultUserPipe} from './pipes/common/filter-default-user.pipe';
import {JoinPipe} from './pipes/text/join.pipe';
import {DeviceImagePipe} from '@shared/pipes/common/device-image.pipe';
import {TimeAgoPipe} from '@shared/pipes/timeAgo/time-ago.pipe';

@NgModule({
  declarations: [
    GasPipe,
    ProcessConditionsPipe,
    LocalizedDatePipe,
    UnitConvertPipe,
    UnitSystemLabelPipe,
    UnitTranslatePipe,
    FirstLetterUpperCasePipe,
    VisualizeResultPipe,
    MatExpansionPanelHeaderComponent,
    FilterDefaultUserPipe,
    JoinPipe,
    DeviceImagePipe,
    TimeAgoPipe
  ],
  imports: [CommonModule],
  exports: [
    GasPipe,
    ProcessConditionsPipe,
    LocalizedDatePipe,
    UnitConvertPipe,
    UnitSystemLabelPipe,
    UnitTranslatePipe,
    FirstLetterUpperCasePipe,
    VisualizeResultPipe,
    MatExpansionPanelHeaderComponent,
    FilterDefaultUserPipe,
    JoinPipe,
    DeviceImagePipe,
    TimeAgoPipe
  ],
  providers: [CookieService]
})
export class SharedModule {}
