// tslint:disable: ban-types
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';
import {MOCK_TOKEN_ID} from '@modules/mock/mock/mock.component';
import {ILogger, LoggerConfig, LoggerService, LogLevel} from '../../../services/logger/logger.service';

const loggerConfig: LoggerConfig = {
  name: '-',
  symbol: '🎭',
  textColor: 'black',
  backgroundColor: 'Plum'
};

const TOKENS = {
  FT_USER_EXTERNALUSER_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxYzA2MDFmMS0wZDBlLTQzYzAtODk2MC0wZTRiZDQzYjNkN2MiLCJleHAiOjI1MzQwMDU3MjgwMDAwMCwibmJmIjowLCJpYXQiOjE2MDYxMDc3MDEsImlzcyI6Imh0dHBzOi8vaWQudWF0LnNpY2suY29tL2F1dGgvcmVhbG1zL3NpY2tzZXJ2aWNlcyIsImF1ZCI6IkZsb3dzaWNEZXNpZ25BcHBsaWNhdGlvbiIsInN1YiI6IjNiYmVmOTkyLTc1ZTctNGM1ZC04MGY0LTk3NTkwYjhmM2I3NSIsInR5cCI6IklEIiwiYXpwIjoiRmxvd3NpY0Rlc2lnbkFwcGxpY2F0aW9uIiwibm9uY2UiOiJWV1JPY1d3MWVXRnVVMVV3VFdGNmIzRnRNVEEyUm5OYVNVOHVkMDlzU0RsbWNERnlaV0ZSUVZjelpHZE0iLCJhdXRoX3RpbWUiOjE2MDYxMDc3MDEsInNlc3Npb25fc3RhdGUiOiI4YjA1N2M1Ni0zOWNjLTQ4ODktYjljMy1hYjMxMjRmMmUzYzciLCJhdF9oYXNoIjoiQWM3OE4xUjBkYko5MklMN2VYeFpzdyIsImFjciI6IjEiLCJzX2hhc2giOiI3VDUtamozTm5ZcmFwblQzbXVtRjlBIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJleHRlcm5hbCBDb3N0dW1lciIsInByZWZlcnJlZF91c2VybmFtZSI6ImV4dC1jb3N0dW1lci51c2VyQGV4YW1wbGUuY29tIiwibG9jYWxlIjoiZW4iLCJnaXZlbl9uYW1lIjoiRXh0IENvc3R1bWVyIiwiZmFtaWx5X25hbWUiOiJVc2VyIiwiZW1haWwiOiJleHQuY29zdHVtZXIudXNlckBleGFtcGxlLmNvbSJ9.of-GCx-3oFYpUzpgLFlI0f-JUUSJszlJmdsvIFNT0oA',
  FT_USER_EXTERNALSERVICE_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxYzA2MDFmMS0wZDBlLTQzYzAtODk2MC0wZTRiZDQzYjNkN2MiLCJleHAiOjI1MzQwMDU3MjgwMDAwMCwibmJmIjowLCJpYXQiOjE2MDYxMDc3MDEsImlzcyI6Imh0dHBzOi8vaWQudWF0LnNpY2suY29tL2F1dGgvcmVhbG1zL3NpY2tzZXJ2aWNlcyIsImF1ZCI6IkZsb3dzaWNEZXNpZ25BcHBsaWNhdGlvbiIsInN1YiI6IjNiYmVmOTkyLTc1ZTctNGM1ZC04MGY0LTk3NTkwYjhmM2I3NSIsInR5cCI6IklEIiwiYXpwIjoiRmxvd3NpY0Rlc2lnbkFwcGxpY2F0aW9uIiwibm9uY2UiOiJWV1JPY1d3MWVXRnVVMVV3VFdGNmIzRnRNVEEyUm5OYVNVOHVkMDlzU0RsbWNERnlaV0ZSUVZjelpHZE0iLCJhdXRoX3RpbWUiOjE2MDYxMDc3MDEsInNlc3Npb25fc3RhdGUiOiI4YjA1N2M1Ni0zOWNjLTQ4ODktYjljMy1hYjMxMjRmMmUzYzciLCJhdF9oYXNoIjoiQWM3OE4xUjBkYko5MklMN2VYeFpzdyIsImFjciI6IjEiLCJzX2hhc2giOiI3VDUtamozTm5ZcmFwblQzbXVtRjlBIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJleHRlcm5hbCBDb3N0dW1lciIsInByZWZlcnJlZF91c2VybmFtZSI6IkV4dC5TZXJ2aWNlQGV4YW1wbGUuY29tIiwibG9jYWxlIjoiZW4iLCJnaXZlbl9uYW1lIjoiRXh0IiwiZmFtaWx5X25hbWUiOiJTZXJ2aWNlIiwiZW1haWwiOiJFeHQuU2VydmljZUBleGFtcGxlLmNvbSJ9.al_dahto_VPqnqoe6cQ8K9DzHOhZyDik_HUh8zmEEcg',
  FT_USER_EXTERNALPARTNER_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxYzA2MDFmMS0wZDBlLTQzYzAtODk2MC0wZTRiZDQzYjNkN2MiLCJleHAiOjI1MzQwMDU3MjgwMDAwMCwibmJmIjowLCJpYXQiOjE2MDYxMDc3MDEsImlzcyI6Imh0dHBzOi8vaWQudWF0LnNpY2suY29tL2F1dGgvcmVhbG1zL3NpY2tzZXJ2aWNlcyIsImF1ZCI6IkZsb3dzaWNEZXNpZ25BcHBsaWNhdGlvbiIsInN1YiI6IjNiYmVmOTkyLTc1ZTctNGM1ZC04MGY0LTk3NTkwYjhmM2I3NSIsInR5cCI6IklEIiwiYXpwIjoiRmxvd3NpY0Rlc2lnbkFwcGxpY2F0aW9uIiwibm9uY2UiOiJWV1JPY1d3MWVXRnVVMVV3VFdGNmIzRnRNVEEyUm5OYVNVOHVkMDlzU0RsbWNERnlaV0ZSUVZjelpHZE0iLCJhdXRoX3RpbWUiOjE2MDYxMDc3MDEsInNlc3Npb25fc3RhdGUiOiI4YjA1N2M1Ni0zOWNjLTQ4ODktYjljMy1hYjMxMjRmMmUzYzciLCJhdF9oYXNoIjoiQWM3OE4xUjBkYko5MklMN2VYeFpzdyIsImFjciI6IjEiLCJzX2hhc2giOiI3VDUtamozTm5ZcmFwblQzbXVtRjlBIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJleHRlcm5hbCBDb3N0dW1lciIsInByZWZlcnJlZF91c2VybmFtZSI6IkV4dC5QYXJ0bmVyQGV4YW1wbGUuY29tIiwibG9jYWxlIjoiZW4iLCJnaXZlbl9uYW1lIjoiRXh0IiwiZmFtaWx5X25hbWUiOiJQYXJ0bmVyIiwiZW1haWwiOiJFeHQuUGFydG5lckBleGFtcGxlLmNvbSJ9.CkIxGQflSdma9WdOG6kPSlNDJg4A4_4ZRzKcS-XHX6k',
  FT_USER_SICKSALES_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxYzA2MDFmMS0wZDBlLTQzYzAtODk2MC0wZTRiZDQzYjNkN2MiLCJleHAiOjI1MzQwMDU3MjgwMDAwMCwibmJmIjowLCJpYXQiOjE2MDYxMDc3MDEsImlzcyI6Imh0dHBzOi8vaWQudWF0LnNpY2suY29tL2F1dGgvcmVhbG1zL3NpY2tzZXJ2aWNlcyIsImF1ZCI6IkZsb3dzaWNEZXNpZ25BcHBsaWNhdGlvbiIsInN1YiI6IjNiYmVmOTkyLTc1ZTctNGM1ZC04MGY0LTk3NTkwYjhmM2I3NSIsInR5cCI6IklEIiwiYXpwIjoiRmxvd3NpY0Rlc2lnbkFwcGxpY2F0aW9uIiwibm9uY2UiOiJWV1JPY1d3MWVXRnVVMVV3VFdGNmIzRnRNVEEyUm5OYVNVOHVkMDlzU0RsbWNERnlaV0ZSUVZjelpHZE0iLCJhdXRoX3RpbWUiOjE2MDYxMDc3MDEsInNlc3Npb25fc3RhdGUiOiI4YjA1N2M1Ni0zOWNjLTQ4ODktYjljMy1hYjMxMjRmMmUzYzciLCJhdF9oYXNoIjoiQWM3OE4xUjBkYko5MklMN2VYeFpzdyIsImFjciI6IjEiLCJzX2hhc2giOiI3VDUtamozTm5ZcmFwblQzbXVtRjlBIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJleHRlcm5hbCBDb3N0dW1lciIsInByZWZlcnJlZF91c2VybmFtZSI6InNpY2suc2FsZXNAZXhhbXBsZS5jb20iLCJsb2NhbGUiOiJlbiIsImdpdmVuX25hbWUiOiJzaWNrIiwiZmFtaWx5X25hbWUiOiJzYWxlcyIsImVtYWlsIjoic2ljay5zYWxlc0BleGFtcGxlLmNvbSJ9.XtRLRzmgNnkZwUk87cpQ4r8D5mO-FHKNDVFtOrA-_xg',
  FT_USER_GBCSALES_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxYzA2MDFmMS0wZDBlLTQzYzAtODk2MC0wZTRiZDQzYjNkN2MiLCJleHAiOjI1MzQwMDU3MjgwMDAwMCwibmJmIjowLCJpYXQiOjE2MDYxMDc3MDEsImlzcyI6Imh0dHBzOi8vaWQudWF0LnNpY2suY29tL2F1dGgvcmVhbG1zL3NpY2tzZXJ2aWNlcyIsImF1ZCI6IkZsb3dzaWNEZXNpZ25BcHBsaWNhdGlvbiIsInN1YiI6IjNiYmVmOTkyLTc1ZTctNGM1ZC04MGY0LTk3NTkwYjhmM2I3NSIsInR5cCI6IklEIiwiYXpwIjoiRmxvd3NpY0Rlc2lnbkFwcGxpY2F0aW9uIiwibm9uY2UiOiJWV1JPY1d3MWVXRnVVMVV3VFdGNmIzRnRNVEEyUm5OYVNVOHVkMDlzU0RsbWNERnlaV0ZSUVZjelpHZE0iLCJhdXRoX3RpbWUiOjE2MDYxMDc3MDEsInNlc3Npb25fc3RhdGUiOiI4YjA1N2M1Ni0zOWNjLTQ4ODktYjljMy1hYjMxMjRmMmUzYzciLCJhdF9oYXNoIjoiQWM3OE4xUjBkYko5MklMN2VYeFpzdyIsImFjciI6IjEiLCJzX2hhc2giOiI3VDUtamozTm5ZcmFwblQzbXVtRjlBIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJleHRlcm5hbCBDb3N0dW1lciIsInByZWZlcnJlZF91c2VybmFtZSI6ImdiYy5zYWxlc0BleGFtcGxlLmNvbSIsImxvY2FsZSI6ImVuIiwiZ2l2ZW5fbmFtZSI6ImdiYyIsImZhbWlseV9uYW1lIjoic2FsZXMiLCJlbWFpbCI6ImdiYy5zYWxlc0BleGFtcGxlLmNvbSJ9.OujB6z_VjosiOV_Vvs6QLI0TYJq_iDLQKEgvYhfWKaE',
  FT_USER_GBCSERVICE_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxYzA2MDFmMS0wZDBlLTQzYzAtODk2MC0wZTRiZDQzYjNkN2MiLCJleHAiOjI1MzQwMDU3MjgwMDAwMCwibmJmIjowLCJpYXQiOjE2MDYxMDc3MDEsImlzcyI6Imh0dHBzOi8vaWQudWF0LnNpY2suY29tL2F1dGgvcmVhbG1zL3NpY2tzZXJ2aWNlcyIsImF1ZCI6IkZsb3dzaWNEZXNpZ25BcHBsaWNhdGlvbiIsInN1YiI6IjNiYmVmOTkyLTc1ZTctNGM1ZC04MGY0LTk3NTkwYjhmM2I3NSIsInR5cCI6IklEIiwiYXpwIjoiRmxvd3NpY0Rlc2lnbkFwcGxpY2F0aW9uIiwibm9uY2UiOiJWV1JPY1d3MWVXRnVVMVV3VFdGNmIzRnRNVEEyUm5OYVNVOHVkMDlzU0RsbWNERnlaV0ZSUVZjelpHZE0iLCJhdXRoX3RpbWUiOjE2MDYxMDc3MDEsInNlc3Npb25fc3RhdGUiOiI4YjA1N2M1Ni0zOWNjLTQ4ODktYjljMy1hYjMxMjRmMmUzYzciLCJhdF9oYXNoIjoiQWM3OE4xUjBkYko5MklMN2VYeFpzdyIsImFjciI6IjEiLCJzX2hhc2giOiI3VDUtamozTm5ZcmFwblQzbXVtRjlBIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJleHRlcm5hbCBDb3N0dW1lciIsInByZWZlcnJlZF91c2VybmFtZSI6ImdiYy5zZXJ2aWNlQGV4YW1wbGUuY29tIiwibG9jYWxlIjoiZW4iLCJnaXZlbl9uYW1lIjoiZ2JjIiwiZmFtaWx5X25hbWUiOiJzZXJ2aWNlIiwiZW1haWwiOiJnYmMuc2VydmljZUBleGFtcGxlLmNvbSJ9.1HLRfiGQuqvb6hOJPMj4IgQmcZ1gbrMVP0p-sRPsy5M',
  FT_USER_GBCEXPERT_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxYzA2MDFmMS0wZDBlLTQzYzAtODk2MC0wZTRiZDQzYjNkN2MiLCJleHAiOjI1MzQwMDU3MjgwMDAwMCwibmJmIjowLCJpYXQiOjE2MDYxMDc3MDEsImlzcyI6Imh0dHBzOi8vaWQudWF0LnNpY2suY29tL2F1dGgvcmVhbG1zL3NpY2tzZXJ2aWNlcyIsImF1ZCI6IkZsb3dzaWNEZXNpZ25BcHBsaWNhdGlvbiIsInN1YiI6IjNiYmVmOTkyLTc1ZTctNGM1ZC04MGY0LTk3NTkwYjhmM2I3NSIsInR5cCI6IklEIiwiYXpwIjoiRmxvd3NpY0Rlc2lnbkFwcGxpY2F0aW9uIiwibm9uY2UiOiJWV1JPY1d3MWVXRnVVMVV3VFdGNmIzRnRNVEEyUm5OYVNVOHVkMDlzU0RsbWNERnlaV0ZSUVZjelpHZE0iLCJhdXRoX3RpbWUiOjE2MDYxMDc3MDEsInNlc3Npb25fc3RhdGUiOiI4YjA1N2M1Ni0zOWNjLTQ4ODktYjljMy1hYjMxMjRmMmUzYzciLCJhdF9oYXNoIjoiQWM3OE4xUjBkYko5MklMN2VYeFpzdyIsImFjciI6IjEiLCJzX2hhc2giOiI3VDUtamozTm5ZcmFwblQzbXVtRjlBIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJleHRlcm5hbCBDb3N0dW1lciIsInByZWZlcnJlZF91c2VybmFtZSI6ImdiYy5leHBlcnRAZXhhbXBsZS5jb20iLCJsb2NhbGUiOiJlbiIsImdpdmVuX25hbWUiOiJnYmMiLCJmYW1pbHlfbmFtZSI6ImV4cGVydCIsImVtYWlsIjoiZ2JjLmV4cGVydEBleGFtcGxlLmNvbSJ9.qXWIK5Xg0Yjgzqc5BWZYB3KBtDJy5xOdXOjPIxf67eQ',
  FT_USER_ADMIN_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxYzA2MDFmMS0wZDBlLTQzYzAtODk2MC0wZTRiZDQzYjNkN2MiLCJleHAiOjI1MzQwMDU3MjgwMDAwMCwibmJmIjowLCJpYXQiOjE2MDYxMDc3MDEsImlzcyI6Imh0dHBzOi8vaWQudWF0LnNpY2suY29tL2F1dGgvcmVhbG1zL3NpY2tzZXJ2aWNlcyIsImF1ZCI6IkZsb3dzaWNEZXNpZ25BcHBsaWNhdGlvbiIsInN1YiI6IjNiYmVmOTkyLTc1ZTctNGM1ZC04MGY0LTk3NTkwYjhmM2I3NSIsInR5cCI6IklEIiwiYXpwIjoiRmxvd3NpY0Rlc2lnbkFwcGxpY2F0aW9uIiwibm9uY2UiOiJWV1JPY1d3MWVXRnVVMVV3VFdGNmIzRnRNVEEyUm5OYVNVOHVkMDlzU0RsbWNERnlaV0ZSUVZjelpHZE0iLCJhdXRoX3RpbWUiOjE2MDYxMDc3MDEsInNlc3Npb25fc3RhdGUiOiI4YjA1N2M1Ni0zOWNjLTQ4ODktYjljMy1hYjMxMjRmMmUzYzciLCJhdF9oYXNoIjoiQWM3OE4xUjBkYko5MklMN2VYeFpzdyIsImFjciI6IjEiLCJzX2hhc2giOiI3VDUtamozTm5ZcmFwblQzbXVtRjlBIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJleHRlcm5hbCBDb3N0dW1lciIsInByZWZlcnJlZF91c2VybmFtZSI6ImFkbWluLlVzZXJAZXhhbXBsZS5jb20iLCJsb2NhbGUiOiJlbiIsImdpdmVuX25hbWUiOiJhZG1pbiIsImZhbWlseV9uYW1lIjoiVXNlciIsImVtYWlsIjoiYWRtaW4uVXNlckBleGFtcGxlLmNvbSJ9.B6DDkSQUy3Fj4ZIh4G2Z3uyB3fgwl9ix9PKb4c_hkKI',
  FT_USER_SICK_TOKEN_LOCAL: sessionStorage.getItem('access_token')
} as const;

//
// Mock Interceptor
//
//
@Injectable({
  providedIn: 'root'
})
class MockInterceptor implements HttpInterceptor {
  private logger: ILogger;

  constructor(private loggerService: LoggerService) {
    this.logger = this.initLogger();
  }

  private initLogger() {
    loggerConfig.name = this.constructor.name;
    return this.loggerService.initLogger(loggerConfig);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.production) {
      return next.handle(req);
    }

    const match = req.url.match(environment.url.aws);

    if (!match) {
      return next.handle(req);
    }

    const TOKEN_ID = localStorage.getItem(MOCK_TOKEN_ID);

    if (!TOKEN_ID) {
      return next.handle(req);
    }

    const TOKEN = TOKENS[TOKEN_ID as keyof typeof TOKENS];
    if (!TOKEN) {
      return next.handle(req);
    }

    const urlBuilder = new URL(req.url);
    urlBuilder.hostname = 'localhost';
    urlBuilder.port = '3000';
    urlBuilder.protocol = 'http';
    urlBuilder.pathname = urlBuilder.pathname.replace('/dev', '/local');

    const forkedReq = req.clone({
      url: urlBuilder.toString(),
      headers: req.headers.set('Authorization', TOKEN)
    });

    this.logger.log('', 'Redirect request to localhost:3000' + urlBuilder.pathname, LogLevel.Info, [
      'req.url' + ' => ' + urlBuilder.toString(),
      'Authorization token for user: ' + TOKEN_ID
    ]);

    return next.handle(forkedReq);
  }
}

export const MockInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: MockInterceptor,
  multi: true
};
