import {DtoDoPropCollectionMap} from '@flowtwin/communication';

export const PROP_COLLECTION_MAP_GET_USERS: DtoDoPropCollectionMap[] = [
  {dtoKey: 'document.id', doKey: 'document.id'},
  {dtoKey: 'document.email', doKey: 'document.email'},
  {dtoKey: 'document.familyName', doKey: 'document.familyName'},
  {dtoKey: 'document.givenName', doKey: 'document.givenName'},
  {dtoKey: 'document.emailVerified', doKey: 'document.emailVerified'},
  {dtoKey: 'document.locale', doKey: 'document.locale'},
  {dtoKey: 'permissions.admin', doKey: 'document.permissions.admin'},
  {dtoKey: 'permissions.supervisor', doKey: 'document.permissions.supervisor'},
  {dtoKey: 'permissions.gbc04expert', doKey: 'document.permissions.gbc04expert'},
  {dtoKey: 'permissions.gbc04service', doKey: 'document.permissions.gbc04service'},
  {dtoKey: 'permissions.gbc04sales', doKey: 'document.permissions.gbc04sales'},
  {dtoKey: 'permissions.sicksales', doKey: 'document.permissions.sicksales'},
  {dtoKey: 'permissions.externalpartner', doKey: 'document.permissions.externalpartner'},
  {dtoKey: 'permissions.externalservice', doKey: 'document.permissions.externalservice'},
  {dtoKey: 'permissions.externalcustomer', doKey: 'document.permissions.externalcustomer'},
  {dtoKey: 'externalPermissions.apps', doKey: 'document.externalPermissions.apps'},
  {dtoKey: 'externalPermissions.productFamilies', doKey: 'document.externalPermissions.productFamilies'}
];
