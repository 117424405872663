import {ReduxAction} from './actions-abstract';
import {AppMode} from '@state/state-service/do-mapper/model/app-mode/app-mode';

export type AppModeType = 'OPERATIONAL' | 'SERVICE';

export type AppModeActions =
  | 'SET_APP_MODE_LOAD'
  | 'SET_APP_MODE_SUCCESS'
  | 'SET_APP_MODE_ERROR'
  | 'GET_APP_MODE_LOAD'
  | 'GET_APP_MODE_SUCCESS'
  | 'GET_APP_MODE_ERROR';

export interface AppModePayload {
  application: AppMode;
}

export type AppModePayloads = AppModePayload;

export class AppModeReduxAction extends ReduxAction {
  type: AppModeActions;
  payload: AppModePayloads;

  constructor(type: AppModeActions, payload: AppModePayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
