import {AbstractDomainObject} from '@flowtwin/communication';
import {Device, InstallationType, UncertaintySetup} from '../device/properties';
import {Quantity} from '../unit/quantity';
import {GasData} from '../gas/gas-composition-doc';
import {ProcessDataEntry} from '@state/state-service/do-mapper/model/artifacts/process-data-entry';
import {UncertaintyUserLimits} from '@state/state-service/do-mapper/model/artifacts/uncertainty-user-limit';

export class AppUncertaintyInput extends AbstractDomainObject {
  processDataEntries: ProcessDataEntry[];
  remarks: string;
  userLimits: UncertaintyUserLimits;
  parameterSetup: UncertaintySetup;
  velocities: Quantity<'Speed'>[];
  installationType: InstallationType | null;
  selectedDevice: Device[] | null;
  gasData: {
    min: GasData | null;
    max: GasData | null;
  };
}

export class AppUncertaintyOutput extends AbstractDomainObject {
  flowrates: Quantity<'OperationalFlow'>[];
  /** actual uncertainty values as requested by user */
  uncertainties: {
    processConditionMin: AppUncertaintyProcessEntryResult;
    processConditionMax: AppUncertaintyProcessEntryResult;
    processCondition1: AppUncertaintyProcessEntryResult;
    processCondition2: AppUncertaintyProcessEntryResult;
    processCondition3: AppUncertaintyProcessEntryResult;
    processCondition4: AppUncertaintyProcessEntryResult;
  };
  /** Raw values - if server-side modification are requested */
  uncertaintiesRaw?: {
    processConditionMin: AppUncertaintyProcessEntryResult;
    processConditionMax: AppUncertaintyProcessEntryResult;
    processCondition1: AppUncertaintyProcessEntryResult;
    processCondition2: AppUncertaintyProcessEntryResult;
    processCondition3: AppUncertaintyProcessEntryResult;
    processCondition4: AppUncertaintyProcessEntryResult;
  };
  /** warnings bitset */
  velocities: Quantity<'Speed'>[];
  warning: AppUncertaintyWarnings;
  userLimits: {machLowFlowLimit: Quantity<'Numeral'>};
}

export interface AppUncertaintyMetadata {
  test: string;
}

export interface AppUncertaintyWarnings {
  deviceOutOfSpec?: boolean;
  signalStrengthWeak?: boolean;
  implausibleResult?: boolean;
}

export interface AppUncertaintyProcessEntryResult {
  name: string;
  active: boolean;
  agc: Quantity<'SoundLevel'>;
  velocityMax: Quantity<'Speed'>;
  velocityMaxAsc: Quantity<'Speed'>;
  flowrateMax: Quantity<'OperationalFlow'>;
  flowrateMaxAsc: Quantity<'OperationalFlow'>;
  uncertainties: Array<Quantity<'AmountRatio'>>;
  uncertaintyQmax: Quantity<'AmountRatio'>;
  uncertaintyQmaxAsc: Quantity<'AmountRatio'>;
  mach: Array<Quantity<'AmountRatio'>>;
  machMax: Quantity<'OperationalFlow'>;
  machMaxAsc: Quantity<'OperationalFlow'>;
  liquidVolumeFraction: Quantity<'AmountRatio'>;
  phaseState: number;
}
