import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {StateService} from '../state-service/state.service';
import {OkResponse} from '../state-service/do-mapper/model/communication/commands';
import {ApiPayloadDeleteOneLoad, ApiPayloadSaveOneLoad, GasReduxAction} from '../actions/actions-gas';
import {ErrorService} from '@state/state-service/error.service';
import {GenericModalPayload} from '@state/actions/actions-modal';
import {ILogger, LoggerConfig, LoggerService, LogLevel} from '../../services/logger/logger.service';
import {CONTRACTS, convertObjectIntoArrayOfKeyValues, Dto, DtoAssemblyDirector} from '@flowtwin/communication';
import {DoMapperGasCompositions} from '@state/state-service/do-mapper/mappers/gas-compositions';
import {CONTRACT_POST_ONE_GAS} from '@state/state-service/dto-mapper/domain-contracts/gas';
import {ApiErrorHandleConfig, handleApiError} from '@state/epics/utility/handle-api-error';

const url = environment.url.aws + '/gases';

const loggerConfig: LoggerConfig = {
  name: '-',
  symbol: '💩',
  textColor: 'black',
  backgroundColor: '#00F5D4'
};

@Injectable({
  providedIn: 'root'
})
export class GasEpicService {
  private logger: ILogger;

  constructor(private http: HttpClient, private errorService: ErrorService, private loggerService: LoggerService) {
    this.logger = this.initLogger();
  }

  private initLogger() {
    loggerConfig.name = this.constructor.name;
    return this.loggerService.initLogger(loggerConfig);
  }

  async getAll(action: GasReduxAction, stateService: StateService) {
    try {
      await stateService.loggedIn$.toPromise();

      const result = await this.http
        .get<OkResponse<Dto>>(url)
        .pipe(
          map(res => {
            if (res.data.payload.length === 0) {
              return [];
            }
            return DoMapperGasCompositions.mapToGasCompositionsDocs(res.data as any);
          })
        )
        .toPromise();

      this.logger.log('', 'getAll', LogLevel.Info, [{Result: result}]);

      const successAction: GasReduxAction = new GasReduxAction('GASES_GET_ALL_SUCCESS', {gases: result});

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'GASES_GET_ALL_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async postOne(action: GasReduxAction, stateService: StateService) {
    try {
      await stateService.loggedIn$.toPromise();

      const domainObject = {
        ...(action.payload as ApiPayloadSaveOneLoad).gas,
        ...{gasComposition: convertObjectIntoArrayOfKeyValues((action.payload as ApiPayloadSaveOneLoad).gas.gasComposition)}
      };

      const postGasDto = new DtoAssemblyDirector(
        'POST_GAS',
        CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_GAS.config,
        CONTRACT_POST_ONE_GAS(domainObject)
      )
        .assembleDto()
        .collect();

      const resultNew = await this.http.post<OkResponse<any>>(url + '/' + domainObject.document.id, postGasDto).toPromise();

      this.logger.log('', 'save', LogLevel.Info, [{Result: resultNew}]);

      const successAction: GasReduxAction = new GasReduxAction('GASES_SAVE_ONE_SUCCESS', {
        gas: (action.payload as ApiPayloadSaveOneLoad).gas
      });
      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'GASES_SAVE_ONE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async deleteOne(action: GasReduxAction, stateService: StateService) {
    try {
      await stateService.loggedIn$.toPromise();

      let completeUrl = `${url}/${(action.payload as ApiPayloadDeleteOneLoad).gas.document.id}`;

      if ((action.payload as ApiPayloadDeleteOneLoad).allUsers) {
        completeUrl += '/allUsers';
      }

      const result = await this.http.delete<OkResponse<any>>(completeUrl).toPromise();

      this.logger.log('', 'delete', LogLevel.Info, [{Result: result}]);

      const successAction: GasReduxAction = new GasReduxAction('GASES_DELETE_ONE_SUCCESS', {
        gas: (action.payload as ApiPayloadDeleteOneLoad).gas
      });
      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'GASES_DELETE_ONE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }
}
