<div class="backdrop" @fadeInOut *ngIf="open"></div>
<div class="dialog-container" (mousedown)="close()" @openClose *ngIf="open">
  <mat-card appearance="outlined" (mousedown)="$event.stopPropagation()" class="dialog-container-content mat-elevation-z9">
    <div class="dialog-header" style="padding: 1rem; text-align: center; color: orangered">
      <ng-content select="[header]"></ng-content>
      <div>
        <button class="mat-elevation-z0" mat-mini-fab (click)="close()" color="primary" data-test="full-screen-dialog-dismiss">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <div class="dialog-body" style="padding: 1rem">
      <ng-content select="[body]"></ng-content>
    </div>
    <div class="dialog-footer" style="padding: 1rem">
      <ng-content select="[footer]"></ng-content>
    </div>
  </mat-card>
</div>
