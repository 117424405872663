import {UnitSystemDocument} from '../model/unit/unit-system-document';
import {convertKeyValueArrayIntoObject, DoAssemblyDirector, Dto} from '@flowtwin/communication';
import {PROP_COLLECTION_MAP_GET_UNIT_SYSTEMS} from '@state/state-service/do-mapper/contracts/unit-systems';

export class DoMapperUnitSystem {
  static mapToUnitSystems(unitSystems: Dto): UnitSystemDocument[] {
    const assembledDo = new DoAssemblyDirector(unitSystems, PROP_COLLECTION_MAP_GET_UNIT_SYSTEMS)
      .createInstance<UnitSystemDocument>(UnitSystemDocument)
      .assembleTransposedDo<UnitSystemDocument>()
      .collect();

    assembledDo.forEach(a => {
      a.unitSystem = convertKeyValueArrayIntoObject(a.unitSystem);
    });

    return assembledDo;
  }
}
