import {environment} from '@env/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {StateService} from '../state-service/state.service';
import {UpgradeReduxAction} from '../actions/actions-upgrade';
import {OkResponse} from '../state-service/do-mapper/model/communication/commands';
import {Dto} from '@flowtwin/communication';
import {map} from 'rxjs/operators';
import {ReduxAction} from '@state/actions/actions-abstract';
import {ApiErrorHandleConfig, handleApiError} from '@state/epics/utility/handle-api-error';
import {ErrorService} from '@state/state-service/error.service';

const projectsUpgradeUrl = environment.url.aws + '/projects';

@Injectable({
  providedIn: 'root'
})
export class UpgradeEpicService {
  constructor(private http: HttpClient, private errorService: ErrorService) {}

  async updateProject(action: UpgradeReduxAction, stateService: StateService) {
    try {
      await stateService.loggedIn$.toPromise();

      await this.http
        .patch<OkResponse<Dto>>(`${projectsUpgradeUrl}/${action.payload.project.document.id}`, {})
        .pipe(
          map(res => {
            console.log(`Project of Id: ${res.data} has been upgraded.`);
          })
        )
        .toPromise();

      // TODO @Arnold: Please check TODO in UpgradeReducerService.setStateOfUpgradeProcess()
      const successAction: UpgradeReduxAction = new UpgradeReduxAction('UPGRADE_PROJECT_SUCCESS', {project: null, state: 'succeed'});

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'UPGRADE_PROJECT_ERROR',
        reduxPayload: {project: null, state: 'error'}
      };

      handleApiError(errorHandleConfig);
    }
  }
}
