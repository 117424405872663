import {Injectable} from '@angular/core';
import {StateService} from '@state/state-service/state.service';
import {TagEpicService} from '@state/epics/tag-epic.service';
import {ApiPayloadGetOneSuccess, BasicProjectReduxAction} from '@state/actions/actions-basic-project';
import {ProjectEpicService} from '@state/epics/project-epic.service';
import {TagReduxAction} from '@state/actions/actions-tag';
import {set} from 'lodash';

@Injectable({
  providedIn: null
})
export class BasicProjectReducerService {
  static assignHandler(
    action: BasicProjectReduxAction,
    stateService: StateService,
    projectEpic: ProjectEpicService,
    tagEpic: TagEpicService
  ) {
    switch (action.type) {
      case 'BASIC_PROJECT_GET_ONE_LOADING':
        BasicProjectReducerService.clearTags(stateService);
        projectEpic.getOneById(action, stateService);
        tagEpic.getAll(action as unknown as TagReduxAction, stateService);
        break;
      case 'BASIC_PROJECT_GET_ONE_SUCCESS':
        BasicProjectReducerService.updateBasicProject(action.payload as ApiPayloadGetOneSuccess, stateService);
        break;
      case 'BASIC_PROJECT_GET_ONE_ERROR':
        // Handled by ErrorService
        break;
      case 'BASIC_PROJECT_RESET':
        BasicProjectReducerService.reset(stateService);
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any BasicProject Reducer-Function.`);
        break;
    }
  }

  static clearTags(stateService: StateService) {
    stateService.update(
      s => {
        s.basicProject.tags = null;
      },
      {
        type: 'Tags State Cleared'
      }
    );
  }

  static updateBasicProject(payload: ApiPayloadGetOneSuccess, stateService: StateService) {
    stateService.update(
      s => {
        const tagName = s.basicProject.data?.document?.tag;
        s.basicProject.data = payload.project;

        if (tagName) {
          set(s, 'basicProject.data.document.tag', tagName);
        }
      },
      {
        type: 'Basic Project updated',
        details: {results: payload}
      }
    );
  }

  static reset(stateService: StateService) {
    stateService.update(
      s => {
        s.basicProject.data = null;
        s.basicProject.tags = null;
      },
      {
        type: 'Basic Project resetted',
        details: {results: null}
      }
    );
  }
}
