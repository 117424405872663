import {ReduxAction} from '@state/actions/actions-abstract';
import {BasicProject} from '@state/state-service/do-mapper/model/project/project-basic';

export type BasicProjectActions =
  | 'BASIC_PROJECT_GET_ONE_LOADING'
  | 'BASIC_PROJECT_GET_ONE_SUCCESS'
  | 'BASIC_PROJECT_GET_ONE_ERROR'
  | 'BASIC_PROJECT_RESET';

// tslint:disable-next-line:no-empty-interface
export interface ApiPayloadGeneric {}

export interface ApiPayloadGetOneSuccess {
  project: BasicProject;
}

export interface ApiPayloadGetOneLoading {
  projectId: string;
}

export interface ApiPayloadDeleteOneSuccess {}

export type BasicProjectPayloads = ApiPayloadGeneric | ApiPayloadGetOneSuccess | ApiPayloadGetOneLoading;

export class BasicProjectReduxAction extends ReduxAction {
  type: BasicProjectActions;
  payload: BasicProjectPayloads;

  constructor(type: BasicProjectActions, payload: BasicProjectPayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
