import {Injectable} from '@angular/core';
import {AppModeReduxAction} from '../actions/actions-app-mode';
import {AppModeEpicService} from '../epics/app-mode.epic.service';
import {StateService} from '@state/state-service/state.service';

@Injectable({
  providedIn: null
})
export class AppModeReducerService {
  static assignHandler(action: AppModeReduxAction, stateService: StateService, epic: AppModeEpicService) {
    switch (action.type) {
      case 'SET_APP_MODE_LOAD':
        epic.setAppMode(action, stateService);
        break;
      case 'SET_APP_MODE_SUCCESS':
        AppModeReducerService.setAppMode(action, stateService);
        break;
      case 'SET_APP_MODE_ERROR':
        break;
      case 'GET_APP_MODE_LOAD':
        epic.getAppMode(action, stateService);
        break;
      case 'GET_APP_MODE_SUCCESS':
        AppModeReducerService.setAppMode(action, stateService);
        break;
      case 'GET_APP_MODE_ERROR':
        break;
    }
  }

  private static setAppMode(action: AppModeReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        s.appMode.mode = action.payload.application.appMode;
      },
      {
        type: `App Mode Set to: ${action.payload.application.appMode}`
      }
    );
  }
}
