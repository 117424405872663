export function accessObjectByPath(objectToAccess, pathInObject) {
  pathInObject = pathInObject.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  pathInObject = pathInObject.replace(/^\./, ''); // strip a leading dot
  const a = pathInObject.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in objectToAccess) {
      objectToAccess = objectToAccess[k];
    } else {
      return;
    }
  }
  return objectToAccess;
}
