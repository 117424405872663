import {ApiGenericSuccess, ReduxAction} from './actions-abstract';

export type VersionActions = 'VERSIONS_GET_ALL_LOAD' | 'VERSIONS_GET_ALL_SUCCESS' | 'VERSIONS_GET_ALL_ERROR';

export type VersionPayloads = ApiGenericSuccess;

export class VersionReduxAction extends ReduxAction {
  type: VersionActions;

  constructor(type: VersionActions, payload: VersionPayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
