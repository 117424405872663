import {HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {StateService} from '@state/state-service/state.service';
import {Dialogs, DialogState, ModalReduxAction} from '@state/actions/actions-modal';

@Injectable({
  providedIn: 'root'
})
export class ValidationInterceptor implements HttpInterceptor {
  constructor(private stateService: StateService) {}

  handleErr(request: HttpRequest<any>, errorResponse: HttpErrorResponse) {
    // If the validation failed in the AWS backend, the server sends a 406 - Not Acceptable response    //
    if (errorResponse.status === 406) {
      const errorDialog: ModalReduxAction = new ModalReduxAction('MODAL_ERROR', {
        modal: {
          dialog: Dialogs.error,
          config: {
            open: DialogState.open,
            message: JSON.stringify({errorResponse, request}, null, 2),
            error: errorResponse,
            cause: 'Validation Interceptor: Handle Error.'
          }
        }
      });
      this.stateService.dispatch(errorDialog);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(
        ok => {
          // do nothing
        },
        error => this.handleErr(req, error)
      )
    );
  }
}

export const ValidationInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ValidationInterceptor,
  multi: true
};
