import {Injectable} from '@angular/core';
import {filter, map, take} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '@env/environment';
import {StateService} from '@state/state-service/state.service';
import {OkResponse} from '../state-service/do-mapper/model/communication/commands';
import {
  ApiPayloadCreateOneLoad,
  ApiPayloadCreateOneSuccess,
  ApiPayloadDeleteByIdLoad,
  ApiPayloadGetAppByIdLoad,
  ApiPayloadGetByIdLoad,
  ApiPayloadSaveOneLoad,
  ApiPayloadShareByIdLoad,
  ProjectReduxAction
} from '../actions/actions-project';
import {SAVE_STATE} from '@state/model/state.model';
import {ErrorService} from '@state/state-service/error.service';
import {GenericModalPayload} from '@state/actions/actions-modal';
import {CONTRACTS, Dto, DtoAssemblyDirector, DtoDefinition} from '@flowtwin/communication';
import {DoMapperProject} from '@state/state-service/do-mapper/mappers/project';
import {DoMapperSizing} from '@state/state-service/do-mapper/mappers/app-sizing';
import {DoMapperUncertainty} from '@state/state-service/do-mapper/mappers/app-uncertainty';
import {AppTransducerInput} from '@state/state-service/do-mapper/model/apps/app-transducer';
import {
  PROP_COLLECTION_MAP_APP_SIZING_INPUT,
  PROP_COLLECTION_MAP_APP_SIZING_OUTPUT
} from '@state/state-service/do-mapper/contracts/app-sizing';
import {PROP_COLLECTION_MAP_PROJECT_CREATE, PROP_COLLECTION_MAP_PROJECT_GET} from '@state/state-service/do-mapper/contracts/project';
import {PROP_COLLECTION_MAP_APP_UNCERTAINTY_INPUT} from '@state/state-service/do-mapper/contracts/app-uncertainty';
import {CONTRACT_CREATE_PROJECT} from '@state/state-service/dto-mapper/domain-contracts/project';
import {SAVING_ENDPOINT_REFERENCE_CONTEXT} from '@state/epics/utility/endpoint-reference-context';
import {successHandler} from '@state/epics/utility/success-call-to-save-api';
import {DoMapperTransducer} from '@state/state-service/do-mapper/mappers/app-transducer';
import {PROP_COLLECTION_MAP_APP_TRANSDUCER_PERFORMANCE_INPUT} from '@state/state-service/do-mapper/contracts/app-transducer';
import {AppUncertaintyInput} from '@state/state-service/do-mapper/model/apps/app-uncertainty';
import {AppSizingInput, AppSizingOutput} from '@state/state-service/do-mapper/model/apps/app-sizing';
import {SubDtoMapperProject} from '@state/state-service/dto-mapper/sub-mappers/project';
import {BasicProject} from '@state/state-service/do-mapper/model/project/project-basic';
import {ReduxAction} from '@state/actions/actions-abstract';
import {clone} from '@shared/utility/clone';
import {NavigationStart, Router} from '@angular/router';
import {BasicProjectReduxAction} from '@state/actions/actions-basic-project';
import {ApiErrorHandleConfig, handleApiError} from '@state/epics/utility/handle-api-error';

const URL_PROJECTS = `${environment.url.aws}/projects`;
const URL_PROJECT = `${environment.url.aws}/project`;

@Injectable({
  providedIn: null
})
export class ProjectEpicService {
  constructor(private http: HttpClient, private errorService: ErrorService, private router: Router) {}

  async restore(action: ProjectReduxAction, stateService: StateService) {
    try {
      await this.http
        .get<OkResponse<any>>(`${URL_PROJECTS}/${(action.payload as ApiPayloadSaveOneLoad).project.document.id}/restore`)
        .toPromise();

      const successAction: ProjectReduxAction = new ProjectReduxAction('PROJECT_CREATE_SUCCESS', {
        project: (action.payload as ApiPayloadSaveOneLoad).project
      } as ApiPayloadCreateOneSuccess);

      stateService.dispatch(successAction);
    } catch (error) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'PROJECT_RESTORE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async create(action: ProjectReduxAction, stateService: StateService) {
    try {
      const {project} = action.payload as ApiPayloadCreateOneLoad;

      const projectDto: Dto = new DtoAssemblyDirector(
        'PROJECT_CREATE' as DtoDefinition,
        CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_CREATE_PROJECT.config,
        CONTRACT_CREATE_PROJECT(project)
      )
        .assembleDto()
        .collect();

      const result = await this.http.post<OkResponse<any>>(URL_PROJECT, projectDto).toPromise();

      let resultState: SAVE_STATE;

      switch (result.status) {
        case 'ok':
          resultState = 'saved';
          break;
        default:
          resultState = 'unsaved';
          break;
      }

      const domainObject = DoMapperProject.mapToBasicProject(PROP_COLLECTION_MAP_PROJECT_CREATE, result.data);

      const successAction: ProjectReduxAction = new ProjectReduxAction('PROJECT_CREATE_SUCCESS', {
        project: domainObject,
        saveState: resultState
      } as ApiPayloadCreateOneSuccess);

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'PROJECT_CREATE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async update(action: ProjectReduxAction, stateService: StateService) {
    try {
      for (const key of Object.keys((action.payload as ApiPayloadSaveOneLoad).project)) {
        const refs = SAVING_ENDPOINT_REFERENCE_CONTEXT.filter(er => er.key === key);

        if (refs) {
          let dto = null;
          for (const ref of refs) {
            if (ref.key === 'document') {
              dto = ref.dtoAssemblyHandler(ref, {
                document: clone((action.payload as ApiPayloadSaveOneLoad).project[key]),
                custom: clone((action.payload as ApiPayloadSaveOneLoad).project['custom']),
                meta: clone((action.payload as ApiPayloadSaveOneLoad).project['meta'])
              });
            } else {
              dto = ref.dtoAssemblyHandler(ref, clone((action.payload as ApiPayloadSaveOneLoad).project[key]));
            }

            if (dto) {
              const projectId = (action.payload as ApiPayloadSaveOneLoad).project.document.id;

              const {isBasicProject} = action.payload as ApiPayloadSaveOneLoad;

              const tagId = isBasicProject ? '-' : (action.payload as ApiPayloadSaveOneLoad).tag.id;
              await ref.endpointCallingHandler(this.http, projectId, tagId, ref, dto, ref.partOfApp, URL_PROJECTS);
            }
          }
        }
      }

      successHandler((action.payload as ApiPayloadSaveOneLoad).project, stateService);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'PROJECT_SAVE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async getAllForUser(action: ProjectReduxAction, stateService: StateService) {
    try {
      const fetchDeleted =
        stateService.state.projects.tableView.showDeleted === undefined ? false : stateService.state.projects.tableView.showDeleted;
      const fetchOutdated =
        stateService.state.projects.tableView.showOutdated === undefined ? false : stateService.state.projects.tableView.showOutdated;

      const projects = await this.http
        .get<OkResponse<Dto>>(`${URL_PROJECTS}?deleted=${fetchDeleted}&outdated=${fetchOutdated}`)
        .pipe(
          map(res => {
            if (!res.data.payload.length) {
              return [];
            }
            return DoMapperProject.mapToBasicProjects(res.data);
          })
        )
        .toPromise();

      const successAction: ProjectReduxAction = new ProjectReduxAction('PROJECTS_GET_ALL_SUCCESS', {projects});
      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'PROJECTS_GET_ALL_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async getAllForAdmin(action: ProjectReduxAction, stateService: StateService) {
    try {
      let lastEvaluatedKeys = [undefined, undefined];
      let routeChanged = false;

      const navigationStart$ = this.router.events
        .pipe(
          filter(event => event instanceof NavigationStart),
          take(1)
        )
        .toPromise();

      do {
        const [lastEvaluatedKey1, lastEvaluatedKey2] = lastEvaluatedKeys;

        const response = await this.http
          .get<OkResponse<Dto>>(`${URL_PROJECTS}admin?lQ1=${lastEvaluatedKey1}&lQ2=${lastEvaluatedKey2}`)
          .pipe(
            map(res => {
              if (!res.data.payload.length) {
                return [];
              }
              return {
                projects: DoMapperProject.mapToBasicProjects(res.data) as BasicProject[],
                lastKeys: JSON.parse(res.data.payload[res.data.payload.length - 1].value.toString()) as any
              };
            })
          )
          .toPromise();

        lastEvaluatedKeys = response?.['lastKeys']
          ? response?.['lastKeys']?.map(lk => lk?.partitionKey.substring(8))
          : [undefined, undefined];

        // Handle the retrieved projects so far
        const successAction: ProjectReduxAction = new ProjectReduxAction('PROJECTS_GET_ALL_ADMIN_SUCCESS', {
          projects: response['projects'],
          isAllFetched: false
        });
        stateService.dispatch(successAction);

        if (!routeChanged) {
          routeChanged = (await Promise.race([navigationStart$, new Promise(resolve => setTimeout(resolve, 0))])) as boolean;
        }
      } while (lastEvaluatedKeys.some(x => x !== undefined) && !routeChanged);

      // Fire completion action
      const successAction: ProjectReduxAction = new ProjectReduxAction('PROJECTS_GET_ALL_ADMIN_SUCCESS', {
        projects: [],
        isAllFetched: true
      });
      stateService.dispatch(successAction);
    } catch (error) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'PROJECTS_GET_ALL_ADMIN_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async getOneById(action: ProjectReduxAction | BasicProjectReduxAction, stateService: StateService) {
    try {
      const project = await this.http
        .get<OkResponse<any>>(URL_PROJECTS + '/' + (action.payload as ApiPayloadGetByIdLoad).projectId)
        .toPromise();

      const domainObject = DoMapperProject.mapToBasicProject(PROP_COLLECTION_MAP_PROJECT_GET, project.data);

      const successAction = new BasicProjectReduxAction('BASIC_PROJECT_GET_ONE_SUCCESS', {project: domainObject});
      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'BASIC_PROJECT_GET_ONE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async getAppById(action: ProjectReduxAction, stateService: StateService) {
    try {
      const apiResponses: OkResponse<any>[] = [];
      const MAX_ENDPOINT_CALLS = 2;

      const payload: ApiPayloadGetAppByIdLoad = action.payload as ApiPayloadGetAppByIdLoad;

      apiResponses.push(
        await this.http
          .get<OkResponse<any>>(`${URL_PROJECTS}/${payload.projectId}/tags/${payload.tagId}/${payload.appId}/input`)
          .toPromise()
      );
      apiResponses.push(
        await this.http
          .get<OkResponse<any>>(`${URL_PROJECTS}/${payload.projectId}/tags/${payload.tagId}/${payload.appId}/output`)
          .toPromise()
      );

      apiResponses.reduce((promise, result, index) => {
        try {
          // TODO @Arnold: Pretty sure we have a timing problem here. As we are not awaiting the response, you can not be sure that the second call finishes before the first one. But this is the assumption made in line 266 "if (index === MAX_ENDPOINT_CALLS - 1)"
          return promise.then(() => responseHandler(result, index));
        } catch (e: any) {
          throw new Error(e);
        }
      }, Promise.resolve());

      let appInputDomain: AppSizingInput | AppUncertaintyInput | AppTransducerInput | null = null;
      let appOutputDomain: AppSizingOutput | null = null;

      function responseHandler(result: OkResponse<any>, index: number) {
        const typedDto = result?.data ? result.data : null;

        switch (payload.appId) {
          case 'selection-sizing':
            if (index % 2 === 0) {
              appInputDomain = typedDto ? DoMapperSizing.mapToLoadAppSizingInput(PROP_COLLECTION_MAP_APP_SIZING_INPUT, typedDto) : null;
            } else {
              appOutputDomain = typedDto ? DoMapperSizing.mapToAppSizingOutput(PROP_COLLECTION_MAP_APP_SIZING_OUTPUT, typedDto) : null;
            }
            break;
          case 'uncertainty-prediction':
            if (index % 2 === 0) {
              appInputDomain = typedDto
                ? DoMapperUncertainty.mapToAppUncertaintyInput(PROP_COLLECTION_MAP_APP_UNCERTAINTY_INPUT, typedDto)
                : null;
            } else {
              // TODO: Implement when another page depends on result
              appOutputDomain = typedDto ? null : null;
            }
            break;
          case 'transducer-performance':
            if (index % 2 === 0) {
              appInputDomain = typedDto
                ? DoMapperTransducer.mapToAppTransducerInput(PROP_COLLECTION_MAP_APP_TRANSDUCER_PERFORMANCE_INPUT, typedDto)
                : null;
            } else {
              // TODO: Implement when another page depends on result
              appOutputDomain = typedDto ? null : null;
            }
            break;
          default:
            throw new Error(`Not yet implemented for app ${(action.payload as ApiPayloadGetAppByIdLoad).appId}`);
        }

        if (index === MAX_ENDPOINT_CALLS - 1) {
          const successAction: ProjectReduxAction = new ProjectReduxAction('APP_GET_BY_ID_SUCCESS', {
            projectId: (action.payload as ApiPayloadGetAppByIdLoad).projectId,
            appId: (action.payload as ApiPayloadGetAppByIdLoad).appId,
            input: appInputDomain,
            output: appOutputDomain
          });

          stateService.dispatch(successAction);
        }
      }
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'APP_GET_BY_ID_ERROR',
        reduxPayload: {appId: (action.payload as ApiPayloadGetAppByIdLoad).appId}
      };

      handleApiError(errorHandleConfig);
    }
  }

  async deleteOneById(action: ProjectReduxAction, stateService: StateService) {
    try {
      let endpointUrl = URL_PROJECTS + '/' + (action.payload as ApiPayloadDeleteByIdLoad).projectId;

      if ((action.payload as ApiPayloadDeleteByIdLoad).deleteForAll) {
        endpointUrl += '/allusers';
      }

      await this.http.delete<OkResponse<any>>(endpointUrl).toPromise();

      const successAction: ProjectReduxAction = new ProjectReduxAction('PROJECT_DELETE_BY_ID_SUCCESS', {
        projectId: (action.payload as ApiPayloadDeleteByIdLoad).projectId
      });

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'PROJECT_DELETE_BY_ID_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async shareById(action: ProjectReduxAction, stateService: StateService) {
    try {
      const endpointUrl = `${URL_PROJECTS}/${(action.payload as ApiPayloadShareByIdLoad).projectId}/share`;

      const dto = SubDtoMapperProject.mapToShareProject((action.payload as ApiPayloadShareByIdLoad).userList);

      await this.http.post<OkResponse<any>>(endpointUrl, dto).toPromise();

      const successAction: ProjectReduxAction = new ProjectReduxAction('PROJECT_SHARE_SUCCESS', {
        projectId: (action.payload as ApiPayloadShareByIdLoad).projectId,
        userList: (action.payload as ApiPayloadShareByIdLoad).userList
      });

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'PROJECT_SHARE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }
}
