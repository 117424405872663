import {AppTransducerInput, AppTransducerOutput} from '../../do-mapper/model/apps/app-transducer';
import {CONTRACTS, convertObjectIntoArrayOfKeyValues, Dto, DtoAssemblyDirector} from '@flowtwin/communication';
import {clone} from '@shared/utility/clone';
import {CONTRACT_POST_APP_TRANSDUCER_INPUT} from '@state/state-service/dto-mapper/domain-contracts/project';
import {POST_COMPUTE_TRANSDUCER} from '@state/state-service/dto-mapper/domain-contracts/app-transducer';
import {User} from '@state/state-service/do-mapper/model/communication/user';
import {GasCompositionDoc} from '@state/state-service/do-mapper/model/gas/gas-composition-doc';

export class SubDtoMapperAppTransducer {
  static mapToSaveAppTransducerInput(appTransducerInput: AppTransducerInput): Dto {
    if (!appTransducerInput || !appTransducerInput.pressure || !appTransducerInput.temperature) {
      return null;
    }

    const domainObject = clone(appTransducerInput);

    return new DtoAssemblyDirector(
      'APP_TRANSDUCER_PERFORMANCE_INPUT_SAVE',
      CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_APP_TRANSDUCER_PERFORMANCE_INPUT.config,
      CONTRACT_POST_APP_TRANSDUCER_INPUT(domainObject)
    )
      .assembleDto()
      .collect();
  }

  static mapToSaveAppTransducerOutput(appTransducerOutput: AppTransducerOutput): Dto {
    if (!appTransducerOutput) {
      return null;
    }

    return null;
  }

  static mapToComputeTransducer(transducerInput: AppTransducerInput, user: User, gasCompositions: GasCompositionDoc[]) {
    // TODO: Find gas Comp from ID

    const enrichedDomainObject = {
      ...transducerInput,
      ...user.document,
      gasComposition: gasCompositions.find(gc => gc.document.id === transducerInput['gasCompositionId']),
      userGroup: 'none'
    };

    enrichedDomainObject.gasComposition = convertObjectIntoArrayOfKeyValues(enrichedDomainObject.gasComposition.gasComposition) as any;

    return new DtoAssemblyDirector(
      'APP_TRANSDUCER_PERFORMANCE_REQUEST',
      CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_APP_TRANSDUCER_PERFORMANCE_RESULT.config,
      POST_COMPUTE_TRANSDUCER(enrichedDomainObject)
    )
      .assembleDto()
      .collect();
  }
}
