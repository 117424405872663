import {HttpErrorResponse} from '@angular/common/http';
import {GenericModalPayload} from '@state/actions/actions-modal';
import {FlowTwinError} from '@state/state-service/do-mapper/model/communication/error';
import {DoMapperFlowTwinError} from '@state/state-service/do-mapper/mappers/flow-twin-error';
import {PROP_COLLECTION_MAP_FLOW_TWIN_ERROR} from '@state/state-service/do-mapper/contracts/flow-twin-error';
import {Actions, ReduxAction} from '@state/actions/actions-abstract';
import {StateService} from '@state/state-service/state.service';
import {ErrorService} from '@state/state-service/error.service';

export type ApiErrorHandleConfig = {
  error: HttpErrorResponse;
  stateService: StateService;
  errorService: ErrorService;
  reduxAction: Actions;
  reduxPayload: any | null;
};

export function getErrorModalConfig(error: HttpErrorResponse): Pick<GenericModalPayload, 'modal'> {
  const flowTwinError: FlowTwinError = DoMapperFlowTwinError.mapToFlowTwinError(PROP_COLLECTION_MAP_FLOW_TWIN_ERROR, error.error);

  return {
    modal: {
      config: {
        message: `${flowTwinError.errorMessage} (Code: ${flowTwinError.errorCode})` || JSON.stringify({error}, null, 2),
        error
      }
    }
  };
}

export function handleApiError({error, errorService, stateService, reduxPayload, reduxAction}: ApiErrorHandleConfig) {
  const errorModalConfig: Pick<GenericModalPayload, 'modal'> = getErrorModalConfig(error);

  errorService.performUnifiedErrorHandling(errorModalConfig, new ReduxAction(reduxAction, reduxPayload), stateService);
}
