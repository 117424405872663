import {AbstractDomainObject} from '@flowtwin/communication';

export class FlowTwinError extends AbstractDomainObject {
  status: string;
  errorMessage: string;
  statusCode: number;
  currentRoute: string;
  errorData: string;
  errorCode: string;
}
