import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {ILogger, LoggerConfig, LoggerService, LogLevel} from '../../services/logger/logger.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from '../state-service/error.service';
import {GenericModalPayload} from '../actions/actions-modal';
import {AppModePayload, AppModeReduxAction} from '../actions/actions-app-mode';
import {SubDtoMapperAppMode} from '@state/state-service/dto-mapper/sub-mappers/app-mode';
import {PROP_COLLECTION_MAP_APP_MODE} from '@state/state-service/do-mapper/contracts/app-mode';
import {Dto} from '../../../../../communication';
import {DoMapperAppMode} from '@state/state-service/do-mapper/mappers/app-mode';
import {OkResponse} from '@state/state-service/do-mapper/model/communication/commands';
import {StateService} from '@state/state-service/state.service';
import {ApiErrorHandleConfig, handleApiError} from '@state/epics/utility/handle-api-error';
import {ApiPayloadDeleteByIdLoad} from '@state/actions/actions-project';

const loggerConfig: LoggerConfig = {
  name: '-',
  symbol: '🧰',
  textColor: 'black',
  backgroundColor: '#f50f63'
};

const appModeUrl = environment.url.aws + '/settings';

@Injectable({
  providedIn: 'root'
})
export class AppModeEpicService {
  private logger: ILogger;

  constructor(private http: HttpClient, private errorService: ErrorService, private loggerService: LoggerService) {
    this.logger = this.initLogger();
  }

  private initLogger() {
    loggerConfig.name = this.constructor.name;
    return this.loggerService.initLogger(loggerConfig);
  }

  async getAppMode(action: AppModeReduxAction, stateService: StateService) {
    try {
      const result = await this.http.get<OkResponse<any>>(appModeUrl).toPromise();

      const domainObject = DoMapperAppMode.mapToAppMode(PROP_COLLECTION_MAP_APP_MODE, result.data as Dto);

      const successAction: AppModeReduxAction = new AppModeReduxAction('GET_APP_MODE_SUCCESS', {
        application: {
          appMode: domainObject.appMode
        }
      } as AppModePayload);

      this.logger.log('', `App Mode is: ${domainObject.appMode}`, LogLevel.Info, []);

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'GET_APP_MODE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async setAppMode(action: AppModeReduxAction, stateService: StateService) {
    try {
      const dto = SubDtoMapperAppMode.mapToAppMode(action.payload);

      await this.http.post(appModeUrl, dto).toPromise();

      const successAction: AppModeReduxAction = new AppModeReduxAction('SET_APP_MODE_SUCCESS', {
        application: action.payload.application
      } as AppModePayload);

      this.logger.log('', `App Mode set to: ${action.payload.application.appMode}`, LogLevel.Info, []);

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'SET_APP_MODE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }
}
