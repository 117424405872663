import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
  selector: 'da-form-expansion-panel',
  templateUrl: './form-expansion-panel.component.html'
})
export class FormExpansionPanelComponent extends FieldWrapper {
  @ViewChild('fieldComponent', {read: ViewContainerRef})
  fieldComponent: ViewContainerRef;

  get valid() {
    return this.formControl.valid;
  }
}
