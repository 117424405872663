import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '@env/environment';
import {Subscription} from 'rxjs';
export const MOCK_TOKEN_ID = 'FT_MOCK_TOKEN';
export const COMPUTE_INTERCEPTOR_ID = 'FT_COMPUTE_INTERCEPTOR';

@Component({
  selector: 'da-mock',
  templateUrl: './mock.component.html',
  styleUrls: ['./mock.component.scss']
})
export class MockComponent implements OnInit, OnDestroy {
  constructor(private http: HttpClient) {}

  envs: string[] = ['prod', 'uat', 'dev', 'local'];
  envsWoProd = this.envs.filter(env => env !== 'prod');
  tables: string[] = ['users', 'unit-systems', 'gas', 'projects', 'oldProjects', 'projects_user_related'];
  sourceenv: string;
  targetenv: string;
  table2Copy: string;
  table2Delete: string;

  subs: Subscription[] = [];
  title = '';

  get showMock() {
    return environment.name === 'dev';
  }

  activeUser = 'PASS_THROUGH';

  get currentMockToken() {
    return localStorage.getItem(MOCK_TOKEN_ID) || 'PASS_THROUGH';
  }

  get computeInterceptor() {
    return JSON.parse(localStorage.getItem(COMPUTE_INTERCEPTOR_ID) || 'false');
  }

  toggleComputeInterceptor() {
    localStorage.setItem(COMPUTE_INTERCEPTOR_ID, '' + !this.computeInterceptor);
  }

  copyDb() {
    if (this.sourceenv === undefined || this.targetenv === undefined || this.table2Copy === undefined) {
      alert('choose source, target environment and a tables to copy');
      return;
    }
    const src = 'twinflow_' + this.sourceenv + '_' + this.table2Copy;
    const tgt = 'twinflow_' + this.targetenv + '_' + this.table2Copy;
    this.http
      .get('http://localhost:3000/__dev/copydb?src=' + src + '&tgt=' + tgt)
      .toPromise()
      .then(() => alert('In Progress, may take some time ...'))
      .catch(() => alert('Error'));
  }

  seedDb() {
    this.http
      .get('http://localhost:3000/__dev/seed')
      .toPromise()
      .then(() => alert('Done'))
      .catch(() => alert('Error'));
  }

  upgradeAll() {
    const URL_PROJECTSUPGRADE = `${environment.url.aws}/projectsupgrade`;
    this.http
      .get(URL_PROJECTSUPGRADE)
      .toPromise()
      .then(() => alert('Done'))
      .catch(() => alert('Error'));
  }

  clearDb() {
    this.http
      .get('http://localhost:3000/__dev/clear')
      .toPromise()
      .then(() => alert('Done'))
      .catch(() => alert('Error'));
  }

  migrateProjects() {
    const URL_PROJECTSMIGRATE = `${environment.url.aws}/migrate/projects`;
    this.http
      .get(URL_PROJECTSMIGRATE)
      .toPromise()
      .then(() => alert('done'))
      .catch(e => alert(`An error occured: ${e}`));
  }

  async badRequest() {
    const url = 'http://localhost:3000/__dev/bad-request';
    const res = await this.http.get(url).toPromise();
    // we should have a bad request by now
    console.log('BadRequest:', res);
  }

  applyAndReload() {
    if (this.activeUser === 'PASS_THROUGH') {
      localStorage.removeItem(MOCK_TOKEN_ID);
    } else {
      localStorage.setItem(MOCK_TOKEN_ID, this.activeUser);
    }
    window.location.reload();
  }

  ngOnInit(): void {
    this.activeUser = this.currentMockToken;
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  clearTable() {
    if (this.table2Delete === undefined) {
      alert('Specify a table to be deleted.');
      return;
    }
    this.http
      .get(`${environment.url.aws}/delete/table?tbd=${this.table2Delete}`)
      .toPromise()
      .then(() => alert('Deletion In Progress, may take some time ...'))
      .catch(() => alert('Error'));
  }
}
