import {ReduxAction} from './actions-abstract';
import {Project} from '@state/state-service/do-mapper/model/project/project';
import {PendingState} from '@modules/pending-operation/pending-operation/pending-operation.component';

export type UpgradeActions = 'UPGRADE_PROJECT_LOAD' | 'UPGRADE_PROJECT_SUCCESS' | 'UPGRADE_PROJECT_ERROR';

export interface Upgrade {
  project: Project;
  state: PendingState;
}

export type UpgradePayload = Upgrade;

export class UpgradeReduxAction extends ReduxAction {
  type: UpgradeActions;
  payload: UpgradePayload;

  constructor(type: UpgradeActions, payload: UpgradePayload) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
