import {DtoDoPropCollectionMap} from '@flowtwin/communication';

export const PROP_COLLECTION_MAP_USER: DtoDoPropCollectionMap[] = [
  {dtoKey: 'staredProjects', doKey: 'document.staredProjects'},
  {dtoKey: 'id', doKey: 'document.id'},
  {dtoKey: 'email', doKey: 'document.email'},
  {dtoKey: 'familyName', doKey: 'document.familyName'},
  {dtoKey: 'givenName', doKey: 'document.givenName'},
  {dtoKey: 'name', doKey: 'document.name'},
  {dtoKey: 'permissions.admin', doKey: 'document.permissions.admin'},
  {dtoKey: 'permissions.supervisor', doKey: 'document.permissions.supervisor'},
  {
    dtoKey: 'permissions.gbc04expert',
    doKey: 'document.permissions.gbc04expert'
  },
  {
    dtoKey: 'permissions.gbc04service',
    doKey: 'document.permissions.gbc04service'
  },
  {dtoKey: 'permissions.gbc04sales', doKey: 'document.permissions.gbc04sales'},
  {dtoKey: 'permissions.sicksales', doKey: 'document.permissions.sicksales'},
  {
    dtoKey: 'permissions.externalpartner',
    doKey: 'document.permissions.externalpartner'
  },
  {
    dtoKey: 'permissions.externalservice',
    doKey: 'document.permissions.externalservice'
  },
  {
    dtoKey: 'permissions.externalcustomer',
    doKey: 'document.permissions.externalcustomer'
  },
  {dtoKey: 'locale', doKey: 'document.locale'},
  {dtoKey: 'externalPermissions.apps', doKey: 'document.externalPermissions.apps'},
  {dtoKey: 'externalPermissions.productFamilies', doKey: 'document.externalPermissions.productFamilies'}
];
