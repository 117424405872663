import {DtoContract} from '../contracts';

export const DOCUMENT: DtoContract[] = [
  {KEY: 'id', TYPE: 'STRING'},
  {KEY: 'name', TYPE: 'STRING'},
  {KEY: 'description', TYPE: 'STRING'},
  {KEY: 'deleted', TYPE: 'BOOLEAN'},
  {KEY: 'creator', TYPE: 'STRING'},
  {KEY: 'userList', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'}
];

export const DOCUMENT_ARRAY: DtoContract[] = [
  {KEY: 'document.id', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
  {KEY: 'document.name', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
  {KEY: 'document.description', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
  {KEY: 'document.deleted', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
  {KEY: 'document.creator', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
  {KEY: 'document.userList', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'}
];
