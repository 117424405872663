import {Injectable} from '@angular/core';
import {environment} from '@env/environment';

export interface LoggerConfig {
  name: string;
  textColor: string;
  backgroundColor: string;
  symbol: string;
}

export enum LogLevel {
  Debug,
  Info,
  Warn,
  Error,
  Off
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  initLogger(config: LoggerConfig): Logger {
    return new Logger(config);
  }
}

export interface ILogger {
  log(msg: string, tag: string | null, level: LogLevel, extraInfo: any[]): void;
}

class Logger implements ILogger {
  config: LoggerConfig = {
    name: 'Logger',
    textColor: 'white',
    backgroundColor: '#000000',
    symbol: '⚪️'
  };

  logLevel: LogLevel = LogLevel.Debug;

  constructor(config: LoggerConfig) {
    this.config = config;
  }

  private enableLogger(env: string) {
    const correctEnvironment = env === 'dev' || env === 'local' || env === 'uat';
    return correctEnvironment ? LogLevel.Debug : LogLevel.Off;
  }

  log(msg: string, tag: string, level: LogLevel = LogLevel.Debug, extraInfo: any[] = []) {
    this.logLevel = this.enableLogger(environment.name);

    if (this.shouldLog(level)) {
      switch (level) {
        case LogLevel.Error:
          this.logError(msg, tag, extraInfo);
          break;
        case LogLevel.Warn:
          this.logWarning(msg, tag, extraInfo);
          break;
        case LogLevel.Debug:
          this.logDebug(msg, tag, extraInfo);
          break;
        case LogLevel.Info:
          this.logInfo(msg, tag, extraInfo);
          break;
      }
    }
  }

  private logError(msg: string, tag: string, params: any[] = []) {
    this.logBannerError(msg, tag, params);
  }

  private logWarning(msg: string, tag: string, params: any[] = []) {
    this.logBannerWarning(msg, tag, params);
  }

  private logInfo(msg: string, tag: string, params: any[] = []) {
    this.logBannerInfo(tag, msg);

    params.forEach(param => {
      this.logBody(param);
    });

    this.logEnd();
  }

  private logDebug(msg: string, tag: string, params: any[] = []) {
    this.logBannerDebug(tag, msg);

    this.logBody(msg);
    params.forEach(param => {
      this.logBody(param);
    });

    this.logEnd();
  }

  private shouldLog(level: LogLevel): boolean {
    let ret = false;
    if ((level >= this.logLevel && level !== LogLevel.Off) || this.logLevel === LogLevel.Debug) {
      ret = true;
    }
    return ret;
  }

  private logBannerDebug = (tag: string | null, msg: string) => {
    console.groupCollapsed(
      `${this.config.symbol ? this.config.symbol : '🐛'} %c [${this.config.name}] ${tag ? tag : 'DFLT'}${msg}`.trim(),
      `color: ${this.config.textColor}; background:${this.config.backgroundColor}; padding:2px`
    );
  };

  private logBannerInfo = (tag: string | null, msg: string) => {
    console.groupCollapsed(
      `${this.config.symbol ? this.config.symbol : 'ℹ️'} %c [${this.config.name}] ${tag ? tag : 'DFLT'}${msg}`.trim(),
      `color: ${this.config.textColor}; background:${this.config.backgroundColor}; padding:2px`
    );
  };

  private logBannerWarning = console.warn.bind(console);
  private logBannerError = console.error.bind(console);

  private logBody = console.log.bind(console);

  private logEnd = console.groupEnd.bind(console);
}
