import {DtoContract} from '../../contracts';
import {EXTERNAL_USER_PERMISSIONS, USER_DOCUMENT, USER_PERMISSIONS} from '../../base-definitions/user';

export const RES_GET_USERS = {
  config: [
    {KEY: 'document.id', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'document.familyName', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'document.givenName', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'document.email', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'document.deleted', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
    {KEY: 'document.emailVerified', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
    {KEY: 'document.locale', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'permissions.admin', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
    {KEY: 'permissions.supervisor', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
    {KEY: 'permissions.gbc04expert', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
    {KEY: 'permissions.gbc04service', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
    {KEY: 'permissions.gbc04sales', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
    {KEY: 'permissions.sicksales', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
    {KEY: 'permissions.externalpartner', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
    {KEY: 'permissions.externalservice', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
    {KEY: 'permissions.externalcustomer', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
    {KEY: 'externalPermissions.apps', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'externalPermissions.productFamilies', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'}
  ] as DtoContract[]
};
export const RES_GET_ME = {
  config: [...USER_DOCUMENT, ...USER_PERMISSIONS, ...EXTERNAL_USER_PERMISSIONS] as DtoContract[]
};
