import {REQ_POST_CREATE_PROJECT, REQ_POST_PROJECT, REQ_SHARE_PROJECT} from './req/project';
import {
  REQ_POST_APP_SELECTION_SIZING_INPUT,
  REQ_POST_APP_SELECTION_SIZING_OUTPUT,
  REQ_POST_APP_SELECTION_SIZING_RESULT
} from './req/app-sizing';
import {REQ_POST_APP_TRANSDUCER_PERFORMANCE_INPUT, REQ_POST_APP_TRANSDUCER_PERFORMANCE_RESULT} from './req/app-transducer';
import {
  REQ_POST_APP_SYSTEM_UNCERTAINTY_INPUT,
  REQ_POST_APP_SYSTEM_UNCERTAINTY_OUTPUT,
  REQ_POST_APP_UNCERTAINTY_PREDICTION_RESULT
} from './req/app-uncertainty';
import {REQ_GET_GAS, REQ_POST_APP_GASDATA_RESULT, REQ_POST_GAS} from './req/gas';
import {REQ_GET_UNIT_SYSTEM, REQ_POST_UNIT_SYSTEM} from './req/unit-systems';
import {REQ_GET_ME, REQ_GET_USERS, REQ_POST_ME, REQ_POST_USERS} from './req/user';
import {RES_GET_PROJECT, RES_GET_PROJECTS, RES_POST_CREATE_PROJECT} from './res/project';
import {
  RES_GET_APP_SELECTION_SIZING_INPUT,
  RES_GET_APP_SELECTION_SIZING_OUTPUT,
  RES_POST_APP_SELECTION_SIZING_RESULT
} from './res/app-sizing';
import {
  RES_GET_APP_SYSTEM_UNCERTAINTY_INPUT,
  RES_GET_APP_SYSTEM_UNCERTAINTY_OUTPUT,
  RES_POST_APP_UNCERTAINTY_PREDICTION_RESULT
} from './res/app-uncertainty';
import {
  RES_GET_APP_TRANSDUCER_PERFORMANCE_INPUT,
  RES_GET_APP_TRANSDUCER_PERFORMANCE_OUTPUT,
  RES_POST_APP_TRANSDUCER_PERFORMANCE_RESULT
} from './res/app-transducer';
import {RES_GET_UNIT_SYSTEM, RES_GET_UNIT_SYSTEMS} from './res/unit-system';
import {RES_GET_GAS, RES_GET_GASES, RES_POST_APP_GASDATA_RESULT, RES_POST_GAS} from './res/gas';
import {RES_GET_METADATA} from './res/metadata';
import {RES_GET_ME, RES_GET_USERS} from './res/user';
import {RES_ERROR} from './res/error';
import {RES_GET_VERSIONS} from './res/version';
import {REQ_POST_FT_SETTINGS} from './req/app-flowtwin';
import {RES_GET_FT_SETTINGS} from './res/app-flowtwin';
import {RES_GET_TAG, RES_GET_TAGS, RES_POST_CREATE_TAG} from './res/tag';
import {REQ_POST_CREATE_TAG, REQ_POST_TAG} from './req/tag';

export const CONTRACTS_FE_AWS_CONFIG = {
  REQ_FE_AWS_ENDPOINTS: {
    REQ_POST_CREATE_PROJECT: REQ_POST_CREATE_PROJECT,
    REQ_POST_CREATE_TAG: REQ_POST_CREATE_TAG,
    REQ_POST_PROJECT: REQ_POST_PROJECT,
    REQ_POST_TAG: REQ_POST_TAG,
    REQ_POST_APP_SELECTION_SIZING_INPUT: REQ_POST_APP_SELECTION_SIZING_INPUT,
    REQ_POST_APP_SELECTION_SIZING_OUTPUT: REQ_POST_APP_SELECTION_SIZING_OUTPUT,
    REQ_POST_APP_SELECTION_SIZING_RESULT: REQ_POST_APP_SELECTION_SIZING_RESULT,
    REQ_POST_APP_TRANSDUCER_PERFORMANCE_INPUT: REQ_POST_APP_TRANSDUCER_PERFORMANCE_INPUT,
    REQ_POST_APP_TRANSDUCER_PERFORMANCE_RESULT: REQ_POST_APP_TRANSDUCER_PERFORMANCE_RESULT,
    REQ_POST_APP_SYSTEM_UNCERTAINTY_INPUT: REQ_POST_APP_SYSTEM_UNCERTAINTY_INPUT,
    REQ_POST_APP_SYSTEM_UNCERTAINTY_OUTPUT: REQ_POST_APP_SYSTEM_UNCERTAINTY_OUTPUT,
    REQ_POST_APP_UNCERTAINTY_PREDICTION_RESULT: REQ_POST_APP_UNCERTAINTY_PREDICTION_RESULT,
    REQ_POST_APP_GASDATA_RESULT: REQ_POST_APP_GASDATA_RESULT,
    REQ_POST_GAS: REQ_POST_GAS,
    REQ_GET_GAS: REQ_GET_GAS,
    REQ_GET_UNIT_SYSTEM: REQ_GET_UNIT_SYSTEM,
    REQ_POST_UNIT_SYSTEM: REQ_POST_UNIT_SYSTEM,
    REQ_GET_USERS: REQ_GET_USERS,
    REQ_POST_USERS: REQ_POST_USERS,
    REQ_GET_ME: REQ_GET_ME,
    REQ_POST_ME: REQ_POST_ME,
    REQ_POST_FT_SETTINGS: REQ_POST_FT_SETTINGS,
    REQ_POST_SHARE_PROJECT: REQ_SHARE_PROJECT
  },
  RES_FE_AWS_ENDPOINTS: {
    RES_POST_CREATE_PROJECT: RES_POST_CREATE_PROJECT,
    RES_POST_CREATE_TAG: RES_POST_CREATE_TAG,
    RES_GET_PROJECT: RES_GET_PROJECT,
    RES_GET_TAG: RES_GET_TAG,
    RES_GET_PROJECTS: RES_GET_PROJECTS,
    RES_GET_TAGS: RES_GET_TAGS,
    RES_GET_APP_SELECTION_SIZING_INPUT: RES_GET_APP_SELECTION_SIZING_INPUT,
    RES_GET_APP_SELECTION_SIZING_OUTPUT: RES_GET_APP_SELECTION_SIZING_OUTPUT,
    RES_POST_APP_SELECTION_SIZING_RESULT: RES_POST_APP_SELECTION_SIZING_RESULT,
    RES_GET_APP_SYSTEM_UNCERTAINTY_OUTPUT: RES_GET_APP_SYSTEM_UNCERTAINTY_OUTPUT,
    RES_GET_APP_SYSTEM_UNCERTAINTY_INPUT: RES_GET_APP_SYSTEM_UNCERTAINTY_INPUT,
    RES_POST_APP_UNCERTAINTY_PREDICTION_RESULT: RES_POST_APP_UNCERTAINTY_PREDICTION_RESULT,
    RES_GET_APP_TRANSDUCER_PERFORMANCE_INPUT: RES_GET_APP_TRANSDUCER_PERFORMANCE_INPUT,
    RES_GET_APP_TRANSDUCER_PERFORMANCE_OUTPUT: RES_GET_APP_TRANSDUCER_PERFORMANCE_OUTPUT,
    RES_POST_APP_TRANSDUCER_PERFORMANCE_RESULT: RES_POST_APP_TRANSDUCER_PERFORMANCE_RESULT,
    RES_GET_UNIT_SYSTEM: RES_GET_UNIT_SYSTEM,
    RES_GET_UNIT_SYSTEMS: RES_GET_UNIT_SYSTEMS,
    RES_POST_GAS: RES_POST_GAS,
    RES_GET_GAS: RES_GET_GAS,
    RES_GET_GASES: RES_GET_GASES,
    RES_POST_APP_GASDATA_RESULT: RES_POST_APP_GASDATA_RESULT,
    RES_GET_USERS: RES_GET_USERS,
    RES_GET_ME: RES_GET_ME,
    RES_GET_METADATA: RES_GET_METADATA,
    RES_ERROR: RES_ERROR,
    RES_GET_VERSIONS: RES_GET_VERSIONS,
    RES_GET_FT_SETTINGS: RES_GET_FT_SETTINGS
  }
};
