import {GasComposition_DTO, GasCompositionDoc_DTO} from '../../dto-mapper/model/gas/gas-compositions-dto';
import {GasComposition, GasCompositionDoc} from '../model/gas/gas-composition-doc';
import {DEFAULT_GASES_DTO} from '../../dto-mapper/model/gas/default-gases-dto';
import {convertKeyValueArrayIntoObject, DoAssemblyDirector, Dto} from '@flowtwin/communication';
import {PROP_COLLECTION_MAP_GASES_GET} from '@state/state-service/do-mapper/contracts/gases';

export class DoMapperGasCompositions {
  static mapToGasCompositionsDocs(gases: Dto) {
    const assembledDo = new DoAssemblyDirector(gases, PROP_COLLECTION_MAP_GASES_GET)
      .createInstance<GasCompositionDoc>(GasCompositionDoc)
      .assembleTransposedDo<GasCompositionDoc>()
      .collect();

    assembledDo.forEach(gas => (gas.gasComposition = convertKeyValueArrayIntoObject(gas.gasComposition)));

    return assembledDo;
  }

  static mapToGasCompositionDoc(gas: GasCompositionDoc_DTO) {
    if (gas.hasOwnProperty('gasComposition') && gas.hasOwnProperty('document')) {
      return gas;
    }

    return {
      document: {
        id: gas?.id || '',
        name: gas?.name || '',
        deleted: gas?.deleted || false,
        userList: gas?.userList || [],
        creator: gas?.creator || '',
        description: gas?.description || ''
      },
      gasComposition: DoMapperGasCompositions.mapToGasComposition(gas)
    };
  }

  static mapToGasComposition(gasComposition: GasComposition_DTO = DEFAULT_GASES_DTO[0]): GasComposition {
    return {
      argon: gasComposition.argon || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      carbonDioxide: gasComposition.carbonDioxide || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      carbonMonoxide: gasComposition.carbonMonoxide || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      ethane: gasComposition.ethane || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      helium: gasComposition.helium || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      hydrogen: gasComposition.hydrogen || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      hydrogenSulfide: gasComposition.hydrogenSulfide || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      iButane: gasComposition.iButane || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      iPentane: gasComposition.iPentane || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      methane: gasComposition.methane || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      nButane: gasComposition.nButane || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      nDecane: gasComposition.nDecane || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      nHeptane: gasComposition.nHeptane || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      nHexane: gasComposition.nHexane || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      nitrogen: gasComposition.nitrogen || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      nNonane: gasComposition.nNonane || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      nOctane: gasComposition.nOctane || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      nPentane: gasComposition.nPentane || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      oxygen: gasComposition.oxygen || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      propane: gasComposition.propane || {
        value: 0,
        unit: 'MolAmountRatio'
      },
      water: gasComposition.water || {
        value: 0,
        unit: 'MolAmountRatio'
      }
    };
  }
}
