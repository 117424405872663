import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '@env/environment';
import {COMPUTE_INTERCEPTOR_ID} from '@modules/mock/mock/mock.component';
import {createDefaultFlowTwinProject} from '@state/state-service/do-mapper/model/project/default-project';
import {ILogger, LoggerConfig, LoggerService, LogLevel} from '../../../services/logger/logger.service';

const loggerConfig: LoggerConfig = {
  name: '-',
  symbol: '🔥',
  textColor: 'white',
  backgroundColor: 'SlateBlue'
};

class MockHandler implements HttpHandler {
  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    const body = {data: {project: createDefaultFlowTwinProject()}};
    const status = 200;
    const event = new HttpResponse({body, status, url: req.url});
    return of(event);
  }
}

//
// Compute Interceptor
//
//
@Injectable({
  providedIn: 'root'
})
class ComputeInterceptor implements HttpInterceptor {
  private logger: ILogger;

  constructor(private loggerService: LoggerService) {
    this.logger = this.initLogger();
  }

  private initLogger() {
    loggerConfig.name = this.constructor.name;
    return this.loggerService.initLogger(loggerConfig);
  }

  get shouldIntercept() {
    return JSON.parse(localStorage.getItem(COMPUTE_INTERCEPTOR_ID) || 'false');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.production) {
      return next.handle(req);
    }

    const match = req.url.match('compute');

    if (!match) {
      return next.handle(req);
    }

    if (!this.shouldIntercept) {
      return next.handle(req);
    }

    this.logger.log('', 'Intercept compute request: ' + req.body.command, LogLevel.Info, [{Body: req.body}]);

    const mockHandler = new MockHandler();

    return mockHandler.handle(req);
  }
}

export const ComputeInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ComputeInterceptor,
  multi: true
};
