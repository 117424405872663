import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {ModalReduxAction} from '@state/actions/actions-modal';

@Injectable({
  providedIn: null
})
export class ModalReducerService {
  static assignHandler(action: ModalReduxAction, stateService: StateService) {
    switch (action.type) {
      case 'MODAL_OK':
        ModalReducerService.updateDialogState(action, stateService);
        break;
      case 'MODAL_INFO':
        break;
      case 'MODAL_WARNING':
        break;
      case 'MODAL_ERROR':
        ModalReducerService.updateDialogState(action, stateService);
        break;
    }
  }

  private static updateDialogState(action: ModalReduxAction, stateService: StateService) {
    const {dialog, config} = action.payload.modal;
    if (!dialog || !config) {
      throw new Error(`dialog or config is undefined: dialog ${dialog}, config ${config}`);
    }

    stateService.update(
      state => {
        state.dialogs[dialog].open = Boolean(config.open);
        state.dialogs[dialog].message = config.message;
        state.dialogs[dialog].error = config.error;
        state.dialogs[dialog].cause = config.cause;
      },
      {type: 'ExceptionDialogComponent.ok'}
    );
  }
}
