import {environment} from '@env/environment';
import {State} from '@state/model/state.model';

export const defaultState: State = {
  appMode: {
    mode: 'OPERATIONAL'
  },
  pages: {
    projects: 'loading',
    project: 'loading',
    gases: 'loading',
    customUnits: 'loading',
    users: 'loading',
    mock: 'loading',
    crm: 'loading',
    appSizing: 'loading',
    appUncertainty: 'loading',
    appTransducer: 'loading'
  },
  user: {
    state: 'loading',
    document: {
      /**
       * TODO @Arnold: General problem I have with the default values approach: We are only satisfying the compiler which gives us a false sense of security.
       *  When using the default object the compiler can not help us to indicate that the "real" user may not have been set at the time of the access.
       *  Similar to the problem with the default project.
       */
      id: '',
      deleted: false,
      emailVerified: false,
      locale: '',
      givenName: '',
      familyName: '',
      email: '',
      staredProjects: [],
      permissions: {
        admin: false,
        supervisor: false,
        gbc04expert: false,
        gbc04service: false,
        gbc04sales: false,
        sicksales: false,
        externalpartner: false,
        externalservice: false,
        externalcustomer: true
      },
      externalPermissions: {
        apps: [],
        productFamilies: []
      }
    }
  },
  projects: {
    state: 'init',
    data: [],
    tableView: {
      pager: {
        itemsPerPage: 5,
        page: 0
      },
      sort: {
        field: 'email',
        direction: 'asc'
      },
      searchTerm: '',
      showDeleted: false,
      showOutdated: false
    },
    upgrade: {
      state: 'discard'
    }
  },

  basicProject: {
    tags: null,
    data: null
  },

  gases: {
    state: 'init',
    data: [],
    tableView: {
      pager: {
        itemsPerPage: 5,
        page: 0
      },
      sort: {
        field: 'email',
        direction: 'asc'
      },
      searchTerm: '',
      showDeleted: false
    }
  },

  project: {
    saved: 'saved',
    subTitle: '',
    steps: [],
    data: null,
    metadata: {
      state: 'init'
    }
  },

  users: {
    state: 'init',
    data: [],
    tableView: {
      pager: {
        itemsPerPage: 5,
        page: 0
      },
      sort: {
        field: 'email',
        direction: 'asc'
      },
      searchTerm: '',
      showDeleted: false
    }
  },

  unitSystems: {
    state: 'init',
    data: [],
    tableView: {
      pager: {
        itemsPerPage: 5,
        page: 0
      },
      sort: {
        field: 'email',
        direction: 'asc'
      },
      searchTerm: '',
      showDeleted: false
    }
  },

  toolbar: {
    title: ''
  },

  splash: {
    open: true
  },

  versions: environment.versions,

  dialogs: {
    error: {
      open: false,
      error: null,
      message: ''
    },
    warning: {
      open: false,
      error: null,
      message: ''
    },
    information: {
      open: false,
      error: null,
      message: ''
    },
    modal: {
      open: false,
      error: null,
      message: ''
    }
  }
};
