import {DtoContract} from '../../contracts';

export const REQ_POST_APP_UNCERTAINTY_PREDICTION_RESULT = {
    config: [
        {KEY: 'processDataEntryMin.name', TYPE: 'STRING'},
        {KEY: 'processDataEntryMin.active', TYPE: 'BOOLEAN'},
        {KEY: 'processDataEntryMin.temperature', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntryMin.pressure', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntryMin.gasCompositionId', TYPE: 'STRING'},
        {KEY: 'processDataEntryMin.molWeight', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntryMin.kappa', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntryMin.usedGasMethod', TYPE: 'STRING'},
        {KEY: 'processDataEntryMax.name', TYPE: 'STRING'},
        {KEY: 'processDataEntryMax.active', TYPE: 'BOOLEAN'},
        {KEY: 'processDataEntryMax.temperature', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntryMax.pressure', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntryMax.gasCompositionId', TYPE: 'STRING'},
        {KEY: 'processDataEntryMax.molWeight', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntryMax.kappa', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntryMax.usedGasMethod', TYPE: 'STRING'},
        {KEY: 'processDataEntry1.name', TYPE: 'STRING'},
        {KEY: 'processDataEntry1.active', TYPE: 'BOOLEAN'},
        {KEY: 'processDataEntry1.temperature', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry1.pressure', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry1.gasCompositionId', TYPE: 'STRING'},
        {KEY: 'processDataEntry1.molWeight', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry1.kappa', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry1.usedGasMethod', TYPE: 'STRING'},
        {KEY: 'processDataEntry2.name', TYPE: 'STRING'},
        {KEY: 'processDataEntry2.active', TYPE: 'BOOLEAN'},
        {KEY: 'processDataEntry2.temperature', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry2.pressure', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry2.gasCompositionId', TYPE: 'STRING'},
        {KEY: 'processDataEntry2.molWeight', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry2.kappa', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry2.usedGasMethod', TYPE: 'STRING'},
        {KEY: 'processDataEntry3.name', TYPE: 'STRING'},
        {KEY: 'processDataEntry3.active', TYPE: 'BOOLEAN'},
        {KEY: 'processDataEntry3.temperature', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry3.pressure', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry3.gasCompositionId', TYPE: 'STRING'},
        {KEY: 'processDataEntry3.molWeight', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry3.kappa', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry3.usedGasMethod', TYPE: 'STRING'},
        {KEY: 'processDataEntry4.name', TYPE: 'STRING'},
        {KEY: 'processDataEntry4.active', TYPE: 'BOOLEAN'},
        {KEY: 'processDataEntry4.temperature', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry4.pressure', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry4.gasCompositionId', TYPE: 'STRING'},
        {KEY: 'processDataEntry4.molWeight', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry4.kappa', TYPE: 'DTOQUANTITY'},
        {KEY: 'processDataEntry4.usedGasMethod', TYPE: 'STRING'},
        {KEY: 'gasCompositions', TYPE: 'ARRAY<ARRAY<ENTITY>>', ARRAY: 'DTOKEYVALUEITEM'},
        {KEY: 'explanation', TYPE: 'STRING'},
        {KEY: 'userLimits.lowFlowUncertainty', TYPE: 'DTOQUANTITY'},
        {KEY: 'userLimits.lowFlowLimit', TYPE: 'DTOQUANTITY'},
        {KEY: 'userLimits.highFlowUncertainty', TYPE: 'DTOQUANTITY'},
        {KEY: 'velocities', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOQUANTITY'},
        {KEY: 'parameterSetup.deviceUncertaintySetups', TYPE: 'ARRAY<ARRAY<ENTITY>>', ARRAY: 'DTOKEYVALUEITEM'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.parameters.vogTheta', TYPE: 'DTOQUANTITY'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.parameters.valueM', TYPE: 'DTOQUANTITY'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.parameters.agcUpperLimit', TYPE: 'DTOQUANTITY'},
        {
            KEY: 'parameterSetup.extendedUncertaintySetup.parameters.isentropicComponentKappa',
            TYPE: 'DTOQUANTITY'
        },
        {KEY: 'parameterSetup.extendedUncertaintySetup.parameters.m4A_uU', TYPE: 'DTOQUANTITY'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.parameters.m4A_uThickness', TYPE: 'DTOQUANTITY'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.parameters.m4B_uU', TYPE: 'DTOQUANTITY'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.parameters.m4B_uThickness', TYPE: 'DTOQUANTITY'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.parameters.m4B_uuAlpha', TYPE: 'DTOQUANTITY'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming', TYPE: 'BOOLEAN'},
        {
            KEY: 'parameterSetup.extendedUncertaintySetup.configuration.unpairedProbesTiming',
            TYPE: 'DTOQUANTITY'
        },
        {
            KEY: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty',
            TYPE: 'BOOLEAN'
        },
        {
            KEY: 'parameterSetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty',
            TYPE: 'DTOQUANTITY'
        },
        {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.useCustomPathAngle', TYPE: 'BOOLEAN'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.customPathAngle', TYPE: 'DTOQUANTITY'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.biasNozzleDistance', TYPE: 'NUMBER'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated', TYPE: 'BOOLEAN'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.nominalDiameter', TYPE: 'DTOQUANTITY'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.configuration.pathCount', TYPE: 'STRING'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation', TYPE: 'BOOLEAN'},
        {KEY: 'parameterSetup.extendedUncertaintySetup.postprocessing.useMachUnit', TYPE: 'BOOLEAN'},
        {
            KEY: 'parameterSetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation',
            TYPE: 'BOOLEAN'
        },
        {KEY: 'parameterSetup.extendedUncertaintySetup.postprocessing.envelope', TYPE: 'BOOLEAN'},
        {KEY: 'selectedDevice', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'}
    ] as DtoContract[]
};
