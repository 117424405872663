import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StateService} from '@state/state-service/state.service';
import {RootReducerService} from '@state/reducers/root-reducer.service';
import {DeviceEpicService} from '@state/epics/device-epic.service';
import {ProjectEpicService} from '@state/epics/project-epic.service';
import {ComputeEpicService} from '@state/epics/compute-epic.service';
import {PageStateService} from '@state/services/page-state.service';
import {StateComponent} from '@state/state.component';
import {ErrorService} from '@state/state-service/error.service';

@NgModule({
  declarations: [StateComponent],
  imports: [CommonModule],
  exports: [StateComponent],
  providers: [StateService, RootReducerService, DeviceEpicService, ProjectEpicService, ComputeEpicService, PageStateService, ErrorService]
})
export class StateModule {}
