import {accessObjectByPath} from '../../util/access-object-by-string-path';
import {Item} from '../model/dto';
import {undefinedChecker} from '../util/undefined-checker';

export function assembleDtoArrayEntity(assembledObject, elem, payload) {
  const domainObject = accessObjectByPath(elem.DOMAINOBJECT, elem.PATHINDOMAINOBJECT);

  if (!domainObject?.length || domainObject.length === 0) {
    assembledObject = {
      key: elem.KEY,
      type: 'ARRAY<' + elem.ARRAY + '>',
      value: null
    } as Item;

    undefinedChecker(payload, assembledObject);
  } else {
    domainObject?.forEach((ae, i) => {
      assembledObject = {
        key: elem.KEY,
        type: 'ARRAY<' + elem.ARRAY + '>',
        value: accessObjectByPath(elem.DOMAINOBJECT, elem.PATHINDOMAINOBJECT + '[' + i + ']')
      } as Item;

      undefinedChecker(payload, assembledObject);
    });
  }
}
