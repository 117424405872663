import {DtoDoPropCollectionMap} from '@flowtwin/communication';

export const PROP_COLLECTION_MAP_APP_TRANSDUCER_PERFORMANCE_INPUT: DtoDoPropCollectionMap[] = [
  {dtoKey: 'pressure.min', doKey: 'pressure.min'},
  {dtoKey: 'pressure.max', doKey: 'pressure.max'},
  {dtoKey: 'pressure.minDesign', doKey: 'pressure.minDesign'},
  {dtoKey: 'pressure.maxDesign', doKey: 'pressure.maxDesign'},
  {dtoKey: 'temperature.min', doKey: 'temperature.min'},
  {dtoKey: 'temperature.max', doKey: 'temperature.max'},
  {dtoKey: 'temperature.minDesign', doKey: 'temperature.minDesign'},
  {dtoKey: 'temperature.maxDesign', doKey: 'temperature.maxDesign'},
  {dtoKey: 'remarks', doKey: 'remarks'},
  {dtoKey: 'gasCompositionId', doKey: 'gasCompositionId'},
  {dtoKey: 'nominalDiameter', doKey: 'nominalDiameter'},
  {dtoKey: 'probeVec.userSelectedProbe', doKey: 'userSelectedProbe'},
  {dtoKey: 'probeVec.selectedProbeKey', doKey: 'sensorType'},
  {dtoKey: 'injectH2', doKey: 'injectH2'},
  {dtoKey: 'xH2', doKey: 'xH2'},
  {dtoKey: 'configuration.exGroupKey', doKey: 'explosionGroup'},
  {dtoKey: 'configuration.gasSelectionKey', doKey: 'sensorGasSelectionMethod'},
  {dtoKey: 'configuration.hsElectronics', doKey: 'hsElectronics'},
  {dtoKey: 'configuration.extendedPNwCalculation', doKey: 'extendedPNwCalculation'}
];

export const PROP_COLLECTION_MAP_APP_TRANSDUCER_PERFORMANCE_OUTPUT: DtoDoPropCollectionMap[] = [
  {dtoKey: 'agc.pMin.innerPath', doKey: 'agc.innerPath.pMin'},
  {dtoKey: 'agc.pMin.outerPath', doKey: 'agc.outerPath.pMin'},
  {dtoKey: 'agc.pMax.innerPath', doKey: 'agc.innerPath.pMax'},
  {dtoKey: 'agc.pMax.outerPath', doKey: 'agc.outerPath.pMax'},
  {dtoKey: 'pathLength.innerPath', doKey: 'various.pathLengthInner'},
  {dtoKey: 'pathLength.outerPath', doKey: 'various.pathLengthOuter'},
  {dtoKey: 'agcAssembly.innerPath', doKey: 'agcAssembly.innerPath'},
  {dtoKey: 'agcAssembly.outerPath', doKey: 'agcAssembly.outerPath'},
  {dtoKey: 'pressureAssembly', doKey: 'various.pressureAssembly'},
  {dtoKey: 'agcState.pMin.innerPath', doKey: 'agcState.innerPath.pMin'},
  {dtoKey: 'agcState.pMin.outerPath', doKey: 'agcState.outerPath.pMin'},
  {dtoKey: 'agcState.pMax.innerPath', doKey: 'agcState.innerPath.pMax'},
  {dtoKey: 'agcState.pMax.outerPath', doKey: 'agcState.outerPath.pMax'},
  {dtoKey: 'limitSearch.maxNW', doKey: 'various.limitSearchMaxNW'},
  {dtoKey: 'limitSearch.minPressure', doKey: 'various.limitSearchMinPressure'},
  {dtoKey: 'hsState', doKey: 'various.hsState'},
  {dtoKey: 'reflectionState', doKey: 'various.reflectionState'},
  {dtoKey: 'probe', doKey: 'various.probe'},
  {dtoKey: 'gasType', doKey: 'various.gasType'},
  {dtoKey: 'summaryState.applicationState', doKey: 'summaries.applicationState'},
  {dtoKey: 'summaryState.applicationRecommendation.Summary', doKey: 'summaries.applicationRecommendation.summary'},
  {dtoKey: 'summaryState.applicationRecommendation.H2State', doKey: 'summaries.applicationRecommendation.h2State'},
  {dtoKey: 'summaryState.applicationRecommendation.SosState', doKey: 'summaries.applicationRecommendation.sosState'},
  {dtoKey: 'summaryState.applicationRecommendation.Pressure', doKey: 'summaries.applicationRecommendation.pressure'},
  {dtoKey: 'summaryState.applicationRecommendation.NominalDiameter', doKey: 'summaries.applicationRecommendation.nominalDiameter'},
  {dtoKey: 'summaryState.agcSimulation', doKey: 'summaries.agcSimulation'},
  {dtoKey: 'summaryState.signalSimulation', doKey: 'summaries.signalSimulation'},
  {dtoKey: 'summaryState.designState.summary', doKey: 'summaries.designState.summary'},
  {dtoKey: 'summaryState.designState.minDesign', doKey: 'summaries.designState.minDesign'}
];
