import {DoAssemblyDirector, Dto} from '@flowtwin/communication';
import {User_DTO} from '../../dto-mapper/model/communication/user-dto';
import {User, User_Class} from '../model/communication/user';
import {PROP_COLLECTION_MAP_USER} from '@state/state-service/do-mapper/contracts/user';
import {PROP_COLLECTION_MAP_GET_USERS} from '@state/state-service/do-mapper/contracts/users';

export class DoMapperUser {
  static mapToUser(userDto: any): User_Class {
    const user = assembleGetMeDo(userDto);
    return {
      state: 'loading',
      document: user.document
    };
  }

  static mapToUsers(userDto: Dto): User_Class[] {
    return new DoAssemblyDirector(userDto, PROP_COLLECTION_MAP_GET_USERS)
      .createInstance<User_Class>(User_Class)
      .assembleTransposedDo<User_Class>()
      .collect();
  }
}

function assembleGetMeDo(dto: Dto): User_Class {
  return new DoAssemblyDirector(dto, PROP_COLLECTION_MAP_USER).createInstance<User_Class>(User_Class).assembleDo<User_Class>().collect();
}
