import {AppTransducerOutput} from '../model/apps/app-transducer';
import {DoAssemblyDirector, Dto, DtoDoPropCollectionMap} from '@flowtwin/communication';

export class DoMapperProjectResults {
  static mapToAppTransducerPerformanceComputedResults(domainContract: DtoDoPropCollectionMap[], typedDto: Dto): AppTransducerOutput {
    return new DoAssemblyDirector(typedDto, domainContract)
      .createInstance<AppTransducerOutput>(AppTransducerOutput)
      .assembleDo<AppTransducerOutput>()
      .collect();
  }
}
