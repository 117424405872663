import {DtoContract} from '../../contracts';
import {
  GAS_COMPOSITION,
  GAS_COMPOSITION_ARRAY,
  GAS_COMPOSITION_DOCUMENT,
  GAS_COMPOSITION_DOCUMENT_ARRAY
} from '../../base-definitions/gas-composition';

export const RES_POST_GAS = {
  config: [{KEY: 'result', TYPE: 'DTOERROR'}] as DtoContract[]
};
export const RES_GET_GAS = {
  config: [...GAS_COMPOSITION_DOCUMENT, ...GAS_COMPOSITION] as DtoContract[]
};

export const RES_GET_GASES = {
  config: [...GAS_COMPOSITION_DOCUMENT_ARRAY, ...GAS_COMPOSITION_ARRAY] as DtoContract[]
};

// TODO: This is the result of SIZING -> Rename and Move into AppSizing (or similar named folder and files)
export const RES_POST_APP_GASDATA_RESULT = {
  config: [
    ...buildContractItems('min'),
    ...buildContractItems('max'),
    {KEY: 'ccus.PhaseNotification', TYPE: 'STRING'},
    {KEY: 'lvf.GlobalLiquidVolumeFraction', TYPE: 'DTOQUANTITY'},
    {KEY: 'lvf.GlobalPhaseState', TYPE: 'NUMBER'},

    {KEY: `warnings.Fl500SoSOutOfBounds`, TYPE: 'BOOLEAN'},
    {KEY: `warnings.Fl500H2TooHigh`, TYPE: 'BOOLEAN'},
    {KEY: `warnings.Fl500H2NotSupported`, TYPE: 'BOOLEAN'},
    {KEY: `warnings.Fl500CO2FractionExceeded`, TYPE: 'BOOLEAN'},
    {KEY: `warnings.Fl500MinPressureForMethaneLow`, TYPE: 'BOOLEAN'}
  ] as DtoContract[]
};

function buildContractItems(conditionName: string): Array<DtoContract> {
  return [
    {KEY: `${conditionName}.ActualConditions.Pressure`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.Temperature`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.CO2Emission`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.CO2EmissionFactor`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.Compressibility`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.Density`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.KFactor`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.MolarMass`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.Repeatability`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.ReynoldsNumber`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.SpeedOfSound`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.Velocity`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.Viscosity`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.LiquidVolumeFraction`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.ActualConditions.PhaseState`, TYPE: 'NUMBER'},

    {KEY: `${conditionName}.StandardConditions.Pressure`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.Temperature`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.CO2Emission`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.CO2EmissionFactor`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.Compressibility`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.Density`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.KFactor`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.MolarMass`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.Repeatability`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.ReynoldsNumber`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.SpeedOfSound`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.Velocity`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.Viscosity`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.LiquidVolumeFraction`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.StandardConditions.PhaseState`, TYPE: 'NUMBER'},

    {KEY: `${conditionName}.Flow.Actual`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.Flow.Energy`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.Flow.Mass`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.Flow.Standard`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.HeatValue.Mass`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.HeatValue.Volume`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.Meter.NominalDiameter`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.Meter.Schedule`, TYPE: 'STRING'},
    {KEY: `${conditionName}.Meter.InnerDiameter`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.Meter.WallThickness`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.MolarMass`, TYPE: 'DTOQUANTITY'},
    {KEY: `${conditionName}.Velocity.GasVelocity`, TYPE: 'DTOQUANTITY'},

    {KEY: `${conditionName}.CcusResults.PhaseIndicator`, TYPE: 'STRING'}
  ];
}
