import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {DeviceEpicService} from '../epics/device-epic.service';
import {DeviceReduxAction} from '../actions/actions-device';
import {Pages, PageStateReduxAction, PayloadPageState} from '@state/actions/actions-page-state';
import {set} from 'lodash';

@Injectable({
  providedIn: null
})
export class DeviceReducerService {
  static assignHandler(action: DeviceReduxAction, stateService: StateService, epic: DeviceEpicService) {
    switch (action.type) {
      case 'DEVICE_SEARCH_LOAD':
        epic.getFilteredMetaDataAndSizingDevice(action, stateService);
        DeviceReducerService.updateInputFetchingOptionsState(true, action, stateService);
        DeviceReducerService.updatePageState(
          {
            type: 'UPDATE_PAGE_STATE',
            payload: {page: Pages.appSizing, state: 'loading'}
          },
          stateService
        );
        break;
      case 'DEVICE_SEARCH_SUCCESS':
        DeviceReducerService.updateFilteredMetadataAndSizingResult(action, stateService);
        DeviceReducerService.updateInputFetchingOptionsState(false, action, stateService);
        DeviceReducerService.updatePageState(
          {
            type: 'UPDATE_PAGE_STATE',
            payload: {page: Pages.appSizing, state: 'ready'}
          },
          stateService
        );
        break;
      case 'DEVICE_SEARCH_ERROR':
        DeviceReducerService.updateInputFetchingOptionsState(false, action, stateService);
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any Device Reducer-Function.`);
        break;
    }
  }

  private static updateInputFetchingOptionsState(state: boolean, action: DeviceReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        set(s, 'project.data.appSizing.input.fetchingInputOptions', state);
      },
      {
        type: `RootReducer: API_CALL - ${action.type}`
      }
    );
  }

  private static updatePageState(action: PageStateReduxAction, stateService: StateService) {
    const {page, state} = action.payload as PayloadPageState;

    stateService.update(
      s => {
        s.pages[page] = state;
      },
      {
        type: 'Page State Updated of: ' + page,
        details: {results: action}
      }
    );
  }

  private static updateFilteredMetadataAndSizingResult(action: DeviceReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        set(s, 'project.data.appSizing.metadata.deviceOptions', action.payload.metadata);
        // TODO @Arnold: Had a runtime error here. action.payload.devices was null. Need to change the type or set a default.
        const selectedDevice = 'devices' in action.payload && action.payload.devices ? [action.payload.devices[0]] : null;
        set(s, 'project.data.appSizing.input.selectedDevice', selectedDevice);
      },
      {
        type: `RootReducer: API_CALL - ${action.type}`
      }
    );
  }
}
