import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';

import {AuthService} from '../authentification/auth.service';
import {from, Observable, of} from 'rxjs';
import {catchError, map, skipWhile, timeout} from 'rxjs/operators';
import {StateService} from '@state/state-service/state.service';
import {LOADING_USER_TIMEOUT} from '@shared/constants';

@Injectable()
export class LoginErrorGuardService implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private state: StateService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return from(this.state.state$).pipe(
      //loading state infinite loop
      timeout(LOADING_USER_TIMEOUT),
      skipWhile(val => val.user.state === 'loading'),
      // if logged in
      map(val => {
        if (val.user.state === 'logged-in') {
          this.redirectTo('/');
        } else if (val.user.state === 'error') {
          return true;
        }
      }),
      catchError(_ => {
        return of(true);
      })
    );
  }

  private redirectTo(destination: string) {
    this.router.navigate([destination]);
  }
}
