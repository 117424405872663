import {Observable, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {TranslationKey} from '@shared/models/translation-key';
import {ButtonConfig, FieldOption} from '../form/input-form-builder';
import {Quantity} from '@state/state-service/do-mapper/model/unit/quantity';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {ActionType} from '@state/actions/actions-abstract';

export type ClassName =
  | 'col-1'
  | 'col-2'
  | 'col-3'
  | 'col-4'
  | 'col-5'
  | 'col-6'
  | 'col-1-to-2'
  | 'col-1-to-3'
  | 'col-2-to-3'
  | 'col-1-to-4'
  | 'col-2-to-4'
  | 'col-3-to-4';

type KeyName = string;

export const panel = (props: {label: TranslationKey; fieldGroup: any[]}) => ({
  templateOptions: {
    label: props.label,
    stepId: props.label
  },
  className: 'col-1-to-4',
  fieldGroupClassName: 'row no-bottom',
  fieldGroup: props.fieldGroup
});

export const textInput = (props: {
  className: ClassName;
  key: KeyName;
  label?: TranslationKey;
  placeholder?: string;
  required?: boolean;
}) => ({
  id: props.key,
  key: props.key,
  type: 'input',
  className: props.className,
  templateOptions: {
    label: props.label || 'project.' + props.key,
    placeholder: props.placeholder,
    required: !!props.required,
    appearance: 'outline'
  }
});

export const numbersField = (props: {
  className: ClassName;
  key: KeyName;
  label?: TranslationKey;
  placeholder?: string;
  required?: boolean;
  hide?: () => boolean;
}) => ({
  key: props.key,
  type: 'input',
  className: props.className,
  hideExpression: props.hide,
  templateOptions: {
    type: 'number',
    label: props.label || 'project.' + props.key,
    placeholder: props.placeholder,
    required: !!props.required,
    appearance: 'outline'
  }
});

export const checkbox = (props: {
  className: ClassName;
  key: KeyName;
  label?: TranslationKey;
  placeholder?: string;
  required?: boolean;
  hide?: () => boolean;
  disabled?: boolean;
}) => ({
  key: props.key,
  type: 'checkbox',
  className: props.className,
  hideExpression: props.hide,
  templateOptions: {
    label: props.label || 'project.' + props.key,
    required: !!props.required,
    disabled: props.disabled
  }
});

export const dropdown = (props: {
  id?: string;
  className: ClassName;
  key: KeyName;
  // options: (string | number)[] | Observable<(string | number)[]>
  options: FieldOption[] | Observable<FieldOption[]>;
  prefixOptionLabels?: string;
  hideExpression?: string;
  label?: string;
  disabled?: boolean;
  hide?: () => boolean;
}) => {
  const {disabled = false, prefixOptionLabels} = props;

  const _opts$ = props.options instanceof Observable ? props.options : of(props.options);

  const optionsLabel = (option: string) => {
    if (prefixOptionLabels === '') {
      return option;
    }

    if (!props.prefixOptionLabels) {
      return props.key + '.' + option;
    }

    return props.prefixOptionLabels + '.' + option;
  };

  const optionsValueLabel = _opts$.pipe(
    filter(optionArray => Array.isArray(optionArray)),
    map(optionArray =>
      optionArray.map(option => {
        return {
          value: option.value, // e.g. 'upStream'
          label: optionsLabel('' + option.label)
        };
      })
    )
  );
  return {
    id: props.id,
    key: props.key,
    type: 'da-dropdown',
    className: props.className,
    hideExpression: props.hide,
    templateOptions: {
      label: props.label ? props.label : props.key,
      options: optionsValueLabel,
      valueProp: 'key',
      labelProp: 'value',
      disabled,
      hide: props.hide
    }
  };
};

export const rangeSlider = (props: {
  id?: string;
  keyMin: KeyName;
  keyMax: KeyName;
  label: string;
  className: ClassName;
  quantityMin?: Quantity<any>;
  quantityMax?: Quantity<any>;
}) => [
  {
    key: props.keyMin,
    className: 'hidden'
  },
  {
    key: props.keyMax,
    className: 'hidden'
  },
  {
    id: props.id,
    key: props.keyMin,
    type: 'da-range-slider-formly',
    className: props.className,
    props: {
      keyMin: props.keyMin,
      keyMax: props.keyMax,
      label: props.label,
      required: true,
      quantity: {min: props.quantityMin, max: props.quantityMax}
    }
  }
];

export const unitInput = (props: {
  key: KeyName;
  className: ClassName;
  required?: boolean;
  prefix?: string;
  disabled?: boolean;
  hideExpression?: string;
  hide?: () => boolean;
  noLabel?: boolean;
  label?: string;
  emitOnChange?: () => boolean;
  limitMin?: number;
  limitMax?: number;
  errorMessageMin?: string;
  errorMessageMax?: string;
  defaultValue?: Quantity<any>;
}) => {
  return {
    id: 'unit-input-field-' + props.key,
    key: props.key,
    type: 'da-unit',
    className: props.className,
    hideExpression: props.hideExpression,
    defaultValue: props.defaultValue,
    templateOptions: {
      isHidden: props?.hide || (() => false),
      label: props.noLabel ? '' : props.label || props.key,
      required: props.required,
      emitOnChange: props?.emitOnChange ?? (() => true),
      limits: [props?.limitMin ?? null, props?.limitMax ?? null],
      errorMessages: [props?.errorMessageMin ?? null, props?.errorMessageMax ?? null]
    }
  };
};

export const textField = (props: {
  className: ClassName;
  key: KeyName;
  label?: TranslationKey;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  maxLength?: number;
  validation?: {messages: {pattern: (error: any, field: FormlyFieldConfig) => string}};
}) => ({
  key: props.key,
  type: 'textarea',
  className: props.className,
  templateOptions: {
    label: props.label || 'project.' + props.key,
    placeholder: props.placeholder,
    required: !!props.required,
    appearance: 'outline',
    rows: props.rows ?? 10,
    maxLength: props.maxLength ?? null
  }
});

export const label = (props: {className: ClassName; label: TranslationKey}) => ({
  type: 'da-label',
  className: props.className,
  templateOptions: {
    label: props.label
  }
});

export const button = (props: {
  className: ClassName;
  label: TranslationKey;
  action?: ActionType;
  clickHandler?: () => any;
  icon?: string;
  hideExpression?: string;
}) => ({
  type: 'da-button',
  className: props.className,
  templateOptions: {
    label: props.label,
    clickHandler: props.clickHandler,
    action: props.action,
    icon: props.icon
  },
  hideExpression: props.hideExpression
});
