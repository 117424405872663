import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
  HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {ILogger, LoggerConfig, LoggerService, LogLevel} from '../../services/logger/logger.service';

const loggerConfig: LoggerConfig = {
  name: '-',
  symbol: '🚔',
  textColor: 'white',
  backgroundColor: '#E53D00'
};

//
// Adds the id token to all request headers
//
// See https://angular.io/guide/http#setting-default-headers
//
@Injectable({
  providedIn: 'root'
})
export class TrafficInterceptor implements HttpInterceptor {
  private logger: ILogger;

  constructor(private loggerService: LoggerService) {
    this.logger = this.initLogger();
  }

  private initLogger() {
    loggerConfig.name = this.constructor.name;
    return this.loggerService.initLogger(loggerConfig);
  }

  handleSuccess(req: HttpRequest<any>, event: HttpEvent<any>) {
    if (event instanceof HttpResponse) {
      const url = new URL(event.url ?? '');

      this.logger.log('', req.method + ' ' + url.pathname + ' ' + url.searchParams, LogLevel.Info, [{Body: event.body}]);
    }
  }

  handleErr(req: HttpRequest<any>, error: HttpErrorResponse) {
    const url = new URL(req.url);

    this.logger.log('🚨', req.method + ' ' + url.pathname + ' ' + url.searchParams, LogLevel.Error, [{Body: error}]);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(
        ok => this.handleSuccess(req, ok),
        error => this.handleErr(req, error)
      )
    );
  }
}

export const LogInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TrafficInterceptor,
  multi: true
};
