import {CONTRACTS, convertObjectIntoArrayOfKeyValues, Dto, DtoAssemblyDirector, DtoKeyValueItem} from '@flowtwin/communication';
import {clone} from '@shared/utility/clone';
import {CONTRACT_POST_PROJECT_BASIC, CONTRACT_POST_SHARE_PROJECT} from '../domain-contracts/project';
import {BasicProject} from '@state/state-service/do-mapper/model/project/project-basic';
import {UnitSystem} from '@state/state-service/do-mapper/model/unit/unit-system-document';
import {DEFAULT_GASES_DO} from '@state/state-service/do-mapper/model/gas/default-gas-data';
import {createGasCompositionDoc} from '@state/state-service/do-mapper/model/gas/gas';

export class SubDtoMapperProject {
  static mapToFlowTwinProjectFromBasic(project: BasicProject): Dto {
    const projectCopy = clone(project);

    if (projectCopy?.custom?.displayUnitSystem?.document && projectCopy?.custom?.displayUnitSystem?.unitSystem) {
      projectCopy.custom.displayUnitSystem.unitSystem = convertUnitSystemIntoDtoKeyValueArray(
        projectCopy.custom.displayUnitSystem.unitSystem
      ) as any;
    }

    if (projectCopy?.custom?.gasCompositionDocs?.length > 0) {
      convertGasCompositionsIntoDtoKeyValueArray(projectCopy);
    } else {
      projectCopy.custom.gasCompositionDocs = [];
      convertGasCompositionsIntoDtoKeyValueArray(projectCopy);
    }

    return new DtoAssemblyDirector(
      'PROJECT_POST',
      CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_PROJECT.config,
      CONTRACT_POST_PROJECT_BASIC(projectCopy)
    )
      .assembleDto()
      .collect();
  }

  static mapToShareProject(userList: string[]): Dto {
    return new DtoAssemblyDirector(
      'PROJECT_POST',
      CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_SHARE_PROJECT.config,
      CONTRACT_POST_SHARE_PROJECT(userList)
    )
      .assembleDto()
      .collect();
  }
}

function convertGasCompositionsIntoDtoKeyValueArray(project: any) {
  if (project.custom.gasCompositionDocs) {
    project.custom.gasCompositionDocs.map(gas => (gas.gasComposition = convertObjectIntoArrayOfKeyValues(gas.gasComposition)));
    // add document properties as key value items
    project.custom.gasCompositionDocs.map(
      gas =>
        (gas.gasComposition = gas.gasComposition.concat(
          convertObjectIntoArrayOfKeyValues({
            id: gas.document.id,
            name: gas.document.name,
            description: gas.document.description
          })
        ))
    );
    // delete document from object and set gascomposition as root array in custom
    project.custom.gasCompositionDocs = project.custom.gasCompositionDocs.map(gas => gas.gasComposition);
  }
  if (!project.custom.gasCompositionDocs || project.custom.gasCompositionDocs.length === 0) {
    project.custom.gasCompositionDocs = [[]];
  }
}

function convertUnitSystemIntoDtoKeyValueArray(unitSystem: UnitSystem): Array<DtoKeyValueItem> {
  return convertObjectIntoArrayOfKeyValues(unitSystem);
}
