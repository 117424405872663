import {DtoContract} from '../../contracts';

export const RES_GET_APP_SELECTION_SIZING_INPUT = {
  config: [
    {KEY: 'flowCondition', TYPE: 'STRING'},
    {KEY: 'gasCompositionId', TYPE: 'STRING'},
    {KEY: 'installationType', TYPE: 'STRING'},
    {KEY: 'measuredFlowValue', TYPE: 'STRING'},
    {KEY: 'nominalSize', TYPE: 'NUMBER'},
    {KEY: 'schedule', TYPE: 'string'},
    {KEY: 'processConditionCombination.max', TYPE: 'NUMBER'},
    {KEY: 'processConditionCombination.min', TYPE: 'NUMBER'},
    {KEY: 'processFlowRangeMin', TYPE: 'DTOQUANTITY'},
    {KEY: 'processFlowRangeMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'processFlowPressureMin', TYPE: 'DTOQUANTITY'},
    {KEY: 'processFlowPressureMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'processFlowTemperatureMin', TYPE: 'DTOQUANTITY'},
    {KEY: 'processFlowTemperatureMax', TYPE: 'DTOQUANTITY'},
    {KEY: 'productFamily', TYPE: 'STRING'},
    {KEY: 'productType', TYPE: 'STRING'},
    {KEY: 'referenceCondition', TYPE: 'STRING'},
    {KEY: 'selectedDevice', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'nozzleDistance', TYPE: 'NUMBER'},
    {KEY: 'fullBore', TYPE: 'BOOLEAN'},
    {KEY: 'innerDiameter', TYPE: 'DTOQUANTITY'},
    {KEY: 'pressureClass', TYPE: 'STRING'},
    {KEY: 'flowConditioner', TYPE: 'STRING'},
    {KEY: 'flowRangeOption', TYPE: 'STRING'}
  ] as DtoContract[]
};
export const RES_GET_APP_SELECTION_SIZING_OUTPUT = {
  config: [
    {KEY: 'min.ActualConditions.Pressure', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.Temperature', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.CO2Emission', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.CO2EmissionFactor', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.Compressibility', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.Density', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.KFactor', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.MolarMass', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.Repeatability', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.ReynoldsNumber', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.SpeedOfSound', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.Velocity', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.Viscosity', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.LiquidVolumeFraction', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.PhaseState', TYPE: 'DTOQUANTITY'},

    {KEY: 'min.StandardConditions.Pressure', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.Temperature', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.CO2Emission', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.CO2EmissionFactor', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.Compressibility', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.Density', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.KFactor', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.MolarMass', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.Repeatability', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.ReynoldsNumber', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.SpeedOfSound', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.Velocity', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.Viscosity', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.LiquidVolumeFraction', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.PhaseState', TYPE: 'DTOQUANTITY'},

    {KEY: 'min.Flow.Actual', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Flow.Energy', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Flow.Mass', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Flow.Standard', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.HeatValue.Mass', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.HeatValue.Volume', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Meter.NominalDiameter', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Meter.Schedule', TYPE: 'STRING'},
    {KEY: 'min.Meter.InnerDiameter', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Meter.WallThickness', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.MolarMass', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Velocity.GasVelocity', TYPE: 'DTOQUANTITY'},

    {KEY: 'max.ActualConditions.Pressure', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.Temperature', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.CO2Emission', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.CO2EmissionFactor', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.Compressibility', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.Density', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.KFactor', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.MolarMass', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.Repeatability', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.ReynoldsNumber', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.SpeedOfSound', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.Velocity', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.Viscosity', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.LiquidVolumeFraction', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.PhaseState', TYPE: 'DTOQUANTITY'},

    {KEY: 'max.StandardConditions.Pressure', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.Temperature', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.CO2Emission', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.CO2EmissionFactor', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.Compressibility', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.Density', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.KFactor', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.MolarMass', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.Repeatability', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.ReynoldsNumber', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.SpeedOfSound', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.Velocity', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.Viscosity', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.LiquidVolumeFraction', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.PhaseState', TYPE: 'DTOQUANTITY'},

    {KEY: 'max.Flow.Actual', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Flow.Energy', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Flow.Mass', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Flow.Standard', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.HeatValue.Mass', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.HeatValue.Volume', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Meter.NominalDiameter', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Meter.Schedule', TYPE: 'STRING'},
    {KEY: 'max.Meter.InnerDiameter', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Meter.WallThickness', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.MolarMass', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Velocity.GasVelocity', TYPE: 'DTOQUANTITY'},
    {KEY: 'ccus.PhaseNotification', TYPE: 'STRING'},
    {KEY: 'lvf.GlobalLiquidVolumeFraction', TYPE: 'DTOQUANTITY'},
    {KEY: 'lvf.GlobalPhaseState', TYPE: 'NUMBER'},

    {KEY: `warnings.Fl500SoSOutOfBounds`, TYPE: 'BOOLEAN'},
    {KEY: `warnings.Fl500H2TooHigh`, TYPE: 'BOOLEAN'},
    {KEY: `warnings.Fl500H2NotSupported`, TYPE: 'BOOLEAN'},
    {KEY: `warnings.Fl500CO2FractionExceeded`, TYPE: 'BOOLEAN'},
    {KEY: `warnings.Fl500MinPressureForMethaneLow`, TYPE: 'BOOLEAN'}
  ] as DtoContract[]
};
export const RES_POST_APP_SELECTION_SIZING_RESULT = {
  config: [
    // devices
    {KEY: 'devices', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.segments', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.measuredFlowValues', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.installationTypes', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.pathLayouts', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.referenceConditions', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.sensorTypes', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.sensorGasSelectionMethod', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.explosionGroups', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'metadata.flowConditions', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.flowConditioners', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.flowRangeOptions', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.nominalSizes', TYPE: 'ARRAY<ENTITY>', ARRAY: 'NUMBER'},
    {KEY: 'metadata.nozzleDistances', TYPE: 'ARRAY<ENTITY>', ARRAY: 'NUMBER'},
    {KEY: 'metadata.pressureClasses', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.productFamilies', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.productTypes', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'metadata.schedules', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {
      KEY: 'metadata.uncertaintySetup.deviceUncertaintySetups',
      TYPE: 'ARRAY<ENTITY>',
      ARRAY: 'DTOKEYVALUEITEM'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.biasNozzleDistance',
      TYPE: 'NUMBER'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.useCustomPathAngle',
      TYPE: 'BOOLEAN'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated',
      TYPE: 'BOOLEAN'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty',
      TYPE: 'BOOLEAN'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.customPathAngle',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming',
      TYPE: 'BOOLEAN'
    },
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.pathCount', TYPE: 'STRING'},
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.nominalDiameter',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.unpairedProbesTiming',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation',
      TYPE: 'BOOLEAN'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation',
      TYPE: 'BOOLEAN'
    },
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.postprocessing.envelope', TYPE: 'BOOLEAN'},
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.vogTheta', TYPE: 'DTOQUANTITY'},
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.agcUpperLimit',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.isentropicComponentKappa',
      TYPE: 'DTOQUANTITY'
    },
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uThickness',
      TYPE: 'DTOQUANTITY'
    },
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uU', TYPE: 'DTOQUANTITY'},
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4A_uU', TYPE: 'DTOQUANTITY'},
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.valueM', TYPE: 'DTOQUANTITY'},
    {KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uuAlpha', TYPE: 'DTOQUANTITY'},
    {
      KEY: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4A_uThickness',
      TYPE: 'DTOQUANTITY'
    }
  ] as DtoContract[]
};
