import {DtoContract} from '../contracts';
import {REQ_POST_APP_GASDATA_RESULT} from "./req/gas";
import {REQ_POST_APP_SELECTION_SIZING_RESULT} from "./req/app-sizing";
import {REQ_POST_APP_TRANSDUCER_PERFORMANCE_RESULT} from "./req/app-transducer";
import {REQ_POST_APP_UNCERTAINTY_PREDICTION_RESULT} from "./req/app-uncertainty";
import {RES_POST_APP_SELECTION_SIZING_RESULT} from "./res/app-sizing";
import {RES_POST_APP_GASDATA_RESULT} from "./res/gas";
import {RES_POST_APP_TRANSDUCER_PERFORMANCE_RESULT} from "./res/app-transducer";
import {RES_POST_APP_UNCERTAINTY_PREDICTION_RESULT} from "./res/app-uncertainty";
import {RES_ERROR} from "./res/error";
import {RES_GET_VERSIONS} from "./res/version";
import {RES_GET_METADATA} from "./res/metadata";

export const CONTRACTS_AWS_BE_CONFIG = {
  REQ_AWS_BE_ENDPOINTS: {
    REQ_POST_APP_GASDATA_RESULT: REQ_POST_APP_GASDATA_RESULT,
    REQ_POST_APP_SELECTION_SIZING_RESULT: REQ_POST_APP_SELECTION_SIZING_RESULT,
    REQ_POST_APP_TRANSDUCER_PERFORMANCE_RESULT: REQ_POST_APP_TRANSDUCER_PERFORMANCE_RESULT,
    REQ_POST_APP_UNCERTAINTY_PREDICTION_RESULT: REQ_POST_APP_UNCERTAINTY_PREDICTION_RESULT
  },
  RES_AWS_BE_ENDPOINTS: {
    RES_POST_APP_GASDATA_RESULT: RES_POST_APP_GASDATA_RESULT,
    RES_POST_APP_SELECTION_SIZING_RESULT: RES_POST_APP_SELECTION_SIZING_RESULT,
    RES_POST_APP_TRANSDUCER_PERFORMANCE_RESULT: RES_POST_APP_TRANSDUCER_PERFORMANCE_RESULT,
    RES_POST_APP_UNCERTAINTY_PREDICTION_RESULT: RES_POST_APP_UNCERTAINTY_PREDICTION_RESULT,
    RES_GET_METADATA: RES_GET_METADATA,
    RES_GET_VERSIONS: RES_GET_VERSIONS,
    RES_ERROR: RES_ERROR
  }
};
