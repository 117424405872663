import {Email, ISODateString, UUID} from '@state/state-service/do-mapper/model/communication/type-alias';
import {UnitSystemDocument} from '@state/state-service/do-mapper/model/unit/unit-system-document';
import {GasCompositionDoc} from '@state/state-service/do-mapper/model/gas/gas-composition-doc';
import {AbstractDomainObject} from '@flowtwin/communication';

export type VersionState = 'UP_TO_DATE' | 'OUTDATED' | 'UPGRADE_FAILED' | 'VALIDATION_FAILED';

export class BasicProject extends AbstractDomainObject {
  document?: ProjectMandatory;
  meta?: ProjectMeta;
  custom?: ProjectCustom;
}

export interface ProjectMandatory {
  id: UUID;
  tag: string;
  reference: string;
  projectName: string;
  description: string;
}

export interface ProjectMeta {
  userList: Email[];
  created: ISODateString;
  crm: string;
  lastSaved: SavingMetadata;
  versionFlag?: VersionState;
  deleted: boolean;
}

export interface ProjectCustom {
  displayUnitSystem: UnitSystemDocument;
  gasCompositionDocs: GasCompositionDoc[];
}

export type SavingMetadata = {
  frontendVersion: string;
  middleWareVersion: string;
  backendVersion: string;
  lastSaved: Date;
  savedBy: string;
};
