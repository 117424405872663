import {User} from '@state/state-service/do-mapper/model/communication/user';
import {GasCompositionDoc} from '../state-service/do-mapper/model/gas/gas-composition-doc';
import {ReduxAction} from './actions-abstract';

export type GasActions =
  | 'GASES_GET_ALL_LOADING'
  | 'GASES_GET_ALL_SUCCESS'
  | 'GASES_GET_ALL_ERROR'
  | 'GASES_SAVE_ONE_LOADING'
  | 'GASES_SAVE_ONE_SUCCESS'
  | 'GASES_SAVE_ONE_ERROR'
  | 'GASES_DELETE_ONE_LOADING'
  | 'GASES_DELETE_ONE_SUCCESS'
  | 'GASES_DELETE_ONE_ERROR'
  | 'GAS_DELETE_ONE_FROM_PROJECT';

// tslint:disable-next-line:no-empty-interface
export interface ApiPayloadGeneric {
  gas?: GasCompositionDoc;
}

export interface ApiPayloadGetAllSuccess {
  gases: GasCompositionDoc[];
}

export interface ApiPayloadSaveOneLoad {
  gas: GasCompositionDoc;
}

export interface ApiPayloadSaveOneSuccess {
  gas: GasCompositionDoc;
}

export interface ApiPayloadDeleteOneLoad {
  gas: GasCompositionDoc;
  allUsers: User[];
}

export interface ApiPayloadDeleteOneSuccess {
  gas: GasCompositionDoc;
}

export type GasPayloads =
  | ApiPayloadGetAllSuccess
  | ApiPayloadSaveOneLoad
  | ApiPayloadSaveOneSuccess
  | ApiPayloadDeleteOneLoad
  | ApiPayloadDeleteOneSuccess
  | ApiPayloadGeneric;

export class GasReduxAction extends ReduxAction {
  type: GasActions;
  payload: GasPayloads;

  constructor(type: GasActions, payload: GasPayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
