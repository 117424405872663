import {DtoContract} from '../../contracts';

export const RES_ERROR = {
  config: [
    {KEY: 'status', TYPE: 'STRING'},
    {KEY: 'errorMessage', TYPE: 'STRING'},
    {KEY: 'statusCode', TYPE: 'NUMBER'},
    {KEY: 'currentRoute', TYPE: 'STRING'},
    {KEY: 'errorData', TYPE: 'STRING'},
    {KEY: 'errorCode', TYPE: 'STRING'}
  ] as DtoContract[]
};
