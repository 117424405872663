//
// FlowTwin App GasApplication
//
// tslint:disable-next-line:class-name
import {GasComposition, GasCompositionDoc} from './gas-composition-doc';
import {GasCompositionUUID} from '../communication/type-alias';
import {FlowQuantity, Quantity} from '../unit/quantity';
import {ReferenceCondition} from '../device/properties';

export interface GasApplicationInput {
  gasApplicationInputList: GasApplicationInputList;
}

export type GasApplicationInputList = GasApplicationInputItem[];

export interface GasApplicationInputItem {
  // Here goes the user input for the flow-twin app 'GasApplication'
  gasComposition: GasCompositionUUID;
  name: string;
  operatingFlow: FlowQuantity;
  operatingPressure: Quantity<'Pressure'>;
  operatingTemperature: Quantity<'Temperature'>;
  ReferenceCondition?: ReferenceCondition;
  NominalDiameter?: Quantity<'Diameter'>;
}

export const createGasComposition: (props?: Partial<GasComposition>) => GasComposition = props => ({
  argon: {value: 0, unit: 'MolAmountRatio'},
  carbonDioxide: {value: 0, unit: 'MolAmountRatio'},
  carbonMonoxide: {value: 0, unit: 'MolAmountRatio'},
  ethane: {value: 0, unit: 'MolAmountRatio'},
  helium: {value: 0, unit: 'MolAmountRatio'},
  hydrogen: {value: 0, unit: 'MolAmountRatio'},
  hydrogenSulfide: {value: 0, unit: 'MolAmountRatio'},
  iButane: {value: 0, unit: 'MolAmountRatio'},
  iPentane: {value: 0, unit: 'MolAmountRatio'},
  methane: {value: 0, unit: 'MolAmountRatio'},
  nButane: {value: 0, unit: 'MolAmountRatio'},
  nDecane: {value: 0, unit: 'MolAmountRatio'},
  nHeptane: {value: 0, unit: 'MolAmountRatio'},
  nHexane: {value: 0, unit: 'MolAmountRatio'},
  nitrogen: {value: 0, unit: 'MolAmountRatio'},
  nNonane: {value: 0, unit: 'MolAmountRatio'},
  nOctane: {value: 0, unit: 'MolAmountRatio'},
  nPentane: {value: 0, unit: 'MolAmountRatio'},
  oxygen: {value: 0, unit: 'MolAmountRatio'},
  propane: {value: 0, unit: 'MolAmountRatio'},
  water: {value: 0, unit: 'MolAmountRatio'},
  ...props
});

export const createGasCompositionDoc: (id: string, name: string, props?: Partial<GasComposition>) => GasCompositionDoc = (
  id,
  name,
  props
) => ({
  document: {
    id,
    name,
    description: '',
    userList: [],
    deleted: false,
    creator: '',
    version: 1
  },
  gasComposition: createGasComposition(props)
});
