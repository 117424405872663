import {Dto, DtoDefinition, Item} from '../model/dto';
import {Contract} from '../../contracts/contracts';
import {assembleDtoDefault} from './assemble-dto-default';
import {assembleDtoArrayOfArrayComplex} from './assemble-dto-array-of-array-complex';
import {assembleDtoArrayOfArrayEntity} from './assemble-dto-array-of-array-entity';
import {assembleDtoArrayEntity} from './assemble-dto-array-entity';
import {assembleDtoDate} from './assemble-dto-date';
import {assembleDtoArrayComplex} from './assemble-dto-array-complex';

export function dtoAssembler(dtoType: DtoDefinition, finalContract: Contract): Dto {
  function assemblePayload(contract: Contract): Item[] {
    const payload: Item[] = [];

    contract.map(elem => {
      const assembledObject = {} as Item;

      switch (elem.TYPE) {
        case 'ARRAY<ARRAY<COMPLEX>>':
          assembleDtoArrayOfArrayComplex(assembledObject, elem, payload);
          break;
        case 'ARRAY<ARRAY<ENTITY>>':
          assembleDtoArrayOfArrayEntity(assembledObject, elem, payload);
          break;
        case 'ARRAY<COMPLEX>':
          assembleDtoArrayComplex(assembledObject, elem, payload);
          break;
        case 'ARRAY<ENTITY>':
          assembleDtoArrayEntity(assembledObject, elem, payload);
          break;
        case 'DTODATE':
          assembleDtoDate(assembledObject, elem, payload);
          break;
        default:
          assembleDtoDefault(assembledObject, elem, payload);
          break;
      }
    });

    return payload;
  }

  return {
    type: dtoType,
    payload: assemblePayload(finalContract)
  };
}
