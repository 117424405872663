import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Dto} from '@flowtwin/communication';
import {ApiPayloadSearchLoad, DeviceReduxAction} from '@state/actions/actions-device';
import {ApiErrorHandleConfig, handleApiError} from '@state/epics/utility/handle-api-error';
import {PROP_COLLECTION_MAP_CALL_DEVICE_SEARCH} from '@state/state-service/do-mapper/contracts/app-sizing';
import {DoMapperSizing} from '@state/state-service/do-mapper/mappers/app-sizing';
import {DoMapperDevice} from '@state/state-service/do-mapper/mappers/device';
import {DoMapperMetadata} from '@state/state-service/do-mapper/mappers/metadata';
import {DeviceSearchFetchResponse_DTO} from '@state/state-service/dto-mapper/model/device/device-dto';
import {RootDtoMapper} from '@state/state-service/dto-mapper/root-dto.mapper';
import {ErrorService} from '@state/state-service/error.service';
import {StateService} from '@state/state-service/state.service';
import {uniqBy} from 'lodash';
import {Subscription} from 'rxjs';
import {ILogger, LogLevel, LoggerConfig, LoggerService} from '../../services/logger/logger.service';

const loggerConfig: LoggerConfig = {
  name: '-',
  symbol: '🔬️️',
  textColor: 'black',
  backgroundColor: '#FFCA3A'
};

const deviceSearchUrl = environment.url.aws + '/devicesearch2';

@Injectable({
  providedIn: null
})
export class DeviceEpicService {
  private logger: ILogger;

  constructor(private http: HttpClient, private errorService: ErrorService, private loggerService: LoggerService) {
    this.logger = this.initLogger();
  }

  private initLogger() {
    loggerConfig.name = this.constructor.name;
    return this.loggerService.initLogger(loggerConfig);
  }

  private apiCall?: Subscription;

  getFilteredMetaDataAndSizingDevice(action: DeviceReduxAction, stateService: StateService) {
    const payload = action.payload as ApiPayloadSearchLoad;
    try {
      const {appSizing} = payload.project;

      const appSizingDTO = RootDtoMapper.mapToCallDeviceSearch(
        appSizing.input,
        uniqBy(
          [...(stateService.state.basicProject.data.custom?.gasCompositionDocs ?? []), ...stateService.state.gases.data],
          ({document}) => document.id
        )
      );

      // tslint:disable-next-line:no-unused-expression
      this.apiCall && this.apiCall.unsubscribe();

      this.apiCall = this.api_DeviceSearch(deviceSearchUrl, appSizingDTO).subscribe(res => {
        this.logger.log('', 'device-search', LogLevel.Info, [{Request: appSizingDTO}, {Response: res}]);

        const deviceSearchResp = DoMapperSizing.mapToAppSizingDeviceSearch(PROP_COLLECTION_MAP_CALL_DEVICE_SEARCH, res.data as any);

        const successAction: DeviceReduxAction = new DeviceReduxAction('DEVICE_SEARCH_SUCCESS', {
          devices:
            deviceSearchResp.devices && deviceSearchResp.devices.length && deviceSearchResp.devices[0]
              ? DoMapperDevice.mapToDeviceDO(deviceSearchResp.devices)
              : null,
          metadata: DoMapperMetadata.mapToMetaData(deviceSearchResp.deviceOptions)
        });

        stateService.dispatch(successAction);

        this.apiCall?.unsubscribe();
      });
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'DEVICE_SEARCH_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  private api_DeviceSearch(url: string, deviceDataRequest: Dto) {
    return this.http.post<DeviceSearchFetchResponse_DTO>(url, deviceDataRequest).pipe();
  }
}
