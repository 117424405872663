import {Quantity} from '../unit/quantity';
import {GasCompositionUUID, UUID} from '../communication/type-alias';
import {Color} from '@angular-material-components/color-picker';

export const PROCESS_DATA_ENTRIES_DEFAULT_COLORS = [
  new Color(48, 242, 0), // green for Min
  new Color(255, 35, 0), // red for Max
  new Color(73, 126, 118), // grey
  new Color(243, 165, 5), // orange
  new Color(233, 0, 255), // purple
  new Color(42, 46, 75) // dark grey
] as const;

/**
 * ProcessPoint defines a process condition of pressure,
 * temperature and gas properties to evaluate uncertainty data
 */
export interface ProcessDataEntry {
  id: UUID;
  active: boolean;
  /** Name of the process data entry. Eg "min", "norm", "max", ... */
  name: string;
  /** Temperature for process point */
  temperature: Quantity<'Temperature'>;
  /** Pressure for process point */
  pressure: Quantity<'Pressure'>;
  /** Reference key to gas composition required for SoS computation. New: each process point can have its own gas composition. */
  gasCompositionId: GasCompositionUUID;
  /** Molecular weight for calculating gas. Used in conjunction with kappa.  */
  molWeight: Quantity<'MolarMass'>;
  /** Gas specific adiabatic index. */
  kappa: Quantity<'Numeral'>;
  /** Defines which method should be used in order to calculate gas properties like SoS. */
  usedGasMethod: 'GasComposition' | 'MolWeightKappa';
  /** FRONTEND ONLY: Whether the user is able to direct edit this entry */
  isWriteProtected: boolean;
  /** BACKEND ONLY: Used to store gas composition from {@link ProcessDataEntry.gasCompositionDoc} */
  color?: Color;
}
