import {AbstractDomainObject} from '@flowtwin/communication';

export class AppVersions extends AbstractDomainObject {
  frontend: {
    base: string;
  };
  aws: {
    base: string;
    unit: string;
    communication: string;
  };
  octave: {
    base: string;
    common: string;
    unit: string;
    matlab: string;
    communication: string;
  };
}
