import {ReduxAction} from '@state/actions/actions-abstract';
import {Tag} from '@state/state-service/do-mapper/model/tag/tag';

export type TagActions =
  | 'TAGS_GET_ALL_SUCCESS'
  | 'TAGS_GET_ALL_ERROR'
  | 'TAGS_GET_ONE_LOADING'
  | 'TAGS_GET_ONE_SUCCESS'
  | 'TAGS_GET_ONE_ERROR'
  | 'TAG_CREATE_ONE_LOADING'
  | 'TAG_CREATE_ONE_SUCCESS'
  | 'TAG_CREATE_ONE_ERROR'
  | 'TAG_SAVE_ONE_LOADING'
  | 'TAG_SAVE_ONE_SUCCESS'
  | 'TAG_SAVE_ONE_ERROR'
  | 'TAG_DELETE_ONE_LOADING'
  | 'TAG_DELETE_ONE_SUCCESS'
  | 'TAG_DELETE_ONE_ERROR';

// tslint:disable-next-line:no-empty-interface
export interface ApiPayloadGeneric {}

export interface ApiPayloadGetAllLoading {
  projectId: string;
}

export interface ApiPayloadGetAllSuccess {
  tags: Tag[];
}

export interface ApiPayloadCreateOneLoading {
  projectId: string;
  tagDescription: string;
  tagName: string;
}

export interface ApiPayloadCreateOneSuccess {
  tag: Tag;
}

export interface ApiPayloadDeleteOneLoading {
  projectId: string;
  tagId: string;
}

export interface ApiPayloadGetOneSuccess {}

export interface ApiPayloadGetOneLoading {
  projectId: string;
  tagId: string;
}

export interface ApiPayloadSaveOneSuccess {
  tag: Tag;
}

export interface ApiPayloadSaveOneLoading {
  projectId: string;
  data: Tag;
}

export interface ApiPayloadDeleteOneSuccess {
  tagId: string;
}

export interface ApiPayloadSetSelectedTag {
  tag: Tag;
}

export type TagPayloads =
  | ApiPayloadGeneric
  | ApiPayloadGetAllLoading
  | ApiPayloadGetAllSuccess
  | ApiPayloadCreateOneLoading
  | ApiPayloadCreateOneSuccess
  | ApiPayloadDeleteOneLoading
  | ApiPayloadDeleteOneSuccess
  | ApiPayloadGetOneSuccess
  | ApiPayloadGetOneLoading
  | ApiPayloadSaveOneSuccess
  | ApiPayloadSaveOneLoading
  | ApiPayloadSetSelectedTag;

export class TagReduxAction extends ReduxAction {
  type: TagActions;
  payload: TagPayloads;

  constructor(type: TagActions, payload: TagPayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
