import {DtoContract} from '../../contracts';

export const RES_GET_APP_TRANSDUCER_PERFORMANCE_INPUT = {
  config: [
    {KEY: 'pressure.min', TYPE: 'DTOQUANTITY'},
    {KEY: 'pressure.max', TYPE: 'DTOQUANTITY'},
    {KEY: 'pressure.minDesign', TYPE: 'DTOQUANTITY'},
    {KEY: 'pressure.maxDesign', TYPE: 'DTOQUANTITY'},
    {KEY: 'temperature.min', TYPE: 'DTOQUANTITY'},
    {KEY: 'temperature.max', TYPE: 'DTOQUANTITY'},
    {KEY: 'temperature.minDesign', TYPE: 'DTOQUANTITY'},
    {KEY: 'temperature.maxDesign', TYPE: 'DTOQUANTITY'},
    {KEY: 'remarks', TYPE: 'STRING'},
    {KEY: 'gasCompositionId', TYPE: 'STRING'},
    {KEY: 'nominalDiameter', TYPE: 'DTOQUANTITY'},
    {KEY: 'probeVec.userSelectedProbe', TYPE: 'BOOLEAN'},
    {KEY: 'probeVec.selectedProbeKey', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'injectH2', TYPE: 'BOOLEAN'},
    {KEY: 'xH2', TYPE: 'DTOQUANTITY'},
    {KEY: 'configuration.exGroupKey', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'configuration.gasSelectionKey', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'configuration.hsElectronics', TYPE: 'BOOLEAN'},
    {KEY: 'configuration.extendedPNwCalculation', TYPE: 'BOOLEAN'}
  ] as DtoContract[]
};
export const RES_GET_APP_TRANSDUCER_PERFORMANCE_OUTPUT = {};
export const RES_POST_APP_TRANSDUCER_PERFORMANCE_RESULT = {
  config: [
    {KEY: 'agc.pMin.innerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'agc.pMin.outerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'agc.pMax.innerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'agc.pMax.outerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'pathLength.innerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'pathLength.outerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'agcAssembly.innerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'agcAssembly.outerPath', TYPE: 'DTOQUANTITY'},
    {KEY: 'pressureAssembly', TYPE: 'DTOQUANTITY'},
    {KEY: 'agcState.pMin.innerPath', TYPE: 'NUMBER'},
    {KEY: 'agcState.pMin.outerPath', TYPE: 'NUMBER'},
    {KEY: 'agcState.pMax.innerPath', TYPE: 'NUMBER'},
    {KEY: 'agcState.pMax.outerPath', TYPE: 'NUMBER'},
    {KEY: 'limitSearch.maxNW', TYPE: 'DTOQUANTITY'},
    {KEY: 'limitSearch.minPressure', TYPE: 'DTOQUANTITY'},
    {KEY: 'hsState', TYPE: 'BOOLEAN'},
    {KEY: 'reflectionState', TYPE: 'BOOLEAN'},
    {KEY: 'designState', TYPE: 'NUMBER'},
    {KEY: 'probe', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'gasType', TYPE: 'DTOKEYVALUEITEM'},
    {KEY: 'summaryState.applicationState', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.applicationRecommendation.Summary', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.applicationRecommendation.Pressure', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.applicationRecommendation.NominalDiameter', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.applicationRecommendation.H2State', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.applicationRecommendation.SosState', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.agcSimulation', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.signalSimulation', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.designState.summary', TYPE: 'BOOLEAN'},
    {KEY: 'summaryState.designState.minDesign', TYPE: 'BOOLEAN'}
  ] as DtoContract[]
};
