import {SICK_BASE_UNITS, UnitSystem, UnitSystemKey} from './unit-system-document';
import {Quantity} from './quantity';
import {isDefined} from '@shared/utility/isDefined';

/**
 * Returns the value to display
 */
export const displayValue =
  (unitSystem: UnitSystem) =>
  (quantity: Quantity<UnitSystemKey>, decimalPlaces = -1): string => {
    if (!quantity) {
      return '';
    }

    const unitKey = quantity.unit; // e.g. Temperature
    const dPlaces = decimalPlaces === -1 ? unitSystem[unitKey].decimalPlaces : decimalPlaces;
    const format = (value: number) => (value != null ? value.toFixed(dPlaces) : '-');
    return format(targetValue(unitSystem)(quantity));
  };

export const targetValue =
  (targetUnitSystem: UnitSystem) =>
  (quantity: Quantity<UnitSystemKey>): number => {
    const unitKey = quantity.unit; // e.g. Temperature
    const targetUnitTag = targetUnitSystem[unitKey].conversion; // e.g. 'UNIT_KELVIN'
    const sickBaseUnitTag = SICK_BASE_UNITS[unitKey].BaseUnit;

    if (!isDefined(quantity.value)) {
      return quantity.value;
    }

    if (sickBaseUnitTag === targetUnitTag) {
      return quantity.value;
    }

    const conversion = SICK_BASE_UNITS[unitKey].Conversions.find(c => c.UnitTag === targetUnitTag);

    if (!conversion) {
      throw Error(`Not able to compute display value for quantity:${quantity} using UnitSystem: ${targetUnitSystem}`);
    }

    return quantity.value * Number(conversion.Factor) + Number(conversion.Offset);
  };
