import {Injectable} from '@angular/core';
import {OAuthErrorEvent, OAuthService} from 'angular-oauth2-oidc';
import {combineLatest, of, timer} from 'rxjs';
import {take} from 'rxjs/operators';
import {environment} from '@env/environment';
import {StateService} from '@state/state-service/state.service';
import {defaultState} from '@state/model/default-state';
import {UserReduxAction} from '@state/actions/actions-user';
import {Router} from '@angular/router';

// This is what the Auth server provides (with examples)
interface IdentityClaims {
  acr: string; //  "0"
  at_hash: string; //  "gbO3nTUlD3WDJkplW_UKeQ"
  aud: string; //  "FlowsicDesignApplication"
  auth_time: string; //  1597118590
  azp: string; //  "FlowsicDesignApplication"
  email: string; //  "ulrich.winkler@sick.de"
  email_verified: boolean; //  true
  exp: number; //  1597126170
  family_name: string; //  "Winkler"
  given_name: string; //  "Ulrich"
  iat: number; //  1597125270
  iss: string; //  "https://id.uat.sick.com/auth/realms/sickservices"
  jti: string; //  "a38077d6-2f3b-4d61-b9cd-5d8ffb94f757"
  locale: string; //  "en"
  name: string; //  "Ulrich Winkler"
  nbf: number; //  0
  nonce: string; //  "TGI5ejJrYUlzc2lpZHFTOG1Pc2R4OHljUHZ0blJ2cXk4RGFPYjB-MUlWUWpV"
  preferred_username: string; //  "ulrich.winkler@sick.de"
  s_hash: string; //  "fmDK8AdMPdP5-0nG_OqRaQ"
  session_state: string; //  "2c5323c3-3e59-407e-be4d-6925c13d1694"
  sub: string; //  "9b507c88-b9b5-4bd3-a1b0-8e611601718c"
  typ: string; //  "ID"
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private oauthService: OAuthService, private stateService: StateService) {}

  async auth() {
    environment.oidc.redirectUri = window.location.origin + window.location.pathname;

    this.oauthService.configure(environment.oidc);
    this.oauthService.setupAutomaticSilentRefresh();

    if (this.oauthService.hasValidIdToken()) {
      const result = await this.oauthService.loadDiscoveryDocument();

      if (result) {
        this.getCurrentUserRecord();
      }
    } else {
      await this.oauthService.loadDiscoveryDocumentAndLogin();
      this.getCurrentUserRecord();
    }

    this.setupAutomaticLogoutInCaseOfTokenExpiry();
  }

  async logout() {
    this.stateService.next(defaultState, {type: 'AutService.logout'});
    this.oauthService.logOut();
  }

  private getCurrentUserRecord() {
    const getUser: UserReduxAction = new UserReduxAction('USER_GET_CURRENT_LOAD', {});
    this.stateService.dispatch(getUser);
  }

  private setupAutomaticLogoutInCaseOfTokenExpiry() {
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        window.location.href = environment.sickIdUrl;
      }
    });
  }
}
