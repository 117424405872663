import _ from 'lodash';

export function addKnownTypeToDto(element: any, lastPropOfPath: string, domainObject: any, path: string, fullPaths: any[]) {
  const elementValue = _.get(element, lastPropOfPath);
  const attachedObject = _.get(domainObject, path);

  if (_.isEmpty(attachedObject)) {
    // traverse empty object to array to push values inside
    // because now it is the last level and for transposing it needs to
    // be an array
    _.set(domainObject, path, []);
  }

  const lastNestedLevelArray = _.get(domainObject, path);
  lastNestedLevelArray.push(elementValue);

  fullPaths.push(path);
}
