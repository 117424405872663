import {DomainContract} from '@flowtwin/communication';

export const POST_COMPUTE_TRANSDUCER = (input: any): DomainContract[] => {
  return [
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'email', PATHINDATATRANSFEROBJECT: 'user.email'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'locale', PATHINDATATRANSFEROBJECT: 'user.locale'},

    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'permissions.admin', PATHINDATATRANSFEROBJECT: 'user.permissions.admin'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'permissions.gbc04expert', PATHINDATATRANSFEROBJECT: 'user.permissions.gbc04expert'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'permissions.gbc04service', PATHINDATATRANSFEROBJECT: 'user.permissions.gbc04service'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'permissions.gbc04sales', PATHINDATATRANSFEROBJECT: 'user.permissions.gbc04sales'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'permissions.sicksales', PATHINDATATRANSFEROBJECT: 'user.permissions.sicksales'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'permissions.externalpartner', PATHINDATATRANSFEROBJECT: 'user.permissions.externalpartner'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'permissions.externalservice', PATHINDATATRANSFEROBJECT: 'user.permissions.externalservice'},
    // tslint:disable-next-line:max-line-length
    {
      DOMAINOBJECT: input,
      PATHINDOMAINOBJECT: 'permissions.externalcustomer',
      PATHINDATATRANSFEROBJECT: 'user.permissions.externalcustomer'
    },

    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'pressure.min', PATHINDATATRANSFEROBJECT: 'pressure.min'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'pressure.max', PATHINDATATRANSFEROBJECT: 'pressure.max'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'pressure.maxDesign', PATHINDATATRANSFEROBJECT: 'pressure.maxDesign'},

    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'temperature.min', PATHINDATATRANSFEROBJECT: 'temperature.min'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'temperature.max', PATHINDATATRANSFEROBJECT: 'temperature.max'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'temperature.minDesign', PATHINDATATRANSFEROBJECT: 'temperature.minDesign'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'temperature.maxDesign', PATHINDATATRANSFEROBJECT: 'temperature.maxDesign'},

    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'gasComposition', PATHINDATATRANSFEROBJECT: 'gasComposition'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'nominalDiameter', PATHINDATATRANSFEROBJECT: 'nominalDiameter'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'userSelectedProbe', PATHINDATATRANSFEROBJECT: 'probeVec.userSelectedProbe'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'sensorType', PATHINDATATRANSFEROBJECT: 'probeVec.selectedProbeKey'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'injectH2', PATHINDATATRANSFEROBJECT: 'injectH2'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'xH2', PATHINDATATRANSFEROBJECT: 'xH2'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'explosionGroup', PATHINDATATRANSFEROBJECT: 'configuration.exGroupKey'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'sensorGasSelectionMethod', PATHINDATATRANSFEROBJECT: 'configuration.gasSelectionKey'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'hsElectronics', PATHINDATATRANSFEROBJECT: 'configuration.hsElectronics'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'extendedPNwCalculation', PATHINDATATRANSFEROBJECT: 'configuration.extendedPNwCalculation'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'userGroup', PATHINDATATRANSFEROBJECT: 'configuration.userGroup'},
    {DOMAINOBJECT: input, PATHINDOMAINOBJECT: 'calcMode', PATHINDATATRANSFEROBJECT: 'calcMode'}
  ];
};
