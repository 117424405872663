import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {Observable} from 'rxjs';

//
// Adds the id token to all request headers
//
// See https://angular.io/guide/http#setting-default-headers
//
@Injectable({
  providedIn: 'root'
})
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(private authService: OAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getIdToken();

    if (!token) {
      return next.handle(req);
    }

    const urlExceptions = [
      'assets.flowsic-applicationdesign.dev-cloud.sick.com',
      'assets.flowsic-applicationdesign.uat-cloud.sick.com',
      'assets.flowsic-applicationdesign.cloud.sick.com'
    ];
    if (urlExceptions.some(element => req.url.includes(element))) {
      return next.handle(req);
    }
    const authReq = req.clone({
      headers: req.headers.set('Authorization', token)
    });

    return next.handle(authReq);
  }
}

export const AuthHeaderInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthHeaderInterceptor,
  multi: true
};
