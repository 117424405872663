import {ReduxAction} from './actions-abstract';
import {GenericDialog} from '@state/model/generic-dialog';

export enum DialogState {
  close,
  open
}

export enum Dialogs {
  error = 'error',
  warning = 'warning',
  information = 'information',
  modal = 'modal'
}

export type ModalAction = 'MODAL_INFO' | 'MODAL_WARNING' | 'MODAL_ERROR' | 'MODAL_OK';

export interface GenericModalPayload {
  modal: Partial<{dialog: Dialogs; config: Partial<GenericDialog>}>;
}

export type ModalActionPayload = GenericModalPayload;

export class ModalReduxAction extends ReduxAction {
  type: ModalAction;
  payload: ModalActionPayload;

  constructor(type: ModalAction, payload: ModalActionPayload) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
