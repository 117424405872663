import {User} from '@state/state-service/do-mapper/model/communication/user';
import {GasCompositionDoc} from '@state/state-service/do-mapper/model/gas/gas-composition-doc';
import {UnitSystemDocument} from '@state/state-service/do-mapper/model/unit/unit-system-document';
import {AppVersions} from '@state/state-service/do-mapper/model/communication/app-versions';
import {FlowTwinProject} from '@state/state-service/do-mapper/model/project/project-flow-twin';
import {Metadata} from '@state/state-service/do-mapper/model/project/metadata';
import {TableView} from '@state/model/table-view';
import {GenericDialog} from '@state/model/generic-dialog';
import {BasicProject} from '@state/state-service/do-mapper/model/project/project-basic';
import {UpgradeProcess} from '@state/model/upgrade-process';
import {AppModeType} from '@state/actions/actions-app-mode';
import {Tag} from '@state/state-service/do-mapper/model/tag/tag';

const LOADING_STATES = ['init', 'loading', 'ready', 'error'] as const;
export type LOADING_STATE = typeof LOADING_STATES[number];
export type SAVE_STATE = 'unsaved' | 'saved';

export function isLoadingState(state: any): state is LOADING_STATE {
  return LOADING_STATES.includes(state);
}

export interface State {
  appMode: {
    mode: AppModeType;
  };
  pages: {
    projects: LOADING_STATE;
    project: LOADING_STATE;
    gases: LOADING_STATE;
    customUnits: LOADING_STATE;
    users: LOADING_STATE;
    mock: LOADING_STATE;
    crm: LOADING_STATE;
    appSizing: LOADING_STATE;
    appUncertainty: LOADING_STATE;
    appTransducer: LOADING_STATE;
  };
  projects: {
    state: LOADING_STATE;
    data: BasicProject[];
    tableView: TableView;
    upgrade: UpgradeProcess;
  };

  basicProject: {
    tags: Tag[] | null;
    data: BasicProject | null;
  };

  project: {
    saved: SAVE_STATE;
    tag?: Tag;
    data: FlowTwinProject | null;
    metadata: Partial<Metadata>;
    steps: string[];
    subTitle: string;
    debugMessage?: string;
  };

  unitSystems: {
    state: LOADING_STATE;
    data: UnitSystemDocument[];
    tableView: TableView;
  };

  user: User;

  users: {
    state: LOADING_STATE;
    data: User[];
    tableView: TableView;
  };

  gases: {
    state: LOADING_STATE;
    data: GasCompositionDoc[];
    tableView: TableView;
  };

  toolbar: {
    title: string;
  };

  splash: {
    open: boolean;
  };

  dialogs: {
    error: GenericDialog;
    warning: GenericDialog;
    information: GenericDialog;
    modal: GenericDialog;
  };

  versions: AppVersions;
}
