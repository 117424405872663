<da-dialog-fullscreen [open]="dialogConfig?.open" (dismiss)="ok()">
  <div header style="padding: 1rem">
    <div mat-card-title>
      {{ 'exception-dialog.title' | translate }}
    </div>
  </div>
  <div body>
    <div class="content">
      <img src="assets/undraw_bug.svg" alt="" />
      {{ 'exception-dialog.message' | translate }}
    </div>
    <hr />
    <h2 class="error-message">{{ dialogConfig?.message ?? '' }}.</h2>
  </div>

  <div footer>
    <div class="row no-margin-bottom">
      <!-- OK -->
      <button mat-flat-button color="primary" class="col-4 no-margin-bottom" (click)="ok()">
        {{ 'exception-dialog.ok' | translate }}
      </button>
    </div>
  </div>
</da-dialog-fullscreen>
