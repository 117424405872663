import {DtoContract} from '../contracts/contracts';
import {Item} from '../dto-assembly-line/model/dto';

export function getMissingContractKeys(
  desiredProperties: Array<{dtoKey: string; doKey: string}>,
  payload: Array<DtoContract> | Array<Item>
) {
  const missingKeys: string[] = [];

  desiredProperties.forEach(property => {
    const hasMatchingKey = payload.some(item => {
      const key = item.KEY || item.key;

      return key === property.dtoKey;
    });

    if (!hasMatchingKey) {
      missingKeys.push(property.dtoKey);
    }
  });

  return missingKeys;
}
