// export function filterStringForArrayValues(str, values) {
//     return values.filter(value => str.includes(value));
// }
//

export function stringIncludesArrayValues(str, values) {
  str = str.toLowerCase();
  values = values.map(value => value.toLowerCase());
  const count = {};
  values.forEach(value => {
    if (str.includes(value)) {
      count[value] = count[value] ? count[value] + 1 : 1;
    }
  });
  let maxValue = '';
  let maxCount: unknown = 0;
  for (const [value, freq] of Object.entries(count)) {
    if (freq > maxCount) {
      maxValue = value;
      maxCount = freq;
    }
  }
  return maxValue;
}
