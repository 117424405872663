import {Injectable} from '@angular/core';
import {StateService} from '@state/state-service/state.service';
import {MetadataEpicService} from '@state/epics/metadata-epic.service';
import {MetadataReduxAction} from '@state/actions/actions-metadata';

@Injectable({
  providedIn: null
})
export class MetadataReducerService {
  static assignHandler(action: MetadataReduxAction, stateService: StateService, epic: MetadataEpicService) {
    switch (action.type) {
      case 'METADATA_GET_ALL_LOAD':
        MetadataReducerService.updateMetadataState(action, stateService);
        epic.getMetadata(action, stateService);
        break;
      case 'METADATA_GET_ALL_SUCCESS':
        MetadataReducerService.updateMetadata(action, stateService);
        break;
      case 'METADATA_GET_ALL_ERROR':
        break;
      default:
        console.warn(`Cannot assign Action of Type ${action.type} to any Metadata Reducer-Function.`);
        break;
    }
  }

  private static updateMetadataState(action: MetadataReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        switch (action.type) {
          case 'METADATA_GET_ALL_LOAD':
            s.project.metadata.state = 'loading';
            break;
          case 'METADATA_GET_ALL_SUCCESS':
            s.project.metadata.state = 'ready';
            break;
          default:
            break;
        }
      },
      {type: 'Metadata State ' + action.type}
    );
  }

  private static updateMetadata(action: MetadataReduxAction, stateService: StateService) {
    stateService.update(
      s => {
        s.project.metadata = action.payload.metadata;
        s.project.metadata.state = 'ready';
      },
      {
        type: 'Update Metadata'
      }
    );
  }
}
