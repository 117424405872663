import {ReduxAction} from './actions-abstract';
import {UserLoggedInState} from '@state/state-service/do-mapper/model/communication/user';
import {FlowTwinProject} from '@state/state-service/do-mapper/model/project/project-flow-twin';
import {Metadata} from '@state/state-service/do-mapper/model/project/metadata';
import {SAVE_STATE} from '@state/model/state.model';
import {UUID} from '@state/state-service/do-mapper/model/communication/type-alias';
import {Project} from '@state/state-service/do-mapper/model/project/project';
import {AppTransducerInput, AppTransducerOutput} from '@state/state-service/do-mapper/model/apps/app-transducer';
import {BasicProject} from '@state/state-service/do-mapper/model/project/project-basic';
import {AppUncertaintyInput, AppUncertaintyOutput} from '@state/state-service/do-mapper/model/apps/app-uncertainty';
import {AppSizingInput, AppSizingOutput} from '@state/state-service/do-mapper/model/apps/app-sizing';
import {Tag} from '@state/state-service/do-mapper/model/tag/tag';

export type ProjectActions =
  | 'PROJECTS_GET_ALL_LOAD'
  | 'PROJECTS_GET_ALL_SUCCESS'
  | 'PROJECTS_GET_ALL_ERROR'
  | 'PROJECTS_GET_ALL_ADMIN_LOAD'
  | 'PROJECTS_GET_ALL_ADMIN_SUCCESS'
  | 'PROJECTS_GET_ALL_ADMIN_ERROR'
  | 'PROJECT_CLEAR_CURRENT'
  | 'PROJECT_SAVE_LOAD'
  | 'PROJECT_SAVE_SUCCESS'
  | 'PROJECT_SAVE_ERROR'
  | 'PROJECT_SHARE_LOAD'
  | 'PROJECT_SHARE_SUCCESS'
  | 'PROJECT_SHARE_ERROR'
  | 'PROJECT_CREATE_LOAD'
  | 'PROJECT_CREATE_SUCCESS'
  | 'PROJECT_CREATE_ERROR'
  | 'PROJECT_RESTORE_LOAD'
  | 'PROJECT_RESTORE_SUCCESS'
  | 'PROJECT_RESTORE_ERROR'
  | 'PROJECT_DELETE_BY_ID_LOAD'
  | 'PROJECT_DELETE_BY_ID_SUCCESS'
  | 'PROJECT_DELETE_BY_ID_ERROR'
  | 'UPDATE_PROJECT_SAVE_STATE'
  | 'GET_APP_DATA_BY_ID'
  | 'APP_GET_BY_ID_SUCCESS'
  | 'APP_GET_BY_ID_ERROR';

// tslint:disable-next-line:no-empty-interface
export interface ApiPayloadGeneric {}

export interface ApiPayloadGetAllLoad {
  silent: boolean;
}

export interface ApiPayloadGetAllSuccess {
  projects: BasicProject[];
}

export interface ApiPayloadGetByIdLoad {
  projectId: string;
}

export interface ApiPayloadGetAppByIdLoad {
  projectId: string;
  tagId: string;
  appId: 'document' | 'appProject' | 'selection-sizing' | 'uncertainty-prediction' | 'transducer-performance';
  input: AppSizingInput | AppUncertaintyInput | AppTransducerInput;
  output: AppSizingOutput | AppUncertaintyInput | AppTransducerOutput;
}

export interface ApiPayloadGetByIdSuccess {
  project: FlowTwinProject;
}

export interface ApiPayloadDeleteByIdLoad {
  projectId: UUID;
  deleteForAll: boolean;
}

export interface ApiPayloadDeleteByIdSuccess {
  projectId: UUID;
}

export interface ApiPayloadSaveOneLoad {
  project: FlowTwinProject;
  metadata: Metadata;
  tag: Tag;
  isBasicProject: boolean;
}

export interface ApiPayloadSaveOneSuccess {
  project: FlowTwinProject;
  saveState: SAVE_STATE;
}

export interface PayloadUpdateProjectSaveState {
  saveState: SAVE_STATE;
}

export interface ApiPayloadCreateOneLoad {
  project: any;
}

export interface ApiPayloadCreateOneSuccess {
  project: FlowTwinProject | Project;
  saveState: SAVE_STATE;
}

export interface ApiPayloadGetAppByIdSuccess {
  projectId: string;
  appId: string;
  input: AppSizingInput | AppUncertaintyInput | AppTransducerInput;
  output: AppSizingOutput | AppUncertaintyOutput | AppTransducerOutput;
}

export interface ApiPayloadShareByIdLoad {
  projectId: UUID;
  userList: string[];
}

export interface ApiPayloadShareByIdSuccess {
  projectId: UUID;
  userList: string[];
}

export type ProjectPayloads =
  | ApiPayloadGeneric
  | ApiPayloadGetAllLoad
  | ApiPayloadGetAllSuccess
  | ApiPayloadGetByIdLoad
  | ApiPayloadGetByIdSuccess
  | ApiPayloadDeleteByIdLoad
  | ApiPayloadDeleteByIdSuccess
  | ApiPayloadSaveOneLoad
  | ApiPayloadSaveOneSuccess
  | ApiPayloadCreateOneLoad
  | ApiPayloadCreateOneSuccess
  | PayloadUpdateProjectSaveState
  | ApiPayloadGetAppByIdLoad
  | ApiPayloadShareByIdLoad
  | ApiPayloadShareByIdSuccess;

export class ProjectReduxAction extends ReduxAction {
  type: ProjectActions;
  payload: ProjectPayloads;

  constructor(type: ProjectActions, payload: ProjectPayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
