import {Injectable} from '@angular/core';
import {StateService} from '../state-service/state.service';
import {
  PayloadProjectTableFavorite,
  PayloadTableSearchTerm,
  PayloadTableShowAllAdmin,
  PayloadTableShowDeleted,
  PayloadTableShowOutdated,
  PayloadTableSort,
  TableReduxAction
} from '../actions/actions-table';
import {clone} from '@shared/utility/clone';

@Injectable({
  providedIn: null
})
export class TableViewReducerService {
  static assignHandler(action: TableReduxAction, stateService: StateService) {
    switch (action.type) {
      case 'TABLE_SORT':
        TableViewReducerService.updateSort(action, stateService);
        break;
      case 'TABLE_SHOW_DELETED':
        TableViewReducerService.updateShowDeleted(action, stateService);
        break;
      case 'TABLE_SHOW_OUTDATED':
        TableViewReducerService.updateShowOutdated(action, stateService);
        break;
      case 'TABLE_SHOW_ALL_ADMIN':
        TableViewReducerService.updateShowAllAdmin(action, stateService);
        break;
      case 'TABLE_SEARCH_TERM':
        TableViewReducerService.updateSearchTerm(action, stateService);
        break;
      case 'TABLE_FAVORITE':
        TableViewReducerService.updateFavorites(action, stateService);
        break;
    }
  }

  private static updateSort(action: TableReduxAction, stateService: StateService) {
    const {table, sort, field} = action.payload as PayloadTableSort;

    stateService.update(
      s => {
        s[table].tableView.sort.direction = sort;
        s[table].tableView.sort.field = field;
      },
      {type: 'Update Table Sort'}
    );
  }

  private static updateShowDeleted(action: TableReduxAction, stateService: StateService) {
    const {table, showDeleted} = action.payload as PayloadTableShowDeleted;

    stateService.update(
      s => {
        s[table].tableView.showDeleted = showDeleted;
      },
      {type: 'Update Table Show Deleted '}
    );
  }

  private static updateShowOutdated(action: TableReduxAction, stateService: StateService) {
    const {table, showOutdated} = action.payload as PayloadTableShowOutdated;

    stateService.update(
      s => {
        s[table].tableView.showOutdated = showOutdated;
      },
      {type: 'Update Table Show Outdated '}
    );
  }

  private static updateShowAllAdmin(action: TableReduxAction, stateService: StateService) {
    const {table, showAllAdmin} = action.payload as PayloadTableShowAllAdmin;

    stateService.update(
      s => {
        s[table].tableView.showAllAdmin = showAllAdmin;
      },
      {type: 'Update Table Show Outdated '}
    );
  }

  private static updateSearchTerm(action: TableReduxAction, stateService: StateService) {
    const {table, searchTerm} = action.payload as PayloadTableSearchTerm;

    stateService.update(
      s => {
        s[table].tableView.searchTerm = searchTerm;
      },
      {type: 'Update Table Search Term to: ' + searchTerm + '.'}
    );
  }

  private static updateFavorites(action: TableReduxAction, stateService: StateService) {
    const {project, user} = clone(action.payload as PayloadProjectTableFavorite);

    stateService.update(
      s => {
        if (!s.user.document.staredProjects) {
          s.user.document.staredProjects = [];
        }

        if (!user.document.staredProjects) {
          user.document.staredProjects = [];
        }

        const alreadyFaved = s.user.document.staredProjects.indexOf(project.document.id) >= 0;

        if (alreadyFaved) {
          s.user.document.staredProjects = user.document.staredProjects.filter(id => id !== project.document.id);
        } else {
          s.user.document.staredProjects = [project.document.id, ...user.document.staredProjects];
        }
      },
      {type: 'Set/Unset Fav Project'}
    );
  }
}
