import {Dto, DtoDefinition} from './model/dto';
import {DomainContract, DtoContract} from '../contracts/contracts';
import {assertArrayContentEquality} from './validation/assert-array-content-equality';
import {assertFilledObjects} from './validation/assert-filled-objects';
import {dtoAssemblyFactory} from './factory/dto.factory';
import {DoDtoContractValidator} from './validation/validate-do-dto-contract';

export class DtoAssemblyDirector {
  private assembledDto: Dto;
  private readonly dtoType: DtoDefinition;
  private readonly domainContracts: DomainContract[];
  private readonly dtoContracts: DtoContract[];
  private falsyConfig: boolean;
  private errorMessage: string;

  constructor(dtoType: DtoDefinition, dtoContracts: DtoContract[], domainContracts: DomainContract[]) {
    this.dtoType = dtoType;
    this.dtoContracts = dtoContracts;
    this.domainContracts = domainContracts;
    this.falsyConfig = false;
    this.errorMessage = null;
  }

  private getFalsyConfigState() {
    if (this.falsyConfig) {
      this.assembledDto = {} as Dto;
      throw new Error(this.errorMessage);
    }

    return this.falsyConfig;
  }

  assembleDto(): DtoAssemblyDirector {
    new DoDtoContractValidator().checkContracts(this.dtoType, this.dtoContracts, this.domainContracts, false);

    this.assembledDto = dtoAssemblyFactory(this.dtoType, this.dtoContracts, this.domainContracts);

    if (this.assembledDto.payload.some(e => e === undefined)) {
      this.falsyConfig = true;
      this.errorMessage = 'Could not find values to given domainObject paths.\n' + JSON.stringify(this.assembledDto.payload, null, 2);
    }

    if (this.getFalsyConfigState()) {
      return this;
    }

    return this;
  }

  assembleTransposedDto(): DtoAssemblyDirector {
    new DoDtoContractValidator().checkContracts(this.dtoType, this.dtoContracts, this.domainContracts, true);

    const equalityResult = assertArrayContentEquality(this.domainContracts, this.dtoContracts);

    if (!equalityResult.isEqual) {
      this.falsyConfig = true;
      this.errorMessage = `Some of your domainObjects are missing mandatory paths to satisfy the dtoContract.Missing Paths: ${JSON.stringify(
        equalityResult.diff
      )}`;
    }

    const assertFilledObject = assertFilledObjects(this.domainContracts);

    if (assertFilledObject.some(element => element.empty)) {
      this.falsyConfig = true;
      this.errorMessage = `You are not allowed to have empty objects inside a transposed type. Empty paths: (count: ${
        assertFilledObject.length
      }, ${assertFilledObject.map(aFO => aFO.key).join(', ')}).`;
    }

    if (this.getFalsyConfigState()) {
      return this;
    }

    this.assembledDto = dtoAssemblyFactory(this.dtoType, this.dtoContracts, this.domainContracts, true);

    return this;
  }

  collect(): Dto {
    return this.assembledDto;
  }
}
