import {DtoContract} from '../../contracts';
import {
  UNIT_SYSTEM_ARRAY,
  UNIT_SYSTEM,
  UNIT_SYSTEM_DOCUMENT,
  UNIT_SYSTEM_DOCUMENT_ARRAY
} from '../../base-definitions/unit-system';

export const RES_GET_UNIT_SYSTEM = {
  config: [...UNIT_SYSTEM_DOCUMENT, ...UNIT_SYSTEM] as DtoContract[]
};
export const RES_GET_UNIT_SYSTEMS = {
  config: [...UNIT_SYSTEM_DOCUMENT_ARRAY, ...UNIT_SYSTEM_ARRAY, {KEY: 'document.version', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'NUMBER'}] as DtoContract[]
};
