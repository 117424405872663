import {Component, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'da-form-input-label',
  templateUrl: './form-input-label.component.html',
  styleUrls: ['./form-input-label.component.scss']
})
export class FormInputLabelComponent extends FieldType implements OnInit {
  get label() {
    return this.field.templateOptions.label || '';
  }

  ngOnInit(): void {}
}
