import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from '@env/environment';
import * as Sentry from '@sentry/angular-ivy';

if (environment.initializeSentry) {
  Sentry.init({
    dsn: 'https://0c94aa9b0da40f0d411ad111ab7cc542@o4506909711663104.ingest.us.sentry.io/4506932635828224',
    environment: environment.name,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (environment.production) {
  enableProdMode();
}

// Adding the implementation of Object.typedKeys() to the Object prototype. Type definition can be found in global.d.ts
Object.typedKeys = Object.keys as any;

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
