import {DoAssemblyDirector, Dto} from '@flowtwin/communication';
import {Tag} from '@state/state-service/do-mapper/model/tag/tag';
import {PROP_COLLECTION_MAP_TAGS_GET} from '@state/state-service/do-mapper/contracts/tag';

export class DoMapperTag {
  static mapToTags(tags: Dto): Tag[] {
    return new DoAssemblyDirector(tags, PROP_COLLECTION_MAP_TAGS_GET).createInstance<Tag>(Tag).assembleTransposedDo<Tag>().collect();
  }

  static mapToTag(tags: Dto): Tag {
    return new DoAssemblyDirector(tags, PROP_COLLECTION_MAP_TAGS_GET).createInstance<Tag>(Tag).assembleDo<Tag>().collect();
  }
}
