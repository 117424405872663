import {DOCUMENT, DOCUMENT_ARRAY} from './document';
import {DtoContract} from '../contracts';

export const GAS_COMPOSITION_DOCUMENT: DtoContract[] = [...DOCUMENT];

export const GAS_COMPOSITION: DtoContract[] = [{KEY: 'gasComposition', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'}];

export const GAS_COMPOSITION_DOCUMENT_ARRAY: DtoContract[] = [...DOCUMENT_ARRAY];

export const GAS_COMPOSITION_ARRAY: DtoContract[] = [{KEY: 'gasComposition', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'DTOKEYVALUEITEM'}];
