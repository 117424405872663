import {Component, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {StateService} from '@state/state-service/state.service';
import {ReduxAction} from '@state/actions/actions-abstract';

@Component({
  selector: 'da-form-button',
  templateUrl: './form-button.component.html',
  styleUrls: ['./form-button.component.scss']
})
export class FormButtonComponent extends FieldType implements OnInit {
  constructor(private stateService: StateService) {
    super();
  }

  get label() {
    return this.field.templateOptions.label || '';
  }

  get icon() {
    return this.field.templateOptions.icon;
  }

  handleClick() {
    if (this.field.templateOptions.clickHandler) {
      this.field.templateOptions.clickHandler();
    } else if (this.field.templateOptions.action) {
      const action: ReduxAction = {
        type: this.field.templateOptions.action,
        payload: ''
      };
      this.stateService.dispatch(action);
    }
  }

  ngOnInit(): void {}
}
