import {DomainContract} from '../../contracts/contracts';

export function assertFilledObjects(domainContracts: DomainContract[]) {
  return domainContracts[0].DOMAINOBJECT.map(obj => {
    const emptyObject = {
      empty: false,
      key: ''
    };
    checkEmptyObjects(obj, emptyObject);
    return emptyObject;
  });
}

const checkEmptyObjects = (o, e) => {
  for (const k in o) {
    if (!o[k] || typeof o[k] !== 'object') {
      continue;
    }

    if (Object.keys(o[k]).length === 0) {
      e.empty = true;
      e.key = k;
    }

    return checkEmptyObjects(o[k], e);
  }
};
