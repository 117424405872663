import {DtoContract} from "../../contracts";
import {UNIT_SYSTEM, UNIT_SYSTEM_DOCUMENT} from "../../base-definitions/unit-system";

export const REQ_GET_UNIT_SYSTEM = {
    config: [{}] as DtoContract[]
};
export const REQ_POST_UNIT_SYSTEM = {
    config: [
        ...UNIT_SYSTEM_DOCUMENT,
        {KEY: 'version', TYPE: 'NUMBER'},
        ...UNIT_SYSTEM
    ] as DtoContract[]
};
