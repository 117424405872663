import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouterHistoryService {
  private currentUrl: string | null = null;
  public previousUrl: string | null = null;

  constructor(private router: Router) {}

  public init() {
    this.currentUrl = this.router.url;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(_ => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = this.router.url;
    });
  }
}
