import {DomainContract, DtoContract} from '../../contracts/contracts';
import {accessObjectByPath} from '../../util/access-object-by-string-path';
import {ArrayElementTypeAnnotation, DtoDefinition} from '../model/dto';
import {checkForLibraryKnownType} from '../../util/type-checker';
import {isPrimitive} from '../../util/is-primitive';
import {getMissingContractKeys} from '../../util/get-missing-contract-keys';
import {getMissingMappings} from '../../util/get-missing-mappings';

export class DoDtoContractValidator {
  checkContracts(dtoType: DtoDefinition, dtoContract: DtoContract[], domainContract: DomainContract[], transpose = false) {
    this.checkDtoDefinition(dtoType);

    this.checkDtoContract(dtoContract);

    this.checkDomainContract(domainContract);

    this.checkLengthRelation(domainContract, dtoContract, dtoType);

    this.checkMissingArrayTypeAnnotation(dtoContract, domainContract, dtoType);

    // this.checkNullValuesInArrays(domainContract);

    this.checkInvalidTypingForTransposed(dtoContract, transpose);

    this.checkWrongContractPropertyValueAccordance(dtoContract, domainContract);
  }

  private checkDtoDefinition = (dtoType: DtoDefinition) => {
    if (!dtoType || dtoType.length <= 0) {
      throw new Error(`Param 'dtoType' is undefined: ${dtoType}.`);
    }
  };

  private checkDtoContract = (dtoContract: DtoContract[]) => {
    if (!dtoContract) {
      throw new Error(`No DtoContract given.`);
    }

    if (dtoContract.length <= 0) {
      throw new Error(`Incorrect DtoContract. Length is ${dtoContract?.length}.`);
    }
  };

  private checkDomainContract = (domainContract: DomainContract[]) => {
    if (!domainContract) {
      throw new Error(`No DomainContract given.`);
    }

    if (domainContract.length <= 0) {
      throw new Error(`Incorrect DomainContract. Length is ${domainContract?.length}.`);
    }
  };

  private checkLengthRelation = (domainContract: DomainContract[], dtoContract: DtoContract[], dtoType: DtoDefinition) => {
    if (domainContract.length !== dtoContract.length) {
      const desiredProperties = domainContract.map(x => {
        return {
          dtoKey: x.PATHINDATATRANSFEROBJECT,
          doKey: x.PATHINDOMAINOBJECT
        };
      });

      const missingKeys = getMissingContractKeys(desiredProperties, dtoContract);
      throw new Error(
        `Your DomainContract requests more mappings than the DTO provides.
        DomainContract Mappings ${desiredProperties.length} - ${dtoContract.length} DTO Keys.
        Your Domain Contract request these keys [${missingKeys.join(', ').toString()}] that are not in your DTO.`
      );
    }
  };

  private checkMissingArrayTypeAnnotation(dtoContract: DtoContract[], domainContract: DomainContract[], dtoType: DtoDefinition) {
    dtoContract.forEach((element, i) => {
      if (element.TYPE.includes('ARRAY') && !element.ARRAY && element.TYPE !== 'ARRAY<TRANSPOSED>') {
        throw new Error(
          `Your contract is missing an array type annotation for ${element.TYPE}. Element in contract: ${dtoContract.indexOf(element) + 1}.`
        );
      }

      if (element.TYPE.includes('ARRAY<COMPLEX>')) {
        const doArray = accessObjectByPath(domainContract[i].DOMAINOBJECT, domainContract[i].PATHINDOMAINOBJECT);

        if (element.ARRAY.length !== doArray.length) {
          throw new Error(
            `Your contract's Array Definition doesn't match to: ${dtoType}.
            DomainArray - ${doArray} (length ${doArray.length}): ${element.ARRAY} (length ${element.ARRAY.length}) - DtoContract
            `
          );
        }
      }
    });
  }

  private checkNullValuesInArrays(domainContract: DomainContract[]) {
    domainContract.forEach((element, i) => {
      const isArray = Object.prototype.toString.call(element.DOMAINOBJECT[Object.keys(element.DOMAINOBJECT)[i]]) === '[object Array]';
      if (isArray) {
        if (element.DOMAINOBJECT[Object.keys(element.DOMAINOBJECT)[i]]?.some(x => !x)) {
          throw new Error(`You are not allowed to have any NULL values inside your Array.`);
        }
      }
    });
  }

  private checkInvalidTypingForTransposed(dtoContract: DtoContract[], transpose: boolean) {
    if (transpose) {
      const invalidType = dtoContract
        .map(element => {
          if (!element.TYPE.includes('TRANSPOSED')) {
            return element;
          }
        })
        .filter(element => element);

      if (invalidType.length > 0) {
        const invalidKeys = invalidType.map(e => e.KEY);
        const invalidTypes = invalidType.map(e => e.TYPE);

        throw new Error(
          `Your contract contains wrong type/s ${invalidTypes.join(', ')} but should be ARRAY<TRANSPOSED>. For key ${invalidKeys.join(
            ', '
          )}.`
        );
      }
    }
  }

  private checkWrongContractPropertyValueAccordance(dtoContract: DtoContract[], domainContract: DomainContract[]) {
    const missingMappings = getMissingMappings(dtoContract, domainContract);

    if (missingMappings.length) {
      throw new Error(
        `Your DTO does not have keys called "${missingMappings
          .join(', ')
          .toString()}". Check your PATHINDATATRANSFEROBJECT-Values of your domainContract.`
      );
    }

    dtoContract.forEach((element, i) => {
      const isArrayType = element.TYPE.includes('ARRAY');
      const isArrayOfArray = element.TYPE.includes('ARRAY<ARRAY');
      const isArrayTransposed = element.TYPE.includes('TRANSPOSED');

      if (isArrayType) {
        let path = '';

        if (isArrayTransposed) {
          path = `[${i}].${domainContract[i].PATHINDOMAINOBJECT}`;
        } else if (isArrayType) {
          // TODO: needs another algo to checkObjectsKeyValueAccordance
          // path = domainContract[i].PATHINDOMAINOBJECT
          return;
        }
        if (isArrayOfArray) {
          // TODO: needs another algo to checkObjectsKeyValueAccordance
          // path = domainContract[i].PATHINDOMAINOBJECT + `[${i}]`
          return;
        }

        const value = accessObjectByPath(domainContract[i].DOMAINOBJECT, path);
        const mapping = dtoContract.find(x => x.KEY === domainContract[i].PATHINDATATRANSFEROBJECT);

        checkObjectsKeyValueAccordance(value, mapping, domainContract[i], i);
      }
    });
  }
}

function checkObjectsKeyValueAccordance(value: any, dtoContract: DtoContract, domainContract: DomainContract, index = 0) {
  const valueType = typeof value;

  const elementType =
    dtoContract.ARRAY instanceof Array
      ? (dtoContract.ARRAY?.[index] as ArrayElementTypeAnnotation)
      : (dtoContract.ARRAY as ArrayElementTypeAnnotation);

  if (elementType === 'DTODATE' || valueType === null || value === null || value === undefined) {
    return;
  }

  const isNotKnownType = !checkForLibraryKnownType(value, elementType);
  const isNotArray = !(value instanceof Array);
  const isNotGivenType = !(valueType.toString().toUpperCase() === dtoContract.ARRAY);

  if (isNotKnownType && isNotGivenType && isNotArray) {
    value = Object.keys(value).length > 0 || isPrimitive(value) ? JSON.stringify(value) : null;

    if (typeof value === 'string') {
      value = value.replace(/\\"/g, '');
      value = value.toString().replace(/"/g, '\\"');
      value = value.replace(/\\"/g, '');
    }

    throw new Error(
      `The value (${value}) of of property ${domainContract.PATHINDOMAINOBJECT} does not match your defined array type of ${dtoContract.ARRAY} from your dtoContract key ${dtoContract.KEY}.`
    );
  } else if (!isNotArray) {
    value.forEach(element => checkObjectsKeyValueAccordance(element, dtoContract, domainContract, index));
  } else {
    return;
  }
}
