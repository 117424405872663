import {Component, OnDestroy, OnInit} from '@angular/core';
import {StateService} from '@state/state-service/state.service';
import {map} from 'rxjs/operators';
import {GenericDialog} from '@state/model/generic-dialog';
import {Subscription} from 'rxjs';
import {Dialogs, DialogState, ModalReduxAction} from '@state/actions/actions-modal';

@Component({
  selector: 'da-exception-dialog',
  templateUrl: './exception-dialog.component.html',
  styleUrls: ['./exception-dialog.component.scss']
})
export class ExceptionDialogComponent implements OnInit, OnDestroy {
  dialogConfig: GenericDialog;
  private subscriptions = new Subscription();

  constructor(private stateService: StateService) {}

  ok() {
    const errorDialog: ModalReduxAction = new ModalReduxAction('MODAL_OK', {
      modal: {
        dialog: Dialogs.error,
        config: {
          open: DialogState.close
        }
      }
    });

    this.stateService.dispatch(errorDialog);
  }

  ngOnInit(): void {
    const initialSub = this.stateService.state$.pipe(map(s => s.dialogs.error)).subscribe(errorDialog => {
      this.dialogConfig = errorDialog;
    });

    this.subscriptions.add(initialSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
