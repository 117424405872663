import {ReduxAction} from './actions-abstract';
import {AppAliases} from '@state/reducers/dependency-reducer';

export interface DependencyKeyValue {
  key: string;
  value: any;
}

export type DependencyAction =
  | 'GET_DEPENDENCY_FOR_PRODUCT'
  | 'GET_DEPENDENCY_FOR_SIZING'
  | 'GET_DEPENDENCY_FOR_UNCERTAINTY'
  | 'GET_DEPENDENCY_FOR_TRANSDUCER';

export interface Dependency {
  dependencies: DependencyKeyValue[];
  app: AppAliases;
}

export type DependencyPayload = Dependency;

export class DependencyReduxAction extends ReduxAction {
  type: DependencyAction;
  payload: DependencyPayload;

  constructor(type: DependencyAction, payload: DependencyPayload) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
