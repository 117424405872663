import {Command} from '@state/state-service/do-mapper/model/communication/commands';
import {UserActions} from '@state/actions/actions-user';
import {MetadataActions} from '@state/actions/actions-metadata';
import {VersionActions} from '@state/actions/actions-version';
import {ProjectActions} from '@state/actions/actions-project';
import {UnitSystemActions} from '@state/actions/actions-unit-system';
import {GasActions} from '@state/actions/actions-gas';
import {ComputeActions} from '@state/actions/actions-compute';
import {DeviceActions} from '@state/actions/actions-device';
import {TableActions} from '@state/actions/actions-table';
import {LocaleActions} from '@state/actions/actions-locale';
import {DisplayTextActions} from '@state/actions/actions-display-text';
import {PageStateActions} from '@state/actions/actions-page-state';
import {InputActions} from '@state/actions/actions-input';
import {ModalAction} from '@state/actions/actions-modal';
import {DependencyAction} from '@state/actions/actions-dependencies';
import {UpgradeActions} from '@state/actions/actions-upgrade';
import {AppModeActions} from '@state/actions/actions-app-mode';
import {TagActions} from '@state/actions/actions-tag';
import {BasicProjectActions} from '@state/actions/actions-basic-project';

export type ActionType = Command;

export class ReduxAction {
  type: Actions;
  payload: any;

  constructor(type: Actions, payload: any) {
    this.type = type;
    this.payload = payload;
  }
}

export type Actions =
  | UserActions
  | MetadataActions
  | VersionActions
  | ProjectActions
  | UnitSystemActions
  | GasActions
  | ComputeActions
  | DeviceActions
  | TableActions
  | LocaleActions
  | DisplayTextActions
  | PageStateActions
  | InputActions
  | ModalAction
  | DependencyAction
  | UpgradeActions
  | AppModeActions
  | TagActions
  | BasicProjectActions;

// TODO: Move into Actions-Generic
export interface ApiGenericSuccess {
  payload: any;
}
