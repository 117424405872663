import {CONTRACTS, convertObjectIntoArrayOfKeyValues, Dto, DtoAssemblyDirector} from '@flowtwin/communication';
import {POST_COMPUTE_UNCERTAINTY} from '@state/state-service/dto-mapper/domain-contracts/app-uncertainty';
import {clone} from '@shared/utility/clone';
import {CONTRACT_POST_APP_UNCERTAINTY_INPUT} from '@state/state-service/dto-mapper/domain-contracts/project';
import {AppUncertaintyInput, AppUncertaintyOutput} from '@state/state-service/do-mapper/model/apps/app-uncertainty';

export class SubDtoMapperUncertainty {
  static mapToSaveAppUncertaintyInput(appUncertaintyInput: AppUncertaintyInput): Dto {
    if (!appUncertaintyInput || !appUncertaintyInput.parameterSetup || !appUncertaintyInput.processDataEntries) {
      return null;
    }

    const domainObject = clone(appUncertaintyInput);

    // convert uncertaintySetup array objects into Array of KeyValueItems
    domainObject.parameterSetup.deviceUncertaintySetups = domainObject.parameterSetup.deviceUncertaintySetups.map(element =>
      convertObjectIntoArrayOfKeyValues(element)
    ) as any;
    // convert processDataEntries array objects into Array of KeyValueItems
    domainObject.processDataEntries = domainObject.processDataEntries.map(element => convertObjectIntoArrayOfKeyValues(element)) as any;

    return new DtoAssemblyDirector(
      'APP_SYSTEM_UNCERTAINTY_INPUT_SAVE',
      CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_APP_SYSTEM_UNCERTAINTY_INPUT.config,
      CONTRACT_POST_APP_UNCERTAINTY_INPUT(domainObject)
    )
      .assembleDto()
      .collect();
  }

  static mapToSaveAppUncertaintyOutput(appUncertaintyOutput: AppUncertaintyOutput): Dto {
    if (!appUncertaintyOutput) {
      return null;
    }

    return null;
  }

  static mapToComputeUncertainty(uncertaintyInput: AppUncertaintyInput) {
    return new DtoAssemblyDirector(
      'APP_UNCERTAINTY_PREDICTION_REQUEST',
      CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_APP_UNCERTAINTY_PREDICTION_RESULT.config,
      POST_COMPUTE_UNCERTAINTY(uncertaintyInput)
    )
      .assembleDto()
      .collect();
  }
}
