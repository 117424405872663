import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormBuilderComponent} from './form-builder/form-builder.component';
import {FormButtonComponent} from './form-button/form-button.component';
import {FormDropdownComponent} from './form-dropdown/form-dropdown.component';
import {FormExpansionPanelComponent} from './form-expansion-panel/form-expansion-panel.component';
import {FormInputLabelComponent} from './form-input-label/form-input-label.component';
import {FormInputUnitComponent} from './form-input-unit/form-input-unit';
import {FormRangeSliderComponent} from './form-range-slider/form-range-slider.component';
import {RepeatSectionComponent} from './repeat-section/repeat-section.component';
import {UnitSystemFormComponent} from './unit-system-form/unit-system-form.component';

import {FormlyMaterialModule} from '@ngx-formly/material';
import {FormlyModule} from '@ngx-formly/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {FormlyWithTranslationProvider} from './translate-extension';
import {MaterialModule} from '@core/material/material.module';
import {TranslateModule} from '@ngx-translate/core';
import {DialogModule} from '@ui/dialog/dialog.module';
import {UIComponentsModule} from '@ui/ui-components/ui-components.module';
import {ConnectFormToStateDirective} from '@ui/sick-forms/connect-form-to-state.directive';

@NgModule({
  declarations: [
    FormBuilderComponent,
    FormButtonComponent,
    FormDropdownComponent,
    FormExpansionPanelComponent,
    FormInputLabelComponent,
    FormInputUnitComponent,
    FormRangeSliderComponent,
    RepeatSectionComponent,
    UnitSystemFormComponent,
    ConnectFormToStateDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormlyMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    UIComponentsModule,
    TranslateModule,
    DialogModule,
    FormlyModule.forRoot({
      types: [
        {name: 'da-unit', component: FormInputUnitComponent},
        {name: 'da-label', component: FormInputLabelComponent},
        {name: 'da-button', component: FormButtonComponent},
        {name: 'da-dropdown', component: FormDropdownComponent},
        {name: 'da-range-slider-formly', component: FormRangeSliderComponent},
        {name: 'da-repeat', component: RepeatSectionComponent}
      ],
      wrappers: [{name: 'da-expansion-panel', component: FormExpansionPanelComponent}]
    })
  ],
  exports: [
    FormBuilderComponent,
    FormButtonComponent,
    FormDropdownComponent,
    FormExpansionPanelComponent,
    FormInputLabelComponent,
    FormInputUnitComponent,
    FormRangeSliderComponent,
    RepeatSectionComponent,
    FormlyMaterialModule,
    FormlyModule,
    FormsModule,
    ReactiveFormsModule,
    UnitSystemFormComponent,
    ConnectFormToStateDirective
  ],
  providers: [FormlyWithTranslationProvider, ConnectFormToStateDirective]
})
export class SickFormsModule {}
