//
// For values ranges see
// https://deagxjira.sickcn.net/jira/browse/GBC04ADSW-231
//

import {convert, UnitTag} from '@state/state-service/do-mapper/model/unit/unit-system-document';
import {uniq} from 'lodash';

export interface RangeSliderValues {
  min: number;
  max: number;
  values: number[];
}

export interface NullableRangeSliderValues {
  min: number | null;
  max: number | null;
  values: number[];
}

export function isNullableRangeSliderValues(value: any): value is NullableRangeSliderValues {
  return value && value.min !== undefined && value.max !== undefined && value.values !== undefined;
}

//
// Temperature
//

const ABSOLUTE_ZERO_POINT = -273.15;
const SICK_TEMP_LIMIT_ABOVE_ABSOLUTE_ZERO = 90;
const GERG_TEMP_LIMIT = Number((ABSOLUTE_ZERO_POINT + SICK_TEMP_LIMIT_ABOVE_ABSOLUTE_ZERO).toFixed(2));

const UNIT_CELSIUS: RangeSliderValues = {
  min: -183.15,
  max: 350,
  values: [GERG_TEMP_LIMIT, ...lin(-180, 10, 350)]
};

// Conversion based on GERG_TEMP_LIMIT
const UNIT_KELVIN: RangeSliderValues = {
  min: 90,
  max: 623.15,
  values: [...lin(90, 10, 620), 623.15]
};

// Conversion based on GERG_TEMP_LIMIT
const UNIT_FAHRENHEIT: RangeSliderValues = {
  min: -297.67,
  max: 662,
  values: [-297.67, ...lin(-290, 10, 660), 662]
};

// Conversion based on GERG_TEMP_LIMIT
const UNIT_RANKINE: RangeSliderValues = {
  min: 162,
  max: 995,
  values: [162, ...lin(170, 10, 990), 995]
};

//
// OperationalFlow Rate (Actual)
//
const UNIT_M3PH: RangeSliderValues = {
  min: 0,
  max: 1_000_000,
  values: [
    ...lin(0, 5, 45),
    60,
    100,
    130,
    150,
    180,
    ...lin(240, 40, 360),
    ...lin(400, 50, 1000),
    1_600,
    3_000,
    4_500,
    7_000,
    8_000,
    10_000,
    14_000,
    20_000,
    24_000,
    32_000,
    ...lin(35_000, 5_000, 95_000),
    ...lin(100_000, 10_000, 200_000),
    ...lin(250_000, 50_000, 500_000),
    ...lin(600_000, 100_000, 100_000)
  ]
};

const UNIT_M3PD = convertRangeValue(UNIT_M3PH, 'UNIT_M3PH', 'UNIT_M3PD');
const UNIT_ACFH = convertRangeValue(UNIT_M3PH, 'UNIT_M3PH', 'UNIT_ACFH');
const UNIT_ACFD = convertRangeValue(UNIT_M3PH, 'UNIT_M3PH', 'UNIT_ACFD');
const UNIT_MMACFD = convertRangeValue(UNIT_M3PH, 'UNIT_M3PH', 'UNIT_MMACFD');
const UNIT_MMACFH = convertRangeValue(UNIT_M3PH, 'UNIT_M3PH', 'UNIT_MMACFH');

///
// NormalizedFlow
//
const UNIT_SM3PH: RangeSliderValues = {
  min: 0,
  max: 250_000_000,
  values: [...lin(0, 100, 1000), ...lin(2000, 1000, 100_000), ...lin(110_000, 10_0000, 1_000_000), ...lin(1_100_000, 100_000, 250_000_000)]
};
const UNIT_SCMD = convertRangeValue(UNIT_SM3PH, 'UNIT_SM3PH', 'UNIT_SCMD');
const UNIT_SCFH = convertRangeValue(UNIT_SM3PH, 'UNIT_SM3PH', 'UNIT_SCFH');
const UNIT_SCFD = convertRangeValue(UNIT_SM3PH, 'UNIT_SM3PH', 'UNIT_SCFD');
const UNIT_MMSCFD = convertRangeValue(UNIT_SM3PH, 'UNIT_SM3PH', 'UNIT_MMSCFD');
const UNIT_MMSCFH = convertRangeValue(UNIT_SM3PH, 'UNIT_SM3PH', 'UNIT_MMSCFH');

///
// Mass FLow
//
const UNIT_KG_PER_HOUR: RangeSliderValues = {
  min: 0,
  max: 100_000_000,
  values: [...lin(0, 1000, 1_500_000), ...lin(1_500_000, 500_000, 10_000_000), ...lin(10_000_000, 5_000_000, 100_000_000)]
};
const UNIT_TON_PER_HOUR = convertRangeValue(UNIT_KG_PER_HOUR, 'UNIT_KG_PER_HOUR', 'UNIT_TON_PER_HOUR');
const UNIT_LONG_TON_PER_HOUR = convertRangeValue(UNIT_KG_PER_HOUR, 'UNIT_KG_PER_HOUR', 'UNIT_LONG_TON_PER_HOUR');
const UNIT_SHORT_TON_PER_HOUR = convertRangeValue(UNIT_KG_PER_HOUR, 'UNIT_KG_PER_HOUR', 'UNIT_SHORT_TON_PER_HOUR');
const UNIT_LB_PER_HOUR = convertRangeValue(UNIT_KG_PER_HOUR, 'UNIT_KG_PER_HOUR', 'UNIT_LB_PER_HOUR');

//
// Pressure
//
const UNIT_BAR = {
  min: -1.01325,
  max: 248.98675,
  values: [-1.01325, ...lin(-1.0, 0.5, 5), ...lin(6, 1, 10), ...lin(12, 2, 25), ...lin(30, 5, 50), ...lin(60, 10, 240), 248.98675]
};

const UNIT_ATMOSPHERE = convertRangeValue(UNIT_BAR, 'UNIT_BAR', 'UNIT_ATMOSPHERE');

const UNIT_MEGAPASCAL = convertRangeValue(UNIT_BAR, 'UNIT_BAR', 'UNIT_MEGAPASCAL');
const UNIT_KILOPASCAL = convertRangeValue(UNIT_BAR, 'UNIT_BAR', 'UNIT_KILOPASCAL');
const UNIT_KILOPASCAL_ABS = convertRangeValue(UNIT_BAR, 'UNIT_BAR', 'UNIT_KILOPASCAL_ABS');
const UNIT_PSI = convertRangeValue(UNIT_BAR, 'UNIT_BAR', 'UNIT_PSI');
const UNIT_PSI_ABS = convertRangeValue(UNIT_BAR, 'UNIT_BAR', 'UNIT_PSI_ABS');
const UNIT_PSI_G = convertRangeValue(UNIT_BAR, 'UNIT_BAR', 'UNIT_PSI_G');
const UNIT_BAR_ABS = convertRangeValue(UNIT_BAR, 'UNIT_BAR', 'UNIT_BAR_ABS');

export const RANGE_SLIDER_VALUES: {
  Temperature: {
    UNIT_RANKINE: RangeSliderValues;
    UNIT_KELVIN: RangeSliderValues;
    UNIT_CELSIUS: RangeSliderValues;
    UNIT_FAHRENHEIT: RangeSliderValues;
  };
  NormalizedFlow: {
    UNIT_SCMD: NullableRangeSliderValues;
    UNIT_MMSCFH: NullableRangeSliderValues;
    UNIT_MMSCFD: NullableRangeSliderValues;
    UNIT_SM3PH: RangeSliderValues;
    UNIT_SCFD: NullableRangeSliderValues;
    UNIT_SCFH: NullableRangeSliderValues;
  };
  OperationalFlow: {
    UNIT_ACFD: NullableRangeSliderValues;
    UNIT_M3PD: NullableRangeSliderValues;
    UNIT_ACFH: NullableRangeSliderValues;
    UNIT_M3PH: RangeSliderValues;
    UNIT_MMACFH: NullableRangeSliderValues;
    UNIT_MMACFD: NullableRangeSliderValues;
  };
  MassFlow: {
    UNIT_LONG_TON_PER_HOUR: NullableRangeSliderValues;
    UNIT_KG_PER_HOUR: RangeSliderValues;
    UNIT_TON_PER_HOUR: NullableRangeSliderValues;
    UNIT_LB_PER_HOUR: NullableRangeSliderValues;
    UNIT_SHORT_TON_PER_HOUR: NullableRangeSliderValues;
  };
  Pressure: {
    UNIT_ATMOSPHERE: NullableRangeSliderValues;
    UNIT_KILOPASCAL: NullableRangeSliderValues;
    UNIT_KILOPASCAL_ABS: NullableRangeSliderValues;
    UNIT_MEGAPASCAL: NullableRangeSliderValues;
    UNIT_PSI_ABS: NullableRangeSliderValues;
    UNIT_PSI_G: NullableRangeSliderValues;
    UNIT_PSI: NullableRangeSliderValues;
    UNIT_BAR_ABS: NullableRangeSliderValues;
    UNIT_BAR: RangeSliderValues;
  };
} = {
  Pressure: {
    UNIT_BAR,
    UNIT_ATMOSPHERE,
    UNIT_MEGAPASCAL,
    UNIT_KILOPASCAL,
    UNIT_KILOPASCAL_ABS,
    UNIT_PSI,
    UNIT_PSI_ABS,
    UNIT_PSI_G,
    UNIT_BAR_ABS
  },
  Temperature: {
    UNIT_CELSIUS,
    UNIT_KELVIN,
    UNIT_FAHRENHEIT,
    UNIT_RANKINE
  },
  OperationalFlow: {
    UNIT_M3PH,
    UNIT_M3PD,
    UNIT_ACFH,
    UNIT_ACFD,
    UNIT_MMACFD,
    UNIT_MMACFH
  },
  NormalizedFlow: {
    UNIT_SM3PH,
    UNIT_SCMD,
    UNIT_SCFH,
    UNIT_SCFD,
    UNIT_MMSCFD,
    UNIT_MMSCFH
  },
  MassFlow: {
    UNIT_KG_PER_HOUR,
    UNIT_TON_PER_HOUR,
    UNIT_LONG_TON_PER_HOUR,
    UNIT_SHORT_TON_PER_HOUR,
    UNIT_LB_PER_HOUR
  }
};

//
// Helper Function
//
function lin(start: number, delta: number, end: number) {
  const ret: number[] = [];
  for (let i = start; i <= end; i = i + delta) {
    ret.push(i);
  }
  return ret;
}

function convertRangeValue(base: RangeSliderValues, from: UnitTag, to: UnitTag) {
  return {
    min: convert({value: base.min, from, to}),
    max: convert({value: base.max, from, to}),
    values: uniq(base.values.map(value => convert({value, from, to})).map(val => Math.floor(val ?? 0)))
  };
}
