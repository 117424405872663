import {DtoDoPropCollectionMap} from '@flowtwin/communication';

export const PROP_COLLECTION_MAP_PROJECT_CREATE: DtoDoPropCollectionMap[] = [
  {dtoKey: 'document.id', doKey: 'document.id'},
  {dtoKey: 'document.projectName', doKey: 'document.projectName'},
  {dtoKey: 'document.description', doKey: 'document.description'},
  {dtoKey: 'document.reference', doKey: 'document.reference'},
  {dtoKey: 'meta.deleted', doKey: 'meta.deleted'},
  {dtoKey: 'meta.userList', doKey: 'meta.userList'},
  {dtoKey: 'meta.created', doKey: 'meta.created'},
  {dtoKey: 'meta.frontendVersion', doKey: 'meta.lastSaved.frontendVersion'},
  {dtoKey: 'meta.backendVersion', doKey: 'meta.lastSaved.backendVersion'},
  {dtoKey: 'meta.awsVersion', doKey: 'meta.lastSaved.middleWareVersion'},
  {dtoKey: 'meta.versionFlag', doKey: 'meta.versionFlag'},
  {dtoKey: 'meta.lastSaved', doKey: 'meta.lastSaved.lastSaved'},
  {dtoKey: 'meta.savedBy', doKey: 'meta.lastSaved.savedBy'},
  {dtoKey: 'custom.unitSystem.name', doKey: 'custom.displayUnitSystem.document.name'},
  {dtoKey: 'custom.unitSystem.id', doKey: 'custom.displayUnitSystem.document.id'},
  {dtoKey: 'custom.unitSystem.description', doKey: 'custom.displayUnitSystem.document.description'},
  {dtoKey: 'custom.unitSystem', doKey: 'custom.displayUnitSystem.unitSystem'}
];

export const PROP_COLLECTION_MAP_PROJECT_GET: DtoDoPropCollectionMap[] = [
  {dtoKey: 'document.id', doKey: 'document.id'},
  {dtoKey: 'document.projectName', doKey: 'document.projectName'},
  {dtoKey: 'document.description', doKey: 'document.description'},
  {dtoKey: 'document.reference', doKey: 'document.reference'},
  {dtoKey: 'meta.userList', doKey: 'meta.userList'},
  {dtoKey: 'meta.awsVersion', doKey: 'meta.lastSaved.middleWareVersion'},
  {dtoKey: 'meta.backendVersion', doKey: 'meta.lastSaved.backendVersion'},
  {dtoKey: 'meta.created', doKey: 'meta.created'},
  {dtoKey: 'meta.deleted', doKey: 'meta.deleted'},
  {dtoKey: 'meta.frontendVersion', doKey: 'meta.lastSaved.frontendVersion'},
  {dtoKey: 'meta.lastSaved', doKey: 'meta.lastSaved.lastSaved'},
  {dtoKey: 'meta.savedBy', doKey: 'meta.lastSaved.savedBy'},
  {dtoKey: 'meta.versionFlag', doKey: 'meta.versionFlag'},
  {dtoKey: 'custom.gasCompositions', doKey: 'custom.gasCompositionDocs'},
  {dtoKey: 'custom.unitSystem', doKey: 'custom.displayUnitSystem.unitSystem'},
  {dtoKey: 'custom.unitSystem.description', doKey: 'custom.displayUnitSystem.document.description'},
  {dtoKey: 'custom.unitSystem.id', doKey: 'custom.displayUnitSystem.document.id'},
  {dtoKey: 'custom.unitSystem.name', doKey: 'custom.displayUnitSystem.document.name'}
];
