import {UnitSystemDocument} from '../../do-mapper/model/unit/unit-system-document';
import {CONTRACTS, convertObjectIntoArrayOfKeyValues, DomainContract, Dto, DtoAssemblyDirector} from '@flowtwin/communication';

const postUnitSystemDtoContract = (unitSystem): DomainContract[] => {
  return [
    {DOMAINOBJECT: unitSystem, PATHINDOMAINOBJECT: 'document.id', PATHINDATATRANSFEROBJECT: 'id'},
    {DOMAINOBJECT: unitSystem, PATHINDOMAINOBJECT: 'document.name', PATHINDATATRANSFEROBJECT: 'name'},
    {DOMAINOBJECT: unitSystem, PATHINDOMAINOBJECT: 'document.deleted', PATHINDATATRANSFEROBJECT: 'deleted'},
    {DOMAINOBJECT: unitSystem, PATHINDOMAINOBJECT: 'document.userList', PATHINDATATRANSFEROBJECT: 'userList'},
    {DOMAINOBJECT: unitSystem, PATHINDOMAINOBJECT: 'document.creator', PATHINDATATRANSFEROBJECT: 'creator'},
    {DOMAINOBJECT: unitSystem, PATHINDOMAINOBJECT: 'document.description', PATHINDATATRANSFEROBJECT: 'description'},
    {DOMAINOBJECT: unitSystem, PATHINDOMAINOBJECT: 'document.version', PATHINDATATRANSFEROBJECT: 'version'},
    {DOMAINOBJECT: unitSystem, PATHINDOMAINOBJECT: 'unitSystem', PATHINDATATRANSFEROBJECT: 'unitSystem'}
  ];
};

export class SubDtoMapperUnitSystems {
  static mapToUnitSystemDocument(unitSystemDocumentDo: UnitSystemDocument): Dto {
    const tempUnitSystem = {
      document: {
        id: unitSystemDocumentDo.document.id,
        name: unitSystemDocumentDo.document.name,
        deleted: unitSystemDocumentDo.document.deleted,
        userList: unitSystemDocumentDo.document.userList,
        creator: unitSystemDocumentDo.document.creator,
        description: unitSystemDocumentDo.document.description,
        version: unitSystemDocumentDo.document['version']
      },
      unitSystem: convertObjectIntoArrayOfKeyValues(unitSystemDocumentDo.unitSystem)
    };

    return new DtoAssemblyDirector(
      'POST_UNIT_SYSTEM',
      CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_UNIT_SYSTEM.config,
      postUnitSystemDtoContract(tempUnitSystem)
    )
      .assembleDto()
      .collect();
  }
}
