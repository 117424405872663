import {DtoContract} from '../../contracts';
import {GAS_COMPOSITION, GAS_COMPOSITION_DOCUMENT} from '../../base-definitions/gas-composition';

// TODO: This is the result of SIZING -> Rename and Move into AppSizing (or similar named folder and files)
export const REQ_POST_APP_GASDATA_RESULT = {
  config: [
    {KEY: 'min.StandardConditions.Pressure', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.StandardConditions.Temperature', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.Pressure', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.ActualConditions.Temperature', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Flow.Actual', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Flow.Standard', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Flow.Mass', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Flow.Energy', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Meter.NominalDiameter', TYPE: 'DTOQUANTITY'},
    {KEY: 'min.Meter.Schedule', TYPE: 'STRING'},
    {KEY: 'min.Meter.InnerDiameter', TYPE: 'DTOQUANTITY'},

    {KEY: 'max.StandardConditions.Pressure', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.StandardConditions.Temperature', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.Pressure', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.ActualConditions.Temperature', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Flow.Actual', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Flow.Standard', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Flow.Mass', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Flow.Energy', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Meter.NominalDiameter', TYPE: 'DTOQUANTITY'},
    {KEY: 'max.Meter.Schedule', TYPE: 'STRING'},
    {KEY: 'max.Meter.InnerDiameter', TYPE: 'DTOQUANTITY'},

    {KEY: 'measuredFlowValue', TYPE: 'STRING'},
    {KEY: 'referenceCondition.key', TYPE: 'STRING'},
    {KEY: 'referenceCondition.pressure', TYPE: 'NUMBER'},
    {KEY: 'referenceCondition.temperatureA', TYPE: 'NUMBER'},
    {KEY: 'referenceCondition.temperatureB', TYPE: 'NUMBER'},
    {KEY: 'combinationIndexMin', TYPE: 'NUMBER'},
    {KEY: 'combinationIndexMax', TYPE: 'NUMBER'},
    {KEY: 'gasComposition', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'calcMode', TYPE: 'STRING'}
  ] as DtoContract[]
};
export const REQ_POST_GAS = {
  config: [...GAS_COMPOSITION_DOCUMENT, ...GAS_COMPOSITION] as DtoContract[]
};

export const REQ_GET_GAS = {
  config: [{}] as DtoContract[]
};
