import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FieldType, FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {RangeSliderEvent} from '@ui/ui-components/range-slider/range-slider.component';
import {isNullableRangeSliderValues, NullableRangeSliderValues, RANGE_SLIDER_VALUES} from '@shared/models/range-slider-values';
import {getFormControl} from '@shared/utility/get-form-control';
import {unitInput} from '../formly-builder';
import {
  getBaseUnitSystemValue,
  getDisplayedUnitSystemValue,
  isQuantity,
  UnitSystemDocument
} from '@state/state-service/do-mapper/model/unit/unit-system-document';
import {StateService} from '@state/state-service/state.service';
import {Quantity} from '@state/state-service/do-mapper/model/unit/quantity';
import {InputReduxAction} from '@state/actions/actions-input';

interface RangeSlider {
  min: UntypedFormControl;
  max: UntypedFormControl;
}

@Component({
  selector: 'da-form-range-slider-formly',
  templateUrl: './form-range-slider.component.html',
  styleUrls: ['./form-range-slider.component.scss']
})
export class FormRangeSliderComponent extends FieldType implements OnInit, OnDestroy {
  project: any;
  values: number[] = [];
  label?: string;
  dialogFormFields$?: BehaviorSubject<FormlyFieldConfig[]> = new BehaviorSubject<FormlyFieldConfig[]>([]);
  minLabel?: string; // initial min label or precession edit value
  maxLabel?: string; // initial max label/ precession edit value
  minIdx?: number; // initial min slider idx
  maxIdx?: number; // initial max idx
  unit?: string;
  precisionDialog = {
    open: false,
    openDialog: () => {
      this.dialogFormFields$.next(this.initDialogFormFields());
      this.precisionDialog.open = true;
    },
    disabled: (input: FormlyFieldConfig[]) => {
      if (this.isMaxSmallerMin(this.form.controls)) {
        this.form.setErrors({maxSmallerMin: this.translateService.instant('validation.error.max.smaller.min')});
        return true;
      }

      return this.isCurrentInputErroneous(input);
    },
    ok: () => {
      this.updateState();
      this.precisionDialog.open = false;
    },
    cancel: () => {
      this.dialogFormFields$.next(this.initDialogFormFields());
      this.precisionDialog.open = false;
    }
  };

  private updateState() {
    Object.entries(this.form.getRawValue()).forEach(entry => {
      const quantity = entry[1] as Quantity<any>;

      const inputPrecision: InputReduxAction = new InputReduxAction('INPUT_PRECISION', {
        control: entry[0],
        value: {
          value: quantity.value ?? 0,
          unit: quantity.unit,
          description: quantity?.description || ''
        }
      });

      this.stateService.dispatch(inputPrecision);
    });

    const inputAction: InputReduxAction = new InputReduxAction('INPUT_CHANGED', {});

    this.stateService.dispatch(inputAction);
  }

  private slider?: RangeSlider;
  private subscriptions: Subscription[] = [];
  private minKeys?: string[];
  private maxKeys?: string[];

  constructor(private stateService: StateService, private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());

    this.project = this.model;
    this.label = this.field.props?.label;
    this.minKeys = FormRangeSliderComponent.getKeys(this.field.props?.keyMin);
    this.maxKeys = FormRangeSliderComponent.getKeys(this.field.props?.keyMax);
    this.slider = this.initControls(this.minKeys, this.maxKeys);

    this.values = this.initValues();
    this.initMinMaxLabels();
    this.initUnitLabel();
    this.initMinIdx();
    this.initMaxIdx();

    this.dialogFormFields$.next(this.initDialogFormFields());

    this.initTranslationSubscription(this.subscriptions);
  }

  sliderChanged(rangeSliderEvent: RangeSliderEvent) {
    const minValue = this.getRangeSliderConfig().values[rangeSliderEvent.minIdx];
    const maxValue = this.getRangeSliderConfig().values[rangeSliderEvent.maxIdx];
    if (rangeSliderEvent.minChanged) {
      this.minValueChanged(minValue);
    }
    if (rangeSliderEvent.maxChanged) {
      this.maxValueChanged(maxValue);
    }
  }

  private initControls(minKeys: string[], maxKeys: string[]): RangeSlider {
    const getControl = getFormControl(this.form);

    const rangeSliderControls = {max: getControl(maxKeys), min: getControl(minKeys)};

    rangeSliderControls.min.setValue(this.field.props?.quantity.min);
    rangeSliderControls.max.setValue(this.field.props?.quantity.max);

    return rangeSliderControls;
  }

  private initTranslationSubscription(subs: Subscription[]) {
    subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.initMinMaxLabels();
        this.initMinIdx();
        this.initMaxIdx();
        this.initUnitLabel();
        this.initValues();
      })
    );
  }

  private initDialogFormFields() {
    return [
      unitInput({
        key: this.field.props?.keyMin,
        className: 'col-1-to-4',
        hide: () => false,
        emitOnChange: () => false,
        limitMin: Number(this.values[0])
      }),
      unitInput({
        key: this.field.props?.keyMax,
        className: 'col-1-to-4',
        hide: () => false,
        emitOnChange: () => false,
        limitMax: Number(this.values[this.values.length - 1])
      })
    ];
  }

  private initValues() {
    return this.getRangeSliderConfig().values;
  }

  private getRangeSliderConfig(): NullableRangeSliderValues {
    const quantity = this.field.props?.quantity.min;
    if (isQuantity(quantity)) {
      const {unit} = quantity;
      const conversion = this.project['displayUnitSystem'].unitSystem[unit].conversion;
      // TODO @Arnold: chain of types is completely broken here
      const rangeSliderValues = RANGE_SLIDER_VALUES[unit][conversion];
      if (isNullableRangeSliderValues(rangeSliderValues)) {
        return rangeSliderValues;
      } else {
        throw new Error(`${rangeSliderValues} is not a NullableRangeSliderValues`);
      }
    } else {
      return {min: 0, max: 1, values: [0, 1]};
    }
  }

  private initMinMaxLabels() {
    this.maxLabel = this.initialMaxValue();
    this.minLabel = this.initialMinValue();
  }

  private initialMinValue() {
    return FormRangeSliderComponent.convert(this.project['displayUnitSystem'], this.slider?.min.value);
  }

  private initialMaxValue() {
    return FormRangeSliderComponent.convert(this.project['displayUnitSystem'], this.slider?.max.value);
  }

  private initUnitLabel() {
    const unit = this.field.props?.quantity.min?.unit;
    if (unit) {
      const conversion = this.project['displayUnitSystem'].unitSystem[unit].conversion;
      this.unit = this.translateService.instant(conversion);
    } else {
      this.unit = '';
    }
  }

  private initMinIdx() {
    this.minIdx = this.findIdx(Number(this.initialMinValue()));
  }

  private initMaxIdx() {
    this.maxIdx = this.findIdx(Number(this.initialMaxValue()));
  }

  private findIdx(val: number) {
    const values = this.getRangeSliderConfig().values;

    for (let i = 0; i < this.getRangeSliderConfig().values.length; i++) {
      if (val <= values[i]) {
        return i;
      }
    }

    return values.length - 1;
  }

  private minValueChanged(value: number) {
    const quantity = this.field.props?.quantity.min as Quantity<any>;

    const nextValue = Number(value);
    const nextQuantity = getBaseUnitSystemValue({
      value: nextValue,
      displayUnitSystem: this.project.displayUnitSystem.unitSystem,
      quant: quantity
    });
    this.slider!.min.setValue(nextQuantity);
    this.slider!.min.markAsDirty();
  }

  private maxValueChanged(value: number) {
    const quantity = this.field.props?.quantity.max as Quantity<any>;

    const nextValue = Number(value);
    const nextQuantity = getBaseUnitSystemValue({
      value: nextValue,
      displayUnitSystem: this.project.displayUnitSystem.unitSystem,
      quant: quantity
    });
    this.slider!.max.setValue(nextQuantity);
    this.slider!.max.markAsDirty();
  }

  private static convert = (unitSystemDocumentDo: UnitSystemDocument, quantity: Quantity<any>) => {
    const {unitSystem} = unitSystemDocumentDo;
    return getDisplayedUnitSystemValue(unitSystem)(quantity, 0);
  };

  private static getKeys = (keyMin: string): string[] => keyMin.split('.');

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  private isMaxSmallerMin(controls) {
    const values = Object.values(controls).map(c => c?.['value']?.value);
    return values?.[0] > values?.[1];
  }

  private isCurrentInputErroneous(inputs: FormlyFieldConfig[]) {
    return this.form.invalid;
  }

  protected readonly UntypedFormControl = UntypedFormControl;
  protected readonly UntypedFormGroup = UntypedFormGroup;
}
