import {DomainContract} from '@flowtwin/communication';

export const CONTRACT_POST_ONE_GAS = (domainObject: any): DomainContract[] => {
  return [
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'document.id', PATHINDATATRANSFEROBJECT: 'id'},
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'document.name', PATHINDATATRANSFEROBJECT: 'name'},
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'document.description', PATHINDATATRANSFEROBJECT: 'description'},
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'document.deleted', PATHINDATATRANSFEROBJECT: 'deleted'},
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'document.creator', PATHINDATATRANSFEROBJECT: 'creator'},
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'document.userList', PATHINDATATRANSFEROBJECT: 'userList'},
    {DOMAINOBJECT: domainObject, PATHINDOMAINOBJECT: 'gasComposition', PATHINDATATRANSFEROBJECT: 'gasComposition'}
  ];
};
