import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PageComponent} from './page/page.component';
import {SideNavComponent} from './sidenav/sidenav.component';
import {SimpleLayoutComponent} from './simple-layout/simple-layout.component';
import {SubLayoutComponent} from './sub-layout/sub-layout.component';
import {MaterialModule} from '@core/material/material.module';
import {UIComponentsModule} from '@ui/ui-components/ui-components.module';
import {DialogModule} from '@ui/dialog/dialog.module';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {UserMenuComponent} from './user-info/user-menu.component';
import {SharedModule} from '@shared/shared.module';
import {ReleaseNotesModule} from '@shared/release-notes/release-notes/release-notes.module';

@NgModule({
  declarations: [PageComponent, SideNavComponent, SimpleLayoutComponent, SubLayoutComponent, UserMenuComponent],
  imports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    UIComponentsModule,
    DialogModule,
    TranslateModule,
    RouterModule,
    SharedModule,
    ReleaseNotesModule
  ],
  exports: [PageComponent, SideNavComponent, SimpleLayoutComponent, SubLayoutComponent, UserMenuComponent]
})
export class SickLayoutModule {}
