import {DtoContract} from '../../contracts';

export const RES_GET_METADATA = {
  config: [
    {KEY: 'segments', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'measuredFlowValues', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    // {KEY: 'gasTypes', TYPE: 'ARRAY<ENTITY>', ARRAY: ''}, // Should not be required, since gas comps are uniqly stored in DB
    {KEY: 'uncertainties', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'installationTypes', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'pathLayouts', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'referenceConditions', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'sensorTypes', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'sensorGasSelectionMethods', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'explosionGroups', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'flowConditions', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'flowConditioners', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'flowRangeOptions', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'nominalSizes', TYPE: 'ARRAY<ENTITY>', ARRAY: 'NUMBER'},
    {KEY: 'nozzleDistances', TYPE: 'ARRAY<ENTITY>', ARRAY: 'NUMBER'},
    {KEY: 'pressureClasses', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'productFamilies', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'productTypes', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'uncertaintySetup.deviceUncertaintySetups', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.configuration.biasNozzleDistance', TYPE: 'NUMBER'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.configuration.useCustomPathAngle', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.configuration.customPathAngle', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.configuration.pathCount', TYPE: 'STRING'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.configuration.nominalDiameter', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.configuration.unpairedProbesTiming', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.postprocessing.useMachUnit', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.postprocessing.envelope', TYPE: 'BOOLEAN'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.parameters.vogTheta', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.parameters.agcUpperLimit', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.parameters.isentropicComponentKappa', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uThickness', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uU', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4A_uU', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.parameters.valueM', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uuAlpha', TYPE: 'DTOQUANTITY'},
    {KEY: 'uncertaintySetup.extendedUncertaintySetup.parameters.m4A_uThickness', TYPE: 'DTOQUANTITY'},
    {KEY: 'schedules', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'}
  ] as DtoContract[]
};
