import {FlowTwinProject} from './project-flow-twin';
import {IMPERIAL_UNIT_SYSTEM_DOC} from '../unit/unit-system-document';
import {DEFAULT_GAS_COMPOSITION_UUID, DEFAULT_GASES_DO} from '@state/state-service/do-mapper/model/gas/default-gas-data';
import {Quantity} from '@state/state-service/do-mapper/model/unit/quantity';

export function createDefaultFlowTwinProject(initial: Partial<FlowTwinProject> = {}): FlowTwinProject {
  return {
    document: {
      id: '',
      projectName: 'default name',
      description: 'default description',
      tag: 'default tag',
      reference: 'default ref'
    },
    meta: {
      userList: [],
      deleted: false,
      created: new Date().toISOString(),
      crm: '',
      lastSaved: {
        frontendVersion: '?',
        middleWareVersion: '?',
        backendVersion: '?',
        savedBy: '?',
        lastSaved: new Date()
      }
    },
    custom: {
      displayUnitSystem: IMPERIAL_UNIT_SYSTEM_DOC,
      gasCompositionDocs: [DEFAULT_GASES_DO[0]]
    },
    appProject: {
      unitSystemId: '',
      unitSystemSpecificTemp: null
    },
    appSizing: {
      state: 'init',
      metadata: {
        deviceOptions: null,
        devices: null,
        data: null,
        filter: null
      },
      input: null,
      output: null
    },
    appUncertainty: {
      state: 'init',
      metadata: null,
      input: null,
      output: null
    },
    appTransducer: {
      state: 'init',
      metadata: null,
      input: null,
      output: null
    },
    ...initial
  };
}

// TODO: Stolen from AWS packages/aws/src/ctrl/devicesearch/app-metadata.ts, because it once were included into the return of createDefaultFlowTwinProject
export const FLARE_DEFAULT_VELOCITY_VECTOR: Array<Quantity<'Speed'>> = [
  {value: 0.03, unit: 'Speed'},
  {value: 0.1, unit: 'Speed'},
  {value: 0.3, unit: 'Speed'},
  {value: 1.0, unit: 'Speed'},
  {value: 3.0, unit: 'Speed'},
  {value: 10.0, unit: 'Speed'}
];
