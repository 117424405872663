import {Device_DTO} from '../../dto-mapper/model/device/device-dto';
import {Device} from '../model/device/properties';

export class DoMapperDevice {
  static mapToDeviceDO(devices: Device_DTO[]): Device[] {
    return devices.map(dev => {
      return {
        productFamily: dev.productFamily,
        productType: dev.productType,
        ambientTemperature: {
          max: dev.ambientTemperature.max,
          min: dev.ambientTemperature.min
        },
        flowConditions: dev.flowConditions,
        flowPressure: {
          max: dev.flowPressure.max,
          min: dev.flowPressure.min,
          option: dev.flowPressure?.option
        },
        flowRange: {
          max: dev.flowRange.max,
          min: dev.flowRange.min,
          option: dev.flowRange?.option
        },
        vog: dev.vog,
        segment: dev.segment,
        uncertainty: dev.uncertainty,
        installationType: dev.installationType,
        flowTemperature: dev.flowTemperature,
        projectGasData: dev.projectGasData,
        supported: dev.supported,
        dimensions: dev.dimensions,
        schedule: dev.dimensions.schedule
      };
    });
  }
}
