import {DtoContract} from '../../contracts';
import {UNIT_SYSTEM} from '../../base-definitions/unit-system';

export const REQ_POST_CREATE_PROJECT = {
  config: [
    {KEY: 'name', TYPE: 'STRING'},
    {KEY: 'description', TYPE: 'STRING'},
    {KEY: 'reference', TYPE: 'STRING'},
    {KEY: 'unitSystemId', TYPE: 'STRING'},
    {KEY: 'feVersion', TYPE: 'STRING'}
  ] as DtoContract[]
};
export const REQ_POST_PROJECT = {
  config: [
    {KEY: 'name', TYPE: 'STRING'},
    {KEY: 'description', TYPE: 'STRING'},
    {KEY: 'reference', TYPE: 'STRING'},
    {KEY: 'unitSystemId', TYPE: 'STRING'},
    {KEY: 'feVersion', TYPE: 'STRING'},
    {KEY: 'deleted', TYPE: 'BOOLEAN'},
    {KEY: 'userList', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'gasCompositions', TYPE: 'ARRAY<ARRAY<ENTITY>>', ARRAY: 'DTOKEYVALUEITEM'},
    ...UNIT_SYSTEM
  ] as DtoContract[]
};

export const REQ_SHARE_PROJECT = {
  config: [{KEY: 'userList', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'}] as DtoContract[]
};
