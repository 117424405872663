import {DtoKeyValueItem} from '../dto-assembly-line/model/dto-key-value-item';

export function convertObjectIntoArrayOfKeyValues(flatObject: any): DtoKeyValueItem[] {
  try {
    const entries = Object.entries(flatObject);

    return entries.map(e => {
      return new DtoKeyValueItem(e[0], e[1]);
    });
  } catch (e) {
    console.error(e);
    return [];
  }
}

export function convertKeyValueArrayIntoObject<T>(keyValueArray: DtoKeyValueItem[]): T {
  try {
    const obj = {};

    keyValueArray.forEach(e => {
      obj[e.key] = e.value;
    });

    return obj as T;
  } catch (e) {
    throw new Error(e);
  }
}
