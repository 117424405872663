import {ArrayElementTypeAnnotation, DtoPrimitive, Item} from '../../dto-assembly-line/model/dto';
import {getLibraryKnownType} from '../validation/get-library-known-type';
import {DtoDoPropCollectionMap} from '../do-assembly-line';
import _ from 'lodash';
import {validateContentWithGivenType} from '../validation/validate-content-with-given-type';

export function assembleDomainObjectTransposedArray(dtoItem: Item, dp: DtoDoPropCollectionMap, domainObject: any) {
  const resultArray = [];

  const values = dtoItem.value as DtoPrimitive[];

  if (values.length === 0) {
    throw new Error('Your top level array cannot be empty.');
  }

  values.forEach((value, f) => {
    const rawType = dtoItem.type.match('(?<=<).+?(?=>)')[0] as ArrayElementTypeAnnotation;

    const obj = dp.doKey.split('.').reduceRight(
      (o, x) => ({[x]: o}),
      ((value: any) => {
        if (!(value instanceof Array)) {
          validateContentWithGivenType(rawType, value);

          return getLibraryKnownType(rawType, dtoItem.value[f]);
        } else {
          return value.map(v => {
            validateContentWithGivenType(rawType, v);

            return getLibraryKnownType(rawType, v);
          });
        }
      })(value)
    );

    resultArray.push(obj);
  });

  domainObject = _.merge(domainObject, resultArray);
}
