import {HttpClient} from '@angular/common/http';
import {OkResponse} from '../../state-service/do-mapper/model/communication/commands';
import {EndpointReferenceContext} from './endpoint-reference-context';
import {Dto} from '@flowtwin/communication';

export async function callSavingEndpoint(
  http: HttpClient,
  projectId: string,
  tagId: string,
  ctx: EndpointReferenceContext,
  data: Dto,
  direction: string,
  url: any
): Promise<OkResponse<any>> {
  let constructedSavingUrl;

  if (direction === null || direction === undefined) {
    constructedSavingUrl = `${url}/${projectId}/${ctx.urlSuffix}`;
  } else {
    constructedSavingUrl = `${url}/${projectId}/tags/${tagId}/${ctx.urlSuffix}/${direction}`;
  }

  return await http.post<OkResponse<any>>(constructedSavingUrl, data).toPromise();
}
