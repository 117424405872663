//
// Example from
// https://stackblitz.com/edit/angular-k8dzcs?file=src%2Fapp%2Ftranslate.extension.ts
//
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {FORMLY_CONFIG} from '@ngx-formly/core';

export class TranslateExtension {
  constructor(private translate: TranslateService) {}
  prePopulate(field: FormlyFieldConfig) {
    const to = field.templateOptions || {};

    const shouldTranslate = to.label || to.placeholder;

    if (!shouldTranslate || to._translated) {
      return;
    }

    to._translated = true;
    field.expressionProperties = {
      ...(field.expressionProperties || {}),
      'templateOptions.label': to.label ? this.translate.stream(to.label) : '',
      'templateOptions.placeholder': to.placeholder ? this.translate.stream(to.placeholder) : ''
    };
  }
}

export function registerTranslateExtension(translate: TranslateService) {
  return {
    extensions: [
      {
        name: 'translate',
        extension: new TranslateExtension(translate)
      }
    ]
  };
}

export const FormlyWithTranslationProvider = {
  provide: FORMLY_CONFIG,
  multi: true,
  useFactory: registerTranslateExtension,
  deps: [TranslateService]
};
