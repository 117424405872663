import {DtoContract} from '../../contracts';

export const RES_POST_CREATE_PROJECT = {
  config: [
    {KEY: 'document.id', TYPE: 'STRING'},
    {KEY: 'document.projectName', TYPE: 'STRING'},
    {KEY: 'document.description', TYPE: 'STRING'},
    {KEY: 'document.reference', TYPE: 'STRING'},
    {KEY: 'meta.userList', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'meta.deleted', TYPE: 'BOOLEAN'},
    {KEY: 'meta.created', TYPE: 'DTODATE'},
    {KEY: 'meta.frontendVersion', TYPE: 'STRING'},
    {KEY: 'meta.backendVersion', TYPE: 'STRING'},
    {KEY: 'meta.awsVersion', TYPE: 'STRING'},
    {KEY: 'meta.versionFlag', TYPE: 'STRING'},
    {KEY: 'meta.lastSaved', TYPE: 'DTODATE'},
    {KEY: 'meta.savedBy', TYPE: 'STRING'},
    {KEY: 'custom.unitSystem.name', TYPE: 'STRING'},
    {KEY: 'custom.unitSystem.id', TYPE: 'STRING'},
    {KEY: 'custom.unitSystem.description', TYPE: 'STRING'},
    {KEY: 'custom.unitSystem', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'custom.gasCompositions', TYPE: 'ARRAY<ARRAY<ENTITY>>', ARRAY: 'DTOKEYVALUEITEM'}
  ] as DtoContract[]
};
export const RES_GET_PROJECT = {
  config: [
    {KEY: 'document.id', TYPE: 'STRING'},
    {KEY: 'document.projectName', TYPE: 'STRING'},
    {KEY: 'document.description', TYPE: 'STRING'},
    {KEY: 'document.reference', TYPE: 'STRING'},
    {KEY: 'meta.userList', TYPE: 'ARRAY<ENTITY>', ARRAY: 'STRING'},
    {KEY: 'meta.deleted', TYPE: 'BOOLEAN'},
    {KEY: 'meta.created', TYPE: 'DTODATE'},
    {KEY: 'meta.frontendVersion', TYPE: 'STRING'},
    {KEY: 'meta.backendVersion', TYPE: 'STRING'},
    {KEY: 'meta.awsVersion', TYPE: 'STRING'},
    {KEY: 'meta.versionFlag', TYPE: 'STRING'},
    {KEY: 'meta.lastSaved', TYPE: 'DTODATE'},
    {KEY: 'meta.savedBy', TYPE: 'STRING'},
    {KEY: 'custom.unitSystem.name', TYPE: 'STRING'},
    {KEY: 'custom.unitSystem.id', TYPE: 'STRING'},
    {KEY: 'custom.unitSystem.description', TYPE: 'STRING'},
    {KEY: 'custom.unitSystem', TYPE: 'ARRAY<ENTITY>', ARRAY: 'DTOKEYVALUEITEM'},
    {KEY: 'custom.gasCompositions', TYPE: 'ARRAY<ARRAY<ENTITY>>', ARRAY: 'DTOKEYVALUEITEM'}
  ] as DtoContract[]
};

export const RES_GET_PROJECTS = {
  config: [
    {KEY: 'document.id', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'document.projectName', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'document.description', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'document.tag', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'document.reference', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'meta.userList', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'meta.deleted', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'BOOLEAN'},
    {KEY: 'meta.created', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'},
    {KEY: 'meta.versionFlag', TYPE: 'ARRAY<TRANSPOSED>', ARRAY: 'STRING'}
  ] as DtoContract[]
};
