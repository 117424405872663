import {reduce, isObject, isEqual, omit, keys} from 'lodash';

export const getDifferenceDeep = (source, other) => {
  return reduce(
    source,
    (result, value, key) => {
      if (isObject(value) && isObject(other[key])) {
        result[key] = getDifferenceDeep(value, other[key]);
      } else if (!isEqual(value, other[key])) {
        result[key] = other[key];
      }
      return result;
    },
    omit(other, keys(source))
  );
};

export const removeEmptyObjects = o => {
  for (const k in o) {
    if (!o[k] || typeof o[k] !== 'object') {
      continue;
    }

    removeEmptyObjects(o[k]);
    if (Object.keys(o[k]).length === 0) {
      delete o[k];
    }
  }
  return o;
};
