import {ReduxAction} from './actions-abstract';
import {UnitSystemDocument} from '../state-service/do-mapper/model/unit/unit-system-document';
import {User} from '@state/state-service/do-mapper/model/communication/user';

export type UnitSystemActions =
  | 'UNIT_SYSTEMS_GET_ALL_LOADING'
  | 'UNIT_SYSTEMS_GET_ALL_SUCCESS'
  | 'UNIT_SYSTEMS_GET_ALL_ERROR'
  | 'UNIT_SYSTEMS_SAVE_ONE_LOADING'
  | 'UNIT_SYSTEMS_SAVE_ONE_SUCCESS'
  | 'UNIT_SYSTEMS_SAVE_ONE_ERROR'
  | 'UNIT_SYSTEMS_DELETE_ONE_LOADING'
  | 'UNIT_SYSTEMS_DELETE_ONE_SUCCESS'
  | 'UNIT_SYSTEMS_DELETE_ONE_ERROR';

// tslint:disable-next-line:no-empty-interface
export interface ApiPayloadGeneric {}

export interface ApiPayloadGetAllSuccess {
  unitSystems: UnitSystemDocument[];
}

export interface ApiPayloadSaveOneLoad {
  unitSystem: UnitSystemDocument;
}

export interface ApiPayloadSaveOneSuccess {
  unitSystem: UnitSystemDocument;
}

export interface ApiPayloadDeleteOneLoad {
  unitSystem: UnitSystemDocument;
  allUsers: User[];
}

export interface ApiPayloadDeleteOneSuccess {
  unitSystem: UnitSystemDocument;
}

export type UnitSystemPayloads =
  | ApiPayloadGeneric
  | ApiPayloadGetAllSuccess
  | ApiPayloadSaveOneLoad
  | ApiPayloadSaveOneSuccess
  | ApiPayloadDeleteOneLoad
  | ApiPayloadDeleteOneSuccess;

export class UnitSystemReduxAction extends ReduxAction {
  type: UnitSystemActions;
  payload: UnitSystemPayloads;

  constructor(type: UnitSystemActions, payload: UnitSystemPayloads) {
    super(type, null);
    this.payload = payload;
    this.type = type;
  }
}
