import {accessObjectByPath} from '../../util/access-object-by-string-path';
import {DtoDate} from '../model/dto-date';
import {undefinedChecker} from '../util/undefined-checker';

export function assembleDtoDate(assembledObject, elem, payload) {
  try {
    assembledObject = {
      key: elem.KEY,
      type: elem.TYPE,
      value: {
        format: 'YYYY-MM-DDTHH:mm:ss.sssZ',
        value: new Date(accessObjectByPath(elem.DOMAINOBJECT, elem.PATHINDOMAINOBJECT)).toISOString()
      } as DtoDate
    };
  } catch (e) {
    throw new Error(`Your given date string is invalid: ${accessObjectByPath(elem.DOMAINOBJECT, elem.PATHINDOMAINOBJECT)}.`);
  }

  undefinedChecker(payload, assembledObject);
}
