import {GasCompositionDoc} from '../../do-mapper/model/gas/gas-composition-doc';
import {CONTRACTS, convertObjectIntoArrayOfKeyValues, Dto, DtoAssemblyDirector, DtoKeyValueItem} from '@flowtwin/communication';
import {clone} from '@shared/utility/clone';
import {CONTRACT_POST_DEVICE_SEARCH} from '../domain-contracts/device-search';
import {Metadata} from '@state/state-service/do-mapper/model/project/metadata';
import {CONTRACT_COMPUTE_SIZING} from '@state/state-service/dto-mapper/domain-contracts/compute';
import {CONTRACT_POST_APP_SIZING_INPUT, CONTRACT_POST_APP_SIZING_OUTPUT} from '@state/state-service/dto-mapper/domain-contracts/project';
import {AppSizingInput, AppSizingMetadata, AppSizingOutput} from '@state/state-service/do-mapper/model/apps/app-sizing';

export class SubDtoMapperAppSizing {
  // store app Sizing
  static mapToSaveAppSizingInput(appSizingInput: AppSizingInput): Dto | null {
    if (!appSizingInput) {
      return null;
    }

    const domainObjectAppSizing = clone(appSizingInput);

    let devices = [];
    if (domainObjectAppSizing?.selectedDevice) {
      devices = [new DtoKeyValueItem(domainObjectAppSizing?.selectedDevice[0].productType, {...domainObjectAppSizing?.selectedDevice})];
      domainObjectAppSizing.selectedDevice = devices as any;
    } else {
      domainObjectAppSizing.selectedDevice = [];
    }

    return new DtoAssemblyDirector(
      'APP_SELECTION_SIZING_SAVE_INPUT',
      CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_APP_SELECTION_SIZING_INPUT.config,
      CONTRACT_POST_APP_SIZING_INPUT(domainObjectAppSizing)
    )
      .assembleDto()
      .collect();
  }

  static mapToSaveAppSizingOutput(appSizingOutput: AppSizingOutput): Dto | null {
    if (!appSizingOutput) {
      return null;
    }

    if (!appSizingOutput?.ccus || Object.keys(appSizingOutput.ccus).length === 0) {
      appSizingOutput.ccus = {PhaseIndicator: null};
    }

    if (!appSizingOutput?.lvf || Object.keys(appSizingOutput.lvf).length === 0) {
      appSizingOutput.lvf = {GlobalLiquidVolumeFraction: {unit: 'AmountRatio', value: 0}, GlobalPhaseState: 1};
    }

    if (!appSizingOutput?.warnings || Object.keys(appSizingOutput.warnings).length === 0) {
      appSizingOutput.warnings = {
        Fl500CO2FractionExceeded: false,
        Fl500H2TooHigh: false,
        Fl500H2NotSupported: false,
        Fl500MinPressureForMethaneLow: false,
        Fl500SoSOutOfBounds: false
      };
    }

    if (!appSizingOutput?.gasdata.max.Meter.MeterInnerDiameter) {
      appSizingOutput.gasdata.max.Meter.MeterInnerDiameter = {unit: 'Diameter', value: 0};
    }
    if (!appSizingOutput?.gasdata.min.Meter.MeterInnerDiameter) {
      appSizingOutput.gasdata.min.Meter.MeterInnerDiameter = {unit: 'Diameter', value: 0};
    }

    return new DtoAssemblyDirector(
      'APP_SELECTION_SIZING_SAVE_RESULT',
      CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_APP_SELECTION_SIZING_OUTPUT.config,
      CONTRACT_POST_APP_SIZING_OUTPUT(appSizingOutput)
    )
      .assembleDto()
      .collect();
  }

  static mapToCallDeviceSearch(
    appSizingInput: AppSizingInput,
    appSelectionSizingMetadata: AppSizingMetadata,
    gasComposition: GasCompositionDoc
  ): Dto {
    // AppSelectionSizingInput_DTO
    const domainObject = clone(appSizingInput);

    const enrichedDomainObject = {
      ...domainObject,
      ...{
        gasComposition: convertObjectIntoArrayOfKeyValues(gasComposition.gasComposition)
      }
    };

    return new DtoAssemblyDirector(
      'APP_SELECTION_SIZING_REQUEST',
      CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_APP_SELECTION_SIZING_RESULT.config,
      CONTRACT_POST_DEVICE_SEARCH(enrichedDomainObject, appSelectionSizingMetadata)
    )
      .assembleDto()
      .collect();
  }

  // compute sizing
  static mapToComputeSizing(sizingInput: AppSizingInput, metadata: Metadata, gasCompositions: GasCompositionDoc[]): Dto {
    const domainObject = clone(sizingInput);

    const enrichedDomainObject = SubDtoMapperAppSizing.enrichComputeCallInput(domainObject, metadata, gasCompositions);

    enrichedDomainObject.gasComposition = convertObjectIntoArrayOfKeyValues(enrichedDomainObject.gasComposition);

    return new DtoAssemblyDirector(
      'APP_SELECTION_SIZING_REQUEST',
      CONTRACTS.REQ_FE_AWS_DTO_CONTRACTS.REQ_POST_APP_GASDATA_RESULT.config,
      CONTRACT_COMPUTE_SIZING(enrichedDomainObject)
    )
      .assembleDto()
      .collect();
  }

  private static enrichComputeCallInput(domainObject: AppSizingInput, metadata: Metadata, gasCompositions: GasCompositionDoc[]) {
    const enriched = domainObject as any;

    enriched.actualFlowMin = {};
    enriched.actualFlowMax = {};
    enriched.standardFlowMin = {};
    enriched.standardFlowMax = {};
    enriched.massFlowMin = {};
    enriched.massFlowMax = {};
    enriched.energyFlowMin = {};
    enriched.energyFlowMax = {};

    switch (domainObject.measuredFlowValue) {
      case 'OperationalFlow':
        enriched.actualFlowMin = domainObject.processFlowRangeMin;
        enriched.actualFlowMax = domainObject.processFlowRangeMax;
        break;
      case 'NormalizedFlow':
        enriched.standardFlowMin = domainObject.processFlowRangeMin;
        enriched.standardFlowMax = domainObject.processFlowRangeMax;
        break;
      case 'MassFlow':
        enriched.massFlowMin = domainObject.processFlowRangeMin;
        enriched.massFlowMax = domainObject.processFlowRangeMax;
        break;
    }

    enriched.referenceCondition = metadata.referenceConditions?.find(rc => rc.key === enriched.referenceCondition);

    enriched.gasComposition = gasCompositions.find(gc => gc.document.id === domainObject.gasCompositionId)?.gasComposition;

    return enriched;
  }
}
