import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TitleService} from './services/title/title.service';
import {SickLayoutModule} from '@ui/sick-layout/sick-layout.module';
import {AuthHeaderInterceptorProvider} from './interceptors/auth-header.interceptor';
import {LogInterceptorProvider} from './interceptors/traffic-interceptor.service';
import {BadRequestInterceptorProvider} from './interceptors/bad-request.interceptor';
import {ValidationInterceptorProvider} from './interceptors/validation.interceptor';
import {RouterHistoryService} from '@core/services/router/router-history.service';
import {AppModeService} from '@core/service-mode/app-mode.service';
import {AuthInterceptorProvider} from '@core/interceptors/auth.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule, SickLayoutModule],
  providers: [
    TitleService,
    AppModeService,
    {
      provide: APP_INITIALIZER,
      useFactory: (routerHistoryService: RouterHistoryService) => () => routerHistoryService.init(),
      deps: [RouterHistoryService],
      multi: true
    },
    AuthInterceptorProvider,
    AuthHeaderInterceptorProvider,
    LogInterceptorProvider,
    ValidationInterceptorProvider,
    BadRequestInterceptorProvider
  ]
})
export class CoreModule {}
