// however you want to do this
const isobject = function (x) {
  return Object.prototype.toString.call(x) === '[object Object]';
};

export function getNestedKeys(obj, prefix = '') {
  const keys = Object.keys(obj);
  prefix = prefix ? prefix + '.' : '';
  return keys.reduce(function (result, key) {
    if (isobject(obj[key])) {
      result = result.concat(getNestedKeys(obj[key], prefix + key));
    } else {
      result.push(prefix + key);
    }
    return result;
  }, []);
}
