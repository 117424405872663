import {Injectable} from '@angular/core';
import {OkResponse} from '../state-service/do-mapper/model/communication/commands';
import {map} from 'rxjs/operators';
import {environment} from '@env/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {StateService} from '../state-service/state.service';
import {ApiPayloadDeleteOneLoad, ApiPayloadSaveOneLoad, UnitSystemReduxAction} from '../actions/actions-unit-system';
import {ErrorService} from '@state/state-service/error.service';
import {GenericModalPayload} from '@state/actions/actions-modal';
import {Dto} from '@flowtwin/communication';
import {DoMapperUnitSystem} from '@state/state-service/do-mapper/mappers/unit-systems';
import {SubDtoMapperUnitSystems} from '@state/state-service/dto-mapper/sub-mappers/unit-systems';
import {ApiErrorHandleConfig, handleApiError} from '@state/epics/utility/handle-api-error';

const url = environment.url.aws + '/unit-systems';

@Injectable({
  providedIn: 'root'
})
export class UnitSystemEpicService {
  constructor(private http: HttpClient, private errorService: ErrorService) {}

  async getAll(action: UnitSystemReduxAction, stateService: StateService) {
    try {
      await stateService.loggedIn$.toPromise();

      const unitSystems = await this.http
        .get<OkResponse<Dto>>(url)
        .pipe(
          map(res => {
            if (res.data.payload.length === 0) {
              return [];
            }

            return DoMapperUnitSystem.mapToUnitSystems(res.data);
          })
        )
        .toPromise();

      const successAction: UnitSystemReduxAction = new UnitSystemReduxAction('UNIT_SYSTEMS_GET_ALL_SUCCESS', {unitSystems});

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'UNIT_SYSTEMS_GET_ALL_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async postOne(action: UnitSystemReduxAction, stateService: StateService) {
    const unitSystemDo = (action.payload as ApiPayloadSaveOneLoad).unitSystem;

    const unitSystemDTO: Dto = SubDtoMapperUnitSystems.mapToUnitSystemDocument(unitSystemDo);

    try {
      await this.http.post(url + '/' + unitSystemDo.document.id, unitSystemDTO).toPromise();

      const successAction: UnitSystemReduxAction = new UnitSystemReduxAction('UNIT_SYSTEMS_SAVE_ONE_SUCCESS', {unitSystem: unitSystemDo});

      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'UNIT_SYSTEMS_SAVE_ONE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }

  async deleteOne(action: UnitSystemReduxAction, stateService: StateService) {
    try {
      let completeUrl = `${url}/${(action.payload as ApiPayloadDeleteOneLoad).unitSystem.document.id}`;

      if ((action.payload as ApiPayloadDeleteOneLoad).allUsers) {
        completeUrl += '/allUsers';
      }

      await this.http.delete<OkResponse<any>>(completeUrl).toPromise();

      const successAction: UnitSystemReduxAction = new UnitSystemReduxAction('UNIT_SYSTEMS_DELETE_ONE_SUCCESS', {
        unitSystem: (action.payload as ApiPayloadDeleteOneLoad).unitSystem
      });
      stateService.dispatch(successAction);
    } catch (error: any) {
      const errorHandleConfig: ApiErrorHandleConfig = {
        error: error as HttpErrorResponse,
        stateService,
        errorService: this.errorService,
        reduxAction: 'UNIT_SYSTEMS_DELETE_ONE_ERROR',
        reduxPayload: null
      };

      handleApiError(errorHandleConfig);
    }
  }
}
