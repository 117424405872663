import {Component, OnInit} from '@angular/core';
import {FieldArrayType} from '@ngx-formly/core';

@Component({
  selector: 'da-repeat-section',
  template: `
    <div *ngFor="let field of field?.fieldGroup; let i = index" class="row">
      <formly-field class="col-1-to-3" [field]="field"> </formly-field>
      <div class="col-4">
        <button type="button" mat-icon-button (click)="remove(i)" style="width: 40px;">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="row">
      <button type="button" mat-stroked-button class="col-1-to-3" (click)="add()">
        {{ to.addText | translate }}
      </button>
    </div>
  `,
  styleUrls: ['./repeat-section.component.scss']
})
export class RepeatSectionComponent extends FieldArrayType implements OnInit {
  constructor() {
    super();
  }

  className(field: any) {
    return field.fieldGroup[0].className;
  }

  ngOnInit(): void {}
}
