<mat-form-field [id]="id" appearance="outline" style="width: 100%" *ngIf="!hide">
  <mat-label>{{ label | translate }}</mat-label>

  <mat-select *ngIf="translateOptions" [formControl]="formControl">
    <mat-option *ngIf="showNone">{{ showNone | translate }}</mat-option>
    <mat-option *ngFor="let option of dropDownOptions | async" [value]="option.value">
      {{ option.label | translate }}
    </mat-option>
  </mat-select>

  <mat-select *ngIf="!translateOptions" [formControl]="formControl">
    <mat-option *ngIf="showNone" {{ showNone | | translate }}></mat-option>
    <mat-option *ngFor="let option of dropDownOptions | async" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
