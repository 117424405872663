import {DtoDoPropCollectionMap} from '@flowtwin/communication';

export const PROP_COLLECTION_MAP_CALL_DEVICE_SEARCH: DtoDoPropCollectionMap[] = [
  {dtoKey: 'devices', doKey: 'devices'},
  {dtoKey: 'metadata.segments', doKey: 'deviceOptions.segments'},
  {dtoKey: 'metadata.measuredFlowValues', doKey: 'deviceOptions.measuredFlowValues'},
  {dtoKey: 'metadata.uncertainties', doKey: 'deviceOptions.uncertainties'},
  {dtoKey: 'metadata.installationTypes', doKey: 'deviceOptions.installationTypes'},
  {dtoKey: 'metadata.pathLayouts', doKey: 'deviceOptions.pathLayouts'},
  {dtoKey: 'metadata.referenceConditions', doKey: 'deviceOptions.referenceConditions'},
  {dtoKey: 'metadata.sensorTypes', doKey: 'deviceOptions.sensorTypes'},
  {dtoKey: 'metadata.sensorGasSelectionMethod', doKey: 'deviceOptions.sensorGasSelectionMethod'},
  {dtoKey: 'metadata.explosionGroups', doKey: 'deviceOptions.explosionGroups'},
  {dtoKey: 'metadata.flowConditions', doKey: 'deviceOptions.flowConditions'},
  {dtoKey: 'metadata.flowConditioners', doKey: 'deviceOptions.flowConditioners'},
  {dtoKey: 'metadata.flowRangeOptions', doKey: 'deviceOptions.flowRangeOptions'},
  {dtoKey: 'metadata.nominalSizes', doKey: 'deviceOptions.nominalSizes'},
  {dtoKey: 'metadata.nozzleDistances', doKey: 'deviceOptions.nozzleDistances'},
  {dtoKey: 'metadata.pressureClasses', doKey: 'deviceOptions.pressureClasses'},
  {dtoKey: 'metadata.productFamilies', doKey: 'deviceOptions.productFamilies'},
  {dtoKey: 'metadata.productTypes', doKey: 'deviceOptions.productTypes'},
  {dtoKey: 'metadata.schedules', doKey: 'deviceOptions.schedules'},
  {
    dtoKey: 'metadata.uncertaintySetup.deviceUncertaintySetups',
    doKey: 'deviceOptions.uncertaintySetup.deviceUncertaintySetups'
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.biasNozzleDistance',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupConfiguration.biasNozzleDistance           '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.useCustomPathAngle',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupConfiguration.useCustomPathAngle           '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.deviceIsFlowCalibrated',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupConfiguration.deviceIsFlowCalibrated       '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.useCustomZeroPointUncertainty',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupConfiguration.useCustomZeroPointUncertainty'
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.customPathAngle',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupConfiguration.customPathAngle              '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.useUnpairedProbesTiming',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupConfiguration.useUnpairedProbesTiming      '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.pathCount',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupConfiguration.pathCount                    '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.customZeroPointUncertainty',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupConfiguration.customZeroPointUncertainty   '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.nominalDiameter',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupConfiguration.nominalDiameter              '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.configuration.unpairedProbesTiming',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupConfiguration.unpairedProbesTiming         '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.postprocessing.activeSoundCorrelation',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupPostprocessing.activeSoundCorrelation      '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.postprocessing.attachUncorrectedDeviation',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupPostprocessing.attachUncorrectedDeviation  '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.postprocessing.envelope',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupPostprocessing.envelope                    '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.vogTheta',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupParameters.vogTheta                        '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.agcUpperLimit',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupParameters.agcUpperLimit                   '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.isentropicComponentKappa',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupParameters.isentropicComponentKappa        '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uThickness',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupParameters.m4B_uThickness                  '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uU',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupParameters.m4B_uU                          '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4A_uU',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupParameters.m4A_uU                          '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.valueM',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupParameters.valueM                          '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4B_uuAlpha',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupParameters.m4B_uuAlpha                     '
  },
  {
    dtoKey: 'metadata.uncertaintySetup.extendedUncertaintySetup.parameters.m4A_uThickness',
    doKey: 'deviceOptions.uncertaintySetup.extendedUncertaintySetupParameters.m4A_uThickness                  '
  }
];

export const PROP_COLLECTION_MAP_COMPUTE_SIZING: DtoDoPropCollectionMap[] = [
  {dtoKey: 'min.ActualConditions.Pressure', doKey: 'gasdata.min.ActualConditions.Pressure'},
  {dtoKey: 'min.ActualConditions.Temperature', doKey: 'gasdata.min.ActualConditions.Temperature'},
  {dtoKey: 'min.ActualConditions.CO2Emission', doKey: 'gasdata.min.ActualConditions.CO2Emission'},
  {dtoKey: 'min.ActualConditions.CO2EmissionFactor', doKey: 'gasdata.min.ActualConditions.CO2EmissionFactor'},
  {dtoKey: 'min.ActualConditions.Compressibility', doKey: 'gasdata.min.ActualConditions.Compressibility'},
  {dtoKey: 'min.ActualConditions.Density', doKey: 'gasdata.min.ActualConditions.Density'},
  {dtoKey: 'min.ActualConditions.KFactor', doKey: 'gasdata.min.ActualConditions.KFactor'},
  {dtoKey: 'min.ActualConditions.MolarMass', doKey: 'gasdata.min.ActualConditions.MolarMass'},
  {dtoKey: 'min.ActualConditions.Repeatability', doKey: 'gasdata.min.ActualConditions.Repeatability'},
  {dtoKey: 'min.ActualConditions.ReynoldsNumber', doKey: 'gasdata.min.ActualConditions.ReynoldsNumber'},
  {dtoKey: 'min.ActualConditions.SpeedOfSound', doKey: 'gasdata.min.ActualConditions.SpeedOfSound'},
  {dtoKey: 'min.ActualConditions.Velocity', doKey: 'gasdata.min.ActualConditions.Velocity'},
  {dtoKey: 'min.ActualConditions.Viscosity', doKey: 'gasdata.min.ActualConditions.Viscosity'},
  {dtoKey: 'min.ActualConditions.LiquidVolumeFraction', doKey: 'gasdata.min.ActualConditions.LiquidVolumeFraction'},
  {dtoKey: 'min.ActualConditions.PhaseState', doKey: 'gasdata.min.ActualConditions.PhaseState'},

  {dtoKey: 'min.StandardConditions.Pressure', doKey: 'gasdata.min.StandardConditions.Pressure'},
  {dtoKey: 'min.StandardConditions.Temperature', doKey: 'gasdata.min.StandardConditions.Temperature'},
  {dtoKey: 'min.StandardConditions.CO2Emission', doKey: 'gasdata.min.StandardConditions.CO2Emission'},
  {dtoKey: 'min.StandardConditions.CO2EmissionFactor', doKey: 'gasdata.min.StandardConditions.CO2EmissionFactor'},
  {dtoKey: 'min.StandardConditions.Compressibility', doKey: 'gasdata.min.StandardConditions.Compressibility'},
  {dtoKey: 'min.StandardConditions.Density', doKey: 'gasdata.min.StandardConditions.Density'},
  {dtoKey: 'min.StandardConditions.KFactor', doKey: 'gasdata.min.StandardConditions.KFactor'},
  {dtoKey: 'min.StandardConditions.MolarMass', doKey: 'gasdata.min.StandardConditions.MolarMass'},
  {dtoKey: 'min.StandardConditions.Repeatability', doKey: 'gasdata.min.StandardConditions.Repeatability'},
  {dtoKey: 'min.StandardConditions.ReynoldsNumber', doKey: 'gasdata.min.StandardConditions.ReynoldsNumber'},
  {dtoKey: 'min.StandardConditions.SpeedOfSound', doKey: 'gasdata.min.StandardConditions.SpeedOfSound'},
  {dtoKey: 'min.StandardConditions.Velocity', doKey: 'gasdata.min.StandardConditions.Velocity'},
  {dtoKey: 'min.StandardConditions.Viscosity', doKey: 'gasdata.min.StandardConditions.Viscosity'},
  {dtoKey: 'min.StandardConditions.LiquidVolumeFraction', doKey: 'gasdata.min.StandardConditions.LiquidVolumeFraction'},
  {dtoKey: 'min.StandardConditions.PhaseState', doKey: 'gasdata.min.StandardConditions.PhaseState'},

  {dtoKey: 'min.Flow.Actual', doKey: 'gasdata.min.Flow.Actual'},
  {dtoKey: 'min.Flow.Energy', doKey: 'gasdata.min.Flow.Energy'},
  {dtoKey: 'min.Flow.Mass', doKey: 'gasdata.min.Flow.Mass'},
  {dtoKey: 'min.Flow.Standard', doKey: 'gasdata.min.Flow.Standard'},
  {dtoKey: 'min.HeatValue.Mass', doKey: 'gasdata.min.HeatValue.Mass'},
  {dtoKey: 'min.HeatValue.Volume', doKey: 'gasdata.min.HeatValue.Volume'},
  {dtoKey: 'min.Meter.NominalDiameter', doKey: 'gasdata.min.Meter.NominalDiameter'},
  {dtoKey: 'min.Meter.Schedule', doKey: 'gasdata.min.Meter.Schedule'},
  {dtoKey: 'min.Meter.InnerDiameter', doKey: 'gasdata.min.Meter.InnerDiameter'},
  {dtoKey: 'min.Meter.WallThickness', doKey: 'gasdata.min.Meter.WallThickness'},
  {dtoKey: 'min.MolarMass', doKey: 'gasdata.min.MolarMass'},
  {dtoKey: 'min.Velocity.GasVelocity', doKey: 'gasdata.min.Velocity.GasVelocity'},

  {dtoKey: 'max.ActualConditions.Pressure', doKey: 'gasdata.max.ActualConditions.Pressure'},
  {dtoKey: 'max.ActualConditions.Temperature', doKey: 'gasdata.max.ActualConditions.Temperature'},
  {dtoKey: 'max.ActualConditions.CO2Emission', doKey: 'gasdata.max.ActualConditions.CO2Emission'},
  {dtoKey: 'max.ActualConditions.CO2EmissionFactor', doKey: 'gasdata.max.ActualConditions.CO2EmissionFactor'},
  {dtoKey: 'max.ActualConditions.Compressibility', doKey: 'gasdata.max.ActualConditions.Compressibility'},
  {dtoKey: 'max.ActualConditions.Density', doKey: 'gasdata.max.ActualConditions.Density'},
  {dtoKey: 'max.ActualConditions.KFactor', doKey: 'gasdata.max.ActualConditions.KFactor'},
  {dtoKey: 'max.ActualConditions.MolarMass', doKey: 'gasdata.max.ActualConditions.MolarMass'},
  {dtoKey: 'max.ActualConditions.Repeatability', doKey: 'gasdata.max.ActualConditions.Repeatability'},
  {dtoKey: 'max.ActualConditions.ReynoldsNumber', doKey: 'gasdata.max.ActualConditions.ReynoldsNumber'},
  {dtoKey: 'max.ActualConditions.SpeedOfSound', doKey: 'gasdata.max.ActualConditions.SpeedOfSound'},
  {dtoKey: 'max.ActualConditions.Velocity', doKey: 'gasdata.max.ActualConditions.Velocity'},
  {dtoKey: 'max.ActualConditions.Viscosity', doKey: 'gasdata.max.ActualConditions.Viscosity'},
  {dtoKey: 'max.ActualConditions.LiquidVolumeFraction', doKey: 'gasdata.max.ActualConditions.LiquidVolumeFraction'},
  {dtoKey: 'max.ActualConditions.PhaseState', doKey: 'gasdata.max.ActualConditions.PhaseState'},

  {dtoKey: 'max.StandardConditions.Pressure', doKey: 'gasdata.max.StandardConditions.Pressure'},
  {dtoKey: 'max.StandardConditions.Temperature', doKey: 'gasdata.max.StandardConditions.Temperature'},
  {dtoKey: 'max.StandardConditions.CO2Emission', doKey: 'gasdata.max.StandardConditions.CO2Emission'},
  {
    dtoKey: 'max.StandardConditions.CO2EmissionFactor',
    doKey: 'gasdata.max.StandardConditions.CO2EmissionFactor'
  },
  {dtoKey: 'max.StandardConditions.Compressibility', doKey: 'gasdata.max.StandardConditions.Compressibility'},
  {dtoKey: 'max.StandardConditions.Density', doKey: 'gasdata.max.StandardConditions.Density'},
  {dtoKey: 'max.StandardConditions.KFactor', doKey: 'gasdata.max.StandardConditions.KFactor'},
  {dtoKey: 'max.StandardConditions.MolarMass', doKey: 'gasdata.max.StandardConditions.MolarMass'},
  {dtoKey: 'max.StandardConditions.Repeatability', doKey: 'gasdata.max.StandardConditions.Repeatability'},
  {dtoKey: 'max.StandardConditions.ReynoldsNumber', doKey: 'gasdata.max.StandardConditions.ReynoldsNumber'},
  {dtoKey: 'max.StandardConditions.SpeedOfSound', doKey: 'gasdata.max.StandardConditions.SpeedOfSound'},
  {dtoKey: 'max.StandardConditions.Velocity', doKey: 'gasdata.max.StandardConditions.Velocity'},
  {dtoKey: 'max.StandardConditions.Viscosity', doKey: 'gasdata.max.StandardConditions.Viscosity'},
  {dtoKey: 'max.StandardConditions.LiquidVolumeFraction', doKey: 'gasdata.max.StandardConditions.LiquidVolumeFraction'},
  {dtoKey: 'max.StandardConditions.PhaseState', doKey: 'gasdata.max.StandardConditions.PhaseState'},

  {dtoKey: 'max.Flow.Actual', doKey: 'gasdata.max.Flow.Actual'},
  {dtoKey: 'max.Flow.Energy', doKey: 'gasdata.max.Flow.Energy'},
  {dtoKey: 'max.Flow.Mass', doKey: 'gasdata.max.Flow.Mass'},
  {dtoKey: 'max.Flow.Standard', doKey: 'gasdata.max.Flow.Standard'},
  {dtoKey: 'max.HeatValue.Mass', doKey: 'gasdata.max.HeatValue.Mass'},
  {dtoKey: 'max.HeatValue.Volume', doKey: 'gasdata.max.HeatValue.Volume'},
  {dtoKey: 'max.Meter.NominalDiameter', doKey: 'gasdata.max.Meter.NominalDiameter'},
  {dtoKey: 'max.Meter.Schedule', doKey: 'gasdata.max.Meter.Schedule'},
  {dtoKey: 'max.Meter.InnerDiameter', doKey: 'gasdata.max.Meter.InnerDiameter'},
  {dtoKey: 'max.Meter.WallThickness', doKey: 'gasdata.max.Meter.WallThickness'},
  {dtoKey: 'max.MolarMass', doKey: 'gasdata.max.MolarMass'},
  {dtoKey: 'max.Velocity.GasVelocity', doKey: 'gasdata.max.Velocity.GasVelocity'},
  {dtoKey: 'ccus.PhaseNotification', doKey: 'ccus.PhaseIndicator'},
  {dtoKey: 'lvf.GlobalLiquidVolumeFraction', doKey: 'lvf.GlobalLiquidVolumeFraction'},
  {dtoKey: 'lvf.GlobalPhaseState', doKey: 'lvf.GlobalPhaseState'},

  {doKey: 'warnings.Fl500SoSOutOfBounds', dtoKey: 'warnings.Fl500SoSOutOfBounds'},
  {doKey: 'warnings.Fl500H2TooHigh', dtoKey: 'warnings.Fl500H2TooHigh'},
  {doKey: 'warnings.Fl500H2NotSupported', dtoKey: 'warnings.Fl500H2NotSupported'},
  {doKey: 'warnings.Fl500CO2FractionExceeded', dtoKey: 'warnings.Fl500CO2FractionExceeded'},
  {doKey: 'warnings.Fl500MinPressureForMethaneLow', dtoKey: 'warnings.Fl500MinPressureForMethaneLow'}
];

export const PROP_COLLECTION_MAP_APP_SIZING_INPUT: DtoDoPropCollectionMap[] = [
  {dtoKey: 'flowCondition', doKey: 'flowCondition'},
  {dtoKey: 'gasCompositionId', doKey: 'gasCompositionId'},
  {dtoKey: 'installationType', doKey: 'installationType'},
  {dtoKey: 'measuredFlowValue', doKey: 'measuredFlowValue'},
  {dtoKey: 'nominalSize', doKey: 'nominalSize'},
  {dtoKey: 'schedule', doKey: 'schedule'},
  {dtoKey: 'innerDiameter', doKey: 'innerDiameter'},
  {dtoKey: 'processConditionCombination.min', doKey: 'processConditionsMin'},
  {dtoKey: 'processConditionCombination.max', doKey: 'processConditionsMax'},
  {dtoKey: 'processFlowRangeMin', doKey: 'processFlowRangeMin'},
  {dtoKey: 'processFlowRangeMax', doKey: 'processFlowRangeMax'},
  {dtoKey: 'processFlowPressureMin', doKey: 'processFlowPressureMin'},
  {dtoKey: 'processFlowPressureMax', doKey: 'processFlowPressureMax'},
  {dtoKey: 'processFlowTemperatureMin', doKey: 'processFlowTemperatureMin'},
  {dtoKey: 'processFlowTemperatureMax', doKey: 'processFlowTemperatureMax'},
  {dtoKey: 'productFamily', doKey: 'productFamily'},
  {dtoKey: 'productType', doKey: 'productType'},
  {dtoKey: 'referenceCondition', doKey: 'referenceCondition'},
  {dtoKey: 'nozzleDistance', doKey: 'nozzleDistance'},
  {dtoKey: 'fullBore', doKey: 'fullBore'},
  {dtoKey: 'pressureClass', doKey: 'pressureClass'},
  {dtoKey: 'flowConditioner', doKey: 'flowConditioner'},
  {dtoKey: 'flowRangeOption', doKey: 'flowRangeOption'},
  {dtoKey: 'selectedDevice', doKey: 'selectedDevice'}
];

export const PROP_COLLECTION_MAP_APP_SIZING_OUTPUT: DtoDoPropCollectionMap[] = [
  {dtoKey: 'min.ActualConditions.Pressure', doKey: 'gasdata.min.ActualConditions.Pressure'},
  {dtoKey: 'min.ActualConditions.Temperature', doKey: 'gasdata.min.ActualConditions.Temperature'},
  {dtoKey: 'min.ActualConditions.CO2Emission', doKey: 'gasdata.min.ActualConditions.CO2Emission'},
  {dtoKey: 'min.ActualConditions.CO2EmissionFactor', doKey: 'gasdata.min.ActualConditions.CO2EmissionFactor'},
  {dtoKey: 'min.ActualConditions.Compressibility', doKey: 'gasdata.min.ActualConditions.Compressibility'},
  {dtoKey: 'min.ActualConditions.Density', doKey: 'gasdata.min.ActualConditions.Density'},
  {dtoKey: 'min.ActualConditions.KFactor', doKey: 'gasdata.min.ActualConditions.KFactor'},
  {dtoKey: 'min.ActualConditions.MolarMass', doKey: 'gasdata.min.ActualConditions.MolarMass'},
  {dtoKey: 'min.ActualConditions.Repeatability', doKey: 'gasdata.min.ActualConditions.Repeatability'},
  {dtoKey: 'min.ActualConditions.ReynoldsNumber', doKey: 'gasdata.min.ActualConditions.ReynoldsNumber'},
  {dtoKey: 'min.ActualConditions.SpeedOfSound', doKey: 'gasdata.min.ActualConditions.SpeedOfSound'},
  {dtoKey: 'min.ActualConditions.Velocity', doKey: 'gasdata.min.ActualConditions.Velocity'},
  {dtoKey: 'min.ActualConditions.Viscosity', doKey: 'gasdata.min.ActualConditions.Viscosity'},
  {dtoKey: 'min.ActualConditions.LiquidVolumeFraction', doKey: 'gasdata.min.ActualConditions.LiquidVolumeFraction'},
  {dtoKey: 'min.ActualConditions.PhaseState', doKey: 'gasdata.min.ActualConditions.PhaseState'},

  {dtoKey: 'min.StandardConditions.Pressure', doKey: 'gasdata.min.StandardConditions.Pressure'},
  {dtoKey: 'min.StandardConditions.Temperature', doKey: 'gasdata.min.StandardConditions.Temperature'},
  {dtoKey: 'min.StandardConditions.CO2Emission', doKey: 'gasdata.min.StandardConditions.CO2Emission'},
  {dtoKey: 'min.StandardConditions.CO2EmissionFactor', doKey: 'gasdata.min.StandardConditions.CO2EmissionFactor'},
  {dtoKey: 'min.StandardConditions.Compressibility', doKey: 'gasdata.min.StandardConditions.Compressibility'},
  {dtoKey: 'min.StandardConditions.Density', doKey: 'gasdata.min.StandardConditions.Density'},
  {dtoKey: 'min.StandardConditions.KFactor', doKey: 'gasdata.min.StandardConditions.KFactor'},
  {dtoKey: 'min.StandardConditions.MolarMass', doKey: 'gasdata.min.StandardConditions.MolarMass'},
  {dtoKey: 'min.StandardConditions.Repeatability', doKey: 'gasdata.min.StandardConditions.Repeatability'},
  {dtoKey: 'min.StandardConditions.ReynoldsNumber', doKey: 'gasdata.min.StandardConditions.ReynoldsNumber'},
  {dtoKey: 'min.StandardConditions.SpeedOfSound', doKey: 'gasdata.min.StandardConditions.SpeedOfSound'},
  {dtoKey: 'min.StandardConditions.Velocity', doKey: 'gasdata.min.StandardConditions.Velocity'},
  {dtoKey: 'min.StandardConditions.Viscosity', doKey: 'gasdata.min.StandardConditions.Viscosity'},
  {dtoKey: 'min.StandardConditions.LiquidVolumeFraction', doKey: 'gasdata.min.StandardConditions.LiquidVolumeFraction'},
  {dtoKey: 'min.StandardConditions.PhaseState', doKey: 'gasdata.min.StandardConditions.PhaseState'},

  {dtoKey: 'min.Flow.Actual', doKey: 'gasdata.min.Flow.Actual'},
  {dtoKey: 'min.Flow.Energy', doKey: 'gasdata.min.Flow.Energy'},
  {dtoKey: 'min.Flow.Mass', doKey: 'gasdata.min.Flow.Mass'},
  {dtoKey: 'min.Flow.Standard', doKey: 'gasdata.min.Flow.Standard'},
  {dtoKey: 'min.HeatValue.Mass', doKey: 'gasdata.min.HeatValue.Mass'},
  {dtoKey: 'min.HeatValue.Volume', doKey: 'gasdata.min.HeatValue.Volume'},
  {dtoKey: 'min.Meter.NominalDiameter', doKey: 'gasdata.min.Meter.NominalDiameter'},
  {dtoKey: 'min.Meter.Schedule', doKey: 'gasdata.min.Meter.Schedule'},
  {dtoKey: 'min.Meter.InnerDiameter', doKey: 'gasdata.min.Meter.InnerDiameter'},
  {dtoKey: 'min.Meter.WallThickness', doKey: 'gasdata.min.Meter.WallThickness'},
  {dtoKey: 'min.MolarMass', doKey: 'gasdata.min.MolarMass'},
  {dtoKey: 'min.Velocity.GasVelocity', doKey: 'gasdata.min.Velocity.GasVelocity'},
  {dtoKey: 'max.ActualConditions.Pressure', doKey: 'gasdata.max.ActualConditions.Pressure'},
  {dtoKey: 'max.ActualConditions.Temperature', doKey: 'gasdata.max.ActualConditions.Temperature'},
  {dtoKey: 'max.ActualConditions.CO2Emission', doKey: 'gasdata.max.ActualConditions.CO2Emission'},
  {dtoKey: 'max.ActualConditions.CO2EmissionFactor', doKey: 'gasdata.max.ActualConditions.CO2EmissionFactor'},
  {dtoKey: 'max.ActualConditions.Compressibility', doKey: 'gasdata.max.ActualConditions.Compressibility'},
  {dtoKey: 'max.ActualConditions.Density', doKey: 'gasdata.max.ActualConditions.Density'},
  {dtoKey: 'max.ActualConditions.KFactor', doKey: 'gasdata.max.ActualConditions.KFactor'},
  {dtoKey: 'max.ActualConditions.MolarMass', doKey: 'gasdata.max.ActualConditions.MolarMass'},
  {dtoKey: 'max.ActualConditions.Repeatability', doKey: 'gasdata.max.ActualConditions.Repeatability'},
  {dtoKey: 'max.ActualConditions.ReynoldsNumber', doKey: 'gasdata.max.ActualConditions.ReynoldsNumber'},
  {dtoKey: 'max.ActualConditions.SpeedOfSound', doKey: 'gasdata.max.ActualConditions.SpeedOfSound'},
  {dtoKey: 'max.ActualConditions.Velocity', doKey: 'gasdata.max.ActualConditions.Velocity'},
  {dtoKey: 'max.ActualConditions.Viscosity', doKey: 'gasdata.max.ActualConditions.Viscosity'},
  {dtoKey: 'max.ActualConditions.LiquidVolumeFraction', doKey: 'gasdata.max.ActualConditions.LiquidVolumeFraction'},
  {dtoKey: 'max.ActualConditions.PhaseState', doKey: 'gasdata.max.ActualConditions.PhaseState'},

  {dtoKey: 'max.StandardConditions.Pressure', doKey: 'gasdata.max.StandardConditions.Pressure'},
  {dtoKey: 'max.StandardConditions.Temperature', doKey: 'gasdata.max.StandardConditions.Temperature'},
  {dtoKey: 'max.StandardConditions.CO2Emission', doKey: 'gasdata.max.StandardConditions.CO2Emission'},
  {dtoKey: 'max.StandardConditions.CO2EmissionFactor', doKey: 'gasdata.max.StandardConditions.CO2EmissionFactor'},
  {dtoKey: 'max.StandardConditions.Compressibility', doKey: 'gasdata.max.StandardConditions.Compressibility'},
  {dtoKey: 'max.StandardConditions.Density', doKey: 'gasdata.max.StandardConditions.Density'},
  {dtoKey: 'max.StandardConditions.KFactor', doKey: 'gasdata.max.StandardConditions.KFactor'},
  {dtoKey: 'max.StandardConditions.MolarMass', doKey: 'gasdata.max.StandardConditions.MolarMass'},
  {dtoKey: 'max.StandardConditions.Repeatability', doKey: 'gasdata.max.StandardConditions.Repeatability'},
  {dtoKey: 'max.StandardConditions.ReynoldsNumber', doKey: 'gasdata.max.StandardConditions.ReynoldsNumber'},
  {dtoKey: 'max.StandardConditions.SpeedOfSound', doKey: 'gasdata.max.StandardConditions.SpeedOfSound'},
  {dtoKey: 'max.StandardConditions.Velocity', doKey: 'gasdata.max.StandardConditions.Velocity'},
  {dtoKey: 'max.StandardConditions.Viscosity', doKey: 'gasdata.max.StandardConditions.Viscosity'},
  {dtoKey: 'max.StandardConditions.LiquidVolumeFraction', doKey: 'gasdata.max.StandardConditions.LiquidVolumeFraction'},
  {dtoKey: 'max.StandardConditions.PhaseState', doKey: 'gasdata.max.StandardConditions.PhaseState'},

  {dtoKey: 'max.Flow.Actual', doKey: 'gasdata.max.Flow.Actual'},
  {dtoKey: 'max.Flow.Energy', doKey: 'gasdata.max.Flow.Energy'},
  {dtoKey: 'max.Flow.Mass', doKey: 'gasdata.max.Flow.Mass'},
  {dtoKey: 'max.Flow.Standard', doKey: 'gasdata.max.Flow.Standard'},
  {dtoKey: 'max.HeatValue.Mass', doKey: 'gasdata.max.HeatValue.Mass'},
  {dtoKey: 'max.HeatValue.Volume', doKey: 'gasdata.max.HeatValue.Volume'},
  {dtoKey: 'max.Meter.NominalDiameter', doKey: 'gasdata.max.Meter.NominalDiameter'},
  {dtoKey: 'max.Meter.Schedule', doKey: 'gasdata.max.Meter.Schedule'},
  {dtoKey: 'max.Meter.InnerDiameter', doKey: 'gasdata.max.Meter.InnerDiameter'},
  {dtoKey: 'max.Meter.WallThickness', doKey: 'gasdata.max.Meter.WallThickness'},
  {dtoKey: 'max.MolarMass', doKey: 'gasdata.max.MolarMass'},
  {dtoKey: 'max.Velocity.GasVelocity', doKey: 'gasdata.max.Velocity.GasVelocity'},
  {dtoKey: 'lvf.GlobalLiquidVolumeFraction', doKey: 'lvf.GlobalLiquidVolumeFraction'},
  {dtoKey: 'lvf.GlobalPhaseState', doKey: 'lvf.GlobalPhaseState'},

  {dtoKey: 'warnings.Fl500SoSOutOfBounds', doKey: 'warnings.Fl500SoSOutOfBounds'},
  {dtoKey: 'warnings.Fl500H2TooHigh', doKey: 'warnings.Fl500H2TooHigh'},
  {dtoKey: 'warnings.Fl500H2NotSupported', doKey: 'warnings.Fl500H2NotSupported'},
  {dtoKey: 'warnings.Fl500CO2FractionExceeded', doKey: 'warnings.Fl500CO2FractionExceeded'},
  {dtoKey: 'warnings.Fl500MinPressureForMethaneLow', doKey: 'warnings.Fl500MinPressureForMethaneLow'}
];
