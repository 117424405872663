import {DtoDoPropCollectionMap} from '@flowtwin/communication';

export const PROP_COLLECTION_MAP_PROJECTS_GET: DtoDoPropCollectionMap[] = [
  {dtoKey: 'document.id', doKey: 'document.id'},
  {dtoKey: 'document.projectName', doKey: 'document.projectName'},
  {dtoKey: 'document.description', doKey: 'document.description'},
  {dtoKey: 'document.tag', doKey: 'document.tag'},
  {dtoKey: 'document.reference', doKey: 'document.reference'},
  {dtoKey: 'meta.deleted', doKey: 'meta.deleted'},
  {dtoKey: 'meta.userList', doKey: 'meta.userList'},
  {dtoKey: 'meta.created', doKey: 'meta.created'},
  {dtoKey: 'meta.versionFlag', doKey: 'meta.versionFlag'}
];
