import {
  DropdownValue,
  FlowCondition,
  InstallationType,
  MeasuredFlowValue,
  NominalSize,
  NozzleDistance,
  PathLayout,
  ProductFamily,
  ReferenceCondition,
  Segment,
  Uncertainty,
  UncertaintySetup
} from '../device/properties';
import {Quantity} from '@state/state-service/do-mapper/model/unit/quantity';
import {LOADING_STATE} from '@state/model/state.model';
import {GasCompositionDoc} from '@state/state-service/do-mapper/model/gas/gas-composition-doc';
import {AbstractDomainObject} from '@flowtwin/communication';

export interface KeyValueItem {
  key: number | string;
  value: string;
  name?: string;
}

export class Metadata extends AbstractDomainObject {
  state: LOADING_STATE;
  segments: Segment[];
  productFamilies: ProductFamily[];
  productTypes: string[];
  uncertainties: Uncertainty[] | null;
  installationTypes: InstallationType[] | null;
  flowConditions: FlowCondition[] | null;
  pathLayouts: PathLayout[] | null;
  nominalSizes: NominalSize[] | null; // sizes in inch
  nozzleDistances: NozzleDistance[] | null;
  pressureClasses: string[] | null;
  measuredFlowValues: MeasuredFlowValue[] | null;
  flowRangeOptions: string[] | null;
  flowConditioners: string[] | null;
  referenceConditions: ReferenceCondition[] | null;
  processConditionsMin: number[] | null;
  processConditionsMax: number[] | null;
  uncertaintySetup: UncertaintySetup;
  innerDiameter?: Quantity<'Diameter'> | null;
  fullBore?: boolean[];
  gasTypes?: GasCompositionDoc[] | null;
  sensorTypes?: KeyValueItem[] | null;
  sensorGasSelectionMethods?: KeyValueItem[] | null;
  explosionGroups?: KeyValueItem[] | null;
  default?: [] | null;
  schedules: KeyValueItem[] | null;

  constructor() {
    super();
  }
}
