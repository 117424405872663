import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '@state/state-service/state.service';
import {getDisplayedUnitSystemValue, UnitSystemKey} from '@state/state-service/do-mapper/model/unit/unit-system-document';
import {Quantity} from '@state/state-service/do-mapper/model/unit/quantity';

@Injectable({
  providedIn: null
})
export class TranslateQuantityService {
  constructor(private httpClient: HttpClient, private stateService: StateService, private translateService: TranslateService) {}

  public translateQuantity(quantity: Quantity<UnitSystemKey>) {
    const displayUnitSystem = this.stateService.state.basicProject.data.custom.displayUnitSystem;

    const value = Number(getDisplayedUnitSystemValue(displayUnitSystem.unitSystem)(quantity));

    const key = displayUnitSystem.unitSystem?.[quantity?.unit]?.conversion || null;

    return {
      value,
      unit: key ? this.translateService.instant(key) : quantity.unit,
      localString: value + ' ' + key ? this.translateService.instant(key) : quantity.unit
      // localStringRounded: roundedValue.toLocaleString('en-US') + ' ' + unit,
    };
  }
}
